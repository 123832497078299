import React from "react";
import { Link } from "react-router-dom";

const Rac = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Preservation</h3>
          <h5 className="text-center">Residential Aftercare</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">
              Residential /Post Placement Aftercare
            </span>{" "}
            services support children and families reunifying from foster care.
            Aftercare services are available to families 2-3 months
            pre-discharge and 6-9 months post-discharge, and are designed to
            sustain treatment outcomes and prevent placement disruption.
            Services are designed to provide a therapeutic framework supporting
            family living for children and adolescents, and helping to
            reintegrate them into their homes and communities. Services MUST be
            available to families 24 hours a day in the home or other
            environments accessible to the family.
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>
                Child/Family Assessment, including Pre- and Post-Discharge Plan
                or Transition Plan
              </li>
              <li>Case Management</li>
              <li>In-home Behavior Management</li>
              <li>
                Parent Education/Parent Training and/or Behavior Management
              </li>
              <li>Therapy</li>
            </ol>
            Based on the reason for referral goals and objectives identified in
            the discharge or transition plan, service plans may include
            additional supports and services necessary to enhance the
            effectiveness required services. These may include:
            <ul>
              <li>Educational supports</li>
              <li>Parent Training</li>
              <li>Support Groups</li>
              <li>Respite</li>
              <li>Transportation</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Rac;
