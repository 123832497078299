import React, { useEffect, useState, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { DeleteUser } from "../../services/accountservice";
import auth from "../../services/authService";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import {
  getUserById,
  saveProviderUsers,
  getAllUsersByUserId,
  getAllUsersByByProgramId,
} from "../../services/providerUserManagement";
import {
  getUserPrograms,
  getUserProgramsById,
  postUserProgramsById,
} from "../../services/userPrograms";
import ProviderUserModal from "./providerUserModal";
import ModeEditOutlineSharpIcon from "@mui/icons-material/ModeEditOutlineSharp";
import CustomDataTable from "../common/customDataTable";

const UserManagement = () => {
  const [validated, setValidated] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [toDelete, setToDelete] = useState(0);
  const [providers, setProviders] = useState([]);
  const [userProviders, setUserProviders] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [hasProgramError, setHasProgramError] = useState(false);

  const sessionUserId = sessionStorage.getItem("UserId");
  const ProgramId = sessionStorage.getItem("ProgramId");

  const handleCloseConfirm = () => {
    setToDelete(0);
    setShowConfirm(false);
  };

  const handleShowConfirm = (id) => {
    setToDelete(id);
    setShowConfirm(true);
  };

  const handleClose = () => {
    setData({});
    setShow(false);
  };
  const handleShow = (id) => {
    fetchDataById(id);
    setShow(true);
  };

  const handleChangeProgram = (event) => {
    const {
      target: { value },
    } = event;

    var uProvider = [];

    setPrograms(value);

    var len = value.length;
    for (var i = 0; i < len; i++) {
      if (typeof value[i] === "number") {
        uProvider.push({
          programId: value[i],
        });
      }
    }

    setUserProviders(uProvider);
  };

  const handleDelete = async () => {
    await DeleteUser(toDelete);
    fetchData();
    setShowConfirm(false);
  };

  const handleSave = (event) => {
    const form = document.getElementById("form");

    setHasProgramError(false);
    var error = false;

    if (programs.length === 0) {
      error = true;
      setHasProgramError(true);
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      if (!error) {
        saveData();
      }
    }
  };

  const saveData = async () => {
    if (userProviders.length > 0) {
      await postUserProgramsById(data.userName, userProviders);
    }
    await saveProviderUsers(data);
    window.location.reload(false);
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  const fetchDataById = async (id) => {
    const { data } = await getUserById(id);
    setData(data);
    setPrograms(data.programs.map((item) => item.programId));
  };

  const fetchData = useCallback(async () => {
    const user = await auth.getCurrentUser();

    let UsrId = sessionUserId ? sessionUserId : user.UserId;
    setUser(user);

    if (!sessionUserId) {
      const { data: rows } = await getAllUsersByByProgramId(ProgramId);
      setRows(rows);

      const { data: program } = await getUserPrograms();

      setProviders(program);
    } else {
      const { data: rows } = await getAllUsersByUserId(UsrId);
      setRows(rows);

      const { data: program } = await getUserProgramsById(UsrId);

      setProviders(program);
    }
  }, [ProgramId, sessionUserId]);

  const handleChange = (event) => {
    event.preventDefault();

    let name = event.target.name || event.target.id;
    const copyOfObject = { ...data };

    delete copyOfObject[name];

    const updatedValue = {};

    updatedValue[name] = event.target.value;

    const newData = {
      ...copyOfObject,
      ...updatedValue,
    };

    setData(newData);
  };

  useEffect(() => {
    fetchData();
  }, [data, fetchData]);

  const columns = [
    {
      field: "edit",
      headerName: "",
      flex: 0.3,
      renderCell: (params) => (
        <div role="button" onClick={() => handleShow(params.row.id)}>
          <ModeEditOutlineSharpIcon />
          Edit
        </div>
      ),
    },
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.0,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "userName",
      headerName: "UserName / Email",
      flex: 1.0,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1.0,
      renderCell: (params) => params.value || "Missing",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1.0,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "firstLogin",
      headerName: "Has User Logged in?",
      flex: 0.5,
      renderCell: (params) => (params.value ? "No" : "Yes"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (params) =>
        params.row.id !== user.UserId && (
          <Button
            variant="danger"
            onClick={() => handleShowConfirm(params.row.id)}
          >
            Delete
          </Button>
        ),
      align: "center",
      headerAlign: "center",
    },
  ];


  return (
    <div className="col">
      <h3 className="mt-3" title="For Providers">
        User Management
      </h3>
      <div className="mb-4">
        <ProviderUserModal />
      </div>

      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete this user?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Close
          </Button>
          &nbsp;
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Body>
      </Modal>
      <Paper>
        <CustomDataTable
          columns={columns}
          rows={rows}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20, 40]}
        />
      </Paper>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User {data.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="form">
            <Form.Group className="mb-2" controlId="name">
              <Form.Label className="fw-bold">headerName:</Form.Label>
              <Form.Control
                type="text"
                className="form-control p-1 p-md-2 p-xl-3"
                defaultValue={data.name}
                onChange={handleChange}
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-2" controlId="userName">
              <Form.Label className="fw-bold">Email:</Form.Label>
              <Form.Control
                type="text"
                className="form-control p-1 p-md-2 p-xl-3"
                defaultValue={data.userName}
                onChange={handleChange}
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-2" controlId="phoneNumber">
              <Form.Label className="fw-bold">Phone Number:</Form.Label>
              <Form.Control
                type="text"
                className="form-control p-1 p-md-2 p-xl-3"
                defaultValue={data.phoneNumber}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="my-4" controlId="type">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" className="fw-bold">
                  Type
                </InputLabel>

                <Select
                  className="flex flex-container p-1 p-md-2"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-fille"
                  value={data.type || ""}
                  label="type"
                  name="type"
                  onChange={handleChange}
                >
                  <MenuItem
                    className="flex flex-row w-100 p-2"
                    value={"ED"}
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      paddingLeft: "20px",
                      paddingTop: "20",
                      clear: "both",
                    }}
                  >
                    Executive Director
                  </MenuItem>
                  <MenuItem
                    className="flex flex-row w-100 p-2"
                    value={"PC"}
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      paddingLeft: "20px",
                      paddingTop: "20",
                      clear: "both",
                    }}
                  >
                    Program Coordinator
                  </MenuItem>
                  <MenuItem
                    className="flex flex-row w-100 start p-2"
                    value={"Other"}
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      paddingLeft: "20px",
                      paddingTop: "20",
                      clear: "both",
                    }}
                  >
                    Other
                  </MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
            <Form.Group className="my-4" controlId="programId">
              <FormControl fullWidth error={hasProgramError}>
                <InputLabel id="demo-multiple-name-label fw-bold">
                  Program(s)
                </InputLabel>
                <Select
                  className="p-2"
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={programs}
                  onChange={handleChangeProgram}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                >
                  {providers.map((provider, i) => (
                    <MenuItem
                      className="p-1 p-md-2"
                      key={i}
                      value={provider.programId}
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        paddingLeft: "20px",
                        paddingTop: "20",
                        clear: "both",
                      }}
                    >
                      {provider.name}
                    </MenuItem>
                  ))}
                </Select>
                {hasProgramError && (
                  <FormHelperText>
                    You must select at least one program
                  </FormHelperText>
                )}
              </FormControl>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserManagement;
