import React from "react";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";
import {
  getProgramsByIdandFiscalYear,
  getServiceModels,
  insertPrograms,
} from "../../services/getProgramService";
import { getCounties } from "../../services/countiesService";
import { getContactByAgencyId } from "../../services/getContactService";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Form from "../common/form";
import { store } from "../../store";
import { setNavigate } from "../../features/navigateSlice";

class AddProgramForm extends Form {
  state = {
    nextProps: 0,
    data: {
      agencyId: this.props.id,
      programByFiscalYear: [
        {
          averageCaseLoadPerMonth: 0,
          totalCasesPerYear: 0,
          federalFundingRequested: 0,
          localMatchPlanned: 0,
          fiscalCounty: 0,
          totalServices: 0,
          homeCounty: 0,
          fiscalYear: this.props.fy,
        },
      ],
    }, //program
    data2: {},
    counties: [],
    ProgramStatuses: [
      { id: null, name: "Choose" },
      { id: 1, name: "Active" },
      { id: 0, name: "InActive" },
      
    ],
    serviceModels: [],
    submit: false,
  };

  async getProgram() {
    const { data } = await getProgramsByIdandFiscalYear(
      this.props.id,
      this.props.fy
    );
    this.setState({ data });
    this.setState((state) => {
      this.state.data.averageCaseLoadPerMonth =
        this.state.data.programByFiscalYear[0].averageCaseLoadPerMonth ?? 0;
      this.state.data.totalCasesPerYear =
        this.state.data.programByFiscalYear[0].totalCasesPerYear ?? 0;
      this.state.data.federalFundingRequested =
        this.state.data.programByFiscalYear[0].federalFundingRequested ?? 0;
      this.state.data.localMatchPlanned =
        this.state.data.programByFiscalYear[0].localMatchPlanned ?? 0;
      this.state.data.fiscalCounty =
        this.state.data.programByFiscalYear[0].fiscalCounty ?? 0;
      this.state.data.totalServices =
        this.state.data.programByFiscalYear[0].totalServices ?? 0;
      this.state.data.homeCounty =
        this.state.data.programByFiscalYear[0].homeCounty ?? 0;

      return state;
    });
  }

  async getAgency() {
    let { data } = await getAgencyByFiscalYearandId(
      this.props.fy,
      this.props.id
    );
    this.setState({ data2: data });
  }

  async populateCounties() {
    const { data } = await getCounties();
    const counties = [{ id: 0, name: "Choose" }, ...data];
    this.setState({ counties });
  }

  async populateServiceModels() {
    const { data } = await getServiceModels();
    let serviceModels = [{ id: 0, name: "Choose" }];
    data.map((serviceModel) =>
      serviceModels.push({
        id: serviceModel.id,
        name:
          serviceModel.serviceModelCategoryAbrv + "/" + serviceModel.modelAbrv,
      })
    );
    this.setState({ serviceModels });
  }

  async setRegion() {
    var Region = this.state.counties.filter(
      (county) =>
        county.id === this.state.data.programByFiscalYear[0].fiscalCounty
    );

    const Reg = Region[0];

    this.setState((state) => {
      this.state.data.region = Reg ? Region[0].region : null;
      return state;
    });
  }

  async populateContactByAgency() {
    const { data: edContact } = await getContactByAgencyId(this.props.id);
    this.setState({ edContact });

    this.setState((state) => {
      this.state.data.ContactName = this.state.edContact.name;
      this.state.data.ContactPhone = this.state.edContact.phone;
      return state;
    });
  }

  async fetchData()
  {
    await this.getAgency();
    await this.populateCounties();
    await this.populateServiceModels();
    //await this.setRegion();
    await this.populateContactByAgency();
  }

  async componentDidMount() {
    this.fetchData();
  }

  async componentWillReceiveProps(nextProps) {
    await this.getAgency();
    await this.populateCounties();
    await this.populateServiceModels();
    //await this.setRegion();
    await this.populateContactByAgency();
  }

  doChange(input) {
    if (input.required) {

      if (input.value.length <= 3) {
        input.className = "form-control is-invalid";
      } else if (input.value.length > 3) {
        input.className = "form-control is-valid";
      } else {
        input.className = "form-control";
      }
    }
  }

  updateNestedState() {
    const { data: program } = this.state;
    this.setState((state) => {
      this.state.data.programByFiscalYear[0].averageCaseLoadPerMonth =
        program.averageCaseLoadPerMonth;
      this.state.data.programByFiscalYear[0].totalCasesPerYear =
        program.totalCasesPerYear;
      this.state.data.programByFiscalYear[0].fiscalCounty =
        program.fiscalCounty;
      this.state.data.programByFiscalYear[0].homeCounty = program.homeCounty;
      this.state.data.programByFiscalYear[0].totalServices =
        program.totalServices;
      return state;
    });
  }

  

  doSubmit() {
    const { data: program, data2: agency } = this.state;
    this.updateNestedState();
    var forms = document.querySelector(".needs-validation");
    forms.classList.add("was-validated");
    if (forms.checkValidity() === true) {
      console.log(program)
      insertPrograms(program);
      store.dispatch(setNavigate());

      const submit = true;
      this.setState({ submit });
    } else {
      const submit = false;
      this.setState({ submit });
    }
  }

  render() {
    const { data: program, data2: agency } = this.state;
    const { submit } = this.state;
    const entityType = agency.entityType === 1 ? "Non-Profit" : "Public Entity";

    return (
      <>
        {submit && <Navigate to={"/admin/programs"} replace={false} />}
        <form
          onSubmit={this.handleSubmit}
          className="needs-validation"
          noValidate
        >
          <div className="row m-2">
            <div className="col-lg-4">
              <Link to={`/admin/agency/${this.props.id}/${this.props.fy}`}>
                <span className="badge bg-success">
                  Agency: {this.props.id + " - "}
                  {agency.name + " - " + entityType}
                </span>
              </Link>
            </div>
          </div>
          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput2("address", "Agency Street Address", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput2("city", "City", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput2("state", "State", "", true)}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput2("zip", "Zip", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput("ContactName", "ED Name", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput("ContactPhone", "Ed Phone", "", true)}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {/*               <span className="text-success fw-bold">
                Program Id: {program.id}
              </span> */}

              {this.renderInput(
                "name",
                `Program Name`,
                null,
                false,
                "text",
                true
              )}
            </div>
            <div className="col-lg-4">
              {this.renderSelect(
                "serviceModelId",
                "Service Model",
                this.state.serviceModels
              )}
            </div>
            <div className="col-lg-4">
              {this.renderSelect(
                "ProgramStatus",
                "Program Status",
                this.state.ProgramStatuses
              )}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("address", "Program street Address")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("city", "Program City")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("zip", "Program Zip")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-2">
              <div className="form-check">
                {this.renderCheck("confidentialAddress", "Hide Street Address")}
              </div>
            </div>
            <div className="col-lg-2">
              {/*               <span className="text-success fw-bold">
                Region: {program.region}
              </span> */}
            </div>
            {/* <div className="col-lg-4">
              {this.renderInput("state", "Program State")}
            </div> */}
            <div className="col-lg-4">
              {this.renderSelect(
                "homeCounty",
                "Home County",
                this.state.counties
              )}
            </div>
            <div className="col-lg-4">
              {this.renderSelect(
                "fiscalCounty",
                "Primary Service Area (Fiscal County)",
                this.state.counties
              )}
            </div>
            <div className="col-lg-4"></div>
          </div>

          <div className="row m-2">
            <br />
          </div>

          {/*           <div className="row m-2">
            <span className="text-success fw-bold">
              Additional Counties Served
            </span>
            {program.countiesServed ? (
              program.countiesServed.map((county) => (
                <span key={county.id}>{county.name}</span>
              ))
            ) : (
              <span></span>
            )}
            <Link to={`/admin/additionalcounties/${program.id}`}>
              <span className="badge bg-success">Add Additional Counties</span>
            </Link>
          </div> */}

          <div className="row m-2">
            <br />
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("vendorId", "Vendor ID")}
            </div>
            <div className="col-lg-4">
              {this.renderInput2("ein", "EIN", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput("oriOac", "ORI OAC")}
            </div>
          </div>

          <div className="row m-2">
            {/* <div className="col-lg-4">
              {this.renderInput("Agency_Yr_End", "Agency Year End")}
            </div> */}
            <div className="col-lg-4">
              {this.renderInput("dunnsId", "Dunns ID")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("everify", "E-Verify")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput(
                "averageCaseLoadPerMonth",
                "Number of Families / Month"
              )}
            </div>
            <div className="col-lg-4">
              {this.renderInput(
                "totalCasesPerYear",
                "Number of Families / Year"
              )}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("totalServices", "Total Cost of Services ($)")}
            </div>
            <div className="col-lg-4">
              {this.renderInput(
                "federalFundingRequested",
                "Federal ($) calculated at .75 of Total Services",
                "",
                true
              )}
            </div>
            <div className="col-lg-4">
              {this.renderInput(
                "localMatchPlanned",
                "Cash Match ($) calculated at .25 of Total Services",
                "",
                true
              )}
            </div>
          </div>

          {/* <div className="row m-2">
            <span className="text-success fw-bold">Contacts:</span>
            {program.contacts ? (
              program.contacts.map((contact) => (
                <div key={contact.id} className="col-lg-4 m-2">
                  <div>{contact.type}</div>
                  <div>Name: {contact.name}</div>
                  <div>Email: {contact.email}</div>
                  <Link to={`/admin/contactEdit/${contact.id}`}>
                    <span className="badge bg-success">Edit</span>
                  </Link>
                </div>
              ))
            ) : (
              <span></span>
            )}
          </div> */}
          {this.renderButton("Submit")}
        </form>
      </>
    );
  }
}

export default AddProgramForm;
