import * as React from "react";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import Button from "react-bootstrap/Button";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import {
  getReportingPeriods,
  postUpdateReportingPeriod,
  grabReportingPeriodInfo,
  postCreateReportingPeriod,
} from "../../services/reportingPeriodsService";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import moment from "moment";


import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker, YearCalendar } from "@mui/x-date-pickers";

const Systemreportingperiods = () => {
  const DateTimeNow = sessionStorage.getItem("DateTimeNow");
  const PriorMonth = moment(DateTimeNow, "YYYY-MM-DD", true)
    .subtract(1, "months")
    .format("YYYY-MM-15");

  /* Modal Control Credit And Edit Modal For Reporting Periods */
  const [rowsReports, setRowsReports] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);

  /* Create Reporting Period */
  const [newMonth, setNewMonth] = React.useState("");
  const [newFiscalYearDate, setNewFiscalYearDate] = React.useState();
  const [newActualStartDate, setNewActualStartDate] = React.useState();
  const [newCalYearDate, setNewCalYearDate] = React.useState();
  const [newActualEndDate, setNewActualEndDate] = React.useState();
  const [newDisplayStartDate, setNewDisplayStartDate] = React.useState();
  const [newDisplayEndDate, setNewDisplayEndDate] = React.useState();

  /* Edit Reporting Period */
  let [editRepId, setEditRepId] = React.useState();
  let [editMonth, setEditMonth] = React.useState();
  let [editFiscalYearDate, setEditFiscalYearDate] = React.useState();
  let [editCalYearDate, setEditCalYearDate] = React.useState();
  let [editActualStartDate, setEditActualStartDate] = React.useState();
  let [editActualEndDate, setEditActualEndDate] = React.useState(); //React.useState<Date | null>(new Date());
  let [editDisplayStartDate, setEditDisplayStartDate] = React.useState();
  let [editDisplayEndDate, setEditDisplayEndDate] = React.useState();

  // Set of functions that control the changing of date data on create reporting period
  function onChangeNewFiscalYearDate(req, res) {
    setNewFiscalYearDate(moment(req.$d).format("YYYY"));
  }
  function onChangeNewCalYearDate(req, res) {
    setNewCalYearDate(moment(req.$d).format("YYYY"));
  }
  function onChangeNewStartDate(req, res) {
    setNewActualStartDate(moment(req.$d).format("YYYY-MM-DD HH:mm"));
  }
  function onChangeNewEndDate(req, res) {
    setNewActualEndDate(moment(req.$d).format("YYYY-MM-DD HH:mm"));
  }

  function onChangeNewDisplayStartDate(req, res) {
    setNewDisplayStartDate(moment(req.$d).format("YYYY-MM-DD HH:mm"));
  }
  function onChangeNewDisplayEndDate(req, res) {
    setNewDisplayEndDate(moment(req.$d).format("YYYY-MM-DD HH:mm"));
  }

  // Set of functions that control the changing of date data on update reporting period
  function onChangeEditDisplayStartDate(req, res) {
    setEditDisplayStartDate(moment(req.$d).format("YYYY-MM-DD hh:mm A"));
  }
  function onChangeEditDisplayEndDate(req, res) {
    setEditDisplayEndDate(moment(req.$d).format("YYYY-MM-DD hh:mm A"));
  }
  function onChangeEditStartDate(req, res) {
    setEditActualStartDate(moment(req.$d).format("YYYY-MM-DD hh:mm A"));
  }
  function onChangeEditFiscalYearDate(req, res) {
    setEditFiscalYearDate(req.$y);
  }
  function onChangeEditCalYearDate(req, res) {
    setEditCalYearDate(req.$y);
  }
  function onChangeEditEndDate(req, res) {
    setEditActualEndDate(moment(req.$d).format("YYYY-MM-DD hh:mm A"));
  }

  const handleCancelNewPeriod = () => {
    setShowCreateModal(false);
  };

  const handleCreateNewPeriodModal = () => {
    setShowCreateModal(false);
  };

  const onCreatePeriodFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleCreateNewPeriod = async () => {
    const postingCreateData = {
      FiscalYear: newFiscalYearDate,
      Month: newMonth,
      newCalYearDate: newCalYearDate, // Keep it as a string
      BeginDate: moment(new Date(newActualStartDate)).format(
        "YYYY-MM-DD HH:mm"
      ),
      EndDate: moment(new Date(newActualEndDate)).format("YYYY-MM-DD HH:mm"),
      DisplayBeginDate: moment(new Date(newDisplayStartDate)).format(
        "YYYY-MM-DD HH:mm"
      ),
      DisplayEndDate: moment(new Date(newDisplayEndDate)).format(
        "YYYY-MM-DD HH:mm"
      ),
    };

    await postCreateReportingPeriod(postingCreateData)
      .then((json) => {
        clearCreateInput();
        fetchData();
      })
      .catch((error) => {
        alert(
          "Sorry An Error Occured While Creating A New Reporting Period...",
          error
        );
      });

    setShowCreateModal(false);
  };

  const clearCreateInput = () => {
    setNewMonth("");
    setNewDisplayStartDate("");
    setNewDisplayEndDate("");
    setNewActualStartDate("");
    setNewActualEndDate("");
    setNewCalYearDate("");
  };

  /*  Related To Create Reporting Period */

  const createNewPeriodInfo = () => {
    setShowCreateModal(true);
  };

  //  Related To Edit Reporting Period
  const handleCloseEditModal = () => {
    setEditModal(false);
  };
  //  Closes Updated Modal
  const handleCancelEditModal = () => {
    setEditModal(false);
  };

  // Pulling one Data Record Into Update Reporting Period Modal View
  const pullEditRepPeriodInfo = async (id) => {
    setEditModal(true);

    var { data: reportingPeriod } = await grabReportingPeriodInfo(id);

    setEditRepId(reportingPeriod.id);
    setEditFiscalYearDate(reportingPeriod.fiscalYear);

    setEditCalYearDate(reportingPeriod.calYear);
    setEditMonth(reportingPeriod.month);
    setEditActualStartDate(reportingPeriod.beginDate);
    setEditActualEndDate(reportingPeriod.endDate);
    setEditDisplayStartDate(reportingPeriod.displayBeginDate);
    setEditDisplayEndDate(reportingPeriod.displayEndDate);
  };

  // Saving Updated Period
  const handleSaveEditReportingPeriod = async (value) => {
    const postingEditData = {
      Id: editRepId,
      FiscalYear: editFiscalYearDate,
      Month: editMonth,
      Calyear: editCalYearDate,
      BeginDate: moment(editActualStartDate).format("YYYY-MM-DD hh:mm A"),
      EndDate: moment(editActualEndDate).format("YYYY-MM-DD hh:mm A"),
      DisplayBeginDate:
        moment(editDisplayStartDate).format("YYYY-MM-DD hh:mm A"),
      DisplayEndDate: moment(editDisplayEndDate).format("YYYY-MM-DD hh:mm A"),
    };

    await postUpdateReportingPeriod(postingEditData)
      .then((json) => {
        fetchData();
      })
      .catch((error) => {
        alert("Sorry Error", error);
      });

    // Closing Modal Upon Update i.e setEditModal(false);
    setEditModal(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Period",
      flex: 1,
      minWidth: 60,
      align: "center",
    },
    {
      field: "fiscalYear",
      headerName: "Fiscal Year",
      flex: 1,
      minWidth: 10,
      align: "center",
    },
    { field: "month", headerName: "Month", flex: 1 },
    {
      field: "calYear",
      headerName: "Calendar Year",
      flex: 1,
      minWidth: 100,
      align: "center",
    },
    {
      cellClassName: "text-success",
      field: "beginDate",
      headerName: "Start Date And Time",
      flex: 1,
      minWidth: 170,
      valueFormatter: (value) => {
        return dayjs(value).format("YYYY-MM-DD hh:mm A");
      },
      align: "center",
    },
    {
      cellClassName: "text-danger",
      field: "endDate",
      headerName: "End Date And Time",
      width: 170,
      valueFormatter: (value) => {
        return dayjs(value).format("YYYY-MM-DD hh:mm A");
      },
      align: "center",
    },
    {
      cellClassName: "text-info",
      field: "displayBeginDate",
      headerName: "Start Date And Time",
      flex: 1,
      minWidth: 170,
      valueFormatter: (value) => {
        return dayjs(value).format("YYYY-MM-DD hh:mm A");
      },
      align: "center",
    },
    {
      cellClassName: "text-warning",
      field: "displayEndDate",
      headerName: "End Date And Time",
      flex: 1,
      minWidth: 170,
      valueFormatter: (value) => {
        return dayjs(value).format("YYYY-MM-DD hh:mm A");
      },
      align: "center",
    },
    {
      field: "none",
      headerName: "Actions",
      flex: 1,
      minWidth: 170,
      renderCell: (params) => (
        <>
          <div className="d-inline-flex justify-content-between">
            {PriorMonth < params.row.endDate ? (
              <Button
                variant="primary"
                value={params.row.id}
                onClick={() => {
                  pullEditRepPeriodInfo(params.row.id);
                }}
              >
                Edit Ok
              </Button>
            ) : (
              <Button
                variant="danger"
                value={params.row.id}
                onClick={() => {
                  //pullEditRepPeriodInfo(params.row.id);
                  alert("Sorry This Period Passed Already");
                }}
              >
                No Edit
              </Button>
            )}
           
          </div>
        </>
      ),
      align: "left",
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "internal_data",
      headerName: "On-screen Display",
      description: "",
      children: [{ field: "displayBeginDate" }, { field: "displayEndDate" }],
    },
    {
      groupId: "naming",
      headerName: "System Actual",
      freeReordering: true,
      children: [{ field: "beginDate" }, { field: "endDate" }],
    },
  ];

  const fetchData = async () => {
    const { data: reportingPeriods } = await getReportingPeriods();
    setRowsReports(reportingPeriods);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid mb-3 mt-5">
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div className="row">
          <div className="container-fluid mb-3">
            <Button onClick={createNewPeriodInfo}>
              (+) Create A New Period
            </Button>
          </div>
        </div>
        <div className="row">
          <div style={{ height: 750, width: "100%" }}>
            <DataGrid
              slots={{ toolbar: GridToolbar }}
              rows={rowsReports}
              getRowId={(rowsReports) => rowsReports.id}
              columns={columns}
              experimentalFeatures={{ columnGrouping: true }}
              disableRowSelectionOnClick
              columnGroupingModel={columnGroupingModel}
            />
          </div>
        </div>

        {/* Modal View Create New Reporting Period */}
        <Modal
          id="NewReportingPeriod"
          show={showCreateModal}
          onHide={handleCreateNewPeriodModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create A New Reporting Period</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onCreatePeriodFormSubmit}>
              <div className="row inline-flex">
                <div className="col-6">
                  <Form.Group className="mb-2">
                    <InputLabel id="year-select-label" className="mb-3 fw-bold">
                      Choose A New Fiscal Year
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <YearCalendar
                        className="w-100 mb-4 mt-2"
                        id="year-select-label"
                        label="Fiscal Year"
                        format="YYYY"
                        value={
                          newFiscalYearDate ? dayjs(newFiscalYearDate) : null
                        }
                        onChange={onChangeNewFiscalYearDate}
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-2">
                    <InputLabel id="year-select-label" className="mb-2 fw-bold">
                      Choose A New Calendar Year
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <YearCalendar
                        className="w-100 mb-4 mt-2"
                        id="year-select-label"
                        label="Calendar Year"
                        format="YYYY"
                        value={newCalYearDate ? dayjs(newCalYearDate) : null}
                        onChange={onChangeNewCalYearDate}
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </div>
              </div>
              <div className="row inline-flex">
                <Form.Group className="mb-2">
                  <FormControl className="mb-2 w-100">
                    <InputLabel id="month-select-label">
                      Select A Month
                    </InputLabel>
                    <Select
                      labelId="month-select-label"
                      id="month-select-label"
                      value={newMonth || ""}
                      label="Select A Month"
                      onChange={(NewMonth) =>
                        setNewMonth(NewMonth.target.value)
                      }
                    >
                      <MenuItem value="January">January</MenuItem>
                      <MenuItem value="February">February</MenuItem>
                      <MenuItem value="March">March</MenuItem>
                      <MenuItem value="April">April</MenuItem>
                      <MenuItem value="May">May</MenuItem>
                      <MenuItem value="June">June</MenuItem>
                      <MenuItem value="July">July</MenuItem>
                      <MenuItem value="August">August</MenuItem>
                      <MenuItem value="September">September</MenuItem>
                      <MenuItem value="October">October</MenuItem>
                      <MenuItem value="November">November</MenuItem>
                      <MenuItem value="December">December</MenuItem>
                    </Select>
                  </FormControl>
                </Form.Group>

                <Form.Group className="mb-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className="w-100"
                      label="Actual Start Date And Time"
                      format="YYYY-MM-DD hh:mm a"
                      value={
                        newActualStartDate ? dayjs(newActualStartDate) : null
                      }
                      onChange={onChangeNewStartDate}
                      slotProps={{
                        textField: {
                          helperText: "YYYY-MM-DD hh:mm am/pm",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Form.Group>

                <Form.Group className="mb-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className="w-100"
                      label="Actual End Date and Time"
                      format="YYYY-MM-DD hh:mm a"
                      value={newActualEndDate ? dayjs(newActualEndDate) : null}
                      onChange={onChangeNewEndDate}
                      slotProps={{
                        textField: {
                          helperText: "YYYY-MM-DD hh:mm am/pm",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Form.Group>

                <Form.Group className="mb-2">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"en"}
                  >
                    <DateTimePicker
                      className="w-100"
                      label="Display Start Date And Time"
                      format="YYYY-MM-DD hh:mm a"
                      ampmInClock={true}
                      value={
                        newDisplayStartDate ? dayjs(newDisplayStartDate) : null
                      }
                      onChange={onChangeNewDisplayStartDate}
                      slotProps={{
                        textField: {
                          helperText: "YYYY-MM-DD hh:mm am/pm",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Form.Group>

                <Form.Group className="mb-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className="w-100"
                      label="Display End Date And Time"
                      format="YYYY-MM-DD hh:mm a"
                      value={
                        newDisplayEndDate ? dayjs(newDisplayEndDate) : null
                      }
                      onChange={onChangeNewDisplayEndDate}
                      slotProps={{
                        textField: {
                          helperText: "YYYY-MM-DD hh:mm am/pm",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Form.Group>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              className="btn-danger"
              variant="secondary"
              onClick={handleCancelNewPeriod}
            >
              Cancel
            </Button>
            <Button
              className="btn-primary"
              variant="secondary"
              onClick={(e) => handleCreateNewPeriod(e)}
            >
              Save New Period
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal View Update Existing Reporting Period */}
        <Modal
          id="EditReportingPeriod"
          show={showEditModal}
          onHide={handleCloseEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Reporting Period: {editRepId}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-2 mt-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="w-100"
                    value={
                      editFiscalYearDate
                        ? dayjs(editFiscalYearDate + "01-01 12:00")
                        : null
                    }
                    onChange={onChangeEditFiscalYearDate}
                    format="YYYY"
                    views={["year"]}
                    label={`"Edit Fiscal Year from ${editFiscalYearDate}"`}
                    openTo="year"
                  />
                </LocalizationProvider>
              </Form.Group>

              <Form.Group className="mb-2 mt-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="w-100"
                    value={
                      "" + editCalYearDate
                        ? dayjs("" + editCalYearDate + "01-01 12:00")
                        : null
                    }
                    onChange={onChangeEditCalYearDate}
                    format="YYYY"
                    views={["year"]}
                    label={`"Edit Calendar Year from ${editCalYearDate}"`}
                    openTo="year"
                  />
                </LocalizationProvider>
              </Form.Group>

              <Form.Group className="mb-2 mt-4">
                <FormControl className="mb-2 w-100">
                  <InputLabel id="month-select-label">Edit Month</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="EditMonth"
                    value={editMonth || ""}
                    label="Select A Month"
                    onChange={(e) => setEditMonth(e.target.value)}
                  >
                    <MenuItem value={"January"}>January</MenuItem>
                    <MenuItem value={"February"}>February</MenuItem>
                    <MenuItem value={"March"}>March</MenuItem>
                    <MenuItem value={"April"}>April</MenuItem>
                    <MenuItem value={"May"}>May</MenuItem>
                    <MenuItem value={"June"}>June</MenuItem>
                    <MenuItem value={"July"}>July</MenuItem>
                    <MenuItem value={"August"}>August</MenuItem>
                    <MenuItem value={"September"}>September</MenuItem>
                    <MenuItem value={"October"}>October</MenuItem>
                    <MenuItem value={"November"}>November</MenuItem>
                    <MenuItem value={"December"}>December</MenuItem>
                  </Select>
                </FormControl>
              </Form.Group>

              <Form.Group className="mb-2 mt-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="w-100"
                    label="Edit Display Start Date And Time"
                    format="YYYY-MM-DD hh:mm A"
                    ampmInClock={true}
                    value={
                      editDisplayStartDate ? dayjs(editDisplayStartDate) : null
                    }
                    onChange={onChangeEditDisplayStartDate}
                    slotProps={{
                      textField: {
                        helperText: "YYYY-MM-DD hh:mm AM/PM",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Form.Group>

              <Form.Group className="mb-2 mt-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="w-100"
                    label="Edit Display End Date And Time"
                    format="YYYY-MM-DD hh:mm A"
                    ampmInClock={true}
                    value={
                      editActualStartDate ? dayjs(editDisplayEndDate) : null
                    }
                    onChange={onChangeEditDisplayEndDate}
                    slotProps={{
                      textField: {
                        helperText: "YYYY-MM-DD hh:mm AM/PM",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Form.Group>

              <Form.Group className="mb-2 mt-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="w-100"
                    label="Edit Actual Start Date And Time?"
                    format="YYYY-MM-DD hh:mm A"
                    ampmInClock={true}
                    value={
                      editActualStartDate ? dayjs(editActualStartDate) : null
                    }
                    onChange={onChangeEditStartDate}
                    slotProps={{
                      textField: {
                        helperText: "YYYY-MM-DD hh:mm AM/PM",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Form.Group>

              <Form.Group className="mb-2 mt-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="w-100"
                    label="Edit Actual End Date and Time?"
                    format="YYYY-MM-DD hh:mm A"
                    inputFormat="YYYY-MM-DD hh:mm A"
                    ampmInClock={true}
                    value={editActualEndDate ? dayjs(editActualEndDate) : null}
                    onChange={onChangeEditEndDate}
                    slotProps={{
                      textField: {
                        helperText: "YYYY-MM-DD hh:mm AM/PM",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Form.Group>

              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  className="btn-danger"
                  variant="secondary"
                  onClick={handleCancelEditModal}
                >
                  Cancel
                </Button>
                <Button
                  //type="submit"
                  className="btn-primary"
                  variant="secondary"
                  onClick={handleSaveEditReportingPeriod}
                >
                  Update Existing Period
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </Box>
    </div>
  );
};

export default Systemreportingperiods;
