import http from "./httpService";

const apiEndpoint = "/Email";

function fetchEmailList() {
  return `${apiEndpoint}/pullSavedEmailList`;
}

function apifetchSingleEmail(data) {
  return `${apiEndpoint}/getSavedEmailList/${data}`;
}

export function sendEmail(data) {
  return http.post(`${apiEndpoint}/send`, data);
}

export function sendEmailBlast(data) {
  return http.post(`${apiEndpoint}/eblast`, data);
}

export function fetchSavedEmailList() {
  return http.get(fetchEmailList());
}

export function fetchSingleEmail(data) {
  return http.get(apifetchSingleEmail(data));
}

export function saveToEmailList(data) {
  return http.post(`${apiEndpoint}/saveToEmailList`, data);
}

export function updateToEmailList(data) {
  return http.put(`${apiEndpoint}/updateSavedEmailList`, data);
}

export function SendSonAccountCredentials(fiscalYear) {
  return http.post(`${apiEndpoint}/SendSonAccountCredentials/${fiscalYear}`);
}

export function SendSonAccountCredentialsSingle(userName) {
  return http.post(
    `${apiEndpoint}/SendSonAccountCredentialsSingle/${userName}`
  );
}
