import React from "react";
import { useState, useEffect } from "react";
import {
  getCountyDirectors,
  getCountyDirectorsById,
  saveCountyDirectors,
} from "../../services/countyDirectors";
import { getCounties } from "../../services/countiesService";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ModeEditOutlineSharpIcon from "@mui/icons-material/ModeEditOutlineSharp";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function ContactMgmtCounty() {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [counties, setCounties] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setData({});
    setShow(false);
  };
  const handleShow = (id) => {
    fetchDataById(id);
    setShow(true);
  };

  const handleSave = (id) => {
    saveData(data);

    setShow(false);
    window.location.reload(false);
  };

  async function saveData() {
    await saveCountyDirectors(data);
  }
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function fetchDataById(id) {
    const { data } = await getCountyDirectorsById(id);

    setData(data);
  }

  async function fetchData() {
    const { data: rows } = await getCountyDirectors();
    setRows(rows);

    const { data: counties } = await getCounties();
    setCounties(counties);
  }

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.id;

    const copyOfObject = { ...data };
    delete copyOfObject[name];

    const updatedValue = {};
    updatedValue[name] = event.target.value;

    let newData = {
      ...copyOfObject,
      ...updatedValue,
    };

    setData(newData);
  };

  function findCounty(id) {
    for (var i = 0; i < counties.length; i++) {
      if (counties[i].id === id) return counties[i].name;
    }
  }

  function findRegion(id) {
    for (var i = 0; i < counties.length; i++) {
      if (counties[i].id === id) return counties[i].region;
    }
  }
  useEffect(() => {
    fetchData();
  }, [data]);

  return (
    <>
      <h3 className="mt-3">County Director Contact Management</h3>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left">County</TableCell>
              <TableCell align="left">Region</TableCell>
              <TableCell align="left">Director Name</TableCell>
              <TableCell align="left">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" style={{ width: 60 }}>
                  <a onClick={(e) => handleShow(row.id)}>
                    <ModeEditOutlineSharpIcon />
                    Edit
                  </a>
                </TableCell>
                <TableCell style={{ width: 160 }} align="left">
                  {findCounty(row.countyId)}
                </TableCell>
                <TableCell style={{ width: 160 }} align="left">
                  {findRegion(row.countyId)}
                </TableCell>
                <TableCell style={{ width: 160 }} align="left">
                  {row.directorName}
                </TableCell>
                <TableCell style={{ width: 160 }} align="left">
                  {row.email}
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Region {findRegion(data.id)} County Director
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="directorName">
              <Form.Label>Directors Name:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.directorName}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="streetAddress">
              <Form.Label>Street Address:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.streetAddress}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="mailingAddress">
              <Form.Label>Mailing Address:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.mailingAddress}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="mailingCity">
              <Form.Label>Mailing City:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.mailingCity}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="mailingState">
              <Form.Label>Mailing State:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.mailingState}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="streetZip">
              <Form.Label>Street Zip:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.streetZip}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="mailingZip">
              <Form.Label>Mailing Zip:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.mailingZip}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="phone">
              <Form.Label>Phone:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.phone}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="fax">
              <Form.Label>Fax:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.fax}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                className="form-control-sm"
                defaultValue={data.email}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
