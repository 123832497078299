import React from 'react'

const admindashboard = () => {
  return (
    <div>
      Admin Dashboard
    </div>
  )
}

export default admindashboard


