import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Button,
} from "@mui/material";
import { Select } from "@mui/material";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import {
  getReportingPeriods,
  postUpdateUnits,
} from "../../services/reportingPeriodsService";
import MenuItem from "@mui/material/MenuItem";
import { getUnitTable } from "../../services/familyServiceLogService";
import Box from "@mui/material/Box";

const EditUnits = () => {
  const [reportingPeriods, setReportingPeriods] = useState([]);
  const [selectedReportingPeriod, setSelectedReportingPeriod] = useState("");
  const [programId, setProgramId] = useState("");
  const [serviceCode, setServiceCode] = useState(""); //known in the database as CodeSub, I'm naming the variable as it was referenced on the request. ML
  const [letterCode, setLetterCode] = useState("");
  const [caseId, setCaseId] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);

  const formatColumnHeader = (header) => {
    // Split camelCase to words
    const words = header.replace(/([a-z])([A-Z])/g, "$1 $2");
    // Capitalize the first letter of each word
    return words.charAt(0).toUpperCase() + words.slice(1);
  };

  useEffect(() => {
    fetchReportingPeriods();
  }, []);

  const fetchReportingPeriods = async () => {
    try {
      const data = await getReportingPeriods();
      setReportingPeriods(data.data);
    } catch (error) {
      console.error("Error fetching reporting periods:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchResults();
    setShowUpdateButton(true);
  };

  const fetchResults = async () => {
    try {
      const TextField = {
        PeriodId: selectedReportingPeriod,
        ProgramId: parseInt(programId),
        ServiceCode: parseFloat(serviceCode),
        LetterCode: letterCode,
        CaseId: caseId,
      };
      const response = await getUnitTable(
        TextField.PeriodId,
        TextField.ProgramId,
        TextField.ServiceCode,
        TextField.LetterCode,
        TextField.CaseId
      );
      console.log("Reporting Response:", response);
      const data = await response.data;

      setTableData(data);
    } catch (error) {
      console.error("Error fetching results:", error);
    }
  };

  const handleUpdateData = async () => {
    try {
      const TextField = {
        PeriodId: selectedReportingPeriod,
        ProgramId: parseInt(programId),
        ServiceCode: parseFloat(serviceCode),
        CorrectUnits: parseFloat(newNumber),
        LetterCode: letterCode,
        CaseId: caseId,
      };
      var response = await postUpdateUnits(TextField);
      console.log("Reporting Response:", response);

      fetchResults();
      setShowModal(true); // Show modal when update is successful
    } catch (error) {
      console.error("Error fetching results:", error);
    }
  };

  return (
    <>
      <h1>Edit Units</h1>
      <Paper
        elevation={3}
        style={{ padding: 20, margin: "20px auto", width: "80%" }}
      >
        <Box component="form">
          <InputLabel id="selected-period-label">Reporting Period</InputLabel>
          <Select
            value={selectedReportingPeriod}
            onChange={(e) => setSelectedReportingPeriod(e.target.value)}
            variant="outlined"
            displayEmpty
            label="Reporting Period"
            labelid="selected-period-label"
            style={{ marginBottom: 10 }}
          >
            <MenuItem value="">Select Reporting Period</MenuItem>
            {reportingPeriods.map((period) => (
              <MenuItem
                key={period.id}
                value={period.id}
              >{`(${period.id}) ${period.month} ${period.calYear}`}</MenuItem>
            ))}
          </Select>
          <TextField
            type="number"
            value={programId}
            onChange={(e) => setProgramId(e.target.value)}
            label="Program ID"
            variant="outlined"
            style={{ marginBottom: 10, marginLeft: 10 }}
          />
          <TextField
            type="number"
            value={caseId}
            onChange={(e) => setCaseId(e.target.value)}
            label="Case ID"
            variant="outlined"
            helperText="Optional"
            style={{ marginBottom: 10, marginLeft: 10 }}
          />
        </Box>
        <Box>
          <TextField
            type="number"
            value={serviceCode}
            onChange={(e) => setServiceCode(e.target.value)}
            label="Service Code"
            variant="outlined"
            style={{ marginBottom: 10 }}
          />
          <TextField
            type="text"
            value={letterCode}
            onChange={(e) => setLetterCode(e.target.value)}
            label="Letter Code"
            variant="outlined"
            helperText="Optional"
            style={{ marginBottom: 10, marginLeft: 10 }}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginBottom: 30 }}
            disabled={!selectedReportingPeriod || !programId || !serviceCode}
          >
            View
          </Button>
        </Box>
        {showUpdateButton && (
          <>
            <Box>
              <TextField
                type="number"
                value={newNumber}
                onChange={(e) => setNewNumber(e.target.value)}
                label="New Value"
                variant="outlined"
                style={{ marginBottom: 10, marginTop: 30 }}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateData}
                style={{ marginBottom: 30 }}
                disabled={!newNumber.toString()}
              >
                Update Data
              </Button>
            </Box>
          </>
        )}

        <Box>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(tableData[0] || {}).map((key) => (
                  <TableCell key={key}>{formatColumnHeader(key)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  {Object.entries(row).map(([key, value], index) => (
                    <TableCell key={index}>
                      {typeof value === "boolean"
                        ? value
                          ? "true"
                          : "false"
                        : value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <div
            style={{
              margin: "100px auto",
              width: 400,
              padding: 20,
              backgroundColor: "#fff",
              borderRadius: 8,
            }}
          >
            <h2>Update Successful!</h2>
            <p>Data has been successfully updated.</p>
            <Button
              onClick={() => setShowModal(false)}
              variant="contained"
              color="primary"
            >
              OK
            </Button>
          </div>
        </Modal>
      </Paper>
    </>
  );
};

export default EditUnits;
