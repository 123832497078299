import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Calendar from "../components/provider/calendar";
// import NewIntake from "../components/provider/newintake";
import ArchivedCases from "../components/provider/archivedcases";
import AwardDashboard from "../components/provider/awarddashboard";
import AwardLetter from "../components/provider/awardinformation";
import ContractorsMeetingRegInfo from "../components/provider/contractorsmeetingreginfo";
import UploadDocs from "../components/provider/contractprep";
import CurrentContract from "../components/provider/currentcontract";
import Events from "../components/provider/events";
import Home from "../components/provider/home";
import NewExit from "../components/provider/newexit";
import ProgrammaticReports from "../components/provider/programmaticreports";
import ResourceGuide from "../components/provider/resourceguide";
import Services from "../components/provider/services";
import StaffQualifications from "../components/provider/staffqualifications";
import SummaryReports from "../components/provider/summaryreports";
import TrainingInfo from "../components/provider/traininginfo";
import TrainingResources from "../components/provider/trainingResources";
import UploadDocuments from "../components/provider/uploaddocuments";
import Users from "../components/provider/users";
import ViewDocuments from "../components/provider/viewdocuments";

import auth from "../services/authService";

import NewCase from "../components/provider/currentFY/newcase";
import PendingCases from "../components/provider/currentFY/pendingCases";

import ErrorPage from "../components/common/errorPage";
import ExitCase from "../components/provider/currentFY/exitcase";
import FamilyServiceLog from "../components/provider/currentFY/familyservicelog";
import OpenCases from "../components/provider/currentFY/opencases";
import ProviderHome from "../components/provider/currentFY/providerhome";
import EditApprovedServices from "../components/provider/editapprovedservices";
import UserManagement from "../components/provider/usermanagement";
import ViewReports from "../components/provider/viewreports";

import { getDistinctFiscalYears } from "../services/getSonServices"; 

const ProviderRoute = () => {
  const user = auth.getCurrentUser();
  const [soNFiscalYear, setSoNFiscalYear] = useState(null);

  useEffect(() => {
    const fetchFiscalYears = async () => {
      try {
        const { data } = await getDistinctFiscalYears();
        const fy = Math.max(...data);
        setSoNFiscalYear(fy);
      } catch (error) {
        console.error("Error fetching fiscal years:", error);
      }
    };

    fetchFiscalYears();
  }, []); // Empty dependency array to run only once on mount

  const currentState = useSelector((state) => state.fiscalYear);
  if (!user) {
    return <Navigate to="/account/login" replace />;
  }

  let Role = user.role;
  let checkRoles = Role === "Provider" || Role === "Son";

  if (!checkRoles) {
    return <Navigate to="/error" replace />;
  }

  if (soNFiscalYear === null) {
    return null; // or a loading spinner
  }

  return (
    <Routes>
      <Route path="/error" element={<ErrorPage code={401} />} />
      <Route path="/provider/error" element={<ErrorPage code={401} />} />

      <Route path="/provider/archivedcases" element={<ArchivedCases />} />
      <Route
        path="/provider/awardinformation"
        element={
          <AwardLetter
            user={user ? user : null}
            soNFiscalYear={soNFiscalYear}
          />
        }
      />
      <Route path="/provider/awarddashboard" element={<AwardDashboard />} />
      <Route path="/provider/calendar" element={<Calendar />} />
      {/* <Route path="/provider/newintake" element={<NewIntake />} /> */}
      <Route
        path="/provider/contractprep"
        element={<UploadDocs user={user ? user : null} soNFiscalYear={soNFiscalYear} />}
      />
      <Route
        path="/provider/contractorsmeetingreginfo"
        element={<ContractorsMeetingRegInfo />}
      />

      <Route
        path="/provider/currentcontract"
        element={
          <CurrentContract
            user={user ? user : null}
            soNFiscalYear={soNFiscalYear}
          />
        }
      />
      <Route
        path="/provider/editapprovedservices/"
        element={<EditApprovedServices fiscalYear={currentState.current} />}
      />
      <Route path="/provider/events" element={<Events />} />
      <Route
        path="/provider/currentfy/familyservicelog/:id"
        element={
          <FamilyServiceLog
            user={user ? user : null}
            fiscalYear={currentState.current}
          />
        }
      ></Route>
      <Route path="/provider/home" element={<Home />} />

      <Route
        path="/provider/currentfy/newcase/"
        element={<NewCase user={user ? user : null} key="1" />}
      ></Route>
      <Route
        path="/provider/currentfy/newcase/:id"
        element={<NewCase user={user ? user : null} key="2" />}
      ></Route>
      <Route
        path="/provider/currentfy/pendingcases"
        element={<PendingCases user={user ? user : null} />}
      ></Route>
      <Route
        path="/provider/currentfy/opencases"
        element={<OpenCases user={user ? user : null} />}
      ></Route>

      <Route
        path="/provider/currentfy/exitcase/:id"
        element={
          <ExitCase
            user={user ? user : null}
            fiscalYear={currentState.current}
          />
        }
      ></Route>
      <Route
        path="/provider/currentfy/home"
        element={
          <ProviderHome
            user={user ? user : null}
            fiscalYear={currentState.current}
          />
        }
      />
      <Route path="/provider/newexit" element={<NewExit />} />
      <Route
        path="/provider/programmaticreports"
        element={<ProgrammaticReports fiscalYear={currentState.current} />}
      />
      <Route
        path="/provider/resourceguide"
        element={
          <ResourceGuide
            user={user ? user : null}
            soNFiscalYear={soNFiscalYear}
          />
        }
      />
      <Route path="/provider/summaryreports" element={<SummaryReports />} />
      <Route path="/provider/services" element={<Services />} />
      <Route
        path="/provider/staffqualifications"
        element={<StaffQualifications />}
      />
      <Route path="/provider/traininginfo" element={<TrainingInfo />} />
      <Route
        path="/provider/trainingresources"
        element={<TrainingResources />}
      />
      <Route
        path="/provider/usermanagement"
        element={<UserManagement fiscalYear={currentState.current} />}
      />
      <Route path="/provider/users" element={<Users />} />
      <Route path="/provider/uploaddocuments" element={<UploadDocuments />} />
      <Route path="/provider/uploaddocsnextFY" />
      {/* <Route path="/provider/contractprep" element={<ContractPrep />} /> */}
      <Route
        path="/provider/viewreports"
        element={<ViewReports fiscalYear={currentState.current} />}
      />

      <Route path="/provider/viewdocuments" element={<ViewDocuments />} />
    </Routes>
  );
};

export default ProviderRoute;
