import React, { useState, useEffect } from "react";
import { getProgramsSonsByFiscalYear } from "../../services/getSonServices";
import CustomDataTable from "../common/customDataTable";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const ApprovedServices = ({ fiscalYear }) => {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [LocalFiscalYear] = useState(sessionStorage.getItem("LocalFiscalYear"));

  const columns = [
    {
      field: "sonId",
      headerName: "SoN",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={`/admin/editapprovedservices/${params.value}`}
          state={{ row: params.row }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "id",
      headerName: "Program Id",
      flex: 1,
    },
    {
      field: "agencyName",
      headerName: "Agency",
      flex: 2,
    },
    {
      field: "name",
      headerName: "Program",
      flex: 2,
    },
    {
      field: "programStatus",
      headerName: "Program Status",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <div>Active</div>
        ) : (
          <div>Inactive</div>
        ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (LocalFiscalYear !== "") {
        const { data: rows } = await getProgramsSonsByFiscalYear(
          LocalFiscalYear
        );
        rows.forEach((row) => {
          row.programStatus = row.programByFiscalYear[0].programStatus
        })
        setRows(rows);
      }
    };

    fetchData();
  }, [LocalFiscalYear]);

  return (
    <Box sx={{ width: "98%" }}>
      <h1 className="py-2">Approved Services</h1>
      <span className="badge bg-success mb-4 p-3">
        Viewing System Fiscal Year: {LocalFiscalYear}
      </span>
      <CustomDataTable
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );
};

export default ApprovedServices;
