import { Card } from '@material-ui/core';
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getCounties } from '../../../services/countiesService';
import { getCountyDirectors } from '../../../services/countyDirectors';
import { getAgencyById } from '../../../services/getAgencyService';
import { getProgramsByIdandFiscalYear, getSonsIdOnly } from '../../../services/getProgramService';


const ProviderHome = ({ user, fiscalYear }) => {
  const [program, setProgram] = useState({});
  const [agency, setAgency] = useState({});
  const [contact, setContact] = useState({})
  const [counties, setCounties] = useState({});
  const [countyDirectors, setCountyDirectors] = useState({});
  const [sonId, setSonId] = useState("");

  const delay = ms => new Promise(res => setTimeout(res, ms));

  let programId = "";


  useEffect(() => {
    async function fetchData() {
      await delay(500);
      let programId = sessionStorage.getItem("ProgramId");

      if(fiscalYear.value && programId){


        var {data: program} = await getProgramsByIdandFiscalYear(programId, fiscalYear.value);

        const progCopy = {...program};
        progCopy.programByFiscalYear = program.programByFiscalYear.find(x => x.fiscalYear === fiscalYear.value);
        setProgram(progCopy);

        var {data: agency} = await getAgencyById(program.agencyId);

        setContact(agency.contacts.find((item) => item.type === "Executive_Director"))
        const agCopy = {...agency};
        agCopy.agenciesByFiscalYear = agency.agenciesByFiscalYear.find(x => x.fiscalYear === fiscalYear.value);
        setAgency(agCopy);

        var {data: counties} = await getCounties();
        setCounties(counties);

        var { data: countyDirectors } = await getCountyDirectors();
        setCountyDirectors(countyDirectors);

        var { data: sonID } = await getSonsIdOnly(programId, fiscalYear.value);
        setSonId(sonID);

      }
    }
    fetchData();
  }, [programId, fiscalYear]);

  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  const cardStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
  };

  return (

    <Paper elevation={10} style={paperStyle}>

      <h1>Home Provider</h1>
      <div className="row py-2">
        <div className="col-lg-6">
          <Card style={cardStyle}>
            <p>
              <b className="fw-bold">Agency</b>
            </p>
            <p>
              <b className="fw-bold">Agency Id</b>: {agency.id}
            </p>
            <p>
              <b className="fw-bold">Agency Name</b>: {agency.name}
            </p>
            <p>
              <b className="fw-bold">Agency Address</b>: {agency.address}
            </p>
            <p>
              <b className="fw-bold">City, State, Zip</b>: {agency.city},&nbsp;{agency.state}&nbsp;{agency.zip}
            </p>
            <p>
              <b className="fw-bold">Executive Director</b>: {contact?.name}
            </p>
            <p>
              <b className="fw-bold">Email</b>: {contact?.email}
            </p>
            <p>
              <b className="fw-bold">Phone</b>: {contact?.phone}
            </p>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card style={cardStyle}>
            <div className="row">
              <p className="col-lg-6">
                <b className="fw-bold">Fiscal County</b>:{" "}
                {counties.length > 1 &&
                  counties.find(
                    (x) => x.id === program.programByFiscalYear.fiscalCounty
                  ).name}
              </p>
              <p className="col-lg-6">
                <b className="fw-bold">Region</b>:{" "}
                {counties.length > 1 &&
                  counties.find(
                    (x) => x.id === program.programByFiscalYear.fiscalCounty
                  ).region}
              </p>
            </div>
            <p>
              <b className="fw-bold">County Director</b>:{" "}
              {countyDirectors.length > 1 &&
                countyDirectors.find(
                  (x) => x.countyId === program.programByFiscalYear.fiscalCounty
                ).directorName}
            </p>
            <p>
              <b className="fw-bold">County Director Email</b>:{" "}
              {countyDirectors.length > 1 &&
                countyDirectors.find(
                  (x) => x.countyId === program.programByFiscalYear.fiscalCounty
                ).email}
            </p>
          </Card>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-lg-6">
          <Card style={cardStyle}>
            <p>
              <b className="fw-bold">Program</b>
            </p>
            <p><b className="fw-bold">Program ID</b>:{" "} {program.id}</p>
            <p><b className="fw-bold">Program Name</b>:{" "} {program.name}</p>
            <p><b className="fw-bold">Program Address</b>:{" "} {program.address}</p>
            <p>
              <b className="fw-bold">City, State, Zip</b>:{" "} {program.city},&nbsp;{program.state}&nbsp;{program.zip}
            </p>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card style={cardStyle}>
            <p>
              <b className="fw-bold">Service Model Category</b>:{" "}
              {program.serviceModel &&
                program.serviceModel.serviceModelCategory}
            </p>
            <p>
              <b className="fw-bold">Service Model</b>:{" "}
              {program.serviceModel && program.serviceModel.serviceModelExact}
            </p>
            <p>
              <b className="fw-bold">Abbreviation</b>:{" "}
                {program.serviceModel?.serviceModelCategoryAbrv}/
                {program.serviceModel?.modelAbrv}
            </p>
          </Card>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-lg-6">
          <Card style={cardStyle}>
            <p>
              <b className="fw-bold">SoN ID</b>: {sonId}
            </p>
            <p>
              <b className="fw-bold">Total Cost of Services</b>:{" "}
              {program.programByFiscalYear &&
                program.programByFiscalYear.totalServices.toLocaleString(
                  "en-US",
                  { style: "currency", currency: "USD" }
                )}
            </p>
            <p>
              <b className="fw-bold">Federal Award</b>:{" "}
              {program.programByFiscalYear &&
                program.programByFiscalYear.federalFundingRequested.toLocaleString(
                  "en-US",
                  { style: "currency", currency: "USD" }
                )}
            </p>
            <p>
              <b className="fw-bold">Local Match Planned</b>:{" "}
              {program.programByFiscalYear &&
                program.programByFiscalYear.localMatchPlanned.toLocaleString(
                  "en-US",
                  { style: "currency", currency: "USD" }
                )}
            </p>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card style={cardStyle}>
            <p><b className="fw-bold">Proposed Caseload:</b></p>
            <p>
              <b className="fw-bold">Number of Families Per Month</b>:{" "}
              {program.programByFiscalYear &&
                program.programByFiscalYear.averageCaseLoadPerMonth}
            </p>
            <p>
              <b className="fw-bold">Number of Families Per Year</b>:{" "}
              {program.programByFiscalYear &&
                program.programByFiscalYear.totalCasesPerYear}
            </p>
          </Card>
        </div>
      </div>
    </Paper>
  );
}

export default ProviderHome;