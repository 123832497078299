import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import jwtDecode from "jwt-decode";
import "react-pro-sidebar/dist/css/styles.css";
import { useLocation } from "react-router-dom";
import "./App.css";
import BannerStatusBar from "./components/layout/bannerStatusBar";
import AdminNav from "./components/layout/adminNav";
import FOOTER from "./components/layout/footer";
import NavBar from "./components/layout/navbar";
import PowerNav from "./components/layout/powerNav";
import SideBar from "./components/layout/sidebar";
import { setFiscalYear } from "./features/fiscalYearSlice";
import AccountRoute from "./routes/accountRoute";
import AdminRoute from "./routes/adminRoute";
import ContentRoute from "./routes/contentRoutes";
import PowerUserRoute from "./routes/powerUserRoute";
import ProviderRoute from "./routes/providerRoute";
import auth from "./services/authService";
import SessionTimeout from "./components/account/SessionTimeout";

function App() {
  const dispatch = useDispatch();

  const [user, setUser] = useState(0);
  let location = useLocation();
  let role = null;
  if (user) role = user.role;

  let pathname = window.location.pathname;
  let isProvider = pathname.includes("/provider") ? true : false;

  let isError = pathname.includes("/error") ? true : false;
  let isAdmin = pathname.includes("/admin") ? true : false;
  let isAccount = pathname.includes("/account") ? true : false;
  let isPowerUser = pathname.includes("/poweruser") ? true : false;

  useEffect(() => {
    try {
      dispatch(setFiscalYear());
      const user = auth.getCurrentUser();
      setUser(user);
    } catch (error) {
      console.log("🚀 ~ file: App.js ~ line 36 ~ useEffect ~ error", error);
    }
    /*
      HB: Not 100% certain below is right. But we had an issue where it did not re-run this useeffect after login, so it required a manual page refresh in order to get the user. 
      This way we are simply rechecking the user every time we navigate to a new page. I tried setting this to localStorage and it did not seem to work. Location works, but
      this might be inefficient.
    */
  }, [location]);

  return (
    <React.Fragment>
      <SessionTimeout />
      <div>
        <BannerStatusBar />
      </div>
      {isError && role === "Admin" && (
        <div className="d-flex">
          <AdminNav />
          <main className="container">
            <AdminRoute />
          </main>
        </div>
      )}
      {isError && role === "Provider" && (
        <div className="d-flex">
          <SideBar />
          <main className="container">
            <ProviderRoute />
          </main>
        </div>
      )}
      {isError && role === "Son" && (
        <div className="d-flex">
          <sonSidebar />
          <main className="container">
            <ProviderRoute />
          </main>
        </div>
      )}

      {!isProvider && !isAdmin && !isAccount && !isPowerUser && !isError && (
        <>
          <main className="container main user">
            <NavBar user={user ? user : null} />
            <ContentRoute />
          </main>
          <FOOTER />
        </>
      )}
      {isProvider && !isError && (
        <div className="d-flex">
          <main className="container provider">
            <SideBar />
            <ProviderRoute user={user ? user : null} />
          </main>
        </div>
      )}
      {isAdmin && !isError && (
        <div className="d-flex">
          <main className="container admin">
            <AdminNav />
            <AdminRoute />
          </main>
        </div>
      )}
      {isPowerUser && !isError && (
        <div className="d-flex">
          <PowerNav />
          <main className="container power">
            <PowerUserRoute />
          </main>
        </div>
      )}
      {isAccount && !isError && (
        <>
          {/*           <NavBar /> */}
          <main className="account">
            <AccountRoute />
          </main>
        </>
      )}
    </React.Fragment>
  );
}

export default App;
