import {
  Button,
  CardActions,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getResourceGuideProgramDetail } from "../../services/resourceGuideService";
import { useState } from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#415868",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function GuideDetails() {
  const classes = useStyles();
  const location = useLocation();
  const fiscalYear = location.state && location.state.fiscalYear;
  const programId = location.state && location.state.programId;
  const [data, setData] = useState({});

  const handleNavigateBack = () => {
    window.history.back();
  };

  const populateList = async () => {
    const { data } = await getResourceGuideProgramDetail(fiscalYear, programId);
    setData(data);
  };

  useEffect(() => {
    populateList();
  }, []);

  const card = (
    <>
      <CardContent>
        <Typography variant="h6" component="div" align="center">
          Contact Information
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" align="center">
          {data.primaryContact}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" align="center">
          {data.agencyPhone}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" align="center">
          {data.confidentialAddress ? "" : data.agencyAddress}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" align="center">
          {data.confidentialAddress ? "" : data.agencyCity}
          {data.confidentialAddress ? "" : ", "}
          {data.confidentialAddress ? "" : data.agencyState}{" "}
          {data.confidentialAddress ? "" : data.agencyZip}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" align="center">
          <a className="link" href={`http://${data.agencyWebAddress}`}>
            {data.agencyWebAddress}
          </a>
        </Typography>
      </CardContent>
    </>
  );

  return (
    <Container
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <div
        style={{
          marginTop: 8,
          left: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: "orange",
            display: "inline",
            px: 12,
            py: 2,
            fontSize: 16,
          }}
          onClick={handleNavigateBack}
        >
          Return to Results
        </Button>
      </div>
      <Grid
        container
        wrap="nowrap"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item md={3}>
          <Box sx={{ my: 5 }}>
            <Card variant="outlined" style={{ borderColor: "red" }}>
              {card}
            </Card>
          </Box>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className="mb-3 mt-2">
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                <Typography sx={{ fontSize: 24 }} color="white" align="center">
                  {data.agencyName} ({data.agencyId})
                </Typography>
                <Typography sx={{ fontSize: 16 }} color="white" align="center">
                  {data.programName}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                <Typography>
                  <strong>Program Description:</strong>
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow style={{ backgroundColor: "white" }}>
              <StyledTableCell component="th" scope="row">
                {data.programDescription}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                <Typography>
                  <strong>Counties Served:</strong>
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow style={{ backgroundColor: "white" }}>
              <StyledTableCell component="th" scope="row">
                {data.countiesServed}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                <Typography>
                  <strong> PSSF Funded Services:</strong>
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow style={{ backgroundColor: "white" }}>
              <StyledTableCell component="th" scope="row">
                {data.description}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                <Typography>
                  <strong> Additional Services Offered:</strong>
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow style={{ backgroundColor: "white" }}>
              <StyledTableCell component="th" scope="row">
                {data.additionalServicesOffered}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default GuideDetails;
