import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Button,
  TextField,
  Paper,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchSavedEmailList,
  fetchSingleEmail,
  saveToEmailList,
  updateToEmailList,
} from "../../services/emailService";
import TinyMCE from "../admin/tinyMCE";

const EditEmail = () => {
  // Initialize emailData state
  const defaultData = {
    audience: `0`,
    Name: `SoN Email`,
    to: "",
    subject: ``,
    test: `0`,
    body: ``,
    From: "communications@pssfnet.com",
    Type: `1`,
  };
  const [emailData, setEmailData] = useState(defaultData);

  const editorRef = useRef(null);
  const placeholders = useMemo(
    () => ["{userName}", "{password}", "{fiscalYear}", "{name}"],
    []
  );
  // State to track which placeholders are present in the body
  const [placeholdersStatus, setPlaceholdersStatus] = useState({});

  // Dialog state variables
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [isLoading, setLoading] = useState(true);

  async function loadFirstEmailTypeOne() {
    try {
      const savedEmails = await fetchSavedEmailList();
      const emailTypeOne = savedEmails.data.find((email) => email.type === 1); // type 1 is SoN email template

      if (emailTypeOne) {
        setEmailData(emailTypeOne);
      }
    } catch (error) {
      console.error("Error loading email data:", error);
    } finally {
      setLoading(false);
    }
  }
  // Load the first saved email entry with type = 1 on initial load
  useEffect(() => {
    loadFirstEmailTypeOne();
  }, []);

  // Update placeholders status whenever the body changes
  useEffect(() => {
    const newPlaceholdersStatus = {};
    placeholders.forEach((placeholder) => {
      newPlaceholdersStatus[placeholder] = emailData.body.includes(placeholder);
    });
    setPlaceholdersStatus(newPlaceholdersStatus);
  }, [emailData.body, placeholders]);

  // Handler to save the current email data
  const handleSave = async () => {
    if (!emailData.id) {
      try {
        setLoading(true);
        await saveToEmailList(emailData);
        await loadFirstEmailTypeOne();
        setDialogMessage("Email saved successfully");
        setDialogOpen(true);
      } catch (error) {
        console.error("Error saving email data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await updateToEmailList(emailData);
        await loadFirstEmailTypeOne();
        setDialogMessage("Email saved successfully");
        setDialogOpen(true);
      } catch (error) {
        console.error("Error saving email data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Handler to load the email data
  const handleLoad = async () => {
    if (emailData.id) {
      try {
        setLoading(true);
        const { data } = await fetchSingleEmail(emailData.id);
        setEmailData(data);
        setDialogMessage("Email loaded successfully");
        setDialogOpen(true);
      } catch (error) {
        console.error("Error loading email data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setEmailData(defaultData);
      setDialogMessage("Email loaded successfully from default values");
      setDialogOpen(true);
    }
  };

  // Check if any placeholders are missing
  const arePlaceholdersMissing = Object.values(placeholdersStatus).some(
    (status) => !status
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          marginTop: 150,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <CircularProgress />
        </Stack>
      </div>
    );
  }
  return (
    <>
      <Paper
        elevation={3}
        style={{ padding: 20, margin: "20px auto", width: "80%" }}
      >
        <Typography variant="h4">Edit SoN Email Template</Typography>

        {/* Message indicating required words with conditional styling */}
        <Typography
          variant="subtitle1"
          color="textSecondary"
          style={{ marginBottom: "16px" }}
        >
          {"To save the email, it must contain the following placeholders: "}
          {placeholders.map((placeholder, index) => (
            <span
              key={index}
              style={{
                color: placeholdersStatus[placeholder] ? "green" : "red",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {placeholder}
            </span>
          ))}
        </Typography>

        <TextField
          label="Subject"
          value={emailData.subject}
          onChange={(e) =>
            setEmailData((prev) => ({
              ...prev,
              subject: e.target.value,
            }))
          }
          fullWidth
          margin="normal"
        />

        <TinyMCE
          value={emailData.body}
          onChange={(content) =>
            setEmailData((prev) => ({
              ...prev,
              body: content,
            }))
          }
          editorRef={editorRef}
        />

        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button variant="contained" color="primary" onClick={handleLoad}>
            Load
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={arePlaceholdersMissing || !emailData.subject}
          >
            Save
          </Button>
        </Box>
      </Paper>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditEmail;
