import http from "./httpService";

const apiEndpoint = "/CountyDirectors";

export function getCountyDirectors() {
  return http.get(apiEndpoint);
}

export function getCountyDirectorsByIdUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCountyDirectorsById(id) {
  return http.get(getCountyDirectorsByIdUrl(id));
}

export function saveCountyDirectors(director) {
  if (director.id) {
    const body = { ...director };
    delete body.id;
    return http.post(getCountyDirectorsByIdUrl(director.id), body);
  }
}
