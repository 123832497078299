import React from "react";

const Checkbox = ({ name, label, check, ...rest }) => {

  return (
      <><input
      type="checkbox"
      className="form-check-input"
      checked={check ?? 0}
      name={name}
      id={name}
      {...rest} />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        {label}
      </label></>
  );
};

export default Checkbox;
