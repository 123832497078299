import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { getAgencyList } from "../../services/getAgencyService";
import { GetProgramList } from "../../services/getProgramService";
import { linkTPSBridge } from "../../services/tpsBridgeService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const TpsConnectorModal = ({
  agProjId,
  agencyName,
  programName,
  onClose,
  proposalId,
  applicantName,
  fiscalAgentName,
  fiscalYear,
  projectId,
  email,
  phone,
  agencyProgramId,
  userId,
  onSubmit,
}) => {
  const [open, setOpen] = useState(true);
  const [selectedAgency, setSelectedAgency] = useState("");
  const [newAgency, setNewAgency] = useState(agencyName);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [newProgram, setNewProgram] = useState(programName);
  const [comment, setComment] = useState("");
  const [agencyList, setAgencyList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [showAddNewAgency, setShowAddNewAgency] = useState(false);
  const [showAddNewAgencyCheck, setShowAddNewAgencyCheck] = useState(true);
  const [showAddNewProgramCheck, setShowAddNewProgramCheck] = useState(false);
  const [showAddNewProgram, setShowAddNewProgram] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose(); // Call the onClose callback from the parent component
  };

  const handleAgencyChange = async (event) => {
    const selectedAgencyId = event.target.value;
    setSelectedAgency(selectedAgencyId);
    setShowAddNewAgencyCheck((prevState) => !prevState);
    setShowAddNewProgramCheck((prevState) => !prevState);

    // Fetch program list based on the selected agency
    const selectedAgency = agencyList.find(
      (agency) => agency.id === selectedAgencyId
    );

    try {
      if (selectedAgency) {
        const { data } = await GetProgramList(selectedAgency.id);
        setProgramList(data);
      } else {
        setProgramList([]);
      }
    } catch (error) {
      console.error("Error fetching program list:", error);
    }
  };

  const handleProgramChange = (event) => {
    setSelectedProgram(event.target.value);
    setShowAddNewAgency(false); // Hide "Add new Agency" option
    setShowAddNewProgramCheck((prevState) => !prevState);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleFormLinkClick = async () => {
    setIsLoading(true);
    const formData = {
      selectedAgency: selectedAgency || 0,
      newAgency,
      selectedProgram: selectedProgram || 0,
      newProgram,
      comment,
      isNewAgency: showAddNewAgency,
      isNewProgram: showAddNewAgency ? true : showAddNewProgram,
      proposalId,
      applicantName,
      fiscalAgentName,
      fiscalYear: parseInt(fiscalYear),
      agProjId,
      projectId,
      email,
      phone,
      agencyProgramId,
      userId,
    };

    try {
      await linkTPSBridge(formData);
      setIsLoading(false); // Set loading state back to false after the async operation is done
      handleClose();
      onSubmit();
    } catch (error) {
      console.error("Error linking TPS Bridge:", error);
       setIsLoading(false);
    }

   
  };

  useEffect(() => {
    const fetchAgencyList = async () => {
      try {
        const { data } = await getAgencyList();
        setAgencyList(data);
      } catch (error) {
        console.error("Error fetching agency list:", error);
      }
    };

    fetchAgencyList();
  }, []);

  useEffect(() => {
    setShowAddNewProgram(showAddNewAgency && selectedAgency !== "");
  }, [showAddNewAgency, selectedAgency, showAddNewAgencyCheck]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Got data for the following provider: {agencyName} - {programName}{" "}
          {agProjId}
        </Typography>

        {showAddNewAgency ? (
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              label="New Agency"
              value={newAgency}
              onChange={(event) => setNewAgency(event.target.value)}
            />
          </FormControl>
        ) : (
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="agency-label">Select PSSF Agency</InputLabel>
            <Select
              value={selectedAgency}
              onChange={handleAgencyChange}
              labelId="agency-label"
              label="Select PSSF Agency"
            >
              <MenuItem value="">Select</MenuItem>
              {agencyList.map((agency) => (
                <MenuItem key={agency.id} value={agency.id}>
                  {agency.name} ({agency.id})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {showAddNewAgency || showAddNewProgram ? (
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              label="New Program"
              value={newProgram}
              onChange={(event) => setNewProgram(event.target.value)}
            />
          </FormControl>
        ) : (
          selectedAgency && (
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel id="program-label">Select Program</InputLabel>
              <Select
                value={selectedProgram}
                onChange={handleProgramChange}
                labelId="program-label"
                label="Select Program"
              >
                <MenuItem value="">Select</MenuItem>
                {programList.map((program) => (
                  <MenuItem key={program.id} value={program.id}>
                    {program.name} ({program.id})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        )}
        {showAddNewAgencyCheck && (
          <FormControl sx={{ mt: 2 }} fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAddNewAgency}
                  onChange={() => setShowAddNewAgency(!showAddNewAgency)}
                />
              }
              label="Add new Agency"
            />
          </FormControl>
        )}

        {showAddNewProgramCheck && (
          <FormControl sx={{ mt: 2 }} fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAddNewProgram}
                  onChange={() => setShowAddNewProgram(!showAddNewProgram)}
                />
              }
              label="Add new Program"
            />
          </FormControl>
        )}

        <TextField
          value={comment}
          onChange={handleCommentChange}
          label="Comment"
          multiline
          rows={4}
          sx={{ mt: 2 }}
          fullWidth
        />
        <LoadingButton
          variant="contained"
          onClick={handleFormLinkClick}
          loading={isLoading}
          sx={{ mt: 2 }}
          fullWidth
        >
          Form Link
        </LoadingButton>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{ mt: 2 }}
          fullWidth
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default TpsConnectorModal;
