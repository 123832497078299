import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ConfirmEmailAddress } from "../../services/accountservice";
import { getCounties } from "../../services/countiesService";
import ChangePasswordForm from "../forms/changePasswordForm";

const ConfirmEmail = () => {
  async function emailConfirm() {
    let Email = { emailAddress: email, token: decodeURIComponent(token) };
    const {data: m} = await ConfirmEmailAddress(Email);
    setMessage(m);
    if(m === "Your email has been confirmed")
    {
      setCSS("offset-3 col-6 mt-5 alert alert-success");
    }
    else if (m==="There was an error confirming your email.")
    {
      setCSS("offset-3 col-6 mt-5 alert alert-danger");
    }
    else
    {
        setCSS("alert alert-danger");
    }
  }

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const id = searchParams.get("id");
  const token = encodeURIComponent(searchParams.get("token"));
  const [message, setMessage] = useState("");
  const [css, setCSS] = useState("");

  useEffect(() => {
    emailConfirm();
  }, [message]);

  return (
    <>
      <div className={css}>{message}</div>
      <ChangePasswordForm username={email} id={id} />
    </>
  );
};

export default ConfirmEmail;
