import React from "react";
import Form from "react-bootstrap/Form";

const Select = ({ name,label, options, ...rest }) => {
  return (
    <div className="form-group">
      <h6 htmlFor={name}>
        {label}
      </h6>
      <Form.Select name={name} id={name} {...rest}>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default Select;