import React, { useState, useEffect } from "react";
import SonTable from "../Tables/sonTable";
import {
  getProgramsSonsByFiscalYear,
  getDistinctFiscalYears,
} from "../../services/getSonServices";
import Pagination from "../common/pagination";
import _ from "lodash";
import { paginate } from "../../utils/paginate";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const SoNHome = (props) => {
  const [sonUsers, setSonUsers] = useState([]);
  const [sortColumn, setSortColumn] = useState([
    { field: "sonId", sort: "asc" },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fy, setFy] = useState(0);
  const [LocalFiscalYear, setLocalFiscalYear] = useState(
    sessionStorage.getItem("LocalFiscalYear")
  );
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  // Define state variable for yearRange
  const [yearRange, setYearRange] = useState([]);

  // Function to reset the year and reload the page
  const resetYear = (val) => {
    sessionStorage.setItem("LocalFiscalYear", parseInt(val.target.value));
    setLocalFiscalYear(parseInt(val.target.value));
    setFy(parseInt(val.target.value));
    window.location.reload();
  };

  // Fetch distinct fiscal years and store them in the state variable
  useEffect(() => {
    const fetchYearRange = async () => {
      const { data } = await getDistinctFiscalYears(); // Fetch distinct fiscal years
      setYearRange(data); // Update the state with fetched data
    };
    fetchYearRange(); // Call the function
  }, []);

  useEffect(() => {
    const populateProgramsSonsList = async () => {
      if (LocalFiscalYear) {
        const { data } = await getProgramsSonsByFiscalYear(LocalFiscalYear);
        setSonUsers(data);
      }
      setLoading(false);
    };
    populateProgramsSonsList();
  }, [fy, location, LocalFiscalYear]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const { length: count } = sonUsers;
  const sorted = _.orderBy(
    sonUsers,
    sortColumn.map((col) => col.field),
    sortColumn.map((col) => col.sort)
  );
  const users = paginate(sorted, currentPage, count);

  return (
    <>
      <div className="row">
        <div className="mb-1">
          <div className="row">
            <div className="col">
              <h1 className="ps-3 mt-5 mb-3">Son Home</h1>
            </div>
          </div>
        </div>

        <div className="d-inline-flex">
          <div className="col-6">
            <label style={{ width: "75%" }} className="fw-bold fs-6 mb-2">
              Change Year To Set View Of Data
            </label>

            <select
              style={{ width: "75%" }}
              className="form-select mb-4"
              onChange={resetYear}
              value={LocalFiscalYear ? LocalFiscalYear : moment().year()}
            >
              {yearRange.map((y, index) => (
                <option key={JSON.stringify(index)} value={y}>
                  {y}
                </option>
              ))}
            </select>
          </div>
          <div
            className="col-6 align-text-bottom position-relative"
            style={{ height: "100%" }}
          >
            <div
              className="badge bg-success mt-4 mb-4 p-3 position-absolute bottom-0"
              style={{ marginTop: 10, width: "100%" }}
            >
              Viewing System Fiscal Year: {LocalFiscalYear}
            </div>
          </div>
        </div>

        <div className="mb-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                marginTop: 150,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack spacing={2} direction="row">
                <CircularProgress />
              </Stack>
            </div>
          ) : count > 0 ? (
            <>
              <SonTable
                sonUsers={users}
                className="mb-3 position-relative"
                fy={LocalFiscalYear}
              />

              <div className="row">
                <div className="d-inline-block mt-3">
                  <Pagination
                    itemsCount={count}
                    className="mt-5 position-relative"
                    pageSize={count}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />

                  <h5>Total Count: {count}</h5>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                marginTop: 150,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack spacing={2} direction="row">
                <h5>No data could be found</h5>
              </Stack>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SoNHome;
