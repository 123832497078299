import http from "./httpService";

const apiEndpoint = "/ResourceGuide";

function resourceGuideFiscalYearAndIdUrl(fy, programId) {
  return `${apiEndpoint}/${fy}/${programId}`;
}

function resourceGuideDraftUrl(programId, fiscalYear) {
  return `${apiEndpoint}/draft/${programId}/${fiscalYear}`;
}

function resourceGuideUpdateUrl(id, approved) {
  return `${apiEndpoint}/update/${id}/${approved}`;
}

export function getResourceGuideByFiscalYear(fy) {
  return http.get(`${apiEndpoint}/resourceGuidesByFY/${fy}`);
}

export function getResourceGuideListByFiscalYear(fy) {
  return http.get(`${apiEndpoint}/AgencyResourceGuideList/${fy}`);
}

export function getResourceGuideProgramDetail(fy, programId) {
  return http.get(
    `${apiEndpoint}/ResourceGuideProgramDetail/${fy}/${programId}`
  );
}

export function getAgencyResourceGuideDetailsList(
  fy,
  Code,
  AgencyId,
  CountiesServed
) {
  console.log(
    "getAgencyResourceGuideDetailsList",
    fy,
    Code,
    AgencyId,
    CountiesServed
  );
  return http.get(`${apiEndpoint}/AgencyResourceGuideDetailsList/${fy}`, {
    params: {
      FiscalYear: fy,
      Code: Code,
      AgencyId: AgencyId,
      CountiesServed: CountiesServed,
    },
  });
}

export function getResourceGuideByFiscalYearandId(fy, programId) {
  return http.get(resourceGuideFiscalYearAndIdUrl(fy, programId));
}

export function saveResourceGuide(programId, fiscalYear, ResourceGuide) {
  const body = { ...ResourceGuide };
  return http.post(
    resourceGuideFiscalYearAndIdUrl(fiscalYear, programId),
    body
  );
}

export function updateResourceGuide(ResourceGuide, id, approved) {
  const body = { ...ResourceGuide };
  return http.post(resourceGuideUpdateUrl(id, approved), body);
}

// New function for saving a draft
export function saveDraftResourceGuide(
  programId,
  fiscalYear,
  ResourceGuide
) {
  const body = { ...ResourceGuide };
  return http.post(resourceGuideDraftUrl(fiscalYear, programId), body);
}
