import React from "react";
import { Link } from "react-router-dom";

const Tes = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">
            PSSF Adoption Promotion &amp; Post-Permanency
          </h3>
          <h5 className="text-center">Transition and Emancipation Support</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">Transition and Emancipation Support</span>{" "}
            services are designed to help youth develop skills for independent
            living and establish meaningful adult connections while
            simultaneously working toward achieving permanency through
            reunification, adoption, or guardianship. Youth who are nearing the
            age of emancipation without an identified permanency resource may
            need additional supports and services to help transition and prepare
            for the opportunities and challenges of independent adult living.
            Without family supports and community networks to help them make
            successful transitions to adulthood, these young adults may
            experience very poor outcomes at a much higher rate than the general
            population. Services are designed to provide enhanced or additional
            supports and services to youth preparing for emancipation, or youth
            who have recently exited foster care, to equip them with life
            skills, educational and career planning necessary for a successful
            transition to independent adult living.
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Youth Assessment & Service Plan</li>
              <li>Case Management</li>
              <li>Adult or Peer Mentoring</li>
              <li>Educational supports</li>
              <li>Employment Supports</li>
              <li>Transitional Life Skills</li>
            </ol>
            Based on the results of the assessment and the goals identified by
            the youth, service plans may also include:
            <ul>
              <li>Health management, such as:</li>
              <ul>
                <li>Medical care resources</li>
                <li>Nutrition</li>
                <li>Substance abuse treament/recover supports</li>
                <li>Pregnancy prevention</li>
              </ul>
              <li>Safety, including:</li>
              <ul>
                <li>Maintaining healthy relationships</li>
                <li>Personal Safety</li>
              </ul>
              <li>Financial Supports</li>
              <ul>
                <li>Personal documents, identification, etc.</li>
                <li>
                  Financial resources management, such as banking, budgeting,
                  establishing or repairing credit, etc.
                </li>
              </ul>
              <li>Behavior Management</li>
              <li>Therapeutic Counseling</li>
              <li>Transportation</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Tes;
