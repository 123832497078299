import React, { useState } from "react";
import { useEffect } from "react";
import { Paper, Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { getCounties } from "../../../services/countiesService";
import { getStates } from "../../../services/getStatesService";
import {
  deleteCaregiver,
  getIncomeOptions,
  getIncomeSources,
  insertCaregiver,
} from "../../../services/casesService";
import FormHelperText from "@mui/material/FormHelperText";

const CaseFormB = ({
  user,
  data,
  setData,
  getData,
  caseId,
  handleNext,
  handleBack,
}) => {
  const [caregiver, setCaregiver] = useState({});
  //const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [states, setStates] = useState({});
  const [counties, setCounties] = useState({});
  const [secondaryCaregivers, setSecondaryCaregivers] = useState({});
  const [sameAddress, setSameAddress] = useState(false);
  const [errors, setErrors] = useState([]);
  
  async function fetchData() {
    //getData();

    const { data: states } = await getStates();
    setStates(states);

    const { data: counties } = await getCounties();
    setCounties(counties);

    const secCaregivers = data.caregivers && data.caregivers.filter((caregiver) => !caregiver.isPrimary);
    setSecondaryCaregivers(secCaregivers);
  }

  async function fetchDataById(id) {
    //const { data } = await getNewServiceById(id);
    //setData(data);
  }

  const handleClose = () => {
    setSameAddress(false);
    setCaregiver({});
    setErrors([]);
    setShow(false);
  };
  const handleShow = (id) => {
    //fetchDataById(id);
    setSameAddress(false);
    setErrors([]);
    setShow(true);
  };

  async function handleSave() {
    let hasError = false;

    setErrors([]);
    let copy = [];

    if(!caregiver.firstName) {
      copy.push("firstName");
      hasError = true
    }

    if(!caregiver.lastName) {
      copy.push("lastName");
      hasError = true
    }

    if(!caregiver.address) {
      copy.push("address");
      hasError = true
    }

    if(!caregiver.city) {
      copy.push("city");
      hasError = true
    }

    if(!caregiver.state) {
      copy.push("state");
      hasError = true
    }

    if(!caregiver.county) {
      copy.push("county");
      hasError = true
    }

    if(!caregiver.zip) {
      copy.push("zip");
      hasError = true
    }

    if(!caregiver.relationshipToPc) {
      copy.push("relationshipToPc");
      hasError = true
    }

    setErrors(copy);

    if (!hasError) {  
      await insertCaregiver(caseId, caregiver);
      getData();
      fetchData();
      handleClose();
    }  
  }

  const handleCheck = () => {
    setSameAddress(!sameAddress);

    const copyOfObject = { ...caregiver };

    copyOfObject["address"] = data.caregivers.find((caregiver) => caregiver.isPrimary).address;
    copyOfObject["city"] = data.caregivers.find((caregiver) => caregiver.isPrimary).city;
    copyOfObject["state"] = data.caregivers.find((caregiver) => caregiver.isPrimary).state;
    copyOfObject["county"] = data.caregivers.find((caregiver) => caregiver.isPrimary).county;
    copyOfObject["zip"] = data.caregivers.find((caregiver) => caregiver.isPrimary).zip;

    setCaregiver(copyOfObject);
  }

  const handleChange = (event) => {
    //event.preventDefault();
    const name = event.target.id;

    const copyOfObject = { ...caregiver };
    delete copyOfObject[name];

    const updatedValue = {};
    updatedValue[name] = event.target.value;

    let newData = {
      ...copyOfObject,
      ...updatedValue,
    };

    setCaregiver(newData);
  };

  const handleDelete = async (id) => {
    await deleteCaregiver(id);
    getData();
    fetchData();
  }

  const relationshipOptions = [
    {id: 1, name: "Grandparent"},
    {id: 2, name: "Ex-Spouse/Partner"},
    {id: 3, name: "Other Relative"},
    {id: 4, name: "No Relation"},
    {id: 5, name: "Spouse/Partner"},
    {id: 6, name: "Parent"}
  ]

  useEffect(() => {
    fetchData();
  }, [data]);

  return (
    <>
      <div className="py-4">
        <h5>
          Primary Caregiver:{" "}
          {data.caregivers && data.caregivers.find((caregiver) => caregiver.isPrimary).firstName}{" "}
          {data.caregivers && data.caregivers.find((caregiver) => caregiver.isPrimary).lastName}
        </h5>
        <h6>Case Id: {caseId}</h6>
      </div>

      <h6>Intake Section B: Secondary Caregiver (SC) Information</h6>
      <p>
        Complete the following information on any other adults who participate
        in or receive services for this case.
        <br />
        If the PC type is Biological Parent, Relative Caregiver, Adoptive
        Parent, or Youth/Homeless youth, include additional adults living in the
        household as secondary caregivers.
        <br />
        If the PC type is Foster Parent or CPA/CCI, include all individuals
        listed on the permanency plan as secondary caregivers.
        <br />
        Each intake can include up to 3 secondary caregivers.
        <br />
      </p>

      <div>
        Secondary Caregivers:
        {secondaryCaregivers && secondaryCaregivers.length > 0 && secondaryCaregivers.map(caregiver => (
          <div key={caregiver.id} className="row py-2">
            <div className="col-md-2">
            {caregiver.firstName}&nbsp;{caregiver.lastName}
            </div>
            <div className="col-md-6"><Button variant="danger" onClick={() => handleDelete(caregiver.id)}>Delete</Button></div>
          </div>
        ))}
      </div>

      <div>
        {secondaryCaregivers && secondaryCaregivers.length < 3 && <Button onClick={handleShow}>Add Secondary Caregiver</Button>}     
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Secondary Caregiver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group className="col-md-6 mb-2" controlId="firstName">
                {errors.find(e => e === "firstName") && (
                  <FormHelperText className="text-danger">First Name is required.</FormHelperText>
                )}
                <Form.Label className="fw-bold">First Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  onChange={handleChange}
                  autoFocus
                  required
                />
              </Form.Group>
              <Form.Group className="col-md-6 mb-2" controlId="lastName">
                {errors.find(e => e === "lastName") && (
                  <FormHelperText className="text-danger">Last Name is required.</FormHelperText>
                )}
                <Form.Label className="fw-bold">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  onChange={handleChange}
                  autoFocus
                  required
                />
              </Form.Group>
            </div>
            <Form.Check 
              type={"checkbox"}
              name={"sameAddress"}
              label={"Same address as Primary Caregiver"}
              onChange={handleCheck}
            />
            {/* 
            HB: This checkbox appears to give a console warning that we are changing uncontrolled inputs to controlled inputs, due to the fact that it affects the values of the below inputs. 
            The warning tells us to choose between controlled and uncontrolled for the lifetime of the component, but unfortunately that seems to run counter to the intended
            functionality of this page. We may simply have to deal with the console warning for now until we can find a way to refactor around it. 
            */}
            <div className="row">
              <Form.Group className="col-md-6 mb-2" controlId="address">
                {errors.find(e => e === "address") && (
                  <FormHelperText className="text-danger">Address is required.</FormHelperText>
                )}
                <Form.Label className="fw-bold">Address</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  onChange={handleChange}
                  autoFocus
                  required
                  value={sameAddress ? data.caregivers.find((caregiver) => caregiver.isPrimary).address : caregiver.address}
                  disabled={sameAddress}
                />
              </Form.Group>
              <Form.Group className="col-md-6 mb-2" controlId="city">
                {errors.find(e => e === "city") && (
                  <FormHelperText className="text-danger">City is required.</FormHelperText>
                )}
                <Form.Label className="fw-bold">City</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  onChange={handleChange}
                  autoFocus
                  required
                  value={sameAddress ? data.caregivers.find((caregiver) => caregiver.isPrimary).city : caregiver.city}
                  disabled={sameAddress}
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6 mb-2" controlId="state">
                {errors.find(e => e === "state") && (
                  <FormHelperText className="text-danger">State is required.</FormHelperText>
                )}
                <Form.Label className="fw-bold">State</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  required
                  value={sameAddress ? data.caregivers.find((caregiver) => caregiver.isPrimary).state : caregiver.state}
                  disabled={sameAddress}
                >
                  <option>Open this select menu</option>
                  {states.length > 1 &&
                    states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.stateName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-6 mb-2" controlId="county">
                {errors.find(e => e === "county") && (
                  <FormHelperText className="text-danger">County is required.</FormHelperText>
                )}
                <Form.Label className="fw-bold">County</Form.Label>
                {caregiver.state == 11 ? (
                  <Form.Select onChange={handleChange} required
                    value={sameAddress ? data.caregivers.find((caregiver) => caregiver.isPrimary).county : caregiver.county}
                    disabled={sameAddress}
                  >
                    <option>Open this select menu</option>
                    {counties.length > 1 &&
                      counties.map((counties) => (
                        <option key={counties.id} value={counties.name}>
                          {counties.name}
                        </option>
                      ))}
                  </Form.Select>
                ) : (
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    onChange={handleChange}
                    autoFocus
                    value={sameAddress ? data.caregivers.find((caregiver) => caregiver.isPrimary).county : caregiver.county}
                    disabled={sameAddress}
                  />
                )}
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-md-6 mb-2" controlId="zip">
                {errors.find(e => e === "zip") && (
                  <FormHelperText className="text-danger">Zip is required.</FormHelperText>
                )}
                <Form.Label className="fw-bold">Zip</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  onChange={handleChange}
                  autoFocus
                  required
                  value={sameAddress ? data.caregivers.find((caregiver) => caregiver.isPrimary).zip : caregiver.zip}
                  disabled={sameAddress}
                />
              </Form.Group>
              <Form.Group className="col-md-6 mb-2" controlId="relationshipToPc">
                {errors.find(e => e === "relationshipToPc") && (
                  <FormHelperText className="text-danger">Relationship to Primary Caregiver is required.</FormHelperText>
                )}
                <Form.Label className='fw-bold'>Relationship to Primary Caregiver</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  required
                  value={data.caregivers ? data.caregivers.find((caregiver) => caregiver.isPrimary).relationshipToPc : ""}
                  >
                  <option value="">Open this select menu</option>
                  {relationshipOptions.length > 1 && relationshipOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            
          </Form>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleNext}>{"Save & Continue"}</Button>
      </Box>
    </>
  );
};

export default CaseFormB;
