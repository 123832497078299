import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { sendEmail } from "../../services/emailService";
import ColoredLine from "../common/coloredLine";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "25px",
  },
  button: {
    width: "10%",
  },
}));

const Contact = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [comment, setComment] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name && email && subject && comment && captchaValue) {
      // Handle form submission here
      if (!validateEmail(email)) {
        setValidEmail(false);
        return;
      }
      sendEmail({
        to: "communications@pssfnet.com",
        body: `
    <div style="font-family:arial;font-size:12px;">
      Name: ${name}
      <br>
      Email: ${email}
      <br>
      Subject: ${subject}
      <br>
      Comment: ${comment}
      <br><br>
      <br><br><br>
    </div>
  `,
        subject: `Question from PSSFNet.com Contact Us page... ${subject}`,
      });
      window.location.reload();
    }
    setValidEmail(true);
    setSubmitted(true);
  };

  const validateEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-10">
          <img
            src={require("../../img/masthead5.png")}
            style={{ align: "center" }}
            className="img-fluid"
            alt="Mast Head 5"
          />
          <div className="mt-5">
            <strong>
              <h3 align="center" className="fw-bold">
                Let's get in touch!
              </h3>
            </strong>
          </div>
          <div className="row justify-content-around" style={{ margin: "50px 0px" }}>
            <div className="row">
              <div className="col-md-6">
                <div className="border border-warning m-2">
                  <strong>
                    <h5 align="center" className="m-2 fw-bold">
                      Roger Hubbard
                    </h5>
                  </strong>
                  <p style={{ textAlign: "center" }}>
                    <i>DFCS - PSSF Grant Supervisor</i>
                    <br />
                    Georgia Department of Human Services
                    <br />
                    47 Trinity Ave. S.W., 2nd Floor
                    <br />
                    Atlanta, Georgia 30334
                    <br />
                    Phone: 404.657.0474
                    <br />
                    Email: roger.hubbard@dhs.ga.gov
                  </p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="border border-warning m-2">
                  <strong>
                    <h5 align="center" className="m-2 fw-bold">
                      PSSF Technical Assistance and Provider Support Team
                    </h5>
                  </strong>
                  <p style={{ textAlign: "center" }}>
                    <i>Brandi Shirey &bull; Briana Evans</i>
                    <br />
                    115 Perimeter Center Place NE
                    <br />
                    Suite 960
                    <br />
                    Atlanta, GA 30346
                    <br />
                    Phone: 770.642.6722
                    <br />
                    Email: communications@pssfnet.com
                  </p>
                </div>
              </div>
            </div>


            <ColoredLine color="#779442" />
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                label="Name"
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
                error={submitted && name === ""}
                className={classes.textField}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Email"
                required
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={(!validEmail && submitted) || (submitted && email === "")}
                helperText={!validEmail && "Please enter a valid email address"}
                className={classes.textField}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Subject"
                required
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                error={submitted && subject === ""}
                className={classes.textField}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Comment"
                required
                multiline
                minRows={4}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                error={submitted && comment === ""}
                className={classes.textField}
                variant="outlined"
                size="small"
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    backgroundColor: "orange",
                    display: "inline",
                    px: 12,
                    py: 2,
                    fontSize: 16,
                  }}
                >
                  Send
                </Button>
              </div>
              <div className={classes.buttonContainer}>
                <ReCAPTCHA
                  sitekey="6LfCIpQjAAAAAD1aNhS78DpS5EmORlYXyQoOqsAz"
                  onChange={handleCaptcha}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
