import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import { 
	Table, 
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Paper,
	Button, 
	ButtonGroup,
	TableContainer,
} from '@mui/material'

const AwardDashboard = () => {
	return ( <></>
/* 		<TableContainer component={Paper} fixed sx={{paddingTop: 10}}>
     		<Table aria-label='simple table' style={{width: 600}} align='center'>
			 <Typography variant='h3' align='center'>Awards  Dashboard <MilitaryTechOutlinedIcon sx={{ fontSize: 'inherit' }}></MilitaryTechOutlinedIcon></Typography>
			 
				<TableBody>
					<TableRow>
						<TableCell>
							<ButtonGroup size="large" orientation="horizontal" aria-label="horizontal outlined button group large button group">
								<Button variant="contained" href="/rsvp/award_notify.cfm" style={{width: 250}} sx={{ p: 1, backgroundColor: '#415868', color: '#ffffff' }}>Award Information</Button>
								<Button variant="contained" href="/rsvp/Provider_View_Contract_Files.cfm" style={{width: 250}} sx={{ p: 1, backgroundColor: '#415868' , color: '#ffffff' }}>FFY2022 Contracts</Button>
							</ButtonGroup>
							<ButtonGroup size="large" orientation="horizontal" aria-label="horizontal outlined button group">
								<Button variant="contained" href="/rsvp/mandatory_webinar.cfm" style={{width: 250}} sx={{ p: 1, backgroundColor: '#415868', color: '#ffffff' }}>Contract Prep Meeting</Button>
								<Button variant="contained" href="/rsvp/contractors_mtg.cfm" style={{width: 250}} sx={{ p: 1, backgroundColor: '#415868', color: '#ffffff' }}>Contractor Meeting</Button>
							</ButtonGroup>
							<ButtonGroup size="large" orientation="horizontal" aria-label="horizontal outlined button group">
								<Button variant="contained" href="/rsvp/contract_prep.cfm" style={{width: 250}} sx={{ p: 1, backgroundColor: '#415868', color: '#ffffff' }}>Contract Preparation</Button>
								<Button variant="contained" href="/rsvp/resource_guide.cfm" style={{width: 250}} sx={{ p: 1, backgroundColor: '#415868', color: '#ffffff' }}>Resource Guide</Button>
							</ButtonGroup>
							<ButtonGroup style={{backgroundColor:415868}} size="large" orientation="horizontal" aria-label="horizontal outlined button group">
								<Button variant="contained" href="/rsvp/Upload_NewDocs.cfm" style={{width: 250}} sx={{ p: 1, backgroundColor: '#415868' , color: '#ffffff' }}>Upload New Documents</Button>
								<Button variant="contained" href="/rsvp/pssfweb_training.cfm" style={{width: 250}} sx={{ p: 1, backgroundColor: '#415868', color: '#ffffff' }}>PSSFWeb Training</Button>
							</ButtonGroup>
						</TableCell>			
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer> */
 );
}

export default AwardDashboard;