import React from "react";
import { Link } from "react-router-dom";

const ContentCard = ({ header, body, link, linkto }) => {
  return (
    <div className="card">
      <div
        className="card-body our-card"
        style={{
          backgroundColor: "#ede7de",
          border: "1px solid #415868",
        }}
      >
        <h5 className="card-title text-center fw-bold">{header}</h5>
        <p className="card-text text-center">{body}</p>
        <p className="card-text text-center">
          <Link
            to={linkto ?? ""}
            rel="noopener noreferrer"
            className="text-warning"
          >
            {link}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ContentCard;
