import React from 'react';
import Form from '../common/form';
import { getContactById, saveContact } from "../../services/getContactService";
import { Navigate } from "react-router-dom";

class ContactEditForm extends Form {
  state = { 
    data: {},
    submit: false
  }
  
  async componentDidMount() {
    const { data } = await getContactById(this.props.id);
    this.setState({ data });
  }

  doSubmit() {
    saveContact(this.state.data);
    const submit = true;
    this.setState({ submit });
  }
  
  render() { 
    const { data, submit } = this.state;
    return (
      <>
        <h1>Edit {data.type}</h1>
        {submit && <Navigate to={"/admin/sonhome"} replace={false} />}
        <form onSubmit={this.handleSubmit}>
          <div className="row m-2">
            <div className="col-lg-4">{this.renderInput("name", "Name")}</div>
          </div>
          <div className="row m-2">
            <div className="col-lg-4">{this.renderInput("phone", "Phone")}</div>
            <div className="col-lg-4">{this.renderInput("email", "Email")}</div>
          </div>
          <div className="m-4">{this.renderButton("Submit")}</div>
        </form>
      </>
    );
  }
}
 
export default ContactEditForm;