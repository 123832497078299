import React from "react";
import Form from "../common/form";
import auth from "../../services/authService";
import { Navigate } from "react-router-dom";
import { UpdatePassword } from "../../services/accountservice";

class ChangePasswordForm extends Form {
  state = { data: { username: "" }, errors: {}, submit: false };

  async getUsername() {
    const user = auth.getCurrentUser();
    this.setState((state) => {
      this.state.data.username = this.props.username
        ? this.props.username
        : user.name;
      this.state.data.id = this.props.id ? this.props.id : user.UserId;
      return state;
    });
  }

  validate = (ex) => {
    const err = ex.response.data.errors;
    const errors = [];
    if (ex.response.status === 401) {
      errors.newPassword = "something has gone wrong on the website's server";
    } else {
      errors.newPassword =
        err.description !== undefined ? err.description[0] : undefined;
    }
    this.setState({ errors });
  };

  async savePassword(data) {
    try {
      await UpdatePassword(data);
    } catch (ex) {
      this.validate(ex);
    }
  }

  async componentDidMount() {
    await this.getUsername();
  }

  doChange(myInput) {
    var letter = document.getElementById("letter");
    var capital = document.getElementById("capital");
    var number = document.getElementById("number");
    var length = document.getElementById("length");
    var special = document.getElementById("special")
    if (myInput.required) {
      var lowerCaseLetters = /[a-z]/g;
      if (myInput.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
      }

      // Validate capital letters
      var upperCaseLetters = /[A-Z]/g;
      if (myInput.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
      }

      // Validate numbers
      var numbers = /[0-9]/g;
      if (myInput.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
      }

      // Validate special
      var specials = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
            if (myInput.value.match(specials)) {
              special.classList.remove("invalid");
              special.classList.add("valid");
            } else {
              special.classList.remove("valid");
              special.classList.add("invalid");
            }

      // Validate length
      if (myInput.value.length >= 6) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
      }

      if (
        myInput.value.match(lowerCaseLetters) &&
        myInput.value.match(upperCaseLetters) &&
        myInput.value.match(numbers) &&
        myInput.value.match(specials) &&
        myInput.value.length >= 6
      ) {
        myInput.className = "form-control is-valid";
      } else {
        myInput.className = "form-control is-invalid";
      }
    }
  }

  doSubmit() {
    const { data } = this.state;

    try {
      const chris = document.getElementById("form");
      chris.className = "was-validated";
      var passVal = document.getElementById("newPassword");
      if(!passVal.classList.contains('is-invalid'))
      {
        this.savePassword(data);
        const submit = true;
        this.setState({ submit });

      }
    } catch (error) {
      const chris = document.getElementById("form");
      chris.className = "was-validated";
      const submit = false;
      this.setState({ submit });
      this.validate(error);
    }
  }

  render() {
    const { errors, submit } = this.state;

    var myInput = document.getElementById("newPassword");
    // When the user clicks on the password field, show the message box

    if (myInput) {
      myInput.onfocus = function () {
        document.getElementById("message").style.display = "block";
      };

      // When the user clicks outside of the password field, hide the message box
      myInput.onblur = function () {
        document.getElementById("message").style.display = "none";
      };
    }
    if (submit === true)
      return <Navigate to="/account/logout" replace={true} />;

    return (
      <>
        <div className="offset-3 col-6 mt-5">
          <h3>Change Password</h3>
          <form onSubmit={this.handleSubmit} className="" id="form">
            {this.renderInput("username", "Username", errors.username, true)}
            <br />
            {this.renderInput(
              "oldPassword",
              "Current Password",
              errors.password,
              false,
              "password",
              true
            )}
            <br />
            {this.renderInput(
              "newPassword",
              "New Password",
              errors.password,
              false,
              "password",
              true
            )}
            <br />
            {this.renderButton("Submit")}
          </form>
        </div>
        <div id="message" className="offset-3 col-6 mt-5">
          <h3>Password must contain the following:</h3>
          <p id="letter" className="invalid">
            A <b>lowercase</b> letter
          </p>
          <p id="capital" className="invalid">
            A <b>capital (uppercase)</b> letter
          </p>
          <p id="number" className="invalid">
            A <b>number</b>
          </p>
          <p id="special" className="invalid">
            A <b>special character</b>
          </p>
          <p id="length" className="invalid">
            Minimum <b>6 characters</b>
          </p>
        </div>
      </>
    );
  }
}

export default ChangePasswordForm;
