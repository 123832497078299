import { Paper, Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getReport } from "../../services/reportService";
import MonthSearch from "./monthsearch";
import auth from "../../services/authService";
import { LoadingButton } from "@mui/lab";

const ViewReports = () => {
  const [data, setData] = useState({
    LocalFiscalYear: sessionStorage.getItem("LocalFiscalYear"),
    reportingPeriodId: sessionStorage.getItem("periodId"),
    fiscalYear: sessionStorage.getItem("LocalFiscalYear"),
    programId: sessionStorage.getItem("ProgramId") ?? 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, [data]);

  const user = auth.getCurrentUser();

  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  const cardStyle = {
    padding: 20,
    //height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
  };

  const handleChange = (event) => {
    const id = event.target.id;
    let newData = { ...data };
    newData[id] = event.target.value;
    setData(newData);
  };

  const handleProgrammaticReport = async () => {
    var parameters = [
      {
        key: "Month",
        value: data.reportingPeriodId,
      },
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
      {
        key: "ProgramID",
        value: data.programId,
      },
      {
        key: "UserID",
        value: user.UserId,
      },
    ];
    try {
      setIsLoading(true);
      await getReport("NewPssf_Programmatic_Report_PartA", parameters);
      await getReport("NewPssf_Programmatic_Report_PartB", parameters);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFslDetailReport = async () => {
    var parameters = [
      {
        key: "Month",
        value: data.reportingPeriodId,
      },
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
      {
        key: "ProgramID",
        value: data.programId,
      },
    ];
    try {
      setIsLoading(true);
      await getReport("NewPssf_Family_Service_Log_Detail", parameters);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFedAwardReport = async () => {
    var parameters = [
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
    ];
    let format = data.Type ?? "PDF";
    try {
      setIsLoading(true);
      await getReport("NewPssf_Federal_Award_Balance", parameters, format);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDSSReport = async () => {
    var parameters = [
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
      {
        key: "ProgramID",
        value: data.programId,
      },
    ];
    try {
      setIsLoading(true);
      await getReport("NewPssf_DSS_Invoice_Summary", parameters);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRDRPreviewReport = async () => {
    var parameters = [
      {
        key: "Region",
        value: data.region,
      },
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
      {
        key: "Month",
        value: data.reportingPeriodId,
      },
    ];
    try {
      setIsLoading(true);
      await getReport("NewPssf_RDR_Preview", parameters);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePercentUtilReport = async () => {
    var parameters = [
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
    ];
    let format = data.Type ?? "PDF";
    try {
      setIsLoading(true);
      await getReport(
        "NewPssf_Percent_Utilization_Summary",
        parameters,
        format
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSONALLReport = async () => {
    var parameters = [
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
    ];
    let format = data.Type ?? "PDF";
    try {
      setIsLoading(true);
      await getReport(
        "NewPssfAgencyProgramRpt",
        parameters,
        format
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleProviderQuarterlyReport = async () => {
    var parameters = [
      {
        key: "Quarter",
        value: data.quarter,
      },
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
      {
        key: "ProgramID",
        value: data.programId,
      },
    ];
    let format = data.Type ?? "PDF";
    try {
      setIsLoading(true);
      await getReport(
        "NewPssf_Provider_quarterly_summary_report",
        parameters,
        format
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper elevation={10} style={paperStyle}>
      <h1>View Reports</h1>
      <Card style={cardStyle}>
        <h4>Programmatic Reports History</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useProgramId={true}
          useReportingPeriodId={true}
          LocalFiscalYear={data.LocalFiscalYear}
          periodId={data.reportingPeriodId}
        />
        <div className="pt-2">
          <LoadingButton
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={handleProgrammaticReport}
            style={{ width: 150 }}
          >
            Get Report
          </LoadingButton>
        </div>
      </Card>

      <Card style={cardStyle}>
        <h4>Provider Quarterly Summary Report</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useQuarterly={true}
          useProgramId={true}
          useRportType={true}
        />
        <div className="pt-2">
          <LoadingButton
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={handleProviderQuarterlyReport}
            style={{ width: 150 }}
          >
            Get Report
          </LoadingButton>
        </div>
      </Card>

      <Card style={cardStyle}>
        <h4>Family Service Log Detail</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useProgramId={true}
          useReportingPeriodId={true}
          LocalFiscalYear={data.LocalFiscalYear}
          periodId={data.reportingPeriodId}
        />
        <div className="pt-2">
          <LoadingButton
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={handleFslDetailReport}
            style={{ width: 150 }}
          >
            Get Report
          </LoadingButton>
        </div>
      </Card>

      <Card style={cardStyle}>
        <h4>Federal Award Balance</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useRportType={true}
          LocalFiscalYear={data.LocalFiscalYear}
        />
        <div className="pt-2">
          <LoadingButton
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={handleFedAwardReport}
            style={{ width: 150 }}
          >
            Get Report
          </LoadingButton>
        </div>
      </Card>

      <Card style={cardStyle}>
        <h4>DSS Invoice</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useProgramId={true}
          LocalFiscalYear={data.LocalFiscalYear}
        />
        <div className="pt-2">
          <LoadingButton
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={handleDSSReport}
            style={{ width: 150 }}
          >
            Get Report
          </LoadingButton>
        </div>
      </Card>

      <Card style={cardStyle}>
        <h4>RDR Preview</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useReportingPeriodId={true}
          useRegion={true}
          LocalFiscalYear={data.LocalFiscalYear}
          periodId={data.reportingPeriodId}
        />
        <div className="pt-2">
          <LoadingButton
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={handleRDRPreviewReport}
            style={{ width: 150 }}
          >
            Get Report
          </LoadingButton>
        </div>
      </Card>

      <Card style={cardStyle}>
        <h4>Percent Utilization Summary</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useRportType={true}
          LocalFiscalYear={data.LocalFiscalYear}
        />
        <div className="pt-2">
          <LoadingButton
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={handlePercentUtilReport}
            style={{ width: 150 }}
          >
            Get Report
          </LoadingButton>
        </div>
      </Card>
      <Card style={cardStyle}>
        <h4>SoN Data All</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useRportType={true}
          LocalFiscalYear={data.LocalFiscalYear}
        />
        <div className="pt-2">
          <LoadingButton
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={handleSONALLReport}
            style={{ width: 150 }}
          >
            Get Report
          </LoadingButton>
        </div>
      </Card>
    </Paper>
  );
};

export default ViewReports;
