import React from "react";
import ColoredLine from "../common/coloredLine";

const Home = () => {


  const pId = Math.floor(Math.random() * (5 - 1 + 1)) + 1;

  return (
    <>
     
        <div className="col text-center">
          <img
            src={require("../../img/masthead" + pId + ".png")}
            className="img-fluid"
            alt=""
            
          />
        </div>
     
      <ColoredLine color="#779442" />
      <div className="container text-center">
      <div className="row align-items-start">
        <div className="col-md-6">
          <strong>
            <h2 align="center">
              Welcome to Promoting Safe and Stable Families - (PSSF)
            </h2>
          </strong>
          <p>
            The Promoting Safe and Stable Families program (Title IV-B subpart 2
            of the Social Security Act) provides federal child welfare funding,
            training and technical assistance to help build state and community
            capacity to meet the needs of families at risk of child welfare
            intervention and families in crisis. Created in 1993 by an amendment
            to the Social Security Act, Promoting Safe and Stable Families was
            most recently reauthorized in 2014 under the Child and Family
            Services Improvement and Innovation Act.
            <br />
            <br />
            The purpose of Promoting Safe and Stable Families is aligned with
            the broad federal policy goals of safety, permanency and well-being,
            particularly maintaining children in their own homes, providing
            families with enhanced capacity to provide for their children's
            needs, and facilitating timely exits from foster care to
            reunification, adoption or guardianship.
            <br />
            <br />
            Funds are distributed to states based on the state's share of
            children in all states receiving Supplemental Nutrition Assistance
            Program (SNAP) benefits. Federal guidelines require a minimum
            spending of 20% of the state allocation in each of the four program
            areas and spending of less than 10% for administrative activities.
            To encourage shared responsibility for protecting children and
            supporting families, Promoting Safe and Stable Families requires a
            non-federal, local match of 25%. Matching contributions are provided
            by private, state or local sources.
            <br />
            <br />
            In Georgia, Promoting Safe and Stable Families is administered by
            the Division of Family and Children Services.
          </p>
        </div>
        <div className="col-md-6">
          <img
            src={require("../../img/home_AwardPie_2022.png")}
            style={{ width: "90%" }}
            alt=""
          />
        </div>
      </div>
     </div>
    </>
  );
};

export default Home;
