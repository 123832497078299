import jwtDecode from "jwt-decode";
import moment from 'moment';
import http from "./httpService";

const apiEndpoint = "/account/login";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(username, password) {
  sessionStorage.removeItem("ProgramId");
  const {data: jwt }= await http.post(apiEndpoint, { username, password });
  localStorage.setItem(tokenKey, jwt);

    const timeStamp = moment();
    sessionStorage.setItem("lastTimeStamp", timeStamp);
}

export function IsLoggedIn(){
  return localStorage.getItem(tokenKey);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  sessionStorage.removeItem("ProgramId");
  window.location = "/account/login";
}
export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  logout,
  getCurrentUser,
  loginWithJwt,
  getJwt,
  IsLoggedIn,
};
