import React from "react";

const Funding = () => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-8">
          <img
            src={require("../../img/masthead4.png")}
            className="img-fluid text-center"
            alt="Young man and woman smiling"
          />
          <strong>
            <h2 className="text-center mt-5 mb-3 fw-bold">
              Funding Opportunity
            </h2>
            <h5 className="text-center">
              Georgia Non-Profit and Public Entities{" "}
              <span style={{ color: "#779442" }}>ONLY</span>
            </h5>
          </strong>
          <div className="mb-5 mt-5">
            <p className="text-center">
              PSSF provides funding for community-based services to children and
              families who may be at risk for child abuse and neglect or who
              have had confirmed reports of child abuse and neglect and are
              involved with the state's child welfare agency.
            </p>
            <br />
            <br />
            <div className="block-handle">
              <div className="proposal-deadline-text">ANNOUNCEMENT</div>
              <br />
              <a
                href={require("../../docs/PSSF_FFY2025_SoN_SectionsABCD.pdf")}
                download
              >
                Promoting Safe and Stable Families Program <b>FFY2025</b>{" "}
                Statement of Need
              </a>
              <br />
              <br />
              Informational Meeting will be held{" "}
              <span className="fw-bold">
                February 29, 2024, 10:00 am - 12:00pm EST{" "}
              </span>
              via Zoom.
              <br />
              Attendance at Informational Meeting is <b>mandatory</b> for
              agencies/organizations interested in submitting a proposal for the
              <b> FFY2025</b> funding cycle. An authorization code will be
              shared during the meeting.
              <br />
              <br />
              <h4 className="mt-5">
                Registration is required. Register in advance using the link
                below
              </h4>
              <br />
              <a
                className="link"
                title="Link: To Zoom Meeting"
                href="https://us02web.zoom.us/meeting/register/tZUlfu2sqTorH9IiOjo1hhvN4aYGuLuuOk8H"
                target="_blank"
                rel="noreferrer"
              >
                Zoom:
                https://us02web.zoom.us/meeting/register/tZUlfu2sqTorH9IiOjo1hhvN4aYGuLuuOk8H
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Funding;
