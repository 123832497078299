import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddAgencyForm from "./addAgencyForm";

function AddAgency() {
  const currentState = useSelector((state) => state.fiscalYear);
  const [fiscalYear, setFiscalYear] = useState(
    currentState.current.value
  );

  const handleChange = (event) => {
    setFiscalYear(event.target.value);
  };

  useEffect(() => {
    // Update the document title using the browser API]
    if (fiscalYear === '') setFiscalYear(currentState.current.value);
  }, [fiscalYear, currentState]);

  return (
    <>
      <h1>Add Agency</h1>
      <div className="row m-2">
        <div className="col-lg-4">
          <FormControl variant="outlined" sx={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-standard-label">
              Fiscal Year
            </InputLabel>
            <Select
              labelId="fiscalYear"
              id="fiscalYear"
              value={fiscalYear}
              onChange={handleChange}
              label="Fiscal Year"
            >
              <MenuItem value={currentState.current.value}>
                {currentState.current.name}
              </MenuItem>
              <MenuItem value={currentState.next.value}>
                {currentState.next.name}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {fiscalYear && <AddAgencyForm fiscalYear={fiscalYear}/>}
    </>
  );
}

export default AddAgency;
