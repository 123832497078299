import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';


export default function ErrorPage({code = 404}) {

function renderSwitch() {
  switch(code) {
    case 401:
      return "You are not authorized to view this page";
    default:
      return "The page you’re looking for doesn’t exist.";
  }
};

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h1" style={{ color: "black" }}>
        {code}
      </Typography>
      
      <Typography variant="h6" style={{ color: "black" }}>
       {renderSwitch()}
      </Typography>
    </Box>
  );
}