import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Paper, Box } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExitFormA from "./exitformA";
import ExitFormB from "./exitformB";
import ExitFormC from "./exitformC";
import { getCaseById, updateCaseById } from "../../../services/casesService";

const steps = ["Exit Section A", "Exit Section B", "Exit Section C"];

const isAdmin = window.location.pathname.includes("admin");

const ExitCase = ({ user }) => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [caseId, setCaseId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const reportingPeriod = sessionStorage.getItem("ReportingPeriod");

  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  const getData = async () => {
    const { data } = await getCaseById(caseId);
    setData(data);
  };

  const saveData = async () => {
    switch (activeStep) {
      case 0:
        updateCaseById(caseId, data, false, true);
        break;
      case 1:
        updateCaseById(caseId, data);
        break;
      case 2:
        updateCaseById(caseId, data, false, false, true);
        break;
      default:
      // code block
    }
  };

  const handleNext = () => {
    saveData();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    //sessionStorage.removeItem("CaseId");
    if (isAdmin) {
      if (data["status"] === "Archived") {
        window.location = "/admin/archivedcases";
      } else window.location = "/admin/opencases";
    } else {
      window.location = "/provider/currentfy/opencases";
    }
  };

  const getComponent = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <ExitFormA
            user={user ? user : null}
            data={data}
            setData={setData}
            getData={getData}
            caseId={caseId ? caseId : null}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 1:
        return (
          <ExitFormB
            user={user ? user : null}
            data={data}
            setData={setData}
            getData={getData}
            caseId={caseId ? caseId : null}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <ExitFormC
            user={user ? user : null}
            data={data}
            setData={setData}
            getData={getData}
            caseId={caseId ? caseId : null}
            handleNext={handleNext}
            handleBack={handleBack}
            reportingPeriod={reportingPeriod}
          />
        );
      default:
        return <h1>Other Exit Steps</h1>;
    }
  };

  useEffect(() => {
    if (id != null) setCaseId(id);
    else if (sessionStorage.getItem("CaseId"))
      setCaseId(sessionStorage.getItem("CaseId"));

    if (caseId != null) getData();
  }, [caseId]);

  return (
    <Paper elevation={10} style={paperStyle}>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Return</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>{getComponent(activeStep)}</React.Fragment>
        )}
      </Box>
    </Paper>
  );
};

export default ExitCase;
