import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { getCounties } from "../../services/countiesService";
import { getServiceCodeList } from "../../services/newServicesService";
import { getResourceGuideListByFiscalYear } from "../../services/resourceGuideService";
import { Button, InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ResourceGuideForm = () => {
  const FY = useSelector((state) => state.fiscalYear);
  const [counties, setCounties] = useState([]);
  const [services, setServices] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState('');
  const [county, setCounty] = useState('');
  const [service, setService] = useState('');
  const [submit, setSubmit] = useState(false);

  const populateAgency = async () => {
    if (FY.current.value) {
      const { data } = await getResourceGuideListByFiscalYear(FY.current.value);
      const agencyList = [{ id: "", name: "Choose" }, ...data];
      setAgencies(agencyList);
    }
  };
  const populateCounties = async () => {
    const { data } = await getCounties();
    const counties = [{ id: "", name: "All" }, ...data];
    setCounties(counties);
  };

  const populateServices = async () => {
    const { data } = await getServiceCodeList();

    const services = [{ id: "", description: "Any", code: "Any" }, ...data];

    setServices(services);
  };

  const handleSave = async () => {
    setSubmit(true);
  };

  useEffect(() => {
    populateCounties();
    populateServices();
    populateAgency();
  }, [FY]);

  return (
    <>
      {submit && (
        <Navigate
          to={"/guide/results"}
          replace={false}
          state={{
            agency: agency,
            county: county,
            service: service,
            fiscalYear: FY.current.value,
          }}
          
        />
      )}
      <form>
        <div className="row">
          <div className="col-8">
            <FormControl variant="outlined" sx={{ minWidth: 640 }} size="small">
              <InputLabel id="agency-label">Search by Agency Name</InputLabel>
              <Select
                labelId="agency-label"
                id="agency"
                value={agency}
                label="agency"
                defaultValue={""}
                onChange={(event) => setAgency(event.target.value)}
              >
                {agencies.map((agency) => (
                  <MenuItem value={agency.id} key={agency.id}>
                    {agency.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className="mt-3">
              If you do not know the name of a PSSF network service provider,
              select from the options provided below.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {/* <h6 className="mb-2">County Served:</h6> */}
            <FormControl variant="outlined" sx={{ minWidth: 240 }} size="small">
              <InputLabel id="agency-label">Counties Served</InputLabel>
              <Select
                labelId="agency-label"
                id="county"
                value={county}
                label="county"
                defaultValue={""}
                onChange={(event) => setCounty(event.target.value)}
              >
                {counties.map((county) => (
                  <MenuItem value={county.name} key={county.id}>
                    {county.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl variant="outlined" sx={{ minWidth: 320 }} size="small">
              <InputLabel id="service-label">Service Offered</InputLabel>
              <Select
                labelId="service-label"
                id="service"
                value={service}
                label="service"
                defaultValue={""}
                onChange={(event) => setService(event.target.value)}
              >
                {services.map((service) => (
                  <MenuItem value={service.code} key={service.code}>
                    {service.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="text-center mt-5">
            <Button
              variant="contained"
              sx={{
                bgcolor: "orange",
                display: "inline",
                fontWeight: "bold",
                px: 12,
                py: 2,
                fontSize: 18,
                "&:hover": {
                  bgcolor: "grey",
                },
              }}
              onClick={handleSave}
            >
              Search
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ResourceGuideForm;
