import { Paper, Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getReport } from "../../services/reportService";
import auth from "../../services/authService";
import MonthSearch from "../admin/monthsearch";
import {
  getServiceTotalsByProgramIdAndReportingPeriod
} from "../../services/providedServicesService";

function ViewReports({ fiscalYear }) {
  
  const ProgramId = sessionStorage.getItem("ProgramId");
  const reportingPeriodId = sessionStorage.getItem("ReportingPeriod");

  const [lockReport, setLockReport] = useState(false);
  const [data, setData] = useState({
    fiscalYear: fiscalYear.value,
    programId: ProgramId,
  });
  

  
  const user = auth.getCurrentUser();

  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  const cardStyle = {
    padding: 20,
    //height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
  };

  const handleChange = (event) => {
    const id = event.target.id;
    let newData = { ...data };
    newData[id] = event.target.value;
    setData(newData);
  };

  const handleProgrammaticReport = async () => {
    var parameters = [
      {
        key: "Month",
        value: data.reportingPeriodId,
      },
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
      {
        key: "ProgramID",
        value: data.programId,
      },
      {
        key: "UserID",
        value: user.UserId,
      },
    ];

    await getReport("NewPssf_Programmatic_Report_PartA", parameters);
    await getReport("NewPssf_Programmatic_Report_PartB", parameters);
  };

  const handleFslDetailReport = async () => {
    var parameters = [
      {
        key: "Month",
        value: data.reportingPeriodId,
      },
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
      {
        key: "ProgramID",
        value: data.programId,
      },
    ];
    await getReport("NewPssf_Family_Service_Log_Detail", parameters);
  };

  const handleDSSReport = async () => {
    var parameters = [
      {
        key: "FiscalYear",
        value: data.fiscalYear,
      },
      {
        key: "ProgramID",
        value: data.programId,
      },
    ];
    
    await getReport("NewPssf_DSS_Invoice_Summary", parameters);
  };
  


  useEffect(() => {
    // lock report if The unit totals that have been entered require an approval by a PSSF Admin.
    const getServiceTotals  = async () => {
      const { data: serviceTotals } =
      await getServiceTotalsByProgramIdAndReportingPeriod(
        ProgramId,
        fiscalYear.value,
        reportingPeriodId
      );
  
      serviceTotals.forEach((service) => {
        let unitThreshold = 0.15 * service.unitsPerYear;
        if (service.totalUnits >= unitThreshold) {
          //Has more than 15% threshold met
         setLockReport(true);
        }
      });
    }
    getServiceTotals();
    setData({
      fiscalYear: fiscalYear.value,
      programId: ProgramId,
    });
  
  }, [fiscalYear,ProgramId, reportingPeriodId]);

  return (
    
    <Paper elevation={10} style={paperStyle}>
      <h1>View Reports</h1>
      <Card style={cardStyle}>
        <h4>Programmatic Reports History</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useReportingPeriodId={true}
          useFiscalYear={false}
          lockCurrentReport={lockReport}
        />
        <div className="pt-2">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handleProgrammaticReport}
            style={{ width: 150 }}
          >
            Get Report
          </button>
        </div>
      </Card>

      <Card style={cardStyle}>
        <h4>Family Service Log Detail</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useReportingPeriodId={true}
          useFiscalYear={false}
        />
        <div className="pt-2">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handleFslDetailReport}
            style={{ width: 150 }}
          >
            Get Report
          </button>
        </div>
      </Card>

      <Card style={cardStyle}>
        <h4>DSS Invoice</h4>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useFiscalYear={false}
        />
        <div className="pt-2">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handleDSSReport}
            style={{ width: 150 }}
          >
            Get Report
          </button>
        </div>
      </Card>
    </Paper>
  );
}

export default ViewReports;
