import React from "react";
import { Route, Routes } from "react-router-dom";
import ChangePassword from "../components/account/changePassword";
import ConfirmEmail from "../components/account/confirmEmail";
import LoginForm from "../components/account/loginForm";
import Logout from "../components/account/logout";
import Results from "../components/account/results";
import ForgotPassword from "../components/account/forgotPassword";
import ChangeForgotPassword from "../components/account/changeForgotPassword";

const AccountRoute = () => {
  return (
    <Routes>
      <Route path="/account/login" element={<LoginForm />} />
      <Route path="/account/logout" element={<Logout />} />
      <Route path="/account/changepassword" element={<ChangePassword />} />
      <Route path="/account/confirmemail" element={<ConfirmEmail /> } />
      <Route path="/account/resetconfirmation" element={<Results /> } />
      <Route path="/account/forgotpassword" element={<ForgotPassword />} />
      <Route path="/account/changeforgotpassword/" element={<ChangeForgotPassword />} />
    </Routes>
  );
};

export default AccountRoute;
