import http from "./httpService";

const apiEndpoint = "/Agency";

function agencyUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function createAgencyUrl() {
  return `${apiEndpoint}`;
}

function agencyFiscalYearUrl(id, fy) {
  return `${apiEndpoint}/${fy}/${id}`;
}

function agencyListWithFiscalYearUrl(fy) {
  return `${apiEndpoint}/${"List"}/${fy}`;
}

function agencyListAllWithFiscalYearUrl(fy) {
  return `${apiEndpoint}/${"ListAll"}/${fy}`;
}

export function getAgencyById(id) {
  return http.get(agencyUrl(id));
}

export function getAgencyList() {
  return http.get(createAgencyUrl());
}

export function getAgencyListWIthFiscalYear(fy) {
  return http.get(agencyListWithFiscalYearUrl(fy));
}

export function getAgencyListAllWIthFiscalYear(fy) {
  return http.get(agencyListAllWithFiscalYearUrl(fy));
}

export function getAgencyByFiscalYearandId(fy, id) {
  return http.get(agencyFiscalYearUrl(id, fy));
}

export function createAgency(Agency) {
  const body = { ...Agency };

  return http.post(createAgencyUrl(), body);
}

export function saveAgency(Agency) {
  if (Agency.id) {
    const body = { ...Agency };
    delete body.id;
    return http.post(agencyUrl(Agency.id), body);
  }
}
