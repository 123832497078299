import React from "react";
import { Link } from "react-router-dom";

const Hvs = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Support</h3>
          <h5 className="text-center">Home Visiting</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">Home Visiting</span> services must utilize
            evidence-based home visiting practice models to support positive
            parent/child relationships, promote optimal child health and
            development, enhance parental self-sufficiency, ensure safe home
            environments and prevent child abuse and neglect. While services can
            also be received elsewhere, the home is the primary service delivery
            setting. Programs vary, but components may include:
            <ul>
              <li>Education in effective parenting and childcare techniques</li>
              <li>
                Education on child development, health, safety, and nutrition
              </li>
              <li>Assistance in gaining access to social support networks </li>
              <li>
                Assistance in obtaining education, employment, and access to
                community services
              </li>
            </ul>
            Services are voluntary support and educational services designed to
            enhance parental capacity to care for children, strengthen
            parent/child relationships and help families identify and access
            community resources. Home visiting programs offer a variety of
            family-focused services to expectant parents and families with new
            babies and young children. They address issues such as maternal and
            child health, positive parenting practices, safe home environments,
            and access to services. Home Visiting services are limited to the
            following evidence-based programs for the prevention of child abuse
            and neglect and/or for child well-being:
            <ul>
              <li>Healthy Families</li>
              <li>Parents As Teachers</li>
              <li>
                Safe Care Augmented (for families who were the subject of a
                unsubstantiated report and referred by DFCS Family Support)
              </li>
            </ul>
            Services, provided in the home, include at a minimum, but are not
            necessarily limited to, assessment and screening, case
            management/service coordination, parent education and/or skill
            building, community linkages and supports. PSSF Home Visiting
            programs MUST maintain fidelity to the selected, approved home
            visitation practice model and satisfy requirements regarding staff
            qualifications, training and supervision, target population,
            services and service delivery. Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Hvs;
