import {  useState } from "react";
import {
  useEffect
}
from "react";
import {
  Paper,
  Box
}
from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import {
  getChildStatusAtExitOptions,
  getExitPermanencyPlans,
}
from "../../../services/casesService";
import {
  getFamilyServiceLog
}
from "../../../services/familyServiceLogService";

const ExitFormC = ({
  user,
  data,
  setData,
  getData,
  caseId,
  handleNext,
  handleBack,
  reportingPeriod
}) => {
  const [validated, setValidated] = useState(false);
  const [childStatusAtExitOptions, setChildStatusAtExitOptions] = useState({});
  const [exitPermanencyPlans, setExitPermanencyPlans] = useState({});
  const [existingFsl, setExistingFsl] = useState({});
  const [fslError, setFslError] = useState("");

  const handleChange = (event) => {
    const name = event.target.id;
    const newData = { ...data };
    newData[name] = event.target.value;
    setData(newData);
  };

  const handleCheck = (event) => {
    const name = event.target.name;
    const newData = { ...data };
    newData[name] = !newData[name];
    setData(newData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //event.stopPropagation();
    //console.log(data);
    //handleNext();
    let hasError = false;

    // ITDEV-186 - Benjamin Carter This is causing clients to next exit case and to resolve time is of the essence fixed many errors and need clients to not be held up to complete exit case 
    if (existingFsl && !existingFsl.programmaticReportComplete) {
      //setFslError("Unfinished FSL");
      //hasError = true;
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false || hasError === true) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleNext();
    }
    setValidated(true);
  };

  async function fetchData() {
    const { data: childStatusAtExitOptions } = await getChildStatusAtExitOptions();
    setChildStatusAtExitOptions(childStatusAtExitOptions);

    const { data: exitPermanencyPlans } = await getExitPermanencyPlans();
    setExitPermanencyPlans(exitPermanencyPlans);

    const { data: existingFsl } = await getFamilyServiceLog(caseId, reportingPeriod);
    setExistingFsl(existingFsl);
  }

  useEffect(() => {
    fetchData();
  }, [data]);

  return (
    <>
      <h1>Exit Section C</h1>
      <p className="fw-bold"><u>Safety</u></p>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="row px-2 form-switch mt-2" controlId="childAbuseAllegation">
          <div className="row">
            <Form.Label className="col-lg-6">Was an allegation or report of child abuse or neglect made to DFCS?</Form.Label>
            <div className="col-lg-4">
              <div className='d-inline-block me-4'>No</div>
              <div className='d-inline-block'>
                <Form.Check        
                  type={"checkbox"}
                  id="childAbuseAllegation"
                  name="childAbuseAllegation"
                  label=""
                  value={data.childAbuseAllegation|| false}
                  checked={data.childAbuseAllegation || false}
                  onChange={handleCheck}
                />
              </div>
              <div className='d-inline-block'>Yes</div>
            </div>
          </div>
        </Form.Group>

        {data.childAbuseAllegation && (
          <Form.Group className="row px-2 form-switch mt-2" controlId="allegationInvestigated">
            <div className="row">
              <Form.Label className="col-lg-6">Was an investigation conducted?</Form.Label>
              <div className="col-lg-4">
                <div className='d-inline-block me-4'>No</div>
                <div className='d-inline-block'>
                  <Form.Check        
                    type={"checkbox"}
                    id="allegationInvestigated"
                    name="allegationInvestigated"
                    label=""
                    value={data.allegationInvestigated || false}
                    checked={data.allegationInvestigated || false}
                    onChange={handleCheck}
                  />
                </div>
                <div className='d-inline-block'>Yes</div>
              </div>
            </div>
          </Form.Group>
        )}

        {data.allegationInvestigated && (
          <Form.Group className="row px-2 form-switch mt-2" controlId="allegationSubstantiated">
            <div className="row">
              <Form.Label className="col-lg-6">Was the allegation substantiated?</Form.Label>
              <div className="col-lg-4">
                <div className='d-inline-block me-4'>No</div>
                <div className='d-inline-block'>
                  <Form.Check        
                    type={"checkbox"}
                    id="allegationSubstantiated"
                    name="allegationSubstantiated"
                    label=""
                    value={data.allegationSubstantiated || false}
                    checked={data.allegationSubstantiated || false}
                    onChange={handleCheck}
                  />
                </div>
                <div className='d-inline-block'>Yes</div>
              </div>
            </div>
          </Form.Group>
        )}

        <Form.Group className="row px-2 form-switch mt-2" controlId="incidentViolence">
          <div className="row">
            <Form.Label className="col-lg-6">Was there an incident of violence in the home reported?</Form.Label>
            <div className="col-lg-4">
              <div className='d-inline-block me-4'>No</div>
              <div className='d-inline-block'>
                <Form.Check        
                  type={"checkbox"}
                  id="incidentViolence"
                  name="incidentViolence"
                  label=""
                  value={data.incidentViolence || false}
                  checked={data.incidentViolence || false}
                  onChange={handleCheck}
                />
              </div>
              <div className='d-inline-block'>Yes</div>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="row px-2 form-switch mt-2" controlId="incidentSubstanceAbuse">
          <div className="row">
            <Form.Label className="col-lg-6">Was there an incident of alcohol or prescription drug abuse or illegal substances used in the home?</Form.Label>
            <div className="col-lg-2">
              <div className='d-inline-block me-4'>No</div>
              <div className='d-inline-block'>
                <Form.Check        
                  type={"checkbox"}
                  id="incidentSubstanceAbuse"
                  name="incidentSubstanceAbuse"
                  label=""
                  value={data.incidentSubstanceAbuse || false}
                  checked={data.incidentSubstanceAbuse || false}
                  onChange={handleCheck}
                />
              </div>
              <div className='d-inline-block'>Yes</div>
            </div>
          </div>
        </Form.Group>

        {/* <Form.Group className="row px-4 form-switch" controlId="incidentSubstanceAbuse">
          <Form.Check
            type={"checkbox"}
            id="incidentSubstanceAbuse"
            name="incidentSubstanceAbuse"
            label="Was there an incident of alcohol or prescription drug abuse or illegal substances used in the home?"
            checked={data.incidentSubstanceAbuse}
            onChange={handleCheck}
          />
        </Form.Group> */}

        <Form.Group controlId="childStatusAtExitOptionId">
          <Form.Label>At the time of exit:</Form.Label>
          <Form.Select
            onChange={handleChange}
            required
            value={data.childStatusAtExitOptionId || ''}
          >
            <option value="">Open this select menu</option>
            {childStatusAtExitOptions.length > 1 &&
              childStatusAtExitOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <p className="fw-bold mt-4"><u>Permanency</u></p>
        <Form.Group controlId="exitPermanencyPlanId">
          <Form.Label>
            Indicate the status of the permanency plan at exit. If there was no
            permanency plan at intake (children were not in DFCS custody),
            select N/A.
          </Form.Label>
          {data.permanencyPlan && <div>Permanency Plan at Intake: {data.permanencyPlan.description}</div>}
          <Form.Select
            onChange={handleChange}
            required
            value={data.exitPermanencyPlanId || ''}
          >
            <option value="">Open this select menu</option>
            {exitPermanencyPlans.length > 1 &&
              exitPermanencyPlans.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <p className="fw-bold mt-4"><u>Well-being Outcomes</u></p>
        <Form.Label>
          During the period of service provision, caregiver(s) being served
          was/were:
        </Form.Label>
        <Form.Group controlId="youthMetDeleopmentalNeeds">
          <div className="row">
            <Form.Label className="col-md-8">Better able to meet the developmental and educational needs of the child(ren)/youth</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetDeleopmentalNeeds"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthMetDeleopmentalNeeds === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetDeleopmentalNeeds"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthMetDeleopmentalNeeds === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetDeleopmentalNeeds"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthMetDeleopmentalNeeds === "N/A"}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2" controlId="youthMetPhysicalHealth">
          <div className="row">
            <Form.Label className="col-md-8">Better able to meet the physical health needs of the child(ren)/youth</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetPhysicalHealth"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthMetPhysicalHealth === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetPhysicalHealth"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthMetPhysicalHealth === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetPhysicalHealth"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthMetPhysicalHealth === "N/A"}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2" controlId="youthMetSocialBehavior">
          <div className="row">
            <Form.Label className="col-md-8">Better able to meet the social/emotional/mental/behavioral health needs of the children/youth</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetSocialBehavior"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthMetSocialBehavior === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetSocialBehavior"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthMetSocialBehavior === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMetSocialBehavior"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthMetSocialBehavior === "N/A"}
            />
          </div>
        </Form.Group>


        <Form.Label>
          During the period of service provision, child(ren)/youth:
        </Form.Label>

        <Form.Group className="mb-2" controlId="youthMaintainedNonCustodial">
          <div className="row">
            <Form.Label className="col-md-8">Maintained regular contact and relationships with non-custodial parent(s)</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMaintainedNonCustodial"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthMaintainedNonCustodial === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMaintainedNonCustodial"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthMaintainedNonCustodial === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMaintainedNonCustodial"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthMaintainedNonCustodial === "N/A"}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2" controlId="youthMaintainedSiblings">
          <div className="row">
            <Form.Label className="col-md-8">Maintained regular contact and relationships with siblings or other relatives</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMaintainedSiblings"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthMaintainedSiblings === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMaintainedSiblings"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthMaintainedSiblings === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthMaintainedSiblings"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthMaintainedSiblings === "N/A"}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2" controlId="youthContinuedEducation">
          <div className="row">
            <Form.Label className="col-md-8">Maintained regular attendance in pre-school, K-12, and/or post-secondary education</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthContinuedEducation"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthContinuedEducation === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthContinuedEducation"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthContinuedEducation === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthContinuedEducation"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthContinuedEducation === "N/A"}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2" controlId="youthParticipatedExtracurricular">
          <div className="row">
            <Form.Label className="col-md-8">Participated in extracurricular activity at school or in community</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthParticipatedExtracurricular"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthParticipatedExtracurricular === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthParticipatedExtracurricular"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthParticipatedExtracurricular === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthParticipatedExtracurricular"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthParticipatedExtracurricular === "N/A"}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2" controlId="youthTruant">
          <div className="row">
            <Form.Label className="col-md-8">Was truant or delinquent</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthTruant"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthTruant === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthTruant"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthTruant === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthTruant"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthTruant === "N/A"}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2" controlId="youthAbusedSubstance">
          <div className="row">
            <Form.Label className="col-md-8">Abused alcohol, prescription drugs, or illegal substances</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthAbusedSubstance"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthAbusedSubstance === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthAbusedSubstance"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthAbusedSubstance === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthAbusedSubstance"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthAbusedSubstance === "N/A"}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2" controlId="youthBecamePregnant">
          <div className="row">
            <Form.Label className="col-md-8">Became pregnant</Form.Label>
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthBecamePregnant"}
              value={"No"}
              label={"No"}
              onChange={handleChange}
              checked={data.youthBecamePregnant === "No"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthBecamePregnant"}
              value={"Yes"}
              label={"Yes"}
              onChange={handleChange}
              checked={data.youthBecamePregnant === "Yes"}
            />
            <Form.Check
              className="col-md-1"
              required
              type={"radio"}
              name={"youthBecamePregnant"}
              value={"N/A"}
              label={"N/A"}
              onChange={handleChange}
              checked={data.youthBecamePregnant === "N/A"}
            />
          </div>
        </Form.Group>
        <div>
          {fslError && (
            <FormHelperText className="text-danger">Cases cannot be exited while a programmatic report is pending.</FormHelperText>
          )}
        </div>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
         
          <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit">{"Save & Continue"}</Button>
        </Box>
      </Form>
    </>
  );
};

export default ExitFormC;
