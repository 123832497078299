import React from "react";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";

import {
  FaGithub,
  FaCalendarAlt,
  FaBriefcase,
  FaFile,
  FaFileAlt,
  FaFileContract,
  FaConciergeBell,
  FaGraduationCap,
  FaCalendarDay,
  FaUser,
  FaTrophy,
  FaFileInvoiceDollar,
  FaInfo,
  FaUpload,
  FaRegFile,
  FaUsers,
  FaDumbbell,
} from "react-icons/fa";

import { Link } from "react-router-dom";
import ColoredLine from "../common/coloredLine";

const PowerNav = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  return ( 
    <div>
      <ProSidebar
        image={false}
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div
            style={{
              padding: "24px",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 14,
              letterSpacing: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            PSSF
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem>Power User</MenuItem>

            <SubMenu title="Home" icon={<FaFile />}>
              <MenuItem>
                <Link to="/poweruser/home" rel="noopener noreferrer">
                  Home
                </Link>
              </MenuItem>
            </SubMenu>

            <SubMenu title="Data" icon={<FaFile />}>
              <MenuItem>
                <Link to="/poweruser/datamanagement" rel="noopener noreferrer">
                  Data Management
                </Link>
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
   );
}
 
export default PowerNav;