import http from "./httpService";

const apiEndpoint = "/Users";

export function getAllUsers() {
  return http.get(apiEndpoint+ "/GetAllUsers");
}




export function getUserContactsByRole(roleId) {
  return http.get(apiEndpoint + "/GetAllUserRoles/" + roleId);
}
