import React from 'react'

const BannerStatusBar = () => {
    const environment = process.env.REACT_APP_ENV;

    let bannerContent = null;
    if (environment === 'Staging') {
        bannerContent = <div className="banner-staging bg-danger text-center">Viewing In Staging Mode</div>;
    }  else if (environment === 'QA') {
        bannerContent = <div className="banner-qa bg-danger text-center">Viewing In QA Mode</div>;
    }  else if (environment === 'Development') {
        bannerContent = <div className="banner-development bg-danger text-center">Viewing In Development Mode</div>;
    }

    return (
        <div className="bg-danger text-center text-white fs-6 container-fluid">
            {bannerContent}
        </div>
    )
}

export default BannerStatusBar