import React from 'react';
import { useParams } from "react-router-dom";
import ContactEditForm from '../forms/contacteditfom';

const ContactEdit = () => {
  const { id } = useParams();
  return ( 
    <>
      <ContactEditForm id={id} />
    </>
  );
}
 
export default ContactEdit;