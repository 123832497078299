/* eslint-disable jsx-a11y/anchor-is-valid */
import ModeEditOutlineSharpIcon from "@mui/icons-material/ModeEditOutlineSharp";
import { Box, Paper, Stack } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import {
  getContractMgmtByFyAndId,
  getProgramsSonsByFiscalYear,
  saveContract,
  saveContractMgmtData,
} from "../../services/getSonServices";

import _ from "lodash";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { checkFileExists, downloadFile } from "../../services/downloadService";
import { sendEmail } from "../../services/emailService";
import CustomDataTable from "../common/customDataTable";

const ExecContractMgmt = () => {
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [unsignedEmail, setUnsignedEmail] = useState("");
  const [executedEmail, setExecutedEmail] = useState("");
  const [file1Filename, setFile1Filename] = useState("");
  const [unsignedContractExists, setUnsignedContractExists] = useState(false);
  const [executedContractExists, setExecutedContractExists] = useState(false);
  const [file2Filename, setFile2Filename] = useState("");
  const [emailContact, setEmailContact] = useState("");
  const [orderBy] = useState("sonId");
  const [order] = useState("asc");

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });

  const [LocalFiscalYear] = useState(sessionStorage.getItem("LocalFiscalYear"));

  async function handleContracts() {
    if (data.unsignedContract) {
      let formData = new FormData();
      formData.append("FormFile", data.unsignedContract);
      formData.append("LocalFiscalYear", LocalFiscalYear);
      formData.append("sonId", data.sonId);
      const formDataObject = {};
      for (const [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }
      saveContract(data.programId, "unsignedContracts", formData);
      data.dateSentToProvider = new Date();
      setData({ ...data });
    }

    if (data.executedContract) {
      let formData = new FormData();
      formData.append("FormFile", data.executedContract);
      formData.append("LocalFiscalYear", LocalFiscalYear);
      formData.append("sonId", data.sonId);
      const formDataObject = {};
      for (const [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }
      saveContract(data.programId, "executedContracts", formData);
      data.dateDivisionExecuted = new Date();
      setData({ ...data });
    }
  }

  const sortRows = useCallback(
    async (rowsToSort) => {
      const sorted = _.orderBy(rowsToSort, orderBy, order);
      setRows(sorted);
    },
    [order, orderBy]
  );

  const fetchData = useCallback(async () => {
    const { data: rows } = await getProgramsSonsByFiscalYear(LocalFiscalYear);

    // Flattening nested data
    const transformedRows = rows.map((row) => {
      const programData = row.programByFiscalYear[0];

      // Extracting required fields and placing them at the root level of the row object
      return {
        ...row,
        programStatus: programData?.programStatus || false,
        contractNumber: programData?.contractNumber || "",
        dateSentToProvider: programData?.dateSentToProvider || "",
        dateProviderSigned: programData?.dateProviderSigned || "",
        dateSentToDivision: programData?.dateSentToDivision || "",
        dateDivisionExecuted: programData?.dateDivisionExecuted || "",
      };
    });

    // Sorting rows
    sortRows(transformedRows);
  }, [LocalFiscalYear, sortRows]);

  async function saveData() {
    await saveContractMgmtData(LocalFiscalYear, data.programId, data);
    setShow(false);
    fetchData();
  }

  const fetchDataById = useCallback(
    async (id) => {
      const { data } = await getContractMgmtByFyAndId(LocalFiscalYear, id);
      setData(data);
      var contact = data.contacts.filter(
        (contact) => contact.type === "SoN Contact"
      )[0];
      setEmailContact(contact);

      var unsignedContractUrl = `unsignedContracts~${LocalFiscalYear}~${id}`;
      var executedContractUrl = `executedContracts~${LocalFiscalYear}~${id}`;

      const { data: executedContractExists, filename: file2Filename } =
        await checkFileExists(
          executedContractUrl,
          `${data.sonId}_executed.pdf`,
          `${data.sonId}_file2`
        );
      setFile2Filename(file2Filename);
      setExecutedContractExists(executedContractExists);

      const { data: unsignedContractExists, filename: file1Filename } =
        await checkFileExists(
          unsignedContractUrl,
          `${data.sonId}_unsigned.pdf`,
          `${data.sonId}_file1`
        );
      setFile1Filename(file1Filename);
      setUnsignedContractExists(unsignedContractExists);

      setUnsignedEmail(
        `<p><strong>${contact.name},</strong></p>
      <p>
        A file has been added to your FFY${LocalFiscalYear}  Contract page. Please login using your SONID and password to view. If discrepancies are 
        found upon review, please email contractsupport@pssfnet.com with your SoN# as the subject line.</p>
      <p> <br /><br />
        <em>PSSF TA Team</em>
      </p>
      `
      );
      setExecutedEmail(
        `<p><strong>${contact.name},</strong></p>
      <p>
        A file has been added to your  FFY${LocalFiscalYear} Contract page. Please login using your SONID and password to view. If discrepancies are 
        found upon review, please email contractsupport@pssfnet.com with your SoN# as the subject line.<br />
      </p>
      <p><br /><br />
      <em>PSSF TA Team</em>
      </p>
      `
      );
    },
    [LocalFiscalYear]
  );

  //Below this line and until return is stuff that I did not change from Chris's code and can be rolled in to a component that makes this whole table structure reusable.
  const handleClose = () => {
    setData({});
    setShow(false);
  };
  const handleShow = (id) => {
    fetchDataById(id);
    setShow(true);
  };

  async function handleSave() {
    await handleContracts();
    await saveData(data);
    setData(data);
  }

  const handleChange = (event) => {
    event.preventDefault();

    const name = event.target.id;

    const copyOfObject = { ...data };
    delete copyOfObject[name];

    const updatedValue = {};
    if (event.target.files) {
      updatedValue[name] = event.target.files[0];
    } else {
      updatedValue[name] = event.target.value;
    }

    let newData = {
      ...copyOfObject,
      ...updatedValue,
    };

    setData(newData);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      field: "actions",
      headerName: "",
      flex: 0.4,
      renderCell: (params) => (
        <a onClick={() => handleShow(params?.row.id)}>
          <ModeEditOutlineSharpIcon />
          <span
            title={params?.row.id}
            className="flex d-inline-flex"
            style={{ fontSize: "0.8rem" }}
          >
            Edit {params?.row.id}
          </span>
        </a>
      ),
    },
    { field: "sonId", headerName: "SoN", flex: 0.7, sortable: true },
    { field: "agencyName", headerName: "Agency", flex: 1.1, sortable: true },
    { field: "name", headerName: "Program", flex: 1.1, sortable: true },
    {
      field: "programStatus",
      headerName: "Program Status",
      flex: 0.7,
      align: "center",
      renderCell: (params) => (
        <div>{params?.value ? "Active" : "Inactive"}</div>
      ),
    },
    {
      field: "contractNumber",
      headerName: "Contract Number",
      flex: 1,
      align: "center",
      valueFormatter: (value) => (value ? value : "- not entered -"),
    },
    {
      field: "dateSentToProvider",
      headerName: "Complete To Provider",
      flex: 1,
      align: "center",
      valueFormatter: (value) => {
        if (!value) return "- not entered -";

        const date = new Date(value);
        const formattedDateTime = date.toLocaleString();
        return formattedDateTime;
      },
    },
    {
      field: "dateProviderSigned",
      headerName: "Uploaded By Provider",
      flex: 1.3,
      align: "center",
      renderCell: (params) =>
        params?.value ? (
          <button
            className="btn btn-link btn-sm"
            onClick={() =>
              downloadFile(
                `signedContracts~${LocalFiscalYear}~${params.row.id}`,
                `${params?.row.sonId}_signed.pdf`
              )
            }
          >
            {params?.value ? new Date(params.value).toLocaleString() : ""}
          </button>
        ) : (
          "- not entered -"
        ),
    },
    {
      field: "dateSentToDivision",
      headerName: "Complete Date",
      flex: 1,
      align: "center",
      valueFormatter: (value) => {
        if (!value) return "- not entered -";

        const date = new Date(value);
        const formattedDateTime = date.toLocaleString();
        return formattedDateTime;
      },
    },
    {
      field: "dateDivisionExecuted",
      headerName: "Received From Division",
      flex: 1.3,
      align: "center",
      valueFormatter: (value) => {
        if (!value) return "- not entered -";

        const date = new Date(value);
        const formattedDateTime = date.toLocaleString();
        return formattedDateTime;
      },
    },
  ];

  return (
    <>
      <div className="mb-2">
        <h1>Executed Contract Management</h1>
        <div className="mb-2">
          <span className="badge bg-success mb-4 p-3">
            Viewing System Fiscal Year {LocalFiscalYear}
          </span>
          <br />
        </div>
        <Box width={"99%"}>
          <Paper>
            <CustomDataTable
              rows={rows}
              columns={columns}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              sortingOrder={["asc", "desc"]}
              getRowHeight={() => "auto"}
              sx={{
                "& .MuiDataGrid-cell": {
                  textOverflow: "initial",
                },
              }}
            />
          </Paper>
        </Box>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Contract Management Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <span>
                <span className="fw-bold pe-2">SoN:</span>
                {data?.sonId}
              </span>
              <span>
                <span className="fw-bold pe-2 text-end">Program Id:</span>
                <span className="text">{data?.programId}</span>
              </span>
            </Stack>
            <span className="text">{data?.contractNumber}</span>
            <hr />
            <Form>
              <Form.Group className="mb-2 fw-bold" controlId="contractNumber">
                <Form.Label>Contract Number:</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  value={data?.contractNumber ? data?.contractNumber : ""}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-2 fw-bold"
                controlId="dateSentToDivision"
              >
                <Form.Label>Date Sent To Division:</Form.Label>
                <Form.Control
                  type="date"
                  className="form-control-sm"
                  value={
                    data.dateSentToDivision
                      ? new Date(data.dateSentToDivision)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <hr />
              <Form.Group
                className="mb-2 mt-2 fw-bold"
                controlId="unsignedContract"
              >
                <Form.Label>Upload File 1:</Form.Label>
                <Form.Control type="file" onChange={handleChange} autoFocus />
              </Form.Group>
              <Button
                variant="primary"
                onClick={() =>
                  sendEmail({
                    to: emailContact?.email,
                    body: unsignedEmail,
                    subject: `${data?.sonId} New File Added by Admin`,
                    cc: "contractsupport@pssfnet.com",
                  })
                }
              >
                Notify 1
              </Button>{" "}
              {unsignedContractExists ? (
                <a
                  className="btn btn-link"
                  onClick={() =>
                    downloadFile(
                      `unsignedContracts~${LocalFiscalYear}~${data?.programId}`,
                      `${data.sonId}_unsigned.pdf`,
                      `${data.sonId}_file1`
                    )
                  }
                >
                  {`${data.sonId}_file1.` + file1Filename.split(".").pop()}
                </a>
              ) : null}
              <hr />
              <Form.Group
                className="mb-2 mt-2 fw-bold"
                controlId="executedContract"
              >
                <Form.Label>Upload File 2:</Form.Label>
                <Form.Control type="file" onChange={handleChange} autoFocus />
              </Form.Group>
              <Button
                variant="primary"
                onClick={() =>
                  sendEmail({
                    to: emailContact?.email,
                    body: executedEmail,
                    subject: `${data?.sonId} New File Added by Admin`,
                    cc: "contractsupport@pssfnet.com",
                  })
                }
              >
                Notify 2
              </Button>{" "}
              {executedContractExists ? (
                <a
                  className="btn btn-link"
                  onClick={() =>
                    downloadFile(
                      `executedContracts~${LocalFiscalYear}~${data?.programId}`,
                      `${data.sonId}_executed.pdf`,
                      `${data.sonId}_file2`
                    )
                  }
                >
                  {`${data.sonId}_file2.` + file2Filename.split(".").pop()}
                </a>
              ) : null}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ExecContractMgmt;
