import React, { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Moment from "moment";
import FormHelperText from "@mui/material/FormHelperText";
import { getPermanencyPlans } from "../../../services/casesService";

const CaseFormE = ({
  user,
  data,
  setData,
  getData,
  caseId,
  handleNext,
  handleBack,
  handleRadioChange
}) => {
  const [pc, setPc] = useState({});
  const [validated, setValidated] = useState(false);
  const [permanencyPlans, setPermanencyPlans] = useState({});




  const handleChange = (event) => {
    const name = event.target.id;
    const newData = { ...data };
    if (name === "permanencyPlan") {
      newData[name] = {
        id: parseInt(event.target.value),
        description: event.target.options[event.target.selectedIndex].text,
      };
    } else {
      newData[name] = event.target.value;
    }
    setData(newData);
  };

  const handleCheck = (event) => {
    const name = event.target.name;
    const newData = { ...data };
    newData[name] = !newData[name];
    setData(newData);
  };

  useEffect(() => {
      async function fetchData() {
        var pc =
          data.caregivers &&
          data.caregivers.find((caregiver) => caregiver.isPrimary);
        setPc(pc);

        const { data: permanencyPlans } = await getPermanencyPlans();
        setPermanencyPlans(permanencyPlans);
      }

    fetchData();
  }, [data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleNext();
    }
    setValidated(true);
  };

  const durationOptions = [
    { id: 1, name: "Less Than 1 Month" },
    { id: 2, name: "1-6 Months" },
    { id: 3, name: "Up To 1 Year" },
    { id: 4, name: "1 Year To 18 Months" },
    { id: 5, name: "More Than 18 Months" },
  ];

  return (
    <>
      <div className="py-4">
        <h5>
          Primary Caregiver: {pc.firstName} {pc.lastName}
        </h5>
        <h6>Case Id: {caseId}</h6>
      </div>

      <h6>Intake Section E: Assessment &amp; Service Plan</h6>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="row px-4" controlId="assessment">
          <Form.Check
            type={"checkbox"}
            id="assessment"
            checked={data.assessment || false}
            name="assessment"
            label="An assessment was conducted at intake to identify any safety concerns and risk factors to be addressed in the individualized service plan."
            onChange={handleCheck}
          />
        </Form.Group>
        {data.assessment && (
          <div className="row">
            <Form.Group className="col-md-6 mb-2" controlId="assessmentDate">
              <Form.Label className="fw-bold">
                Enter date of assessment
              </Form.Label>
              <Form.Control
                type="date"
                className="form-control-sm"
                defaultValue={Moment(data.assessmentDate).format("YYYY-MM-DD")}
                onChange={handleChange}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="col-md-6 mb-2" controlId="assessmentName">
              <Form.Label className="fw-bold">
                Enter name of standardized assessment used
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.assessmentName}
                onChange={handleChange}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group
              className="col-md-6 mb-2"
              controlId="assessmentNameOther"
            >
              <Form.Label className="fw-bold">
                Enter name of any other screening tools used by this program for
                this individual/family at intake
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.assessmentNameOther}
                onChange={handleChange}
                autoFocus
                required
              />
            </Form.Group>
          </div>
        )}
        <Form.Group className="row px-4" controlId="servicesByReferral">
          <Form.Check
            type={"checkbox"}
            id="servicesByReferral"
            checked={data.servicesByReferral || false}
            name="servicesByReferral"
            label="Services and/or Service plan were specified by the referral source."
            onChange={handleCheck}
          />
        </Form.Group>

        <Form.Group className="col-md-6 mb-2" controlId="additionalAssessment">
          <Form.Label className="fw-bold">
            Additional Assessment
            <FormHelperText>
              If this intake is placement change, was an additional standardized
              assessment necessary to address any new safety concerns and risk
              factors identified as a result of the change?
            </FormHelperText>
          </Form.Label>
          <Form.Check
            required
            type={"radio"}
            name={"additionalAssessment"}
            value={0}
            label={"No"}
            onChange={(e) => {
              handleChange(e);
              handleRadioChange("additionalAssessment", 0);
            }}
            checked={data.additionalAssessment === 0}
          />
          <Form.Check
            required
            type={"radio"}
            name={"additionalAssessment"}
            value={1}
            label={"Yes"}
            onChange={(e) => {
              handleChange(e);
              handleRadioChange("additionalAssessment", 1);
            }}
            checked={data.additionalAssessment === 1}
          />
          <Form.Check
            required
            type={"radio"}
            name={"additionalAssessment"}
            value={2}
            label={"N/A"}
            onChange={(e) => {
              handleChange(e);
              handleRadioChange("additionalAssessment", 2);
            }}
            checked={data.additionalAssessment === 2}
          />
        </Form.Group>

        {data.additionalAssessment === 1 && (
          <div className="row">
            <Form.Group
              className="col-md-6 mb-2"
              controlId="additionalAssessmentDate"
            >
              <Form.Label className="fw-bold">
                Enter date of additional assessment
              </Form.Label>
              <Form.Control
                type="date"
                className="form-control-sm"
                defaultValue={Moment(data.additionalAssessmentDate).format(
                  "YYYY-MM-DD"
                )}
                onChange={handleChange}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group
              className="col-md-6 mb-2"
              controlId="additionalAssessmentName"
            >
              <Form.Label className="fw-bold">
                Enter name of additional standardized assessment used
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={data.additionalAssessmentName}
                onChange={handleChange}
                autoFocus
                required
              />
            </Form.Group>
          </div>
        )}

        <Form.Group className="col-md-6 mb-2" controlId="planDuration">
          <Form.Label className="fw-bold">
            Based on needs identified to be be addressed by core services, what
            is the expected duration of this individualized service plan?
          </Form.Label>
          <Form.Select
            onChange={handleChange}
            required
            defaultValue={data.planDuration}
          >
            <option value="">Open this select menu</option>
            {durationOptions.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-2" controlId="howInvolvedFamily">
          <Form.Label className="fw-bold">
            How involved was the family/individual in the development of their
            service plan?
            <FormHelperText>
              Answer n/a if services and/or service plan were specified by
              referral source.
            </FormHelperText>
          </Form.Label>
          <Form.Check
            required
            type={"radio"}
            name={"howInvolvedFamily"}
            value={0}
            label={"Very Involved"}
            onChange={(e) => {
              handleChange(e);
              handleRadioChange("howInvolvedFamily", 0);
            }}
            checked={data.howInvolvedFamily === 0}
          />
          <Form.Check
            required
            type={"radio"}
            name={"howInvolvedFamily"}
            value={1}
            label={"Somewhat Involved"}
            onChange={(e) => {
              handleChange(e);
              handleRadioChange("howInvolvedFamily", 1);
            }}
            checked={data.howInvolvedFamily === 1}
          />
          <Form.Check
            required
            type={"radio"}
            name={"howInvolvedFamily"}
            value={2}
            label={"Not Very Involved"}
            onChange={(e) => {
              handleChange(e);
              handleRadioChange("howInvolvedFamily", 2);
            }}
            checked={data.howInvolvedFamily === 2}
          />
          <Form.Check
            required
            type={"radio"}
            name={"howInvolvedFamily"}
            value={3}
            label={"N/A"}
            onChange={(e) => {
              handleChange(e);
              handleRadioChange("howInvolvedFamily", 3);
            }}
            checked={data.howInvolvedFamily === 3}
          />
        </Form.Group>

        <Form.Group className="col-md-6 mb-2" controlId="permanencyPlan">
          <Form.Label className="fw-bold">
            Permanency Plan
            <FormHelperText>
              Only select a permanency plan if child(ren) has been removed from
              the home and placed in Foster Care (or with relatives). Otherwise,
              select N/A.
            </FormHelperText>
          </Form.Label>
          <Form.Select
            onChange={handleChange}
            required
            value={data?.permanencyPlan?.id ? data.permanencyPlan.id : ""}
          >
            <option value="">Open this select menu</option>
            {permanencyPlans.length > 1 &&
              permanencyPlans.map((option) => {
                if (option.id !== 7) {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.description}
                    </option>
                  );
                }
                return null;
              })}
          </Form.Select>
        </Form.Group>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit">{"Save & Continue"}</Button>
        </Box>
      </Form>
    </>
  );
};

export default CaseFormE;
