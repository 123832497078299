import { Button, Container, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getAgencyResourceGuideDetailsList } from "../../services/resourceGuideService";
import { Navigate } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#415868",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
function GuideResults() {
  const classes = useStyles();
  const location = useLocation();
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const agency = location.state && location.state.agency;
  const county = location.state && location.state.county;
  const service = location.state && location.state.service;
  const fiscalYear = location.state && location.state.fiscalYear;
  const [submit, setSubmit] = useState(false);
  const [programId, setProgramId] = useState(false);

  const populateList = () => {
    getAgencyResourceGuideDetailsList(fiscalYear, service, agency, county).then(
      ({ data }) => {
        setData(data);
        setCount(data.length);
      }
    );
  };

  const handleNavigateBack = () => {
    window.history.back();
  };

  const handleDetail = (programId) => {
    setProgramId(programId)
    setSubmit(true);
  };

  useEffect(() => {
    populateList();
  }, []);
  return (
    <>
      {submit && (
        <Navigate
          to={"/guide/details"}
          replace={false}
          state={{
            fiscalYear: fiscalYear,
            programId: programId,
          }}
        />
      )}
      <Container
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div
          style={{
            marginTop: 8,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "orange",
              display: "inline",
              px: 12,
              py: 2,
              fontSize: 16,
            }}
            onClick={handleNavigateBack}
          >
            Return to Search
          </Button>
        </div>
        <Typography variant="h5">({count}) Search Results</Typography>
        {data.map((row, index) => (
          <React.Fragment key={index}>
            <TableContainer
              component={Paper}
              className="mb-3 mt-2"
              key={row.programId}
            >
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Agency Name:</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.agencyName} ({row.agencyId})
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      PrimaryCounty:
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.county}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow style={{ backgroundColor: "#ede7de" }}>
                    <StyledTableCell component="th" scope="row">
                      Program Name:
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.programName}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Counties Served:
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.countiesServed}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow style={{ backgroundColor: "#ede7de" }}>
                    <StyledTableCell component="th" scope="row">
                      PSSF Program Area:
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.programArea}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Service Model:
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.serviceModel}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              key={`${row.programId}-button`}
              component="span"
              sx={{ display: "flex", justifyContent: "center", mb: 5 }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "orange",
                  display: "inline",
                  fontWeight: "bold",
                  px: 12,
                  py: 2,
                  fontSize: 12,
                }}
                onClick={() => handleDetail(row.programId)}
              >
                More Information {">>"}
              </Button>
            </Box>
          </React.Fragment>
        ))}
      </Container>
    </>
  );
}

export default GuideResults;
