import React from 'react';
import SoNEditForm from '../forms/soneditform';
import { useParams } from 'react-router-dom';

const SoNEdit = () => {
  const { id, fy } = useParams();
  return (
    <>
      <div className="row p-4">
        <span className="text-success fw-bold fs-4
        
        ">Program Management</span>
        <SoNEditForm id={id} fy={fy} />
      </div>

    </>
  );
}
 
export default SoNEdit;