import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useCallback } from "react";
import {
  getSonUsers,
} from "../../services/tpsBridgeService";
import TpsConnectorModal from "../admin/tpsconnectormodal";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SendSonAccountCredentialsSingle } from "../../services/emailService";
import CustomDataTable from "../common/customDataTable";

export default function TpsBridgeTable(props) {
  const {projectId, fiscalYear, isEmailing, setIsEmailing} = props;
  const [rows, setRows] = useState([]);
  const [loadingData, setLoadingData] = useState(true); // Loading state for data fetching
  const [loadingProject, setLoadingProject] = useState(true); // Loading state for project changes
  const [openModal, setOpenModal] = useState(false);
  const [selectedAgProjId, setSelectedAgProjId] = useState(null);
  const [selectedAgencyName, setSelectedAgencyName] = useState(null);
  const [selectedProgramName, setSelectedProgramName] = useState(null);
  const [selectedProposalId, setSelectedProposalId] = useState(null);
  const [applicantName, setApplicantName] = useState(null);
  const [fiscalAgentName, setFiscalAgentName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [agencyProgramId, setAgencyProgramId] = useState(null);
  const [userId, setUserId] = useState(null);

  const handleSendEmail = async (rowData) => {
    setIsEmailing(rowData.id); // Show loading indicator on button click
    try {
      await SendSonAccountCredentialsSingle(rowData.sonId);
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setIsEmailing(-1); // Set back to false after email sending is complete
    }
  };

const columns = [
  { field: "sonId", headerName: "SoN ID", flex: 0 },
  {
    field: "agencyName",
    headerName: "Agency Name",
    flex: 2,
    align: "left",
    renderCell: (params) => {
      const handleCellClick = () => {
        //const rowData = params.row;
        // Open modal or perform any action
      };

      const isLinkFormed = params.row.tpsLink === "Link Formed";
      return (
        <button
          onClick={handleCellClick}
          style={{
            color: isLinkFormed ? "#999999" : "#799447", // Gray out the link if tpsLink > 0
            background: "none",
            border: "none",
            cursor: "pointer",
            textAlign: "left", // Align the button text to the left
            textDecoration: isLinkFormed ? "none" : "underline", // Remove underline if tpsLink > 0
          }}
          disabled={isLinkFormed} // Disable the button if tpsLink > 0
        >
          {params.value}
        </button>
      );
    },
  },
  { field: "programName", headerName: "Program Name", flex: 1 },
  {
    field: "applicantName",
    headerName: "Applicant Name",
    flex: 1,
  },
  {
    field: "agencyType",
    headerName: "Agency Type",
    description: "This column has a value getter and is not sortable.",
    sortable: true,
    flex: 1,
  },
  {
    field: "tpsLink",
    headerName: "",
    sortable: true,
    flex: 0.5,
  },
  {
    field: "sendEmail",
    headerName: "Send Email",
    sortable: false,
    flex: 0,
    renderCell: (params) => (
      <LoadingButton
        variant="contained"
        color="primary"
        disabled={isEmailing !== -1}
        onClick={() => handleSendEmail(params.row)}
        loading={isEmailing === params.row.id}
      >
        Send
      </LoadingButton>
    ),
  },
];

  const handleModalSubmit = () => {
    fetchData();
  };

  const fetchData = useCallback(async () => {
    try {
      setLoadingData(true); // Set loading state to true
      const { data } = await getSonUsers(projectId);
      const transformedDataPromise = transformData(data);
      const transformedData = await transformedDataPromise;
      setRows(transformedData);
      setLoadingData(false); // Set loading state to false
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingData(false); // Set loading state to false
    }
  }, [projectId]);

  useEffect(() => {
    setLoadingProject(true); // Set loading state to true when changing project
    fetchData();
    setLoadingProject(false); // Set loading state to false after fetching data
  }, [projectId,fetchData]);

  const transformData = async (data) => {
    return data.map((item) => {
      return {
        id: item.proposalId,
        sonId: `${item.projectPrefix}${item.proposalId}`,
        agencyName: item.agencyName,
        programName: item.programName,
        applicantName: `${item.userFirstName} ${item.userLastName}`,
        agencyType: item.agType,
        agProjId: item.agProjId,
        fiscalAgentName: item.fiscalAgentName,
        tpsLink: item.tpsLink > 0 ? "Link Formed" : "",
        email: item.userEmail,
        phone: item.userPhone,
        agencyprogramId: item.agencyprogramId,
        userId: item.userId,
      };
    });
  };

  const handleCellClick = (params) => {
    const rowData = params.row;
    setSelectedAgProjId(rowData.agProjId);
    setSelectedAgencyName(rowData.agencyName);
    setSelectedProgramName(rowData.programName);
    setSelectedProposalId(rowData.id);
    setApplicantName(rowData.applicantName);
    setFiscalAgentName(rowData.fiscalAgentName);
    setUserEmail(rowData.email);
    setUserPhone(rowData.phone);
    setAgencyProgramId(rowData.agencyprogramId);
    setUserId(rowData.userId);
    setOpenModal(true);
  };

  return (
    <>
      {loadingData || loadingProject ? ( // Display "Loading..." when loadingData or loadingProject is true
        <Grid
          container
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Loading...
        </Grid>
      ) : (
        <div style={{ width: "99%" }}>
          <CustomDataTable
            rows={rows}
            columns={columns}
            pageSize={100}
            onCellClick={(params) => {
              if (params.field !== "sendEmail") {
                handleCellClick(params);
              }
            }}
          />
        </div>
      )}
      {openModal && (
        <TpsConnectorModal
          agProjId={selectedAgProjId}
          agencyName={selectedAgencyName}
          programName={selectedProgramName}
          proposalId={selectedProposalId}
          applicantName={applicantName}
          fiscalAgentName={fiscalAgentName}
          fiscalYear={fiscalYear}
          projectId={projectId}
          email={userEmail}
          phone={userPhone}
          agencyProgramId={agencyProgramId}
          userId={userId}
          onClose={() => setOpenModal(false)}
          onSubmit={handleModalSubmit}
        />
      )}
    </>
  );
}
