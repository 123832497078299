import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getTPSProjects } from "../../services/tpsBridgeService";
import TpsBridgeTable from "../Tables/tpsBridgeTable";
import { Box } from "@mui/material";
import { SendSonAccountCredentials } from "../../services/emailService";
import { LoadingButton } from "@mui/lab";

export default function TpsConnector(props) {
  const [projectList, setProjectList] = useState([]);
  const [project, setProject] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEmailing, setIsEmailing] = useState(-1); //use -1 as off, this will store the row ID of what is being emailed, 0 being the mail all option.

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedProject = projectList.find(
      (projectItem) => projectItem.projectFiscalYear === selectedValue
    );
    setProject(selectedValue);
    setSelectedProjectId(selectedProject?.projectId);
  };

  const handleEmail = async () => {
    setIsEmailing(0); // Show loading indicator on button click
    try {
      await SendSonAccountCredentials(project);
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setIsEmailing(-1); // Set back to false after email sending is complete
    }
  };

  useEffect(() => {
    const populateProjects = async () => {
      try {
        const { data } = await getTPSProjects();
        setProjectList(data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };

    populateProjects();
  }, []);

  useEffect(() => {
    const selectedProject = projectList.find(
      (projectItem) => projectItem.projectFiscalYear === project
    );
    setSelectedProjectId(selectedProject?.projectId);
  }, [project, projectList, selectedProjectId]);

  if (loading) {
    return <Grid container>Loading...</Grid>;
  }

  return (
    <Grid container mt={3}>
      <Grid item xs={12} mb={4}>
        <Typography variant="h4">TPS Applicants</Typography>
      </Grid>
      <Grid item xs={4} mb={4}>
        <FormControl fullWidth>
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            labelId="project-label"
            id="project"
            value={project}
            label="Project"
            onChange={handleChange}
          >
            {projectList.map((projectItem) => (
              <MenuItem
                key={projectItem.projectId}
                value={projectItem.projectFiscalYear}
              >
                {projectItem.projectName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Box flexGrow={1} />
      {selectedProjectId && (
        <Grid item xs={4}>
          <LoadingButton
            loading={isEmailing === 0} // Set the loading state of the button
            disabled={isEmailing !== -1}
            variant="contained"
            color="primary"
            onClick={handleEmail} // Call the handleEmail function on button click
          >
            Email Username and Password TO aLL Linked Users
          </LoadingButton>
        </Grid>
      )}
      {selectedProjectId && (
        <TpsBridgeTable
          projectId={selectedProjectId}
          fiscalYear={project}
          isEmailing={isEmailing}
          setIsEmailing={setIsEmailing}
        />
      )}
    </Grid>
  );
}
