import http from "./httpService";
import download from 'downloadjs'

const username = "reports";
const password = "fleetw00d!";
const reportUrl = process.env.REACT_APP_REPORT_URL;
const urlPredicate = "&rs:Command=Render&rs:Format=";
let ext = ".pdf";

export async function getReport(reportName, parameters, format ="PDF" ) {
  const tok = `${username}:${password}`;
  const hash = btoa(tok);
  const Basic = "Basic " + hash;

  let url = `${reportUrl}${reportName}&`;
  
  parameters.map( (parameter, i) => {
    url = url + `${parameter.key}=${parameter.value}`
    if(parameters.length !== i + 1) url = url + "&"
  });

  if (format === "excel") ext = ".xls";
  if (format === "PDF") ext = ".pdf";

  return http
    .get(`${url}${urlPredicate}${format}`, {
      headers: { Authorization: Basic},
      responseType: "blob",
    })
    .then(response => {
      const content = response.headers['content-type'];
      download(response.data, `${reportName}${ext}`, content)
   })
   .catch(error => console.log(error));
}