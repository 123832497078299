import React from "react";
import auth from "../../services/authService";
import ChangePasswordForm from "../forms/changePasswordForm";

const ChangePassword = () => {
    return (
      <>
        <ChangePasswordForm />
      </>
    );
}
 
export default ChangePassword;