import React, { Component } from "react";
import ResourceGuideForm from "../forms/resourceGuideForm";

class Guide extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row justify-content-center">
          <div
            className="col-10"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={require("../../img/masthead2.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="row mt-5 mb-5 text-center">
            <h3 className="fw-bold">Search The Resource Guide!</h3>
          </div>
          <div className="row justify-content-around">
            <div className="col-10">
              <div className="border border-warning p-4 mb-5">
                <p>
                  Welcome to the Promoting Safe and Stable Families Program{" "}
                  Family Service Resource Guide.
                  <br />
                  Information is available for all service providers currently
                  receiving funding from Promoting Safe and Stable Families.
                </p>
                <ResourceGuideForm />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Guide;
