import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: {
    name: "Current FY",
    value: "",
  },
  next: {
    name: "Next FY",
    value: "",
  },
  son: {
    name: "SoN FY",
    value: "",
  },
};

export function getCurrentFiscalYear(date, FY, opt_monthStart /* default: 9 (October) */) {
  opt_monthStart = opt_monthStart === undefined ? 9 : opt_monthStart;
  var month = date.getMonth(),
    year = date.getFullYear(),
    yearOffset = Math.floor((month - (opt_monthStart % 12 || 12)) / 12)+ 1;   //1-25-2023 this is causing 2023 +1 = 2024;  //changed admin routes from son to current
    //yearOffset = Math.floor((month - (opt_monthStart % 12 || 12)) / 12);        //1-25-2023 removed +1 to stop causing 2024;

  return FY === "Current" ? yearOffset + year : yearOffset + year + 1;  //1-25-2023 removed +1 to allows records;
  //return FY === "Current" ? yearOffset + year : yearOffset + year +1; //1-25-2023 removing +1 to creates records error;
}

export const fiscalYearSlice = createSlice({
  name: "FY",
  initialState,
  reducers: {
    setCurrentFY: (state, action) => {
      state.current.value = action.payload;
    },
    setNextFY: (state, action) => {
      state.next.value = action.payload;
    },
    setSonFY: (state, action) => {
      state.son.value = action.payload;
    },
  },
});
export const { setCurrentFY, setNextFY, setSonFY } = fiscalYearSlice.actions;
export default fiscalYearSlice.reducer;

export const setFiscalYear = () => {
  const setFiscalYearThunk = async (dispatch) => {
    dispatch(setCurrentFY(getCurrentFiscalYear(new Date(),'Current')));
    dispatch(setNextFY(getCurrentFiscalYear(new Date(),'Next')));
    dispatch(setSonFY(getCurrentFiscalYear(new Date(),'Son', 11)));
  };
  return setFiscalYearThunk;
};
