// eblastForm.jsx

import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";


const EblastForm = (props) => {
  const {
    data,
    handleChange,
    handleSelectChange,
    handleSubjectChange,
    blastSubject,
    handleFieldChange,
  } = props;

  const [LocalFiscalYear] = useState(sessionStorage.getItem("LocalFiscalYear"));

  return (
    <div className="position-relative px-3 mt-5 mb-sm-1  mb-md-5 mb-xl-5">
      <h6 className="p-1 fw-bold">Eblast Controls</h6>
      <hr className="mt-4" />

      <FormControl variant="standard" fullWidth>
        <InputLabel id="Audience-label">Select Audience</InputLabel>
        <Select
          labelId="Audience-label"
          id="audience"
          name="audience"
          value={data?.audience ? data.audience : `0`}
          onChange={(e) => {handleSelectChange(e);
          handleFieldChange("to")
          }}
          label="Select Audience"
          required
        >
          <MenuItem value={`0`} className="text-warning">
            <em>Choose An Email Communication Option</em>
          </MenuItem>
          <MenuItem value={`1`}>All Providers</MenuItem>
          <MenuItem value={`2`}>Specific Provider</MenuItem>
          <MenuItem value={`3`}>
            Regional Contacts From Specific Region
          </MenuItem>
          <MenuItem value={`4`}>
            All Regional Contacts From Specific county
          </MenuItem>
          <MenuItem value={`5`}>All County Contacts</MenuItem>
          <MenuItem value={`6`}>All {LocalFiscalYear} Son Contacts</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="standard" fullWidth>
        <TextField
          id="cc"
          name="cc"
          label="CC"
          defaultValue=""
          helperText="Separate by commas e.g. name@gmail.com, you@gmail.com"
          variant="standard"
          onChange={handleChange}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <TextField
          id="bcc"
          name="bcc"
          label="BCC"
          defaultValue=""
          helperText="Separate by commas e.g. name@gmail.com, you@gmail.com"
          variant="standard"
          onChange={handleChange}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <TextField
          id="subject"
          name="subject"
          label="Subject"
          value={blastSubject}
          helperText="Subject"
          variant="standard"
          required={true}
          onChange={(e) => 
            {handleSubjectChange(e);
            handleFieldChange("subject");
            }}
        />
      </FormControl>
      <FormControl className="mt-3 mb-5" variant="standard" fullWidth>
        <InputLabel id="select-test-label">
          For Testing or For Real {props.testStatus}
        </InputLabel>
        <Select
          labelId="select-test-label"
          id="test"
          name="test"
          value={data.test ? data.test : `1`}
          onChange={handleChange}
          label="For Testing or For Real"
        >
          <MenuItem value={`1`}>Send a Test</MenuItem>
          <MenuItem value={`0`}>Send for Real</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default EblastForm;
