import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  FormHelperText,
  Alert,
} from "@mui/material";
import { UpdateUser } from "../../services/accountservice";
import { postUserProgramsById } from "../../services/userPrograms";

const EditUserModal = ({
  show,
  onHide,
  fetchData,
  providers,
  data,
  setData,
}) => {
  const [validated, setValidated] = useState(false);
  const [showPrograms, setShowPrograms] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showUsername, setShowUsername] = useState(false);
  const [hasRoleError, setHasRoleError] = useState(false);
  const [hasProgramError, setHasProgramError] = useState(false);
  const [hasSingleProgramError, setHasSingleProgramError] = useState(false);
  const [selectedNewPrograms, setSelectedNewPrograms] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const availableRoles = ["Son", "Provider", "PowerUser", "Admin"];
  const availableTypes = ["", "ED", "PC", "Other"];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (data.role === "Provider" || data.role === "Son") {
      setShowPrograms(true);
      setShowType(data.role === "Provider");
      setShowUsername(data.role === "Son");

      if (data.role === "Son") {
        setSelectedNewPrograms([data.programId]);
      } else {
        setSelectedNewPrograms(data.programs);
      }
    } else {
      setShowPrograms(false);
      setShowType(false);
      setShowUsername(false);
    }
  }, [data]);

  const handleChangeCreate = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setData((prevState) => ({ ...prevState, role: selectedRole }));
    if (selectedRole === "Provider" || selectedRole === "Son") {
      setShowPrograms(true);
      setShowType(selectedRole === "Provider");
      setShowUsername(selectedRole === "Son");
      if (selectedRole === "Son") {
        if (data.programs[0]) {
          setData((prevState) => ({ ...prevState, programId: data.programs[0]}));
        }
      } else {
        if (data.programId) {
          setData((prevState) => ({ ...prevState, programs: [data.programId]}));
        }
      }
    } else {
      setShowPrograms(false);
      setShowType(false);
      setShowUsername(false);
      setSelectedNewPrograms([]);
    }
  };

  const handleChangeSelectNewPrograms = (event) => {
    const { value } = event.target;
    if (data.role === "Son") {
      const selectedProgram = typeof value === "string" ? value : String(value);
      setSelectedNewPrograms([selectedProgram]);
      setData((prevState) => ({
        ...prevState,
        programId: selectedProgram,
        programs: [selectedProgram],
      }));
    } else {
      const newSelectedPrograms =
        typeof value === "string" ? value.split(",") : value;
      setSelectedNewPrograms(newSelectedPrograms);
      setData((prevState) => ({
        ...prevState,
        programId: null,
        programs: newSelectedPrograms,
      }));
    }
  };

  const handleSubmitEdit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setErrorMessage("");
    if (
      form.checkValidity() === false ||
      !data.role ||
      (showPrograms && selectedNewPrograms.length === 0) ||
      (data.role === "Provider" && !data.type)
    ) {
      setValidated(true);
      if (!data.role) setHasRoleError(true);
      if (showPrograms && selectedNewPrograms.length === 0)
        setHasProgramError(true);
      if (data.role === "Provider" && !data.type)
        setHasSingleProgramError(true);
      return;
    }
    try {
      if (data.role !== "Son") {
        data.userName = data.email;
      }
      await UpdateUser(data);
      if (data.role === "Provider") {
        await postUserProgramsById(data.userName, selectedNewPrograms);
      }
      fetchData();
      onHide();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const backendErrors = error.response.data.errors;
        const errorMessagesArray = Array.isArray(backendErrors)
          ? backendErrors
          : Object.values(backendErrors).flat();
        setErrorMessage(`Error editing user: ${errorMessagesArray.join(", ")}`);
      } else {
        setErrorMessage("Error editing user.");
      }
      console.error("Error editing user:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Edit User {data.id}
          {data.name ? ` | ${data.name}` : null}
          {data.type ? ` - ${data.type}` : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        )}
        <Form noValidate validated={validated} onSubmit={handleSubmitEdit}>
          <Form.Group className="mb-3" controlId="validationName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="name"
              defaultValue={data.name}
              onChange={handleChangeCreate}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter email"
              name="email"
              defaultValue={data.email}
              onChange={handleChangeCreate}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationPhoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter phone number"
              name="phoneNumber"
              defaultValue={data.phoneNumber}
              onChange={handleChangeCreate}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationRole">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              name="role"
              value={data.role ? data.role : availableRoles[0]}
              onChange={handleRoleChange}
              isInvalid={hasRoleError}
            >
              <option value="">Select role</option>
              {availableRoles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a role.
            </Form.Control.Feedback>
          </Form.Group>
          {showType && (
            <Form.Group className="mb-3" controlId="validationType">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={data.type ? data.type : availableTypes[0]}
                onChange={handleChangeCreate}
                isInvalid={hasSingleProgramError}
              >
                <option value="">Select type</option>
                {availableTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a type.
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {showPrograms && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="programs-label">Program(s)</InputLabel>
              <Select
                labelId="programs-label"
                id="programs"
                multiple={data.role !== "Son"}
                value={selectedNewPrograms}
                onChange={handleChangeSelectNewPrograms}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Programs" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={String(value)} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {providers.map((provider, i) => (
                  <MenuItem key={i} value={provider.id}>
                    {provider.id} - {provider.name}
                  </MenuItem>
                ))}
              </Select>
              {hasProgramError && (
                <FormHelperText error>
                  Please select at least one program.
                </FormHelperText>
              )}
            </FormControl>
          )}
          {showUsername && (
            <Form.Group className="mb-3" controlId="validationUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                required={data.role === "Son"}
                type="text"
                placeholder="Enter username"
                name="userName"
                defaultValue={data.userName}
                onChange={handleChangeCreate}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid username.
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmitEdit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserModal;
