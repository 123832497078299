import React from 'react';
import { useParams } from "react-router-dom";
import AdditionalCountiesForm from '../forms/additionalcountiesform';

const AdditionalCounties = () => {
  const { id } = useParams();
  return ( 
    <>
      <h1>Edit Additional Counties Served {id}</h1>
      <AdditionalCountiesForm id={id} />
    </>
  );
}
 
export default AdditionalCounties;