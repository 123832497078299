  import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

function NavBar({ user }) {
  const [navToHome, setNavToHome] = useState('');

  useEffect(() => {
    let navToHome = "/admin/sonhome";
    if (user?.role === "Admin") {
      setNavToHome("/admin/sonhome");
    } else if (user?.role === "Provider") {
        setNavToHome("/provider/currentfy/newcase");
    } else if (user?.role === "Son") {
       setNavToHome("/provider/awardinformation");
    } else {
       setNavToHome(null);
    }
  }, [user]);

  return (
    <React.Fragment>
    <>
      

      <div className="row justify-content-center mb-5 ms-3">
      
          <div className="col-sm-6 col-md-6 col-lg-3">
            <NavLink className="nav-item nav-link" to="/">
              <>
                <img
                  type="image"
                  alt="Pssf Network Promoting Safe & Stable Familes Logo"
                  src={require("../../img/header_pssfLogo.png")}
                  style={{
                    border: 0,
                    position: "relative",
                    top:  30,
                    
                  }}
                  className="img-fluid"
                />
              </>
            </NavLink>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-3 align-content-start-0">
            <div className="row">
            <img
              type="image"
              alt="Georgia Departmant Of Human Services"
              src={require("../../img/DHS Seal Logo Horizontal DFCS.png")}
              style={{
                border: 0,
                position: "relative",
                top: 20,
                
              }}
              className="img-fluid"
              />

              </div>
              <div className="row text-center">
              {!user && (
              
              <Link
                to="/account/login"
                rel="noopener noreferrer"
                className="btn btn-primary w-25  mx-auto mt-3"
              >
                Log in
              </Link>
            )}
            {user && (
              <>
                <p className="text-warning text-center me-2">Welcome, {user.name}</p>
                <div className="row">
                  <div className="col-6">
                    <Link
                      to={`${navToHome}`}
                      rel="noopener noreferrer"
                      className="text-primary w-25  mx-auto mt-3"
                      >
                      Dashboard
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to="/account/logout"
                      rel="noopener noreferrer"
                      className="text-primary w-25  mx-auto mt-3"
                      >
                      Logout
                    </Link>
                  </div>
                </div>
              </>
            )}
              </div>

          </div>


          
      </div>

      <header>
        <nav className="navbar navbar-expand-lg  navbar-custom">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav nav-fill w-100">
              <NavLink className="nav-item nav-link nav-link" to="/pssfga">
                PSSF IN GEORGIA
              </NavLink>
              <NavLink className="nav-item nav-link" to="/pssfyear">
                PSSF THIS YEAR
              </NavLink>
              <NavLink className="nav-item nav-link" to="/guide">
                RESOURCE GUIDE
              </NavLink>
              <NavLink className="nav-item nav-link" to="/links">
                HELPFUL LINKS
              </NavLink>
              <NavLink className="nav-item nav-link" to="/funding">
                FUNDING OPPORTUNITIES
              </NavLink>
              <NavLink className="nav-item nav-link" to="/contact">
                CONTACT US
              </NavLink>
            </div>
          </div>
        </nav>
      </header>
    </>
    </React.Fragment>
  );
}

export default NavBar;
