import {
  Card,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import auth from "../../services/authService";
import { getCasesByProgramId } from "../../services/casesService";
import { sendEmail } from "../../services/emailService";
import {
  getFamilyServiceLogByProgramByFiscalYear,
  updateFamilyServiceLogProgReportComplete,
} from "../../services/familyServiceLogService";
import { getAgencyById } from "../../services/getAgencyService";
import {
  getProgramsByIdandFiscalYear,
  updateProgramById,
  updateResetProgramById,
} from "../../services/getProgramService";
import {
  getServiceTotalsByProgramIdAndReportingPeriod,
  updateProvidedServicesUnits,
} from "../../services/providedServicesService";
import { getReportingPeriods } from "../../services/reportingPeriodsService";
import { getReport } from "../../services/reportService";

//ICONS
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AlarmIcon from "@mui/icons-material/Alarm";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProgrammaticReports = ({ fiscalYear }) => {
  const [serviceTotals, setServiceTotals] = useState([]);
  const [program, setProgram] = useState({});
  const [agency, setAgency] = useState({});
  const [cases, setCases] = useState([]);
  const [fsls, setFsls] = useState([]);
  const [unitsError, setUnitsError] = useState("");
  const [fslError, setFslError] = useState(false);
  const [orderBy, setOrderBy] = useState("sonId");
  const [order, setOrder] = useState("asc");
  const [reportingMonth, setreportingMonth] = useState([]);

  const [openAlertSnack, setOpenAlertSnack] = useState(false);

  const [doneState, setDoneState] = useState(true);
  const [emailDisplay, setEmailDisplay] = useState(false);

  const [lockReport, setLockReport] = useState(0);
  const [lockSaveBtn, setlockSaveBtn] = useState(false);
  const [openDoneConfirm, setopenDoneConfirm] = useState(false);

  const handleOpenDoneModal = () => setopenDoneConfirm(true);
  const handleCloseDoneModal = () => setopenDoneConfirm(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertSnack(false);
  };

  const classes = useStyles2();

  // Load up Sessions
  const programId = sessionStorage.getItem("ProgramId");
  const pfyId = sessionStorage.getItem("ProgramByFiscalYearId");
  const reportingPeriod = sessionStorage.getItem("ReportingPeriod");

  const endDateString = sessionStorage.getItem("endDate");
  const beginDateString = sessionStorage.getItem("beginDate");

  const beginDate = new Date(beginDateString);
  const endDate = new Date(endDateString);
  const now = new Date(); //  Thu Mar 09 2023 11:26:25 GMT-0500 (Eastern Standard Time)

  var isPeriod = "";

  function dateChecking(beginDate, endDate, now) {
    if (now <= endDate && now >= beginDate) {
      return true;
    }

    return false;
  }

  if (dateChecking(beginDate, endDate, now)) {
    isPeriod = true;
  } else {
    isPeriod = false;
    if (programId === 2575 && doneState === false) {
      setDoneState(true); // Benjamin C. with doneState to true with useState being set to 0 this user has an alert so we need to disable but done button until later.
      setLockReport(true);
    }
  }

  let totalCost = 0;
  //HB: Both APPROVED and LOCKED are considered "locked" statuses. The providers should not be updating data if it has been approved. A SQL job will reset this status at the beginning of each month so that providers can report normally.
  /*HB(11/03/2022):
    The Alert Status has crept in scope beyond just being alerts at this point. In fact, the whole alerts thing in general is a more signifcant feature than I had originally understood. 
    Adding on DONE as a new "locked" status for when they've completed the report. This is disctinct from APPROVED for two reasons: 
      1) APPROVED indicates that there was an alert created and the admin approved the report, something that is not always true for DONE reports
      2) APPROVED still allows the provider to select the "Done" button, which the DONE status prevents
    If this weren't my last day I'd go in and refactor all of this. We probably want to separate the alerts from these locking statuses, renaming AlertStatus to something like LockStatus. Then maybe we could create an 
    alerts table that stores program Id, reporting period Id, date alert was generated, and the date the alert was resolved. That way we have a history of these alerts. It seems like such a history might end up being 
    important, but I do not know for sure.
  */
  /*BenjaminC(03/08/2023):
    ITDEV-171 Sensitive Reporting Period Open Times Of Availablitlity of The Button.
    1. When the page load up the done button should be disabled and opened if they change the save button to the right.
    2. When the change the save button to the right then the done button is open
    3  When they click the done button then they should read the message regarding approval by a PSSF admin should display then. button goes back disable.
    4.  When PSSF Admin approves the units the button should be available again status should be APPROVED_STATUS
    5.  When it's an approved status the provider then goes and clicks done again and download the reports.

  */
  const UNLOCKED_STATUS = 0;
  const LOCKED_STATUS = 1;
  const APPROVED_STATUS = 2;
  const DONE_STATUS = 3;
  const user = auth.getCurrentUser();

  const getStatusLabel = (status) => {
    switch (status) {
      case UNLOCKED_STATUS:
        return "Unlocked";
      case LOCKED_STATUS:
        return "Locked";
      case APPROVED_STATUS:
        return "Approved";
      case DONE_STATUS:
        return "Done";
      default:
        return "Unknown";
    }
  };

  // async function fetchData(index) {
  const fetchData = useCallback(
    async (index) => {
      if (fiscalYear.value && programId) {
        var { data: program } = await getProgramsByIdandFiscalYear(
          programId,
          fiscalYear.value
        );

        // Benjamin C if from fetch Data Provider has an alertStatus === 1 of {alertStatus: 1} then we are gonna make the done button disabled until resolved or unlesss thresh hold is over 15% of total units
        // Note theres many == in conditinal cases throwing flags through system from type errors however this is correct === do not change unless the database sends back something different like a string "".
        // Benjamin C this disables the button with useState set to 0 at top 03.08.2023
        if (program.alertStatus === 1) {
          setDoneState(true);
        }
        if (program.doneLockStatus === parseInt(reportingPeriod)) {
          setLockReport(1);
        }

        setProgram(program);

        var { data: agency } = await getAgencyById(program.agencyId);
        const agCopy = { ...agency };
        agCopy.agenciesByFiscalYear = agency.agenciesByFiscalYear.find(
          (x) => x.fiscalYear === fiscalYear.value
        );
        setAgency(agCopy);

        const { data: cases } = await getCasesByProgramId(programId);
        const openCases = cases.filter(
          (_case) =>
            _case.status === "Open" || _case.status === "Exit Incomplete"
        );
        setCases(openCases);

        const { data: fsls } = await getFamilyServiceLogByProgramByFiscalYear(
          programId,
          reportingPeriod
        );

        setFsls(fsls);

        const { data: serviceTotals } =
          await getServiceTotalsByProgramIdAndReportingPeriod(
            programId,
            fiscalYear.value,
            reportingPeriod
          );

        // Adding availableBal to each row on print out.
        const newRows = serviceTotals.map((row, i) => {
          return {
            ...row,
            availableBal: row.unitsPerYear - row.unitsBal,
          };
        });

        const sorted = newRows.sort((a, b) => {
          return a.codeSub - b.codeSub;
        });

        setServiceTotals(sorted);

        const { data: reportingPeriods } = await getReportingPeriods();

        const reportingPeriodObject = reportingPeriods.find(
          (item) => item.id === Math.abs(reportingPeriod)
        );

        // Retrieve the month from the reporting period object
        const reportingMonth = reportingPeriodObject.month;

        setreportingMonth(reportingMonth);
      }
    },
    [fiscalYear.value, programId, reportingPeriod]
  );

  const handleSort = async (column) => {
    const isAsc = orderBy === column && order === "asc";

    const sorted = _.orderBy(serviceTotals, column, isAsc ? "desc" : "asc");
    setServiceTotals(sorted);

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  const handleLock = async (program, alertedServices, services) => {
    let emailBody =
      "Please review your approved service delivery schedule in addition to service descriptions, proposed caseloads, and expected frequency and delivery.<br/><br/>Before your report can be completed, you are required to reply to this email to verify each of the following units reported are correct, by providing a clear explanation as for any increase in expected services:<br/><br/>";

    services.map((service, i) => {
      let letterCode =
        service.programCode && service.programCode !== ""
          ? `${service.programCode}`
          : "";
      let isAlertedService = alertedServices.some(
        (alertService) => alertService.newServicesId === service.newServicesId
      );
      let fontColor = isAlertedService ? "red" : "black";

      emailBody += `<span style="color: ${fontColor};">${service.codeSub}${letterCode}-${service.description}-${service.subDescription}<br/>Approved units per year: ${service.unitsPerYear}<br/>Units invoiced this month: ${service.totalUnits}<br/><br/></span>`;
      return emailBody;
    });

    emailBody +=
      "Please do not provide specific details/case notes within this email. When referring to specific cases or FSL information, please reference any intake ID numbers.<br/><br/>If these numbers were reported in error, or are inconsistent with actual service delivery, please describe the errors, but do not make any changes to your report until the TA Team has advised you on how to move forward with corrections.<br/><br/>Once the TA Team receives and reviews your response, you will be notified by email that you can finalize and print your Invoice and Report, to submit for processing.<br/><br/>Thank You,<br/>PSSF Technical Assistance Team";

    await updateProgramById(program);
    try {
      sendEmail({
        to: user.email,
        body: emailBody,
        subject: `PSSF Invoice Alert, Immediate Action Required: ${agency.name}, ${program.id}`,
        CC: "communications@pssfnet.com",
      });
    } catch (error) {
      alert("Sorry There Has Been An Error...", error);
    }

    // #3 We sent an email now disable done button so it won't be pressed again. Also show the approval notification that admin has to approve your units changed.
    setDoneState(true); // Benjamin C. with state being set to 0 setting to true locks done button
    setEmailDisplay(true);
    return;
  };

  const handleSave = async (event, row) => {
    setlockSaveBtn(true);
    setDoneState(false); //Should load disabled from having alert status 1, if so enable done button to false which allowsuser to click done and go back disabled.

    setUnitsError("");
    //HB: Backfill all of the group service values.
    var service = serviceTotals.find(
      (service) => service.newServicesId === row.newServicesId
    );

    if (service.oneToOne === false) {
      if (service.groupUnits === 0) {
        setUnitsError("floatingUnits");
      } else if (parseInt(service.groupUnits) > parseInt(service.unitsPerYear)) {
        setUnitsError("tooManyUnits");
      }
    } else {
      if (
        parseInt(service.totalUnits) !== parseFloat(service.totalUnits) ||
        parseInt(service.totalUnits) === 0
      ) {
        setUnitsError("floatingUnits");
        return;
      } else if (
        parseInt(service.totalUnits) > parseInt(service.unitsPerYear)
      ) {
        setUnitsError("tooManyUnits");
        return;
      }
    }

    await updateProvidedServicesUnits(
      service.newServicesId,
      service.oneToOne ? service.totalUnits : service.groupUnits,
      reportingPeriod
    );

    // #1 Reference: Because they have saved the button the right it's okay to unlock the done button now but setting SetDone to false if they are within reporting period.
    if (isPeriod === true) {
      setDoneState(false);
    }
  };

  function changeProgramToLock(event) {
    const updatedProgram = { ...program, doneLockStatus: true };

    setProgram(updatedProgram);
    setLockReport(1); // Set Done To True
  }

  const handleApprove = async () => {
    //HB: Approve report and allow them to generate the programmatic report, but they still cannot modify any FSL data

    setDoneState(false); // Set Done To true because the
    const newProgram = { ...program };
    newProgram.alertStatus = APPROVED_STATUS;
    newProgram.alertResolved = new Date();
    newProgram.doneLockStatus = reportingPeriod;
    setProgram(newProgram);

    await updateProgramById(newProgram);
    fetchData();
  };

  const handleReset = async () => {
    setLockReport(0); // Unlocks the report
    setDoneState(!doneState); // Set Done To false by setting 0

    //HB: Unlock the report and let them change stuff
    const newProgram = { ...program };
    newProgram.alertStatus = UNLOCKED_STATUS;
    newProgram.alertResolved = null;
    newProgram.doneLockStatus = 0;
    setProgram(newProgram);

    await updateResetProgramById(newProgram);
  };

  const resetGroupServiceUnits = (row) => {
    updateProvidedServicesUnits(row.newServicesId, 0, reportingPeriod);

    const newServiceTotal = serviceTotals.map((obj) => {
      if (obj.oneToOne === false) {
        return { ...obj, groupUnits: 0 };
      }

      return obj;
    });

    setServiceTotals(newServiceTotal);
  };

  const handleChange = (event, row) => {
    var { value } = event.target;
    var startBalance = row.unitsPerYear - row.unitsBal;

    // Calculate the difference between unitsPerYear and groupUnits
    const newbalance = row.unitsPerYear - row.unitsBal - value;
    const newtotal = newbalance + startBalance;

    if (value > newtotal) {
      return false;
    } else {
      const updatedRow = {
        ...row,
        groupUnits: value,
        availableBal: newbalance,
      };

      // Find the index of the row in the serviceTotals array
      const rowIndex = serviceTotals.findIndex(
        (service) => service.newServicesId === row.newServicesId
      );

      var updatedServiceTotals = [...serviceTotals];

      updatedServiceTotals[rowIndex] = updatedRow;

      setServiceTotals(updatedServiceTotals);

      if (row.unitsPerYear <= event.target.value) {
        return false;
      }
    }
  };

  // To Not allow decimals on keypress
  const handleEnter = (event, row) => {
    if (event.key === ".") {
      event.preventDefault();
      return false;
    }

    if (event.target.value > row.unitsPerYear) event.preventDefault();
    return false;
  };

  const paperStyle = {
    padding: 20,
    height: "100%",
    width: "98%",
    margin: "20px auto",
    justify: "flex-end",
  };

  const cardStyle = {
    padding: 20,
    //height: "100%",
    width: "100%",
    margin: "20px auto",
  };

  const handleDone = async (event, isAdmin = false) => {
    var fslIsErrored = false;
    setopenDoneConfirm(false);
    //HB: Check for alerts, then set ProgrammaticReportComplete to 1 and generate the PDF report if everything is good.
    cases.map((openCase) => {
      if (
        !fsls.find((fsl) => {
          return fsl.caseId === openCase.id;
        })
      ) {
        setFslError(true);
        fslIsErrored = true;
      }
      return openCase;
    });

    if (fslIsErrored && !isAdmin) return;

    setlockSaveBtn(false);

    let hasAlert = false;
    let alertedServices = [];

    if (program.alertStatus !== APPROVED_STATUS) {
      //HB: If the admin has approved the report, don't worry about checking its validity here
      serviceTotals.map((service) => {
        let unitThreshold = 0.15 * service.unitsPerYear;
        if (service.totalUnits >= unitThreshold) {
          //Has more than 15% threshold met
          hasAlert = true;
          alertedServices.push(service);
          setDoneState(true); //Benjamin C. because we are setting hasAlert true we are disabling the done button with true
        }

        return service;
      });
    }

    if (hasAlert) {
      const newProgram = { ...program };
      newProgram.alertStatus = LOCKED_STATUS;
      newProgram.doneLockStatus = reportingPeriod;
      newProgram.alertCreated = new Date();

      setProgram(newProgram);

      await handleLock(newProgram, alertedServices, serviceTotals);
      newProgram.alertStatus = DONE_STATUS;
      newProgram.doneLockStatus = reportingPeriod;
      setProgram(newProgram);
      await updateProgramById(newProgram);

      //BenjamnC Setting Snack alert for toast experience
      setOpenAlertSnack(false); // Set it to false so it would not show  6.15.2023 BC
      setDoneState(true); // BenjaminC with state being set to 0 setting to true locks done button
      setLockReport(1); // Set Done To True

      changeProgramToLock(true);
    } else {
      await updateFamilyServiceLogProgReportComplete(
        program.id,
        reportingPeriod
      );

      var parameters = [
        {
          key: "Month",
          value: reportingPeriod,
        },
        {
          key: "FiscalYear",
          value: fiscalYear.value,
        },
        {
          key: "ProgramID",
          value: program.id,
        },
        {
          key: "UserID",
          value: user.UserId,
        },
      ];

      // BenjaminC 1-25-2024  When the report should be downloaded or not.
      // ONCE CONFIRMED Lock status should be activated.
      // one of two things happen.
      // if no alert triggered, programmatic report part a and b will be generated as downloads.
      // if Alert generated, admin must approve before programmatic reports parts a and b can be generated

      if (program.alertResolved && program.alertCreated) {
        await getReport("NewPssf_Programmatic_Report_PartA", parameters);
        await getReport("NewPssf_Programmatic_Report_PartB", parameters);
      } else if (!program.alertResolved && !program.alertCreated) {
        await getReport("NewPssf_Programmatic_Report_PartA", parameters);
        await getReport("NewPssf_Programmatic_Report_PartB", parameters);
      }

      const newProgram = { ...program };
      newProgram.alertStatus = DONE_STATUS;
      newProgram.doneLockStatus = reportingPeriod;
      setProgram(newProgram);
      await updateProgramById(newProgram);

      //BenjamnC Setting Snack alert for toast experience
      //setOpenAlertSnack(true);
      setDoneState(true); // BenjaminC with state being set to 0 setting to true locks done button
      setLockReport(1); // Set Done To True

      changeProgramToLock(true);
    }
  };

  useEffect(async () => {
    fetchData();
  }, [programId, reportingPeriod, fiscalYear, fetchData]);

  return (
    <Paper elevation={10} style={paperStyle}>
      <h1>Programmatic Reports</h1>
      <div className="py-4">
        <p>
          <b className="fw-bold">Program ID</b>: {program && program.id}
        </p>
        <p>
          <b className="fw-bold">Program Name</b>: {program && program.name}
        </p>
        <p>
          <b className="fw-bold">Agency Name</b>: {agency && agency.name}
        </p>
        <p>
          <b className="fw-bold">Model Category</b>:{" "}
          {program.serviceModel && program.serviceModel.modelAbrv}/
          {program.serviceModel &&
            program.serviceModel.serviceModelCategoryAbrv}
        </p>
        <p>
          <b className="fw-bold">Month</b>: {reportingMonth}
        </p>
        <p>
          <b className="fw-bold" title={"Lockreport: " + lockReport}>
            Program Lock Status:
          </b>

          {/* B.C. If the report is locked and if reporting period is -Int from api indicating outside of current reporting period  */}
          {lockReport === 1 || reportingPeriod < 0 ? (
            <span
              className="fw-bold text-danger mb-2"
              title={getStatusLabel(program?.alertStatus)}
            >
              <EnhancedEncryptionIcon />
            </span>
          ) : (
            <span
              className="fw-bold text-success"
              title={getStatusLabel(program?.alertStatus)}
            >
              <LockOpenIcon />
            </span>
          )}
        </p>
      </div>
      <div className="mb-5">
        <hr />
        <p>
          Review all totals carefully. If group services were provided, enter
          the number of groups held during the month and select save before
          selecting the "done" button to generate your report. Reports will
          download automatically unless approval is required by PSSFAdmin.
          <br />
          <br />
          To submit your report for payment processing:
          <br />
          <br />
          Download both Part A and Part B. Part A requires a signature before
          submitting. Email both Part A and B to your County Director and email
          address identified on your invoice and cc: INVsubmit@pssfnet.com.
          Please use separate emails if you have multiple programs.
        </p>
      </div>
      {unitsError === "floatingUnits" && (
        <h6>
          <FormHelperText className="text-danger">
            ** Enter a whole number greater than 0 for units. **
          </FormHelperText>
        </h6>
      )}
      {unitsError === "tooManyUnits" && (
        <h6>
          <FormHelperText className="text-danger">
            ** You have entered more units than are available per year for a
            service. **
          </FormHelperText>
        </h6>
      )}
      {fslError && (
        <h6>
          <FormHelperText className="text-danger ">
            ** You must complete a Family Service Log for all open cases before
            submitting your Programmatic Report. **
          </FormHelperText>
        </h6>
      )}
      <TableContainer component={Paper}>
        <Table
          id="progreportsTable"
          className={classes.table}
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "codeSub"}
                  direction={order}
                  onClick={() => handleSort("codeSub")}
                >
                  Service Code
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">Service Code Description</TableCell>
              <TableCell align="center">Sub Description</TableCell>
              <TableCell align="center">Unit Balance</TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "totalUnits"}
                  direction={order}
                  onClick={() => handleSort("totalUnits")}
                >
                  Units
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "unitCost"}
                  direction={order}
                  onClick={() => handleSort("unitCost")}
                >
                  Unit Cost
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">Total Cost</TableCell>
              <TableCell style={{ width: 130 }}>
                Group Service
                <br />
                Units
              </TableCell>
              <TableCell>Remaining Units</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceTotals &&
              serviceTotals.map((row) => {
                var rowCost = (
                  row.unitCost *
                  (row.oneToOne ? row.totalUnits : row.groupUnits)
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                });
                totalCost =
                  totalCost +
                  parseFloat(
                    row.unitCost *
                      (row.oneToOne ? row.totalUnits : row.groupUnits)
                  );

                return (
                  <TableRow key={row.newServicesId}>
                    <TableCell align="center">
                      {row.programCode
                        ? row.codeSub + row.programCode
                        : row.codeSub}
                    </TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    <TableCell align="center">{row.subDescription}</TableCell>
                    <TableCell align="center" style={{ width: 200 }}>
                      <strong>Total:</strong> {row.unitsPerYear}
                      <br />
                      {row.oneToOne ? (
                        ""
                      ) : (
                        <>
                          <strong> YTD:</strong> {row.unitsBal}
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      title={`unitsPerYear: ${row.unitsPerYear}`}
                    >
                      {row.oneToOne ? row.totalUnits : row.groupUnits}
                    </TableCell>
                    <TableCell align="center">
                      {row.unitCost.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </TableCell>
                    <TableCell align="center">{rowCost}</TableCell>
                    <TableCell align="center">
                      {row.oneToOne ? (
                        ""
                      ) : (
                        <div className="d-inline-flex">
                          <div className="col-6">
                            <Form.Group controlId="units">
                              <Form.Control
                                style={{ minWidth: 50, width: "50%" }}
                                name="verify-input-unitTotals"
                                type="number"
                                className="form-control"
                                title={row.unitsPerYear}
                                rel={row.unitsPerYear}
                                disabled={lockReport || lockSaveBtn}
                                step="1"
                                min="0"
                                value={row.groupUnits || 0}
                                onChange={(event) => handleChange(event, row)}
                                onKeyDown={(event) => handleEnter(event, row)}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div
                        className="col-6 mt-1"
                        style={{
                          minWidth: 50,
                          width: "50%",
                          fontSize: "0.875rem",
                        }}
                      >
                        {row.oneToOne ? "" : row.availableBal}
                      </div>
                    </TableCell>

                    <TableCell style={{ width: 80 }} align="center">
                      {row.oneToOne ? (
                        ""
                      ) : (
                        <>
                          <button
                            type="button"
                            className={
                              lockSaveBtn
                                ? "btn btn-outline-danger"
                                : "btn btn-outline-primary"
                            }
                            name="saveUnitTotals"
                            onClick={(event) => {
                              handleSave(event, row);
                              window.location.reload();
                            }}
                            disabled={
                              lockReport === 1 || lockSaveBtn === true
                                ? true
                                : false
                            }
                          >
                            Save
                          </button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Sum Total Cost:</TableCell>
              <TableCell align="center">
                {totalCost.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Card className="mt-5" style={cardStyle}>
        <div>
          <p className="fw-bold">
            Review services and information above before selecting "Done".
          </p>
        </div>

        {emailDisplay === true && (
          <div className="mb-3">
            <h4>
              <FormHelperText className="text-danger">
                The <strong>unit totals</strong> that have been entered require
                an approval by a <strong>PSSF Admin</strong>. <br />
                Please respond by replying to the email generated from
                <strong> communications@pssfnet.com</strong>.
              </FormHelperText>
            </h4>
          </div>
        )}

        <div className="d-flex justify-content-between">
          {isPeriod === true && doneState === true ? (
            <button
              id="doneButtonNoAdmin1"
              type="button"
              className="btn btn-primary truePeriod px-2 w-25"
              onClick={handleOpenDoneModal}
            >
              Done
            </button>
          ) : (
            <>
              <button
                id="doneButtonAdmin2"
                type="button"
                className={
                  isPeriod === true && lockReport !== 1
                    ? "btn btn-primary px-2 w-25"
                    : reportingPeriod < 0
                    ? "btn btn-danger px-2 w-25"
                    : //When Non of the conditions are met
                      "btn btn-primary px-2 w-25"
                }
                onClick={handleDone}
              >
                Done
              </button>
              &nbsp;
            </>
          )}

          {user.role === "Admin" && (
            <>
              <button
                id="doneButton"
                title="Only Admins Can See This Button..."
                type="button"
                className="btn btn-primary px-2 w-25"
                onClick={(event) => handleDone(event, true)}
              >
                Admin Done
              </button>
              &nbsp;
              <button
                id="adminDoneBtn"
                title="Only Admins Can Approve"
                type="button"
                className="btn btn-success w-25"
                onClick={handleApprove}
              >
                Approve
              </button>
              &nbsp;
              <button
                id="adminResetBtn"
                type="button"
                className="btn btn-warning"
                onClick={handleReset}
              >
                Reset
              </button>
            </>
          )}
        </div>
      </Card>

      <Snackbar
        open={openAlertSnack}
        autoHideDuration={8000}
        onClose={handleCloseAlert}
        message="Please Check Your Email The unit totals that have been entered require approval by a PSSF Admin. Please respond by replying to the email generated from communications@pssfnet.com.!"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDoneConfirm}
        onClose={handleCloseDoneModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDoneConfirm}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Please confirm before going to the next step.
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Review your units and totals on screen before proceeding. Once you
              go to the next step and click "done" your report will be locked
              and you will not be allowed to make edits. Do you confirm?
            </Typography>

            <div className="d-flex justify-content-between mt-5">
              <button
                id="doneButtonNoAdmin1"
                type="button"
                className="btn btn-success truePeriod px-2 w-25"
                onClick={handleDone}
              >
                Confirm
              </button>

              <button
                id="doneButtonNoAdmin1"
                type="button"
                className="btn btn-danger truePeriod px-2 w-25"
                onClick={handleCloseDoneModal}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ProgrammaticReports;
