import React from "react";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";
import {
  getProgramsByIdandFiscalYear,
  getServiceModels,
} from "../../services/getProgramService";
import { saveSonProgram, saveSonAgency } from "../../services/getSonServices";
import { getCounties } from "../../services/countiesService";
import { getContactByAgencyId } from "../../services/getContactService";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Form from "../common/form";

class SoNEditForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {}, //program
      data2: {},
      counties: [],
      serviceModels: [],
      statusModels: [],
      statusProgram: [],
      submit: false,
      status: true,
      awayMessage: "Away",
    };
  }

  async getProgram() {
    const { data } = await getProgramsByIdandFiscalYear(
      this.props.id,
      this.props.fy
    );

    this.setState({ data });
    this.setState((state) => {

      this.state.data.fiscalCounty =
        this.state.data.programByFiscalYear[0].fiscalCounty ?? 0;
      this.state.data.statusProgram =
        this.state.data.programByFiscalYear[0].programStatus ?? 0;
      this.state.status = this.state.data.programByFiscalYear[0].programStatus;

      return state;
    });
  }

  async getAgency() {
    let { data } = await getAgencyByFiscalYearandId(
      this.props.fy,
      this.state.data.agencyId
    );

    this.setState({ data2: data });
  }

  async populateCounties() {
    const { data } = await getCounties();
    const counties = [{ id: 0, name: "Choose" }, ...data];
    this.setState({ counties });
  }

  async populateServiceModels() {
    const { data } = await getServiceModels();
    let serviceModels = [{ id: 0, name: "Choose" }];
    data.map((serviceModel) =>
      serviceModels.push({
        id: serviceModel.id,
        name:
          serviceModel.serviceModelCategoryAbrv + "/" + serviceModel.modelAbrv,
      })
    );
    this.setState({ serviceModels });
  }


  async populateContactByAgency() {
    const { data: edContact } = await getContactByAgencyId(
      this.state.data.agencyId
    );
    this.setState({ edContact });

    this.setState((state) => {
      this.state.data.ContactName = this.state.edContact.name;
      this.state.data.ContactPhone = this.state.edContact.phone;
      return state;
    });
  }

  async componentDidMount() {
    await this.getProgram();
    await this.getAgency();
    await this.populateCounties();
    await this.populateServiceModels();
    await this.populateContactByAgency();
  }


  handleStatusChange = ({ currentTarget: input }) => {
    const errors = {};
   
    const data = { ...this.state.data };
    data[input.name] = input.value;

    if (input.required) this.doChange(input);

    const data2 = { ...this.state.data2 };
    data2[input.name] = input.value;

    this.setState({ data, errors, data2 });
  };

  doSubmit(submit) {

    const { data: program, data2: agency } = this.state;
  
    try {
      saveSonAgency(agency).then(
        saveSonProgram(program)
          .then(this.saveSubmit())
          .catch((error) => console.log(error))
      );
    } catch (error) {
      const submit = false;
      this.setState({ submit });
    }
  }

  saveSubmit() {
    const submit = true;

    this.setState({ submit });
  }

  render() {
    const { data: program, data2: agency } = this.state;
    const { submit } = this.state;
    const entityType = agency.entityType === 1 ? "Non-Profit" : "Public Entity";

    return (
      <>
        {submit && <Navigate to={"/admin/sonhome"} replace={false} />}
        <form onSubmit={this.handleSubmit}>
          <div className="row m-2">
            <div className="col-lg-4">
              <Link to={`/admin/agency/${agency.id}/${this.props.fy}`}>
                <span className="badge bg-success">
                  Agency: {agency.id + " - "}
                  {agency.name + " - " + entityType}
                </span>
              </Link>
            </div>
          </div>
          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput2("address", "Agency Street Address", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput2("city", "City", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput2("state", "State", "", true)}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput2("zip", "Zip", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput("ContactName", "ED Name", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput("ContactPhone", "Ed Phone", "", true)}
            </div>
          </div>
          <div className="row m-2">
            <div className="col-lg-4">
              <Link
                to={`/admin/soneditprograms/${program.id}/${this.props.fy}`}
              >
                <span className="badge bg-success">
                  Program Id: {program.id}
                </span>
              </Link>
            </div>
          </div>
          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("name", `Program Name`)}
            </div>
            <div className="col-lg-4">
              {this.renderSelect(
                "serviceModelId",
                "Service Model",
                this.state.serviceModels
              )}
            </div>
            <div className="col-lg-4">
              {this.renderInput("address", "Program street Address")}
            </div>

          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("city", "Program City")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("zip", "Program Zip")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-2">
              <div className="form-check">
                {this.renderCheck("confidentialAddress", "Hide Street Address")}
              </div>
            </div>
      
            <div className="col-lg-4"></div>
          </div>

          <div className="row m-2">
            <br />
          </div>

          <div className="row m-2">
            <span className="text-success fw-bold">
              Additional Counties Served
            </span>
            {program.countiesServed ? (
              program.countiesServed.map((county) => (
                <span key={county.id}>{county.name}</span>
              ))
            ) : (
              <span></span>
            )}
            <Link to={`/admin/additionalcounties/${program.id}`}>
              <span className="badge bg-success">
                Update Additional Counties
              </span>
            </Link>
          </div>

          <div className="row m-2">
            <br />
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("vendorId", "DHS Supplier ID")}
            </div>
            <div className="col-lg-4">
              {this.renderInput2("ein", "EIN", "", true)}
            </div>
            <div className="col-lg-4">
              {this.renderInput("oriOac", "ORI OAC")}
            </div>
          </div>

          <div className="row m-2">
        
            <div className="col-lg-4">
              {this.renderInput("dunnsId", "SAM.gov Entity ID")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("everify", "E-Verify")}
            </div>
          </div>

          <div className="row m-2">
        
          </div>

          <div className="row m-2">
        
          </div>

          <div className="row m-2">
            <span className="text-success fw-bold">Contacts:</span>
            {program.contacts ? (
              program.contacts.map((contact) => (
                <div key={contact.id} className="col-lg-4 m-2">
                  <div>{contact.type}</div>
                  <div>Name: {contact.name}</div>
                  <div>Email: {contact.email}</div>
                  <Link to={`/admin/contactEdit/${contact.id}`}>
                    <span className="badge bg-success">Edit</span>
                  </Link>
                </div>
              ))
            ) : (
              <span></span>
            )}
          </div>

          {this.renderButton("Submit")}
        </form>
      </>
    );
  }
}

export default SoNEditForm;
