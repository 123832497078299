import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ModeEditOutlineSharpIcon from "@mui/icons-material/ModeEditOutlineSharp";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import {
  deleteServiceById,
  getNewServiceById,
  getNewServicesByProgramId,
  getSerivceOptions,
  getServiceTypes,
  insertNewService,
  saveNewServicesById,
} from "../../services/newServicesService";
import { getProgramsByIdandFiscalYear } from "../../services/getProgramService";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";
import NumberFormat from "react-number-format";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

/* ================EAS Component Begins Here - Above Needs Abstracting==================*/
const EditApprovedServices = () => {

  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [currentService, setCurrentService] = useState({});
  const [insertedService, setInsertedService] = useState({});
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [program, setProgram] = useState(0);
  const [agency, setAgency] = useState(0);
  const [serviceOptions, setServiceOptions] = useState({});
  const [serviceTypes, setServiceTypes] = useState({});
  const [toDelete, setToDelete] = useState(0);
  const [totalServiceHours, setTotalServiceHours] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const location = useLocation();
  const [combinedStrings, setCombinedStrings] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [ignoreDuplicate, setIgnoreDuplicate] = useState(false);

  const [LocalFiscalYear] = useState(sessionStorage.getItem("LocalFiscalYear"));
  const fiscalYearValue = LocalFiscalYear; // Use LocalFiscalYear value as fiscalYearValue

  const { row } = location.state;
  const sonId = row.sonId;
  const programId = row.id;

  const fetchData = useCallback(async () => {
    const { data: rows } = await getNewServicesByProgramId(
      programId,
      //fiscalYearValue
      LocalFiscalYear
    );

    setRows(rows);

      const combinedStringsList = rows.map(
        (row) => `${row.codeSub}-${row.programCode ?? ""}`
      );
      setCombinedStrings(combinedStringsList);

    const { data: program } = await getProgramsByIdandFiscalYear(
      programId,
      fiscalYearValue
      //LocalFiscalYear
    );

    setProgram(program);

    const { data: agency } = await getAgencyByFiscalYearandId(
      fiscalYearValue,
      //LocalFiscalYear,
      program.agencyId
    );
    setAgency(agency);

    const { data: serviceOptions } = await getSerivceOptions();
    setServiceOptions(serviceOptions);

    const { data: serviceTypes } = await getServiceTypes();
    setServiceTypes(serviceTypes);

    var serviceHours = 0;
    var cost = 0;
    rows.forEach((row) => {
      serviceHours = serviceHours + row.duration * row.unitsPerYear;
      cost = cost + row.unitCost * row.unitsPerYear;
    });
    setTotalServiceHours(serviceHours);
    setTotalCost(cost);

    // rows.forEach( row => {
    //   setTotalServiceHours(totalServiceHours + (row.duration * row.unitsPerYear))
    //   setTotalCost(totalCost + (row.unitCost * row.unitsPerYear))
    // });
  }, [LocalFiscalYear, fiscalYearValue, programId]);

  /* =========Stuff to potentially roll into a separate component============== */
  async function fetchDataById(id) {
    const { data } = await getNewServiceById(id);
    setCurrentService(data);
  }

  const handleClose = () => {
    setCurrentService({});
    setShow(false);
  };
  const handleShow = (id) => {
    fetchDataById(id);
    setShow(true);
  };

  async function handleSave() {
    await saveNewServicesById(currentService);
    fetchData();
    handleClose();
  }

  const handleInsert = async () => {
    // Check if the combination already exists
    const newServiceCombination = `${
      serviceOptions.find(row => row.id === parseInt(insertedService.newServiceCodeId, 10))
        ?.codeSub
    }-${insertedService.programCode ?? ""}`;
    if (combinedStrings.includes(newServiceCombination) && !ignoreDuplicate) {
      // Show confirmation modal
      setIsDuplicate(true);
    } else {
      // Continue with the insertion
      await insertNewService(programId, fiscalYearValue, insertedService);
      setIgnoreDuplicate(false);
      fetchData();
    }
  };

  async function handleDelete() {
    await deleteServiceById(toDelete);
    fetchData();
    setShowConfirm(false);
  }

  const handleCloseConfirm = () => {
    setToDelete(0);
    setShowConfirm(false);
  };

  const handleShowConfirm = (id) => {
    setToDelete(id);
    setShowConfirm(true);
  };

  const handleChange2 = (event) => {
    const name = event.target.id;
    var value = event.target.value;

    // Check if the value is a valid numbe
    if (!isNaN(value)) {
      //const duration = parseFloat(value).toFixed(2);

      if (event.type === "blur") {
        value = value.toFixed(2);
      }

      if (/^\d*\.?\d{0,2}$/.test(value)) {
        var copyOfObject = { ...currentService };
        copyOfObject[name] = value;
        setCurrentService(copyOfObject);
      }
    }
  };

  const handleChange = (use2 = false, event) => {
    const name = event.target.id;

    const copyOfObject = use2 ? { ...insertedService } : { ...currentService };
    delete copyOfObject[name];

    const updatedValue = {};
    updatedValue[name] = event.target.value;

    let newData = {
      ...copyOfObject,
      ...updatedValue,
    };

    use2 ? setInsertedService(newData) : setCurrentService(newData);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData();
  }, [currentService, fetchData]);

  return (
    <>
      <h1 className="mt-4">
        {"Approved Services for Program ID "} {programId}
      </h1>
      {/* <span className="badge bg-success mb-4">{fiscalYear.name}</span> */}
      <span style={{ fontSize: "20px" }} className="badge bg-success mb-4 mt-2">
        System Viewing {fiscalYearValue}
      </span>
      <div className="row">
        <div className="row offset-md-2">
          <div className="col-2 fw-bold text-end fw-bold">Agency:</div>
          <div className="col-10 text-start">{agency.name}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-2 fw-bold text-end fw-bold">Program:</div>
          <div className="col-10 text-start">{program.name}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-2 fw-bold text-end fw-bold">Program ID:</div>
          <div className="col-10 text-start">{program.id}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-2 fw-bold text-end fw-bold">Model:</div>
          <div className="col-10 text-start">
            {program.serviceModel &&
              `${program.serviceModel.serviceModelCategoryAbrv}/${program.serviceModel.modelAbrv}`}
          </div>
        </div>
        <div className="row offset-md-2">
          <div className="col-2 fw-bold text-end fw-bold">SON ID:</div>
          <div className="col-10 text-start">{sonId}</div>
        </div>
      </div>
      <br />

      <Modal show={isDuplicate} onHide={() => setIsDuplicate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Duplicate service code+subcode found. Include letter code for each.
          Do you still want to submit?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsDuplicate(false)}>
            No, Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setIsDuplicate(false);
              setIgnoreDuplicate(true);
              // Continue with the insertion
              handleInsert();
            }}
          >
            Yes, Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          style={{ maxHeight: `${53 * rows.length}px` }}
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="fw-bold" align="center">
                -
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Sub Code
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Letter Code
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Service Type
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Description
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Sub Code Description
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Additional Description
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Duration
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Total Units
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Units Cost
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Service Hours
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Cost
              </TableCell>
              <TableCell className="fw-bold" align="center">
                -
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.id}>
                {/* {console.log('Line 366: row', row)} */}
                <TableCell component="th" scope="row" style={{ width: 60 }}>
                  <div role="button" onClick={(e) => handleShow(row.id)}>
                    <ModeEditOutlineSharpIcon />
                    Edit
                  </div>
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.codeSub}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.programCode}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.typeDescription}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.description}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.subDescription}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.programCodeDesc}
                </TableCell>
                <TableCell
                  title={`Duration: ` + row.duration}
                  style={{ width: 160 }}
                  align="center"
                >
                  <NumberFormat
                    value={row.duration}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.unitsPerYear}
                </TableCell>
                <TableCell
                  title={`Unit Cost: ` + row.unitCost}
                  style={{ width: 160 }}
                  align="center"
                >
                  <NumberFormat
                    value={row.unitCost}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </TableCell>
                <TableCell
                  title={`Service Hours: ` + row.duration * row.unitsPerYear}
                  style={{ width: 160 }}
                  align="center"
                >
                  <NumberFormat
                    value={row.duration * row.unitsPerYear}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  <NumberFormat
                    value={row.unitCost * row.unitsPerYear}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="danger"
                    onClick={() => handleShowConfirm(row.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, { value: -1, label: "All" }]}
                colSpan={6}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                // SelectProps={{
                //   inputProps: { "aria-label": "rows per page" },
                //   native: true,
                // }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <div className="flex py-4">
        <div className="float-end row">
          <div className="col-md-6">
            <span className="fw-bold">Total Service Hours: </span>
            <NumberFormat
              value={totalServiceHours}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
          <div className="col-md-6">
            <span className="fw-bold">Total Cost: </span>
            <p>
              <NumberFormat
                value={totalCost}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
                fixedDecimalScale
              />
            </p>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group className="col-lg-6 mb-2" controlId="programCode">
                <Form.Label>Letter Code:</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  value={
                    currentService.programCode ? currentService.programCode : ""
                  }
                  onChange={(event) => handleChange(false, event)}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="col-lg-6 mb-2" controlId="programCodeDesc">
                <Form.Label>Additional Description:</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  value={
                    currentService?.programCodeDesc
                      ? currentService.programCodeDesc
                      : ""
                  }
                  onChange={(event) => handleChange(false, event)}
                />
              </Form.Group>
              <Form.Group className="col-lg-6 mb-2" controlId="duration">
                <Form.Label>Duration:</Form.Label>
                <Form.Control
                  type="number"
                  className="form-control-sm"
                  //step={0.01}
                  min={0.0}
                  value={currentService?.duration}
                  onChange={(event) => handleChange2(event)}
                />
              </Form.Group>
              <Form.Group className="col-lg-6 mb-2" controlId="unitsPerYear">
                <Form.Label>Units Per Year:</Form.Label>
                <Form.Control
                  type="number"
                  className="form-control-sm"
                  value={
                    currentService?.unitsPerYear
                      ? currentService.unitsPerYear
                      : ""
                  }
                  onChange={(event) => handleChange(false, event)}
                />
              </Form.Group>
              <Form.Group className="col-lg-6 mb-2" controlId="unitCost">
                <Form.Label>Unit Cost:</Form.Label>
                <Form.Control
                  type="number"
                  className="form-control-sm"
                  value={currentService?.unitCost}
                  onChange={(event) => handleChange2(event)}
                />
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-2"
                controlId="newServiceTypeId"
              >
                <Form.Label>Service Type</Form.Label>
                <Form.Select
                  value={currentService.codeSub}
                  onChange={(event) => handleChange(false, event)}
                >
                  <option>Open this select menu</option>
                  {serviceTypes.length > 1 &&
                    serviceTypes.map((option, i) => (
                      <option key={i} value={option.id}>
                        {option.codeSub} = {option.description} |{" "}
                        {option.subDescription}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            variant="secondary"
            className="bg-danger"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button variant="primary" className="bg-success" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Close
          </Button>
          &nbsp;
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Body>
      </Modal>

      <div className="py-4">
        <Form>
          <Form.Group className="col-md-6 mb-2" controlId="newServiceCodeId">
            <Form.Label>Service</Form.Label>
            <Form.Select onChange={(event) => handleChange(true, event)}>
              <option>Open this select menu</option>
              {serviceOptions.length > 1 &&
                serviceOptions.map((option, i) => (
                  <option key={i} value={option.id}>
                    {option.codeSub} = {option.description} |{" "}
                    {option.subDescription}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <div className="row">
            <Form.Group className="col-lg-6 mb-2" controlId="programCode">
              <Form.Label>Letter Code:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={insertedService.programCode}
                onChange={(event) => handleChange(true, event)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-2" controlId="programCodeDesc">
              <Form.Label>Additional Description:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={insertedService.programCodeDesc}
                onChange={(event) => handleChange(true, event)}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-2" controlId="duration">
              <Form.Label>Duration:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={insertedService.duration}
                onChange={(event) => handleChange(true, event)}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-2" controlId="unitsPerYear">
              <Form.Label>Units Per Year:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={insertedService.unitsPerYear}
                onChange={(event) => handleChange(true, event)}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-2" controlId="unitCost">
              <Form.Label>Unit Cost:</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={insertedService.unitCost}
                onChange={(event) => handleChange(true, event)}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-2" controlId="newServiceTypeId">
              <Form.Label>Service Type</Form.Label>
              <Form.Select onChange={(event) => handleChange(true, event)}>
                <option>Open this select menu</option>
                {serviceTypes.length > 1 &&
                  serviceTypes.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.description}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </div>
          <Button
            variant="primary"
            onClick={() => {
              setIgnoreDuplicate(true);
              handleInsert();
            }}
          >
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
};

export default EditApprovedServices;
