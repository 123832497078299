import { Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function TrainingResources() {
  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };
  return (
    <Paper elevation={10} style={paperStyle}>
      <h1 className="mb-5">Training &amp; Resources</h1>

      <div className="row mb-5">
        <div className="container-fluid">
          <h4>Online Training</h4>
          <p>
            The online training listed below is being made available at no cost
            to PSSF providers, staff and volunteers as a PSSF program resource
            enhancement.
          </p>

          <ul>
            <li>
              <a
                className="link"
                style={{
                  color: "#779442",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                href="https://www.prosolutionstraining.com/store/product/?tProductVersion_id=1093"
                title="Link: https://www.prosolutionstraining.com/store/product/?tProductVersion_id=1093"
                rel="noopener noreferrer"
                target="_blank"
              >
                Mandated Reporters Georiga
              </a>
            </li>
            <li>
              <a
                className="link"
                style={{
                  color: "#779442",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                href="https://www.prosolutionstraining.com/store/product/?tProductVersion_id=1598"
                title="Link: https://www.prosolutionstraining.com/store/product/?tProductVersion_id=1598"
                rel="noopener noreferrer"
                target="_blank"
              >
                Safe Sleep for Georgia Babies
              </a>
            </li>
            <li>
              <a
                className="link"
                style={{
                  color: "#779442",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                href="https://www.prosolutionstraining.com/store/product/?tProductVersion_id=1227"
                title="Link: https://www.prosolutionstraining.com/store/product/?tProductVersion_id=1227"
                rel="noopener noreferrer"
                target="_blank"
              >
                Trauma-Informed Practice for Professionals Working with Foster
                Children
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row mb-5">
        <div className="container-fluid">
          <h4>Instructions for Accessing Training</h4>
          <ul>
            <li>
              Select one of the training links. This will take you to the
              ProSolutions Training website*.
            </li>
            <li>
              The course selected will be noted at the top of your screen.
            </li>
            <li>Select "Order Now" to begin the training.</li>
            <li>Click on "View Shopping Cart" link.</li>
            <li>
              Select "Purchase Now" although there is no charge for you to take
              these trainings.
            </li>
          </ul>

          <small>
            *If you do not already have an account with ProSolutions Training,
            you will have to set one up to continue to the training so that you
            are able to obtain a certificate when you've completed the course.
          </small>
        </div>
      </div>

      <div className="row mb-5">
        <div className="container-fluid">
          <h4>Other Resources</h4>

          <ul>
            <li>
              <Link
                to={require("../../docs/PSSFWeb101_manual_02212023.pdf")}
                rel="noopener noreferrer"
                target="_blank"
                title="PDF PSSF Web 101 Manual Download"
                style={{
                  color: "#779442",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                PSSFWeb 101 Manual
              </Link>
            </li>
            <li>
              <Link
                to={require("../../docs/2024_QERTemplate.xlsx")}
                rel="noopener noreferrer"
                target="_blank"
                title="PDF FFY203 Reporting Dates & Deadlines Download"
                style={{
                  color: "#779442",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                FFY2024 QER Template
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Paper>
  );
}

export default TrainingResources;
