import React from "react";
import { Link } from "react-router-dom";

const Sfv = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Reunification</h3>
          <h5 className="text-center">Supervised Family Visitation</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">Supervised Family Visitation</span>{" "}
            services provide increased opportunities for children in foster care
            to visit with their families in less restrictive but secure,
            non-threatening environments. Supervised visitation centers may also
            provide opportunities for children to maintain connections with
            siblings placed in different placements or visit with extended
            family members or other significant adults. These community-based
            visitation centers are designed with a parent’s schedule in mind and
            should increase the frequency and quality of interactions between
            parents and children and children and their siblings and other
            significant adults.
            <br />
            <br />
            Successful family reunification is based, in part, on the family or
            primary caregiver demonstrating an understanding of the child's
            needs and their competency to meet those identified needs during
            observed visits. Services are designed to establish or sustain
            parent-child and sibling relationships and facilitate the
            achievement of reunification case plan goals.
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Initial Assessment & Service Plan</li>
              <li>Case Management</li>
              <li>
                Substance Abuse Treatment, Relapse and/or Recovery Support
              </li>
              <li>Parent Education or Parent Training</li>
            </ol>
            Transportation should be coordinated to remove barriers to
            consistent visitation. If transportation by caseworker, foster
            parent(s) or relative caregiver(s) is not available, transportation
            should be provided as one of the additional services. Other services
            may include:
            <ul>
              <li>Pre-reunification parent education</li>
              <li>Post-reunification in-home follow up</li>
              <li>Therapeutic counseling</li>
              <li>Transportation to and from visits, for child or parent</li>
              <li>Peer mentoring</li>
              <li>Support groups</li>
              <li>Substance abuse treatment and recovery support</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Sfv;
