import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getAgencyListAllWIthFiscalYear } from "../../services/getAgencyService";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import CustomDataTable from "../common/customDataTable";

export default function AgenciesWithFiscalYearTable() {
  const currentState = useSelector((state) => state.fiscalYear);
  const [rows, setRows] = useState([]);
const [paginationModel, setPaginationModel] = React.useState({
  pageSize: 50,
  page: 0,
});
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: .3,
      renderCell: (params) => (
        <Link
          className="text-success"
          to={`/admin/agency/${params.value}/${currentState.current.value}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "name",
      headerName: "Agency Name",
      flex: 3,
    },
    {
      field: "programsCount",
      headerName: "Agency Program(s) Count",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isAgencyActive",
      headerName: "Agency Status",
      flex: 0.5,
      renderCell: (params) => (params.value ? "Active" : "Inactive"),
      align: "center",
      headerAlign: "center",
    },
  ];

  const fetchData = useCallback(async () => {
    const { data: rows } = await getAgencyListAllWIthFiscalYear(
      currentState.current.value
    );
    setRows(rows);
  }, [currentState]);

  useEffect(() => {
    if (currentState.current.value !== "") {
      fetchData();
    }
  }, [currentState, fetchData]);

  return (
    <Box sx={{ width: "99%" }}>
      <h5 className="fw-bold">
        Viewing Agencies Local Fiscal Year: '{currentState.current.value}'
      </h5>
      <CustomDataTable
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );
}
