import React, { Component } from "react";
import SonFilesTable from "../Tables/sonFilesTable";
import { getDocumentGroupStatus } from "../../services/getSonServices";
import Paper from "@material-ui/core/Paper";

import TableContainer from "@material-ui/core/TableContainer";

class FileManagement extends Component {
  state = {
    sonUsers: [],
    fy: 0,
  };

  LocalFiscalYear = sessionStorage.getItem('LocalFiscalYear');

  async populateDocGroupStatus() {
    const { data } = await getDocumentGroupStatus(this.LocalFiscalYear);
    this.setState({ sonUsers: data });
    const sonUsers = data.filter((data) => {
      return data.programByFiscalYear[0].totalServices > 0;
    });
    this.setState({ sonUsers });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async componentDidMount() {
    await this.setStateAsync({ fy: this.LocalFiscalYear });
    await this.populateDocGroupStatus();
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { sonUsers } = this.state;
    const { length: count } = this.state.sonUsers;


    return (
      <>
        <div className="container-fluid">
          <h1>File Management</h1>

          <div className="row">
            <span className="badge bg-success mb-4 p-3">
              Viewing System Fiscal Year: {this.LocalFiscalYear}
            </span>
            <br />
          </div>

          <div className="mb-5">
            {count > 0 ? (
              <>
                <TableContainer component={Paper}>
                  <SonFilesTable sonUsers={sonUsers} fy={this.LocalFiscalYear} />
                </TableContainer>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 150,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>Sorry There Is No Data For This Selection</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default FileManagement;
