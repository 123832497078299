import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useLocation } from "react-router-dom";
import {
  getNewServiceById,
  getNewServicesByProgramId,
  getSerivceOptions,
  getServiceTypes,
} from "../../services/newServicesService";
import { getProgramsByIdandFiscalYear } from "../../services/getProgramService";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

/* ================EAS Component Begins Here - Above Needs Abstracting==================*/
const EditApprovedServices = ({ fiscalYear }) => {
  const programId = sessionStorage.getItem("ProgramId");
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [data2, setData2] = useState({});
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [program, setProgram] = useState(0);
  const [agency, setAgency] = useState(0);
  const [serviceOptions, setServiceOptions] = useState({});
  const [serviceTypes, setServiceTypes] = useState({});
  const [toDelete, setToDelete] = useState(0);
  const [totalServiceHours, setTotalServiceHours] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  async function fetchData() {
    const { data: rows } = await getNewServicesByProgramId(
      programId,
      fiscalYear.value
    );
    setRows(rows);

    const { data: program } = await getProgramsByIdandFiscalYear(
      programId,
      fiscalYear.value
    );
    setProgram(program);

    const { data: agency } = await getAgencyByFiscalYearandId(
      fiscalYear.value,
      program.agencyId
    );
    setAgency(agency);

    const { data: serviceOptions } = await getSerivceOptions();
    setServiceOptions(serviceOptions);

    const { data: serviceTypes } = await getServiceTypes();
    setServiceTypes(serviceTypes);

    var serviceHours = 0;
    var cost = 0;
    rows.forEach((row) => {
      serviceHours = serviceHours + row.duration * row.unitsPerYear;
      cost = cost + row.unitCost * row.unitsPerYear;
    });
    setTotalServiceHours(serviceHours);
    setTotalCost(cost);

    // rows.forEach( row => {
    //   setTotalServiceHours(totalServiceHours + (row.duration * row.unitsPerYear))
    //   setTotalCost(totalCost + (row.unitCost * row.unitsPerYear))
    // });
  }

  /* =========Stuff to potentially roll into a separate component============== */

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    {
      fiscalYear.value && fetchData();
    }
    //console.log(sessionStorage.getItem("ProgramId"));
  }, [data, fiscalYear, programId]);

  if (!fiscalYear) return null;
  return (
    <>
      <h1>
        {fiscalYear.name == "Current FY"
          ? "Edit Current Year's Services for "
          : "Edit Approved Services for "}{" "}
        {programId}
      </h1>
      <span className="badge bg-success mb-4">{fiscalYear.name}</span>
      <div className="row offset-md-2">
        <div className="col-lg-4">Agency:</div>
        <div className="col-lg-6">{agency.name}</div>
      </div>
      <div className="row offset-md-2">
        <div className="col-lg-4">Program:</div>
        <div className="col-lg-6">{program.name}</div>
      </div>
      <div className="row offset-md-2">
        <div className="col-lg-4">Program ID:</div>
        <div className="col-lg-6">{program.id}</div>
      </div>
      <div className="row offset-md-2">
        <div className="col-lg-4">Model:</div>
        <div className="col-lg-6">
          {program.serviceModel &&
            `${program.serviceModel.serviceModelCategoryAbrv}/${program.serviceModel.modelAbrv}`}
        </div>
      </div>
      <br />

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Sub Code</TableCell>
              <TableCell align="center">Service Type</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Sub Code Description</TableCell>
              <TableCell align="center">Letter Code</TableCell>
              <TableCell align="center">Additional Description</TableCell>
              <TableCell align="center">Duration</TableCell>
              <TableCell align="center">Total Units</TableCell>
              <TableCell align="center">Units Cost</TableCell>
              <TableCell align="center">Service Hours</TableCell>
              <TableCell align="center">Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.id}>
                <TableCell style={{ width: 160 }} align="center">
                  {row.codeSub}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.typeDescription}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.description}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.subDescription}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.programCode}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.programCodeDesc}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.duration}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.unitsPerYear}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.unitCost}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  {row.duration * row.unitsPerYear}
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  ${row.unitCost * row.unitsPerYear}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <div className="py-4">
        <div className="float-end row">
          <div className="col-md-6">
            Total Service Hours: {totalServiceHours}
          </div>
          <div className="col-md-6">Total Cost: {totalCost}</div>
        </div>
      </div>
    </>
  );
};

export default EditApprovedServices;
