import React, { useState } from "react";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomDataTable from "../common/customDataTable";

const SonFilesTable = ({ sonUsers, fy }) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  // Preprocess sonUsers to add new properties for sortable columns
  const preprocessSonUsers = (sonUsers) => {
    return sonUsers.map((sonUser) => {
      const programByFiscalYear = sonUser.programByFiscalYear?.[0] || {};

      // Extract desired values and add them as new properties to sonUser
      return {
        ...sonUser,
        programStatus: programByFiscalYear.programStatus
          ? "Active"
          : "Inactive",
        contractCompliant: programByFiscalYear.contractCompliant,
        annexCCompliant: programByFiscalYear.annexCCompliant,
        annexDCompliant: programByFiscalYear.annexDCompliant,
        formsCompliant: programByFiscalYear.formsCompliant,
        otherCompliant: programByFiscalYear.otherCompliant,
      };
    });
  };

  // Preprocess sonUsers array
  const processedSonUsers = preprocessSonUsers(sonUsers);

  // Define columns in the format required by CustomDataTable
  const columns = [
    {
      field: "sonId",
      headerName: "Son Id",
      flex: 0.5,
      renderCell: (params) => (
        <Link to={`/admin/sonfilemanagement/${fy}/${params.row.id}`}>
          {params.value}
        </Link>
      ),
      sortable: true,
    },
    {
      field: "agencyName",
      headerName: "Agency",
      flex: 1,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Program",
      flex: 1,
      sortable: true,
    },
    {
      field: "programStatus",
      headerName: "Program Status",
      renderCell: (params) => <div className="fst-italic">{params.value}</div>,
      flex: 0.7,
      sortable: true,
    },
    {
      field: "contractCompliant",
      headerName: "Contract",
      renderCell: (params) => {
        return params.value ? (
          <CheckCircleIcon sx={{ color: "green" }} />
        ) : (
          <CancelIcon sx={{ color: "darkorange" }} />
        );
      },
      flex: 0.5,
      sortable: true,
    },
    {
      field: "annexCCompliant",
      headerName: "Annex C",
      renderCell: (params) => {
        return params.value ? (
          <CheckCircleIcon sx={{ color: "green" }} />
        ) : (
          <CancelIcon sx={{ color: "darkorange" }} />
        );
      },
      flex: 0.5,
      sortable: true,
    },
    {
      field: "annexDCompliant",
      headerName: "Annex D",
      renderCell: (params) => {
        return params.value ? (
          <CheckCircleIcon sx={{ color: "green" }} />
        ) : (
          <CancelIcon sx={{ color: "darkorange" }} />
        );
      },
      flex: 0.5,
      sortable: true,
    },
    {
      field: "formsCompliant",
      headerName: "Forms",
      renderCell: (params) => {
        return params.value ? (
          <CheckCircleIcon sx={{ color: "green" }} />
        ) : (
          <CancelIcon sx={{ color: "darkorange" }} />
        );
      },
      flex: 0.5,
      sortable: true,
    },
    {
      field: "otherCompliant",
      headerName: "Other",
      renderCell: (params) => {
        return params.value ? (
          <CheckCircleIcon sx={{ color: "green" }} />
        ) : (
          <CancelIcon sx={{ color: "darkorange" }} />
        );
      },
      flex: 0.5,
      sortable: true,
    },
  ];

  return (
    <div style={{ width: "99%" }}>
      <CustomDataTable
        rows={processedSonUsers}
        columns={columns}
        sortingOrder={["asc", "desc", null]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </div>
  );
};

export default SonFilesTable;
