import React from "react";
import { Route, Routes } from "react-router-dom";
import PowerUserHome from "../components/poweruser/home";
import DataManagement from "../components/poweruser/datamanagement";

const PowerUserRoute = () => {
  return (
    <Routes>
      <Route path="/poweruser/home" element={<PowerUserHome />} />
      <Route path="/poweruser/datamanagement" element={<DataManagement />} />
    </Routes>
  );
};

export default PowerUserRoute;
