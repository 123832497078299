import React from "react";
import { Link } from "react-router-dom";

const Str = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Preservation</h3>
          <h5 className="text-center">
            Substance Abuse Treatment &amp; Recovery
          </h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">
              Substance Abuse Treatment and Recovery Support
            </span>{" "}
            services are provided to parents when substance abuse treatment,
            random substance abuse drug screenings and/or sustained abstinence
            are required to prevent maltreatment, removal of the child from the
            home, or as a condition for reunification. Services include
            intensive, hands-on parenting component to ensure child safety,
            prevent abandonment and prevent or reduce the incidence of child
            abuse and neglect. Relapse prevention support to include in-home
            follow-up and 24-hour relapse/crisis management support provided by
            an accredited Addictions Counselor.
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Initial Assessment & Service Plan</li>
              <li>Case Management</li>
              <li>
                Substance Abuse Treatment, Relapse and/or Recovery Support
              </li>
              <li>Parent Education or Parent Training</li>
            </ol>
            Based on the reason for referral, the results of the assessment, and
            case plan goals, service plans may also include additional supports
            and services needed to effectively address the circumstances that
            resulted in the family’s involvement with the child welfare agency
            to prevent reoccurrence. These may include:
            <ul>
              <li>Drug testing</li>
              <li>Transportation</li>
              <li>Therapy</li>
              <li>Educational Supports</li>
              <li>Support Groups</li>
              <li>Childcare</li>
              <li>Family Life Skills (Budgeting, Housekeeping, etc.)</li>
              <li>Employment Training, Job Skills or Vocational Training</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Str;
