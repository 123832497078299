import http from "./httpService";

const apiEndpoint = "/Case";

export function getCaseById(id) {
  return http.get(`${apiEndpoint}/caseById/${id}`);
}

export function getCasesByProgramId(ProgramId) {
  return http.get(`${apiEndpoint}/casesByProgramId/${ProgramId}`);
}

export function getIncomeSources() {
  return http.get(`${apiEndpoint}/incomeSources`);
}

export function getIncomeOptions() {
  return http.get(`${apiEndpoint}/incomeOptions`);
}

export function getReferralReasons() {
  return http.get(`${apiEndpoint}/referralReasons`);
}

export function getReferralSources() {
  return http.get(`${apiEndpoint}/referralSources`);
}

export function getFamilyStatuses() {
  return http.get(`${apiEndpoint}/familyStatuses`);
}

export function getChildren() {
  return http.get(`${apiEndpoint}/children`);
}

export function getChildrenByCaseId(id) {
  return http.get(`${apiEndpoint}/children/${id}`);
}

export function getExitReasons() {
  return http.get(`${apiEndpoint}/exitReasons`);
}

export function getExitStatuses() {
  return http.get(`${apiEndpoint}/exitStatuses`);
}

export function getCoreServicesParticipationOptions() {
  return http.get(`${apiEndpoint}/coreServicesParticipationOptions`);
}

export function getEffectivenessOfParticipationOptions() {
  return http.get(`${apiEndpoint}/effectivenessOfParticipationOptions`);
}

export function getProgressAchievementOptions() {
  return http.get(`${apiEndpoint}/progressAchievementOptions`);
}

export function getImprovements() {
  return http.get(`${apiEndpoint}/improvements`);
}

export function getChildStatusAtExitOptions() {
  return http.get(`${apiEndpoint}/childStatusAtExitOptions`);
}

export function getExitPermanencyPlans() {
  return http.get(`${apiEndpoint}/exitPermanencyPlans`);
}

export function getPermanencyPlans() {
  return http.get(`${apiEndpoint}/permanencyPlans`);
}

export function updateCaseById(
  id,
  _case,
  intakeComplete = false,
  beginExit = false,
  exitComplete = false
) {
  const body = { ..._case };
  if (
    intakeComplete &&
    body["status"] !== "Exit Incomplete" &&
    body["status"] !== "Archived" &&
    body["status"] !== "Open"
  ) {
    body["status"] = "Intake Pending";
    body["dateOfIntake"] = new Date();
  }
  if (beginExit && body["status"] !== "Archived")
    body["status"] = "Exit Incomplete";
  if (exitComplete && body["status"] !== "Archived") {
    body["status"] = "Archived";
    body["dateOfExit"] = new Date();
  }
  return http.post(`${apiEndpoint}/update/${id}`, body);
}

export function insertCaseSectionA(programId, _case) {
  const body = { ..._case };
  return http.post(`${apiEndpoint}/${programId}`, body);
}

export function insertCaregiver(caseId, caregiver) {
  const body = { ...caregiver };
  return http.post(`${apiEndpoint}/insertCaregiver/${caseId}`, body);
}

export function deleteCaregiver(id) {
  return http.delete(`${apiEndpoint}/caregiver/${id}`);
}

export function insertChild(caseId, child) {
  const body = { ...child };
  return http.post(`${apiEndpoint}/insertChild/${caseId}`, body);
}

export function deleteChild(id) {
  return http.delete(`${apiEndpoint}/child/${id}`);
}

export function deleteCase(id) {
  return http.delete(`${apiEndpoint}/deleteCase/${id}`);
}

export function updateChild(id, child) {
  const body = { ...child };
  return http.post(`${apiEndpoint}/updateChild/${id}`, body);
}

export function updateCaseStatusById(id, status) {
  return http.post(`${apiEndpoint}/updateCaseStatus/${id}/${status}`);
}
