import React from "react";
import {
  getAgencyByFiscalYearandId,
  saveAgency,
} from "../../services/getAgencyService";
import { getContactByAgencyId } from "../../services/getContactService";
import Form from "../common/form";

class AgencyEditForm extends Form {
  state = {
    data: {}, //agency
    //data2: {},

    submit: false,
  };

  async getAgency() {
    const { id, fy } = this.props;
    let { data } = await getAgencyByFiscalYearandId(fy, id);

    this.setState({ data });
  }

  async populateContactByAgency() {
    const { data: edContact } = await getContactByAgencyId(this.state.data.id);
    this.setState((state) => ({
      data: {
        ...state.data,
        ContactName: edContact.name ?? null,
        ContactPhone: edContact.phone ?? null,
        ContactEmail: edContact.email ?? null,
        ContactFax: edContact.fax ?? null,
      },
    }));
  }

  async populateAgenciesByFiscalYear() {
    this.setState((state) => {
      this.state.data.yearEnd = this.state.data.agenciesByFiscalYear[0].yearEnd;
      return state;
    });
  }

  async componentDidMount() {
    await this.getAgency();
    await this.populateContactByAgency();
    await this.populateAgenciesByFiscalYear();
  }

  updateNestedState() {
    this.setState((prevState) => {
      const { data: agency } = prevState;
      const newData = { ...prevState.data };
      newData.contacts = newData.contacts ?? [];
      newData.contacts[0] = newData.contacts[0] ?? {};

      newData.contacts[0].type =
        newData.contacts[0].type ?? "Executive_Director";
      newData.contacts[0].dateCreated =
        newData.contacts[0].dateCreated ?? new Date();

      newData.contacts[0].name = agency.ContactName ?? null;
      newData.contacts[0].phone = agency.ContactPhone ?? null;
      newData.contacts[0].email = agency.ContactEmail ?? null;
      newData.contacts[0].fax = agency.ContactFax ?? null;

      newData.agenciesByFiscalYear = newData.agenciesByFiscalYear ?? [];
      newData.agenciesByFiscalYear[0] = newData.agenciesByFiscalYear[0] ?? {};
      newData.agenciesByFiscalYear[0].yearEnd = agency.yearEnd;

      return { data: newData };
    });
  }

  async doSubmit() {
    const { data } = this.state;
    try {
      this.updateNestedState();
      await saveAgency(data);
      /*       const submit = true;
      this.setState({ submit }); */
    } catch (error) {
      const submit = false;
      this.setState({ submit });
    }
  }

  render() {
    // const { data: agency, data2 } = this.state;

    // const { submit } = this.state;
    const entityTypes = [
      { id: 1, name: "Non-Profit" },
      { id: 2, name: "Public Entity" },
    ];
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("name", "Agency Name")}
            </div>
            <div className="col-lg-4">
              {this.renderSelect("entityType", "Entity Type", entityTypes)}
            </div>
            <div className="col-lg-4">
              {this.renderInput("yearEnd", "Year End")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-3">
              {this.renderInput("address", "Agency Mailing Address")}
            </div>
            <div className="col-lg-3">{this.renderInput("city", "City")}</div>
            <div className="col-lg-3">
              {this.renderInput("state", "State", "", false)}
            </div>
            <div className="col-lg-3">{this.renderInput("zip", "Zip")}</div>
          </div>

          <div className="row m-2">
            <div className="col-lg-3">
              {this.renderInput("streetAddress", "Agency Street Address")}
            </div>
            <div className="col-lg-3">
              {this.renderInput("streetCity", "City")}
            </div>
            <div className="col-lg-3">
              {this.renderInput("streetState", "State", "", false)}
            </div>
            <div className="col-lg-3">
              {this.renderInput("streetZip", "Zip")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("phone", "Phone Number")}
            </div>
            <div className="col-lg-4">{this.renderInput("fax", "Fax")}</div>
            <div className="col-lg-4">
              {this.renderInput("email", "Email Address")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("webAddress", "Web Address")}
            </div>
            <div className="col-lg-4">{this.renderInput("ein", "EIN")}</div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("ContactName", "ED Name")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("ContactPhone", "ED Phone")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("ContactEmail", "ED Email")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("ContactFax", "ED Fax")}
            </div>
          </div>

          <div className="m-4">{this.renderButton("Save")}</div>
        </form>
      </>
    );
  }
}

export default AgencyEditForm;
