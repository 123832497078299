import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProgramsWithFiscalYearTable from "../Tables/programsWithFiscalYearTable";
import { useSelector } from "react-redux";
import { FaPlusCircle } from "react-icons/fa";


function Programs() {
  const currentState = useSelector((state) => state.fiscalYear);
  const [fiscalYear, setFiscalYear] = useState(currentState.current.value);

  useEffect(() => {
    // Update the document title using the browser API]
    if (fiscalYear === "") setFiscalYear(currentState.current.value);
  }, [fiscalYear, currentState]);

  return (
    <>
      <div  className="row">
        <div className="col">
            <h1>Programs</h1>
        </div>
        <div className="col mt-2 text-end pe-3 position-relative p-3">
          <span className="border border-success text-success mt-3 relative p-3 mb-3">Viewing as Year: {currentState.current.value}</span>
        </div>
      </div>
      <div className="mb-4">
          <Link
            className="text-success"
            to={`/admin/add-program/${currentState.current.value}`}
          >
            <FaPlusCircle />
            <span className="ps-3">Add A New Program</span>
          </Link>
      </div>
      {fiscalYear && <ProgramsWithFiscalYearTable fiscalYear={fiscalYear} />}
    </>
  );
}




export default Programs;
