import React, { Component } from "react";
import Checkbox from "./checkBox";
import Input from "./input";
import Select from "./select";
import Textarea from "./textarea";

class Form extends Component {
  state = {
    data: {},
    data2: {},
    errors: {},
  };

  // validate = () => {
  //   const options = { abortEarly: false };
  //   const { error } = Joi.validate(this.state.data, this.schema, options);
  //   if (!error) return null;

  //   const errors = {};
  //   for (let item of error.details) errors[item.path[0]] = item.message;
  //   return errors;
  // };

  // validateProperty = ({ name, value }) => {
  //   const obj = { [name]: value };
  //   const schema = { [name]: this.schema[name] };
  //   const { error } = Joi.validate(obj, schema);
  //   return error ? error.details[0].message : null;
  // };

  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };

  handleCheck = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = data[input.name] === true ? false : true;

    this.setState({ data });
  };

  handleCheck2 = ({ currentTarget: input }) => {
    const data2 = { ...this.state.data2 };
    data2[input.name] = data2[input.name] === true ? false : true;

    this.setState({ data2 });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = {};
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    
    if(input.required) this.doChange(input);

    

    const data2 = { ...this.state.data2 };
    data2[input.name] = input.value;

    this.setState({ data, errors, data2 });
  };

  handleMultiCheck = ({ currentTarget: input }) => {
    const data = [...this.state.data];
    const id = parseInt(input.id);

    let newArr = [];
    if (data.find((elem) => elem.id === id)) {
      newArr = data.filter((elem) => elem.id !== id);
    } else {
      newArr = [...data, { id: id, name: input.name }];
    }

    this.setState({ data: newArr });
  };

  renderButton(label, extClass = " btn-primary", disable = false) {
    let classes = "btn";
    classes += `${extClass}`;
    return (
      <button className={classes} disabled={disable}>
        {label}
      </button>
    );
  }
  renderSelect(name, label, options) {
    const { data } = this.state;

    return (
      <Select
        name={name}
        value={data[name] === null ? "" : data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
      />
    );
  }

  renderSelectStatus(name, label, options) {
    const { data } = this.state;
    return (
      <Select
        name={name}
        value={data[name] === null ? "" : data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
      />
    );
  }

  renderInput(name, label, errors, disable, type = "text", required = false) {
    const { data } = this.state;
    return (
      <Input
        type={type}
        name={name}
        defaultValue={data[name] === null ? "" : data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors}
        disable={disable}
        required={required}
        className="form-control"
      />
    );
  }

  renderCheck(name, label) {
    const { data, type = "checkbox" } = this.state;
    return (
      <Checkbox
        type={type}
        name={name}
        check={data[name] === null ? "" : data[name]}
        onChange={this.handleCheck}
        label={label}
      />
    );
  }

  renderCheck2(name, label) {
    const { data2, type = "checkbox" } = this.state;
    return (
      <Checkbox
        type={type}
        name={name}
        check={data2[name] === null ? "" : data2[name]}
        onChange={this.handleCheck2}
        label={label}
      />
    );
  }

  renderInput2(name, label, errors, disable) {
    const { data2, type = "text" } = this.state;

    return (
      <Input
        type={type}
        name={name}
        defaultValue={data2[name] === null ? "" : data2[name]}
        label={label}
        onChange={this.handleChange}
        error={errors}
        disable={disable}
      />
    );
  }

  renderMultiCheckbox(check, name, id) {
    return (
      <Checkbox
        name={name}
        id={id}
        checked={check}
        onChange={this.handleMultiCheck}
      />
    );
  }

  renderTextarea(name, label, errors, disable) {
    const { data } = this.state;
    return (
      <Textarea
        name={name}
        value={data[name] === null ? "" : data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors}
        disable={disable}
      />
    );
  }
}

export default Form;
