import React from "react";
import { Link } from "react-router-dom";

const Cis = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Preservation</h3>
          <h5 className="text-center">Crisis Intervention</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">Crisis Intervention</span> services are
            designed for children/youth and caregivers to address behaviors that
            threaten the safety and/or placement stability. Services are
            designed to support families in crisis where children are at high
            risk for removal from the home primarily due to child behavior.
            Service providers must be knowledgeable of and collaborate with DFCS
            and other community- and faith-based agencies to ensure families
            receive the array of supports and services they need to maintain
            safe and stable home environments. Services should be available to
            families 24 hours a day in the home and/or group home primarily but
            may include other environments as needed.
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Child Assessment &amp; Service Plan</li>
              <li>Case Management</li>
              <li>Crisis Intervention</li>
              <li>In-Home Behavior Management</li>
              <li>Therapy</li>
            </ol>
            Based on the reason for referral, needs identified in the assessment
            and case plan goals, service plans may include additional supports
            and services necessary to enhance the effectiveness required
            services. These may include:
            <ul>
              <li>Parent Education/Parent Training</li>
              <li>Educational Supports</li>
              <li>Respite</li>
              <li>Support Groups</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Cis;
