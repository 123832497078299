import React, { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  deleteChild,
  getChildrenByCaseId,
  insertChild,
  updateChild,
} from "../../../services/casesService";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import ChildForm from "./childform";

const CaseFormC = ({
  user,
  data,
  setData,
  getData,
  caseId,
  handleNext,
  handleBack,
}) => {
  const [pc, setPc] = useState({});
  const [show, setShow] = useState(false);
  const [secondaryCaregivers, setSecondaryCaregivers] = useState({});
  const [childToAdd, setChildToAdd] = useState({
    childCaregiverRelationships: [],
  });
  const [children, setChildren] = useState({});
  const [hasError, setHasError] = useState(false);
  const [hasDueDateError, setHasDueDateError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [over18, setOver18] = useState(false);
  const [orderBy, setOrderBy] = useState("dateOfBirth");
  const [order, setOrder] = useState("asc");
  const [isEdit, setIsEdit] = useState(false);

  async function fetchData() {
    var pc =
      data.caregivers &&
      data.caregivers.find((caregiver) => caregiver.isPrimary);
    setPc(pc);

    var secondaryCaregivers =
      data.caregivers &&
      data.caregivers.find((caregiver) => !caregiver.isPrimary);
    setSecondaryCaregivers(secondaryCaregivers);

    var { data: children } = await getChildrenByCaseId(caseId);
    const sorted = _.orderBy(children, orderBy, order);
    setChildren(sorted);
  }

  const handleChildChange = (event) => {
    const name = event.target.id;

    const copyOfObject = { ...childToAdd };
    delete copyOfObject[name];

    const updatedValue = {};
    updatedValue[name] = event.target.value;

    let newData = {
      ...copyOfObject,
      ...updatedValue,
    };

    if (name === "dateOfBirth") {
      var birth_date = new Date(event.target.value);
      birth_date.setFullYear(birth_date.getFullYear() + 18);
      birth_date <= new Date() ? setOver18(true) : setOver18(false);
    }

    setChildToAdd(newData);
  };

  const handleChildCheck = (event) => {
    const name = event.target.name;

    const copyOfObject = { ...childToAdd };
    copyOfObject[name] = !copyOfObject[name];
    let newData = {
      ...copyOfObject,
    };
    setChildToAdd(newData);
  };

  const handleChange = (event) => {
    const name = event.target.id;

    const newData = { ...data };

    newData.caregivers.find((caregiver) => caregiver.isPrimary)[name] =
      event.target.value;

    setData(newData);
  };

  const handleCheck = (event) => {
    const name = event.target.name;

    const newData = { ...data };

    newData.caregivers.find((caregiver) => caregiver.isPrimary)[name] =
      !newData.caregivers.find((caregiver) => caregiver.isPrimary)[name];

    if (
      name === "pregnancy" &&
      !newData.caregivers.find((caregiver) => caregiver.isPrimary)[name]
    ) {
      newData.caregivers.find((caregiver) => caregiver.isPrimary).dueDate =
        null;
    }
    setData(newData);
  };

  const handleChildRelationship = (event, caregiver) => {
    var ccRelationship = {
      caregiverId: caregiver.id,
      relationship: event.target.value,
    };

    const childCopy = { ...childToAdd };
    if (
      childCopy.childCaregiverRelationships.find(
        (relationship) => relationship.caregiverId === caregiver.id
      )
    ) {
      childCopy.childCaregiverRelationships.find(
        (relationship) => relationship.caregiverId === caregiver.id
      ).relationship = event.target.value;
    } else {
      childCopy["childCaregiverRelationships"].push(ccRelationship);
    }

    setChildToAdd(childCopy);
  };

  const handleClose = () => {
    setErrors([]);
    setOver18(false);
    setChildToAdd({ childCaregiverRelationships: [] });
    setIsEdit(false);
    setShow(false);
  };
  const handleShow = (id) => {
    //fetchDataById(id);
    setErrors([]);
    setOver18(false);
    setShow(true);
  };

  async function handleSave() {
    let hasErrorLocal = false;
    setErrors([]);
    let copy = [];

    if (!childToAdd.firstName) {
      copy.push("firstName");
      hasErrorLocal = true;
    }

    if (!childToAdd.lastName) {
      copy.push("lastName");
      hasErrorLocal = true;
    }

    if (!childToAdd.sex && childToAdd.sex !== 0) {
      copy.push("sex");
      hasErrorLocal = true;
    }

    if (!childToAdd.dateOfBirth) {
      copy.push("dateOfBirth");
      hasErrorLocal = true;
    }

    if (!childToAdd.educationStatus) {
      copy.push("educationStatus");
      hasErrorLocal = true;
    }

    if (
      childToAdd.childCaregiverRelationships.length < data.caregivers.length
    ) {
      copy.push("childCaregiverRelationships");
      hasErrorLocal = true;
    }

    for (var i = 0; i < children.length; i++) {
      if (
        childToAdd.firstName === children[i].firstName &&
        childToAdd.lastName === children[i].lastName &&
        childToAdd.dateOfBirth === children[i].dateOfBirth.split("T")[0]
      ) {
        copy.push("duplicateChild");
        hasErrorLocal = true;
      }
    }

    setErrors(copy);

    if (!hasErrorLocal) {
      isEdit
        ? await updateChild(childToAdd.id, childToAdd)
        : await insertChild(caseId, childToAdd);
      getData();
      fetchData();
      handleClose();
    }
  }

  const handleDelete = async (id) => {
    await deleteChild(id);
    getData();
    fetchData();
  };

  const handleEdit = (id) => {
    setChildToAdd(data.children.find((x) => x.id === id));
    setIsEdit(true);
    handleShow();
  };

  const handleSubmit = (event) => {
    let hasErrorLocal = false;
    setHasError(false);
    let pc = data.caregivers.find((caregiver) => caregiver.isPrimary);
    if (
      !pc.adoptiveRecruit &&
      !pc.pregnancy &&
      !pc.isYouth &&
      (!data.children || data.children.length < 1)
    ) {
      setHasError(true);
      hasErrorLocal = true;
    }

    if (pc.pregnancy && !pc.dueDate) {
      setHasDueDateError(true);
      hasErrorLocal = true;
    }

    if (hasErrorLocal === true) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleNext();
    }
  };

  const handleSort = async (column) => {
    const isAsc = orderBy === column && order === "asc";

    const sorted = _.orderBy(children, column, isAsc ? "desc" : "asc");
    setChildren(sorted);

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };
  

  useEffect(() => {
    fetchData();
  }, [data]);

  const edStatuses = [
    { id: 1, value: "Infant/Toddler (age 0-2)" },
    { id: 2, value: "Preschooler (age 3-5)" },
    { id: 3, value: "Elementary Grades K-8" },
    { id: 4, value: "High School (Child)" },
    { id: 5, value: "Other Educational Environment" },
    { id: 6, value: "Not in School" },
  ];

  const relationships = [
    { id: 1, value: "Adoptive Parent" },
    { id: 2, value: "Biological Parent" },
    { id: 3, value: "Foster Parent" },
    { id: 4, value: "Grandparent" },
    { id: 5, value: "Group Home or Residential Facility" },
    { id: 6, value: "Other" },
    { id: 7, value: "Other relative" },
    { id: 8, value: "Sibling" },
    { id: 9, value: "Teen Parent" },
  ];

  return (
    <>
      <div className="py-4">
        <h5>
          Primary Caregiver: {pc.firstName} {pc.lastName}
        </h5>
        <h6>Case Id: {caseId}</h6>
      </div>

      <h6>Intake Section C: Information on Children in Household/Placement</h6>
      <p>
        Identify all children residing in the household.
        <br />
        If children are in foster care, only list siblings residing together in
        this placement.
        <br />
        Do not list teen parent as a dependent if they were identified as the
        primary or secondary caregiver.
        <br />
      </p>

      <Form>
        <Form.Group className="row px-4" controlId="adoptiveRecruit">
          <Form.Check
            type={"checkbox"}
            id="adoptiveRecruit"
            checked={
              (data.caregivers &&
                data.caregivers.find((caregiver) => caregiver.isPrimary)
                  .adoptiveRecruit) ||
              false
            }
            name="adoptiveRecruit"
            label="Is the PC an adoptive parent that does not yet have children?"
            onChange={handleCheck}
          />
        </Form.Group>
        <Form.Group className="row px-4" controlId="pregnancy">
          <Form.Check
            type={"checkbox"}
            id="pregnancy"
            checked={
              (data.caregivers &&
                data.caregivers.find((caregiver) => caregiver.isPrimary)
                  .pregnancy) ||
              false
            }
            name="pregnancy"
            label="Is the PC is pregnant and does not have children but eligible for services due to pregnancy?"
            onChange={handleCheck}
          />
        </Form.Group>
        {data.caregivers.find((caregiver) => caregiver.isPrimary).pregnancy && (
          <FormControl required error={hasDueDateError}>
            {hasDueDateError && (
              <FormHelperText>Please enter due date.</FormHelperText>
            )}
            <Form.Group className="mb-2 px-4" controlId="dueDate">
              <Form.Label className="fw-bold">Due Date</Form.Label>
              <Form.Control
                required
                type="date"
                className="form-control-sm"
                defaultValue={data?.primaryCaregiver?.dueDate ? data.primaryCaregiver.dueDate.toString().split("T")[0] : ""}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
          </FormControl>
        )}
        <Form.Group className="row px-4" controlId="isYouth">
          <Form.Check
            type={"checkbox"}
            id="isYouth"
            checked={
              (data.caregivers &&
                data.caregivers.find((caregiver) => caregiver.isPrimary)
                  .isYouth) ||
              false
            }
            name="isYouth"
            label="Is the PC a youth/young adult who does not have dependent children and is eligible for PSSF services due to being either a.)  homeless youth/independent youth, b.) youth eligible for foster care services (preparing for emancipation or recently emancipated but eligible for extended foster care services."
            onChange={handleCheck}
          />
        </Form.Group>
      </Form>

      {data.children && data.children.length > 0 && (
        <TableContainer className="py-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Sex</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "dateOfBirth"}
                    direction={order}
                    onClick={() => handleSort("dateOfBirth")}
                  >
                    DOB
                  </TableSortLabel>
                </TableCell>
                <TableCell>Education Status</TableCell>
                <TableCell>Developmental Disability</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {children &&
                children.length > 0 &&
                children.map((child) => (
                  <TableRow key={child.id}>
                    <TableCell>
                      <Button
                        variant="primary"
                        onClick={() => handleEdit(child.id)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      {child.firstName}&nbsp;{child.lastName}
                    </TableCell>
                    <TableCell>{child.sex === 0 ? "Female" : "Male"}</TableCell>
                    <TableCell>
                      {Moment(child.dateOfBirth).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>{child.educationStatus}</TableCell>
                    <TableCell>
                      {child.developmentalDisability ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(child.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div>{<Button onClick={handleShow}>Add Child</Button>}</div>
      <FormControl required error={hasError}>
        {hasError && (
          <FormHelperText>Please add at least one child.</FormHelperText>
        )}
      </FormControl>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Child</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChildForm 
            child={childToAdd} 
            handleChildChange={handleChildChange} 
            errors={errors} 
            edStatuses={edStatuses} 
            handleChildRelationship={handleChildRelationship} 
            relationships={relationships}
            over18={over18}
            handleChildCheck={handleChildCheck}
            data={data}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>

          {errors.find((e) => e === "duplicateChild") && (
            <FormHelperText className="text-danger">
              There already exists a child with this name and date of birth.
            </FormHelperText>
          )}
        </Modal.Footer>
      </Modal>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleSubmit}>{"Save & Continue"}</Button>
      </Box>
    </>
  );
};

export default CaseFormC;