import React, { useEffect, useState } from "react";
import ArchivedCasesTable from "../Tables/archivedCasesTables";
import { getProgramById } from "../../services/getProgramService";
import { getAgencyById } from "../../services/getAgencyService";
import { getCasesByProgramId } from "../../services/casesService";
import { Paper } from "@material-ui/core";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const ArchivedCases = () => {
  let ProgramId = sessionStorage.getItem("ProgramId");

  const [program, setProgram] = useState({});
  const [agency, setAgency] = useState({});
  const [cases, setCases] = useState([]);
  const [count, setCount] = useState(0)
  const pfyId = sessionStorage.getItem("ProgramByFiscalYearId");

  const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));




  useEffect(() => {

    const fetchData = async () => {

    const {data: program} = await getProgramById(ProgramId);

    setProgram(program)
    const {data: agency} = await getAgencyById(program.agencyId);

    setAgency(agency)

    const { data: rows } = await getCasesByProgramId(ProgramId);

    const filteredRows = rows.filter(
      (row) =>  row.status === "Archived"
    );


    setCases(filteredRows);
    setCount(filteredRows.length);
    }

    fetchData();

  }, [pfyId, count, ProgramId]);


  return (
    <>
    <Grid container spacing={1}>
      <Grid item xs={12} className="mt-3">
        <Item>

          <h1 className="mb-5 text-center">Archived Cases</h1>

          <div className="row">
            <div className="row">
              <div className="col-6 fw-bold">Agency:</div>
              <div className="col-6 text-start"> {agency.name}</div>
            </div>
            <div className="row">
              <div className="col-6 fw-bold">Program: </div>
              <div className="col-6 text-start"> {program.name}</div>
            </div>
            <div className="row">
            <div className="col-6 fw-bold">Program ID:</div>
            <div className="col-6 text-start">{ProgramId}</div>
          </div>
          <div className="row">
            <div className="col-6 fw-bold">Number of archived Cases</div>
            <div className="col-6 text-start">{count}</div>
          </div>
        </div>

        </Item>
      </Grid>
    </Grid>

      <div className="mt-4">
        <ArchivedCasesTable cases={cases} />
      </div>
    </>
  );
};

export default ArchivedCases;
