import React from "react";
import { useParams } from "react-router-dom";
import AgencyEditForm from "../forms/agencyeditform";

const Agency = () => {
  const { id, fy } = useParams();
  
  return (
    <>
      <h1>Edit Agency {id}</h1>
      {fy && <AgencyEditForm id={id} fy={fy} />}
    </>
  );
};

export default Agency;

