import React from "react";
import { Link } from "react-router-dom";

const Rcs = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Preservation</h3>
          <h5 className="text-center">Relative Caregivers</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            Whenever possible, relatives are the preferred resource for children
            who must be removed from their birth parents because placement with
            relatives increases stability and safety and helps to maintain
            family connections and cultural traditions. Relative
            Caregiver/Kinship Family services offer a comprehensive array of
            support services to grandparents and relative caregivers who are the
            primary caregivers of children other than their own. Services are
            designed to:
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Initial Family Assessment &amp; Service Plan</li>
              <li>Case Management</li>
              <li>
                Caregiver Healthcare Screening, Monitoring/Support and/or
                Education
              </li>
              <li>
                Parent Education/Parent Training and/or Behavior Management
              </li>
              <li>Educational Supports</li>
            </ol>
            Based on the reason for referral, needs identified in the assessment
            and case plan goals, service plans may also include additional
            supports and services needed to effectively address the
            circumstances that resulted in the disruption of the family and to
            support relative caregivers in their efforts to prevent children
            from entering foster care. These may include:
            <ul>
              <li>Peer Support Groups</li>
              <li>Therapy</li>
              <li>After-school supervision or enrichment activities</li>
              <li>Legal Services</li>
              <li>Parent/Child Activities</li>
              <li>Respite</li>
              <li>Emergency Childcare</li>
              <li>Transportation</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Rcs;
