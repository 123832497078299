import React from "react";
import { Link } from "react-router-dom";

const Cfa = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Reunification</h3>
          <h5 className="text-center">Child and Family Advocacy</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">Child and Family Advocacy</span> services
            help to ensure that children involved in dependency proceedings are
            appointed representation, a Court-Appointed Special Advocate (CASA)
            or Guardian ad Litem (GAL), to advocate for timely permanency
            decisions that are in the best interest of the child. Child and
            Family Advocacy also provides support to children and their families
            to promote and sustain reunification or other permanency options
            such as adoption or legal guardianship. Child and Family Advocacy
            providers work in collaboration with DFCS and the Courts first and
            foremost to ensure that children are safe and that families receive
            the timely and responsive services they need, minimize the trauma of
            out-of-home placement and prevent placement disruptions. These
            services help to ensure that the needs of children are met and that
            families receive needed supports so that children who must be
            removed from their home maintain connections to their families and
            communities, achieve permanency as quickly as possible, and do not
            experience a subsequent removal after reunification.
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Assessment & Court Report (Recommendations)</li>
              <li>Case Management</li>
              <li>Ongoing In-Court Supports</li>
              <li>Child Contacts</li>
              <li>Follow-Up Collateral Contacts</li>
            </ol>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Cfa;
