//@ts-check
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navigate:0 
};


export const navigateSlice = createSlice({
  name: "navigateReload",
  initialState,
  reducers: {
    setNavigate: (state) => {
      state.navigate ++;
    }
}});
export const { setNavigate} = navigateSlice.actions;
export default navigateSlice.reducer;

/* export const setRefreshPage = () => {
  const setNavigateThunk = async (dispatch, getState) => {
    const data = getState();
  };
  return setNavigateThunk;
};
 */