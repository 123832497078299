import http from "./httpService";

const apiEndpoint = "/Son";

const apiEndpointAgency = "/Son/postAgency";

const apiEndpointAdditionalCounties = "/Son/postAdditionalCounties";

const apiProposalFilesEndpoint = "/Son/proposalFiles";
const apiApprovedFileEndpoint = "/Son/approveProposalFile";
const apiSaveContractEndpoint = "/Son/saveContract";
const apiPendingProposalFilesEndpoint = "/Son/pendingProposalFiles";
const apiCommentEndpoint = "/Son/Comments";

let LocalFiscalYear = sessionStorage.getItem("LocalFiscalYear");

if (isNaN(LocalFiscalYear) || LocalFiscalYear < 1) {
  var month_start = 11;     //  11 = November
  var month = new Date().getMonth(),
      year = new Date().getFullYear(),
      yearOffset = Math.floor((month - ( month_start % 12 || 12)) / 12)+ 1;

      //var systemYear = yearOffset + year  + 1;
  var systemYear = yearOffset + year;     //ITEDEV-431

  //LocalFiscalYear = new Date().getFullYear();   // IDEV-425
  sessionStorage.setItem("LocalFiscalYear", parseInt(systemYear));
}

export function getSonContacts(FY) {
  return http.get(apiEndpoint + "/programsSonsByFiscalYear/" + FY);
}


export function getProgramsSonsByFiscalYear(FY) {
  return http.get(apiEndpoint + "/programsSonsByFiscalYear/" + FY);
}

export function getTpsProviderDocuments(id) {
  return http.get(apiEndpoint + "/tpsDocumentStore/" + id);
}

export function getDocumentGroupStatus(fiscalYear) {
  return http.get(`${apiEndpoint}/documentGroupsStatus/${fiscalYear}`);
}

export function getContractMgmtByFyAndId(fiscalYear, id) {
  return http.get(
    `${apiEndpoint}/contractMgmtByFyAndId/${fiscalYear}/${id}`
  );
}

function sonUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function sonAgencyUrl(id) {
  return `${apiEndpointAgency}/${id}`;
}

function sonAdditionalCountiesUrl(id) {
  return `${apiEndpointAdditionalCounties}/${id}`;
}

function getProgramProposalFilesUrl(id, fy, tpsProviderId) {
  return `${apiProposalFilesEndpoint}/${fy}/${id}/${tpsProviderId}`;
}

function getProgramPendingProposalFilesUrl(id, fy) {
  return `${apiPendingProposalFilesEndpoint}/${fy}/${id}`;
}

function saveProvisionalFileUrl(id, fy) {
  return `${apiProposalFilesEndpoint}/${id}/${fy}`;
}

function saveContractUrl(id, fy, type, sonId) {
  return `${apiSaveContractEndpoint}/${id}/${fy}/${type}/${sonId}`;
}

function saveApprovedFileUrl(id, fy, fileName) {
  return `${apiApprovedFileEndpoint}/${id}/${fy}/${fileName}`;
}

export function getProgramProposalFiles(id, LocalFiscalYear, tpsProviderId) {
  return http.get(
    getProgramProposalFilesUrl(id, LocalFiscalYear, tpsProviderId)
  );
}

export function getProgramPendingProposalFiles(id, fy) {
  return http.get(getProgramPendingProposalFilesUrl(id, fy));
}

export function getCommentsByProgram(id) {
  return http.get(`${apiCommentEndpoint}/${id}`);
}

export function saveComment(Comment) {
  if (Comment.ProgramId) {
    const body = { ...Comment };
    return http.post(`${apiCommentEndpoint}`, body);
  }
}

//do submit
export function saveSonProgram(Program) {
  if (Program.id) {
    let body = { ...Program };
    delete body.id;
    return http.post(sonUrl(Program.id), body);
  }
}

//do submit
export function saveSonAgency(Agency) {
  if (Agency.id) {
    const body = { ...Agency };
    delete body.id;
    return http.post(sonAgencyUrl(Agency.id), body);
  }
}

export function saveAdditionalCounties(id, counties) {
  if (counties) {
    const body = counties;
    return http.post(sonAdditionalCountiesUrl(id), body);
  }
}

export function saveContractMgmtData(LocalFiscalYear, id, data) {
  let body = { ...data };
  return http.post(
    `${apiEndpoint}/contractMgmtByFyAndId/${LocalFiscalYear}/${id}`,
    body
  );
}

export function saveProvisionalFile(id, fy, formData) {
  if (formData) {
    const body = formData;
    http.post(saveProvisionalFileUrl(id, fy), body);
    //Axios.post(saveProvisionalFileUrl(id), body);
  }
}

export function saveApprovedFile(id, fy, fileName) {
  if (fileName) {
    http.post(saveApprovedFileUrl(id, fy, fileName));
    //Axios.post(saveProvisionalFileUrl(id), body);
  }
}

export function postTpsProviderDocument(id, doc) {
  if (doc) {
    return http.post(`${apiEndpoint}/tpsDocumentStore/${id}`, doc);
  }
}

export function saveContract(id, type, formData) {
  const fy = formData.get("LocalFiscalYear");
  const sonId = formData.get("sonId");

  if (formData) {
    const body = formData;
    http.post(saveContractUrl(id, fy, type, sonId), body);
  }
}


export function getDistinctFiscalYears() {
  return http.get(`${apiEndpoint}/GetDistinctFiscalYears`);
}