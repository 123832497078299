import React, { Component } from "react";
import { Link } from "react-router-dom";

const Shy = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Support</h3>
          <h5 className="text-center">Supports &amp; Services for Homeless Youth</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">
              Supports and Services for Homeless Youth
            </span>{" "}
            provide supportive services to assist unaccompanied homeless youth
            transition to independent living and become self-sufficient,
            including providing opportunities for youth to improve:
            <ul>
              <li>
                Community involvement, including youth and adult partnerships
              </li>
              <li>Education</li>
              <li>Employment</li>
              <li>Health and safety</li>
            </ul>
            Services ensure that youth are engaged in the process to understand
            their needs, identify their goals, and create a plan for achieving
            those goals. PSSF Supports and Services for Homeless Youth focus on
            developing skills necessary to support oneself, including training
            and employment services and obtaining permanent housing. Support can
            include anything from assistance with getting vital documents, such
            as birth certificates, to support in completing education, managing
            money, and finding employment. Services also focus on developing
            relationships and establishing connections between youth and caring
            adults and the youth and the community.
            <br />
            <br />
            Supports and Services for Homeless Youth objectives include:
            <ul>
              <li>Reducing homelessness</li>
              <li>
                Establishing permanent connections between youth and a caring
                adult
              </li>
              <li>Preventing exploitation and abuse of youth on the streets</li>
              <li>
                Increasing educational and employment opportunities for homeless
                youth
              </li>
            </ul>
            Target population may include:
            <ul>
              <li>Homeless youth or young adults</li>
              <li>Victims of commercial sexual exploitation</li>
            </ul>
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Initial Youth Assessment & Service Plan</li>
              <li>Case Management</li>
              <li>Adult Mentoring</li>
              <li>Life Skills</li>
              <li>Therapy</li>
            </ol>
            Based on needs identified by the initial assessment, additional
            supports and services may be necessary to address challenges, gaps
            or barriers. Proposal will need to demonstrate how additional
            service(s) proposed will increase the effectiveness of core services
            and improve outcomes. These may include transportation, emergency
            aid, and the following:
            <br />
            <br />
            Educational Support
            <ul>
              <li>GED program</li>
              <li>Tutoring</li>
              <li>Educational records</li>
            </ul>
            Employment Support
            <ul>
              <li>Job skills or vocational training</li>
              <li>
                Job search, resume preparation, interview skills, applications,
                interview follow-up
              </li>
              <li>Educational records</li>
            </ul>
            Financial Management
            <ul>
              <li>Personal documents, identification, etc.</li>
              <li>
                Financial resources management, banking, budgeting,
                establishing/repairing credit
              </li>
            </ul>
            Health Management
            <ul>
              <li>Substance abuse treatment/recovery supports</li>
              <li>Medical care resources</li>
              <li>Medication management</li>
              <li>Nutrition</li>
              <li>Pregnancy prevention</li>
            </ul>
            Safety
            <ul>
              <li>Behavior management</li>
              <li>Healthy relationships</li>
              <li>Personal safety</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Shy;
