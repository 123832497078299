import React from "react";
import { Link } from "react-router-dom";

const Pps = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Preservation</h3>
          <h5 className="text-center">Placement Prevention</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">Placement Prevention</span> services
            are short-term home- and/or center-based services to children and
            families with DFCS involvement where children are still in parental
            custody to provide additional supports and services to support case
            plan objectives or follow-up supports at case closure to sustain and
            maintain family stability. These services are provided as a part of
            a family's safety and/or CPS case plan designed to safely maintain
            children in their homes and/or prevent unnecessary placement into
            foster care.
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Initial Assessment &amp; Service Plan</li>
              <li>Case Management</li>
              <li>Parent Education or Parent Training</li>
              <li>Behavior Management</li>
              <li>Life Skills</li>
            </ol>
            Recommended evidence-based parent education/parent training
            curricula include:
            <ul>
              <li>Incredible Years</li>
              <li>Nurturing Parent Program</li>
              <li>Triple P: Positive Parenting Program</li>
            </ul>
            Recommended evidence-based home visiting programs include:
            <ul>
              <li>Safe Care Augmented</li>
              <li>Parent Aide</li>
            </ul>
            Based on the reason for referral, needs identified in the assessment
            and case plan goals, additional supports and services may be
            necessary to effectively address the circumstances that resulted in
            the family's involvement with the child welfare agency to ensure
            that the child remains safely in the home. These may include:
            <ul>
              <li>Therapy</li>
              <li>Substance Abuse Recovery Support</li>
              <li>Educational Supports</li>
              <li>Transportation</li>
              <li>Employment Training, Job Skills or Vocational Training</li>
              <li>Healthy Relationship/Co-Parenting</li>
              <li>Emergency Childcare</li>
              <li>Support Groups</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Pps;
