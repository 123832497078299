import http from "./httpService";

const apiEndpoint = "/Account/ChangePassword";

const apiRegisterEndpoint = "/Account/register";

const apiUpdateUserEndpoint = "/Account/update";

const apiDeleteEndpoint = "/Account";

const apiConfirmEmailEndpoint = "/Email/ConfirmEmail";

const apiForgotPasswordEndpoint = "/Account/forgotPassword";

const apiChangeForgotPasswordEndpoint = "/Account/changeForgotPassword";

function passwordUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function confirmEmailUrl() {
  return `${apiConfirmEmailEndpoint}`;
}


function deleteAccountUrl(id) {
  return `${apiDeleteEndpoint}/${id}`;
}

function registerUrl() {
  return `${apiRegisterEndpoint}`;
}

function updateUserUrl() {
  return `${apiUpdateUserEndpoint}`;
}

function forgotPasswordUrl(){
  return `${apiForgotPasswordEndpoint}`;
}

function changeForgotPasswordUrl(){
  return `${apiChangeForgotPasswordEndpoint}`;
}

export function UpdatePassword(password) {
  if (password.id) {
    const body = { ...password};
    delete body.id;
    return http.post(passwordUrl(password.id), body);
  }
}

export function UpdateUser(user) {
  const body = { ...user};
  return http.post(updateUserUrl(), body);
}

export function CreateUser(user) {
    const body = { ...user };
    return http.post(registerUrl(), body);
}

export function DeleteUser(id) {
  return http.delete(deleteAccountUrl(id));
}

export function ConfirmEmailAddress(email) {
  const body = { ...email };
  return http.post(confirmEmailUrl(),body);
}

export function ForgotPassword(email) {
  const body = { ...email };
  return http.post(forgotPasswordUrl(),body);
}

export function ChangeForgotPassword(data) {
  const body = { ...data};
  return http.post(changeForgotPasswordUrl(), body);
}