import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomDataTable from "../../components/common/customDataTable";
import { getProgramsNamesFiscalYear } from "../../services/getProgramService";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

export default function ProgramsWithFiscalYearTable({ fiscalYear }) {
  const [rows, setRows] = useState([]);
  const refresh = useSelector((state) => state.refresh.navigate);

  // Define the columns configuration for CustomDataTable
  const columns = [
    {
      field: "id",
      headerName: "Program ID",
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <Link
          className="text-success"
          to={`/admin/sonedit/${fiscalYear}/${params.row.id}`}
        >
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "agencyName",
      headerName: "Agency Name",
      sortable: true,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Program Name",
      sortable: true,
      flex: 1,
    },
    {
      field: "programStatus",
      headerName: "Program Status",
      sortable: true,
      flex: 1,
      renderCell: (params) =>
        params.row.programStatus ? "Active" : "Inactive",
    },
    {
      field: "actions",
      headerName: "View Program",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          className="btn btn-primary"
          onClick={() => handleBtnSwitchProgram(params.row.id)}
        >
          View Program
        </Button>
      ),
    },
  ];

  // Fetch data from the API
  async function fetchData() {
    const response = await getProgramsNamesFiscalYear(fiscalYear);
    setRows(response.data);
  }

  useEffect(() => {
    fetchData();
  }, [refresh]);


  // Function to handle button click for switching programs
  const handleBtnSwitchProgram = (value) => {
    sessionStorage.setItem("ProgramId", value);
    sessionStorage.setItem("showNavSubMenu", true);
    window.location.reload(false);
  };

  return (
    <Box sx={{ width: "99%" }}>
      <CustomDataTable
        rows={rows}
        columns={columns}
        paginationModel={{ pageSize: 50, page: 0 }}
        rowHeight={60}
      />
    </Box>
  );
}
