import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import NumberFormat from "react-number-format";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CustomDataTable from "../common/customDataTable";


const SonTable = ({ sonUsers, fy }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const columns = [
    {
      field: "sonId",
      headerName: "Son Id",
      flex: .8,
      renderCell: (params) => (
        <span className="fst-italic">
          {params.row.programByFiscalYear[0].programStatus ? (
            <span className="fst-italic">
              <Link
                to={`/admin/soneditprograms/${params.row.id}/${fy}`}
                state={{ sonUsers: params.row }}
              >
                {params.value}
              </Link>
            </span>
          ) : (
            <>
              <span className="fst-italic" onClick={handleButtonClick}>
                <Link to={`#`}>{params.value}</Link>
              </span>
              <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    flex: 1,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    p: 2,
                  }}
                >
                  <h2 id="simple-modal-title">Program Locked</h2>
                  <p id="simple-modal-description">
                    Sorry, this program is not currently available for viewing.
                    This program is currently locked.
                  </p>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                </Box>
              </Modal>
            </>
          )}
        </span>
      ),
    },
    {
      field: "id",
      headerName: "Program Id",
      flex: .6,
      renderCell: (params) => (
        <Link to={`/admin/sonedit/${fy}/${params.row.id}`}>{params.value}</Link>
      ),
    },
    { field: "agencyName", headerName: "Agency", flex: 1.5 },
    { field: "name", headerName: "Program", flex: 1.5 },
    {
      field: "serviceModelCategory",
      headerName: "Model Category",
      flex: .7,
      renderCell: (params) => params.row.serviceModel?.serviceModelCategoryAbrv,
    },
     {
      field: "modelAbrv",
      headerName: "Payment Model",
      flex: .7,
      renderCell: (params) => params.row.serviceModel?.modelAbrv,
    },
     {
      field: "totalServices",
      headerName: "Total Services",
      flex: 1,
      renderCell: (params) => (
        (
          <NumberFormat
            value={
              params.row.programByFiscalYear[0].federalFundingRequested +
              params.row.programByFiscalYear[0].localMatchPlanned
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale
          />
        )
      ),
    },
    {
      field: "federalFundingRequested",
      headerName: "Federal",
      flex: 1,
      renderCell: (params) => (
        <NumberFormat
          value={params.row.programByFiscalYear[0].federalFundingRequested}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale
        />
      ),
    },
    {
      field: "localMatchPlanned",
      headerName: "Cash Match",
      flex: 1,
      renderCell: (params) => (
        <NumberFormat
          value={params.row.programByFiscalYear[0].localMatchPlanned}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale
        />
      ),
    },
    {
      field: "contractCompliant",
      headerName: "Compliance Status",
      flex: 1,
      renderCell: (params) => (
        <span className="fst-italic">
          {params.row.programByFiscalYear[0].contractCompliant === null ? (
            <div>N/A</div>
          ) : (
            <div>{params.row.programByFiscalYear[0].contractCompliant}</div>
          )}
        </span>
      ),
    },
    {
      field: "programStatus",
      headerName: "Program Status",
      flex: 1,
      renderCell: (params) => (
        <span className="fst-italic">
          {params.row.programByFiscalYear[0].programStatus ? (
            <div>Active</div>
          ) : (
            <div>Inactive</div>
          )}
        </span>
      ),
    },
  ];

  return (
    <Box sx={{ width: "99%" }}>
      <CustomDataTable
        columns={columns}
        rows={sonUsers}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textAlign: "left",
          },
        }}
      />
    </Box>
  );
};

export default SonTable;
