import React, { useEffect, useState } from "react";
import { getPrograms } from "../../services/userPrograms";
import { getAllUsers } from "../../services/userService";
import { Box, Typography } from "@mui/material";
import { FaUser, FaPlusCircle } from "react-icons/fa";
import CustomDataTable from "../common/customDataTable";
import CreateUserModal from "./createUserModal";
import EditUserModal from "./editUserModal";
import ConfirmDeleteModal from "./confirmDeleteModal";
import { getUserById } from "../../services/providerUserManagement";
import Button from "react-bootstrap/Button";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [data, setData] = useState({});
  const [toDelete, setToDelete] = useState(0);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("America/New_York");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data: users } = await getAllUsers();
    setUsers(users);
    const { data: programs } = await getPrograms();
    setProviders(programs);
  };

  const fetchDataById = async (id) => {
    const { data } = await getUserById(id);
    setData(data);
  };

  const handleShowUserModal = () => {
    setShowUserModal(true);
  };

  const handleShowEditModal = (id) => {
    fetchDataById(id);
    setShowEditModal(true);
  };

  const handleShowConfirmDeleteModal = (id) => {
    setToDelete(id);
    setShowConfirmDeleteModal(true);
  };

  const impersonate = async (id, userName) => {
    sessionStorage.setItem("UserId", id);
    sessionStorage.setItem("Username", userName);
    window.location.reload(false);
  };

  const columns = [
    {
      field: "icon",
      headerName: "",
      minWidth: 90,
      renderCell: (params) => (
        <div
          className="pointer"
          onClick={() => handleShowEditModal(params.row.id)}
        >
          <FaUser aria-label="edit this user" size={25} />
          <span style={{ display: "block", marginTop: "5px" }}>Edit</span>
        </div>
      ),
      sortable: false,
      filterable: true,
      align: "center",
      flex: 0.5,
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      filterable: true,
      minWidth: 10,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "userName",
      headerName: "Username/Email",
      align: "left",
      flex: 1,
      minWidth: 225,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      align: "left",
      flex: 0.8,
      minWidth: 150,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 60,
      align: "center",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 80,
      type: "singleSelect",
      valueOptions: ["Son", "Provider", "PowerUser", "Admin"],
      editable: false,
    },
  {
    field: "created",
    headerName: "Date Added",
    align: "center",
    flex: 1.5,
    type: "datetime",
    valueFormatter: ( value ) => {
      return value
        ? dayjs
            .tz(value, "UTC")
            .tz("America/New_York")
            .format("YYYY-MM-DD hh:mm:ss A")
        : "-";
    },
  },
  {
    field: "lastLogin",
    headerName: "Last Login Date",
    align: "center",
    flex: 1.5,
    type: "datetime",
    valueFormatter: ( value) => {
      return value
        ? dayjs
            .tz(value, "UTC")
            .tz("America/New_York")
            .format("YYYY-MM-DD hh:mm:ss A")
        : "No Record";
    },
  },
    {
      field: "viewProviderActions",
      headerName: "View As",
      cellClassName: "text-center",
      align: "right",
      flex: 1,
      minWidth: 60,
      renderCell: (params) => (
        <>
          {params.row.role === "Provider" && (
            <Button
              className="btn btn-success btn-sm pointer text-center"
              variant="success"
              value={params.row.id}
              onClick={() => {
                impersonate(params.row.id, params.row.userName);
              }}
            >
              View Provider
            </Button>
          )}
        </>
      ),
    },
    {
      field: "deleteActions",
      headerName: "Actions",
      flex: 1,
      minWidth: 10,
      renderCell: (params) => (
        <Button
          className="btn btn-danger btn-sm pointer"
          variant="danger"
          value={params.row.id}
          onClick={() => handleShowConfirmDeleteModal(params.row.id)}
        >
          Delete User
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: "center", mt: 3, mb: 3 }}
        >
          User Management
        </Typography>
        <button
          className="btn btn-link text-success"
          onClick={handleShowUserModal}
        >
          <FaPlusCircle className="me-2" />
          Add User
        </button>
        <div className="mb-4">
          <CreateUserModal
            show={showUserModal}
            onHide={() => {
              setShowUserModal(false);
            }}
            fetchData={fetchData}
            providers={providers}
          />
          <EditUserModal
            show={showEditModal}
            onHide={() => {
              setShowEditModal(false);
              setData({});
            }}
            fetchData={fetchData}
            providers={providers}
            data={data}
            setData={setData}
          />
          <ConfirmDeleteModal
            show={showConfirmDeleteModal}
            onHide={() => setShowConfirmDeleteModal(false)}
            toDelete={toDelete}
            fetchData={fetchData}
          />
        </div>
        <div style={{ width: "99%" }}>
          <CustomDataTable
            columns={columns}
            rows={users}
            getRowId={(users) => users.id}
            pageSizeOptions={[25, 50, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowSpacing={(params) => ({
              top: params.isFirstVisible ? 0 : 5,
              bottom: params.isLastVisible ? 0 : 5,
            })}
          />
        </div>
      </Box>
    </div>
  );
};

export default UserManagement;
