import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getAgencyListAllWIthFiscalYear } from "../../services/getAgencyService";
import AddProgramForm from "./addProgramForm";


let LocalFiscalYear = sessionStorage.getItem("LocalFiscalYear")

function AddProgram() {
  const currentState = useSelector((state) => state.fiscalYear);
  const [fiscalYear, setFiscalYear] = useState(LocalFiscalYear);

  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState('');

  const handleChange = (event) => {
    setAgency(event.target.value);
  };

  async function fetchData() {
    const { data: agencies } = await getAgencyListAllWIthFiscalYear(fiscalYear);
    setAgencies(agencies);
    if (fiscalYear === "") setFiscalYear(LocalFiscalYear);
  }

  useEffect(() => {
    // Update the document title using the browser API]
    fetchData();
  }, [fiscalYear, currentState, agency]);

  const listItems = agencies.map((agency) => (
    <MenuItem value={agency.id} key={agency.id}>
      {agency.name} ({agency.id})
    </MenuItem>
  ));

  return (
    <>
      <div className="row m-2">
        <div className="col-lg-6">
          <h1>Add Program</h1>
          <FormControl variant="outlined" sx={{ minWidth: 220 }} size="small">
            <InputLabel id="demo-simple-select-standard-label">
              Agencies
            </InputLabel>
            <Select
              labelId="agency"
              id="agency"
              value={agency}
              onChange={handleChange}
              label="Agency"
            >
              {listItems}
            </Select>
          </FormControl>
        </div>
      </div>
        {agency && (
          <AddProgramForm id={agency} fy={fiscalYear} />
        )}
    </>
  );
}

export default AddProgram;
