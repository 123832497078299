import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Paper, Box } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CaseFormA from "./caseFormA";
import CaseFormB from "./caseFormB";
import CaseFormC from "./caseFormC";
import { getCaseById, insertCaseSectionA, updateCaseById } from "../../../services/casesService";
import CaseFormD from "./caseFormD";
import CaseFormE from "./caseFormE";

const steps = ["Section A", "Section B", "Section C", "Section D", "Section E"];

const isAdmin = window.location.pathname.includes("admin");

const NewCase = ({ user }) => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [caseId, setCaseId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  //const [skipped, setSkipped] = useState(0); HB: I am not going to worry about skips at the moment. Will add later if necessary
  // const sonId = user.name;
  const programId = sessionStorage.getItem("ProgramId");

  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  /*
      HB: I don't think we are going to have any optional steps, but I will keep this here for now just in case
  */

  //  const isStepOptional = (step) => {
  //    return false;
  //    //  return step === 1;
  //  };

  /*
      HB: I am fairly certain that we don't need to handle skipping on this page. However, this was all included in the bootstrap react documentation
      for steppers, so I figured I'd include it
  */

  //  const isStepSkipped = (step) => {
  //    return skipped.has(step);
  //  };

  const getData = async () => {
    const {data} = await getCaseById(caseId);
    setData(data);
  }

  const saveData = async () => {
    switch (activeStep) {
      case 0:
        if(caseId)
        {
          updateCaseById(caseId, data);
        } else {
          var { data: tempCaseId } = await insertCaseSectionA(programId, data)
          setCaseId(tempCaseId);
          sessionStorage.setItem("CaseId", tempCaseId);
        }
        break;
      case 1:
        // Handle Section B
        // updateCaseById(caseId, data)
        break;
      case 2:
        // Handle Section C
        updateCaseById(caseId, data);
        break;
      case 3:
        // Handle Section D
        updateCaseById(caseId, data);
        break;
      case 4:
        // Handle Section E
        //const newData = { ...data };
        //newData["dateOfIntake"] = new Date();
        //setData(newData);
        updateCaseById(caseId, data, true);
        break;
      default:
      // code block
    }
  };

  const handleNext = () => {
    //let newSkipped = skipped;
    //if (isStepSkipped(activeStep)) {
    //  newSkipped = new Set(newSkipped.values());
    //  newSkipped.delete(activeStep);
    //}

    saveData();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);



    //setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  //const handleSkip = () => {
  //  if (!isStepOptional(activeStep)) {
  //
  //
  //    throw new Error("You can't skip a step that isn't optional.");
  //  }
  //
  //  setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //  setSkipped((prevSkipped) => {
  //    const newSkipped = new Set(prevSkipped.values());
  //    newSkipped.add(activeStep);
  //    return newSkipped;
  //  });
  //};

  const handleRadioChange = (fieldName, value) => {
    // Update the state in the parent component
    setData((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  const handleReset = () => {
    //sessionStorage.removeItem("CaseId");
    isAdmin
      ? (window.location = `/admin/editopencase/${caseId}`)
      : (window.location = "/provider/currentfy/newcase");
  };

  const getComponent = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <CaseFormA
            user={user ? user : null}
            data={data}
            setData={setData}
            getData={getData}
            caseId={caseId ? caseId : null}
            handleNext={handleNext}
            handleBack={handleBack}
            handleRadioChange={handleRadioChange}
          />
        );
      case 1:
        return (
          <CaseFormB
            user={user ? user : null}
            data={data}
            setData={setData}
            getData={getData}
            caseId={caseId ? caseId : null}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <CaseFormC
            user={user ? user : null}
            data={data}
            setData={setData}
            getData={getData}
            caseId={caseId ? caseId : null}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 3:
        return (
          <CaseFormD
            user={user ? user : null}
            data={data}
            setData={setData}
            getData={getData}
            caseId={caseId ? caseId : null}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 4:
        return (
          <CaseFormE
            user={user ? user : null}
            data={data}
            setData={setData}
            getData={getData}
            caseId={caseId ? caseId : null}
            handleNext={handleNext}
            handleBack={handleBack}
            handleRadioChange={handleRadioChange}
          />
        );
      default:
        return <h1>Other New Intake Steps</h1>;
    }
  };

  useEffect(() => {
    //var sessionCaseId = sessionStorage.getItem("CaseId");
    if (id != null) setCaseId(id);
    else if(sessionStorage.getItem("CaseId")) setCaseId(sessionStorage.getItem("CaseId"));

    if(caseId != null) getData();

  }, [caseId]);

  return (
    <Paper elevation={10} style={paperStyle}>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            //if (isStepOptional(index)) {
            //  labelProps.optional = (
            //    <Typography variant="caption">Optional</Typography>
            //  );
            //}
            // if (isStepSkipped(index)) {
            //   stepProps.completed = false;
            // }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Return</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getComponent(activeStep)}
          </React.Fragment>
        )}
      </Box>
    </Paper>
  );
};

export default NewCase;
