import http from "./httpService";

const apiEndpoint = "/EblastSave";

function apifetchEmailBlastByid(id) {
    return `${apiEndpoint}/getEmailBlastByid/${id}`;
}

function fetchSavedEmailBlast() {
    return `${apiEndpoint}/pullSavedEmailBlastList/`;
}

function saveEmailBlastToDb() {
    return `${apiEndpoint}/saveToEmailBlastSent/`;
}

export function fetchEmailBlastByid(data) {
    return http.get(apifetchEmailBlastByid(data));
}

export function apifetchEmailBlastSentList() {
    return http.get(fetchSavedEmailBlast());
}

export function apiSetSaveSentEmailToArchived(data) {
    return http.put(`${apiEndpoint}/archiveEmailBlastSent/${data}`);
};

export function saveEmailBlast(data) {
    const body = { ...data };
    return http.post(saveEmailBlastToDb(), body);
}
