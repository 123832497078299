import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import Button from "react-bootstrap/Button"; //using this because of a bug with @material-ui/core's button with the table
import moment from "moment";
import Grid from "@mui/material/Grid";

import {
  getProgramById,
  getProgramByFiscalYearById,
} from "../../../services/getProgramService";
import { getAgencyById } from "../../../services/getAgencyService";
import { getCasesByProgramId } from "../../../services/casesService";
import { getFamilyServiceLogByProgramByFiscalYear } from "../../../services/familyServiceLogService";
import { getReport } from "../../../services/reportService";

import auth from "../../../services/authService";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { AiOutlineDownload } from "react-icons/ai";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CustomDataTable from "../../common/customDataTable";

const OpenCases = () => {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [sortModel, setSortModel] = React.useState([
    {
      field: "sonId",
      sort: "asc",
    },
  ]);
  const [rows, setRows] = useState([]);
  const [program, setProgram] = useState({});
  const [agency, setAgency] = useState({});

  const [count, setCount] = useState(0);
  const [familyServiceLogs, setFamilyServiceLogs] = useState([]);
  const [lockReport, setLockReport] = useState(0);

  const ProgramId = sessionStorage.getItem("ProgramId");
  const reportingPeriod = sessionStorage.getItem("ReportingPeriod");
  const pfyId = sessionStorage.getItem("ProgramByFiscalYearId");
  const isAdmin = window.location.pathname.includes("admin");

  const endDateString = sessionStorage.getItem("endDate");
  const beginDateString = sessionStorage.getItem("beginDate");

  const beginDate = new Date(beginDateString);
  const endDate = new Date(endDateString);

  const displayBeginDate = sessionStorage.getItem("beginDateDisplay");
  const displayEndDate = sessionStorage.getItem("endDateDisplay");

  const dateCheck = moment().format("L");

  const periodId = sessionStorage.getItem("periodId");
  let isPeriod = "";

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  function dateChecking(beginDate, endDate, now) {
    return now <= endDate && now >= beginDate;
  }

  if (dateChecking(beginDate, endDate, dateCheck)) {
    isPeriod = true;
  } else {
    isPeriod = false;
  }

  const user = auth.getCurrentUser();

  const handleDownload = async (id) => {
    await getReport("NewPssf_PendingIntake", [
      {
        key: "Case_id",
        value: id,
      },
    ]);
  };

  const handleClick = (path) => {
    window.location = path;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: program } = await getProgramById(ProgramId);
        const { data: agency } = await getAgencyById(program.agencyId);

        setAgency(agency);
        const { data: rows } = await getCasesByProgramId(ProgramId);

        rows.forEach((row) => {
          // Find the primary caregiver and combine first and last names
          const primaryCaregiver = row.caregivers.find(
            (caregiver) => caregiver.isPrimary
          );
          if (primaryCaregiver) {
            row.primaryCaregiverFullName = `${primaryCaregiver.lastName}, ${primaryCaregiver.firstName}`;
          } else {
            row.primaryCaregiverFullName = "";
          }

          // Determine the intake month
          row.intakeMonth = moment(row.dateCreated).format("MMMM, YYYY");

          // Find the oldest child and combine first and last names
          if (row.children.length > 0) {
            let oldestChild = row.children[0];

            for (const child of row.children) {
              if (
                new Date(child.dateOfBirth) < new Date(oldestChild.dateOfBirth)
              ) {
                oldestChild = child;
              }
            }

            row.oldestChildFullName = `${oldestChild.lastName}, ${oldestChild.firstName}`;
          } else {
            row.oldestChildFullName = "";
          }
        });

        const filteredRows = rows.filter(
          (row) => row.status === "Open" || row.status === "Exit Incomplete"
        );

        setRows(filteredRows);
        setCount(filteredRows.length);

        const { data: programData } = await getProgramByFiscalYearById(
          ProgramId
        );
        setProgram(programData.program);

        const doneLockStatus =
          parseInt(reportingPeriod) === programData.program.doneLockStatus;
        setLockReport(doneLockStatus ? 1 : 0);

        const { data: familyServiceLogs } =
          await getFamilyServiceLogByProgramByFiscalYear(
            ProgramId,
            reportingPeriod
          );

        setFamilyServiceLogs(familyServiceLogs);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [ProgramId, reportingPeriod]);

  const columns = [
    {
      field: "id",
      headerName: "Case Id",
      flex: 0.7, // Changed from width: 160 to flex: 1
      renderCell: (params) => (
        <div>
          {user.role === "Admin" ? (
            <Link to={`/admin/editopencase/${params.value}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )}
        </div>
      ),
    },
    {
      field: "intakeMonth",
      headerName: "Intake Month",
      flex: 1, // Changed from width: 160 to flex: 1
    },
    {
      field: "statusIcon",
      headerName: "Status",
      flex: 0.5, // Changed from width: 80 to flex: 0.5
      renderCell: (params) => {
        const fsl = familyServiceLogs.find(
          (log) => log.caseId === params.row.id
        );
        return (
          fsl &&
          (fsl.noServicesProvided ? (
            <CloseIcon className="text-danger" />
          ) : (
            <CheckIcon className="text-success" />
          ))
        );
      },
    },
    {
      field: "familyServiceLog",
      headerName: "Family Service Log",
      flex: 1, // Changed from width: 160 to flex: 1
      renderCell: (params) => {
        const rowId = params.row.id;
        const handleClick = (path) => {
          window.location.href = path;
        };

        if (lockReport === 1 || reportingPeriod < 1) {
          return (
            <Button
              href={
                isAdmin
                  ? `/admin/familyservicelog/${rowId}`
                  : `/provider/currentfy/familyservicelog/${rowId}`
              }
              className="btn bg-danger"
              title={
                reportingPeriod < 1
                  ? "Passed Reporting Period Locked"
                  : "Report Locked In Done Status"
              }
              disabled
              style={{ color: "white" }} // Add style to set font color to white
            >
              <EnhancedEncryptionIcon />
              <span className="p-2">Disabled FSL</span>
            </Button>
          );
        } else if (isPeriod || ProgramId === 2575 || lockReport === 0) {
          return (
            <Button
              href={
                isAdmin
                  ? `/admin/familyservicelog/${rowId}`
                  : `/provider/currentfy/familyservicelog/${rowId}`
              }
              className="btn bg-primary"
              onClick={() =>
                handleClick(
                  isAdmin
                    ? `/admin/familyservicelog/${rowId}`
                    : `/provider/currentfy/familyservicelog/${rowId}`
                )
              }
              style={{ color: "white" }} // Add style to set font color to white
            >
              <LockOpenIcon />
              Complete FSL
            </Button>
          );
        } else {
          return null;
        }
      },
    },

    {
      field: "primaryCaregiverFullName",
      headerName: "Primary Caregiver",
      flex: 1, // Set the appropriate flex value as needed
    },
    {
      field: "oldestChildFullName",
      headerName: "Oldest Child",
      flex: 1, // Set the appropriate flex value as needed
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.75,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <AiOutlineDownload
            title="Download Document"
            alt="Document Download Icon"
            role="button"
            size={24}
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => handleDownload(params.row.id)}
          />
          <Button
            onClick={() =>
              handleClick(
                isAdmin
                  ? `/admin/exitcase/${params.row.id}`
                  : `/provider/currentfy/exitcase/${params.row.id}`
              )
            }
          >
            Exit Case
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} className="mt-3">
          <Item>
            <div className="row mt-1">
              <h1 className="mt-3">OPEN CASES</h1>

              <div className="row">
                <div className="row">
                  <div className="col-6 fw-bold">Agency:</div>
                  <div className="col-6 text-start">
                    {" "}
                    {agency?.name ? agency.name : "Choose A Program"}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 fw-bold">Program:</div>
                  <div className="col-6 text-start">
                    {program && program.name
                      ? program.name
                      : program && program.program && program.program.name
                      ? program.program.name
                      : "Choose A Program"}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 fw-bold">Program ID:</div>
                  <div className="col-6 text-start">
                    {ProgramId ? ProgramId : "Choose A Program"}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 fw-bold">Number of Open Cases</div>
                  <div className="col-6 text-start">{count}</div>
                </div>
              </div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={6} className="mt-3">
          <Item>
            <div className="row text-center mt-1">
              <h1 title={periodId} className="mt-3">
                Reporting Period
              </h1>

              <small className="mb-2">
                {displayBeginDate ? (
                  <>
                    <span className="fw-bold">Begins: </span>
                    {moment(displayBeginDate).format("MMMM Do YYYY")}
                  </>
                ) : null}

                {displayEndDate ? (
                  <>
                    <span className="fw-bold">Ends: </span>
                    {moment(displayEndDate).format("MMMM Do YYYY")}
                  </>
                ) : null}
              </small>

              <small className="mb-2">
                <span className="fw-bold ms-2">Today: </span>
                {dateCheck}
              </small>

              <small className="mb-2">
                <span className="fw-bold ms-2">Program Lock Status: </span>

                {lockReport === 1 || reportingPeriod <= 1 ? (
                  <span
                    title={
                      reportingPeriod < 1
                        ? "Passed Reporting Period Locked"
                        : "Report Locked In Done Status"
                    }
                    className="text-danger fw-bold"
                  >
                    <EnhancedEncryptionIcon />
                    Locked
                  </span>
                ) : (
                  <span className="text-success fw-bold">
                    <LockOpenIcon />
                    Unlocked
                  </span>
                )}
              </small>

              {!pfyId ? (
                <div className="text-danger fw-bold mt-3">
                  Please Choose A Program To View Open Cases
                </div>
              ) : null}
            </div>
          </Item>
        </Grid>
      </Grid>
      <div className="py-4">
        <Paper>
          <div sx={{ width: "99%" }}>
            <CustomDataTable
              rows={rows}
              columns={columns}
              paginationModel={paginationModel}
              rowHeight={60}
              onPaginationModelChange={setPaginationModel}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              pageSizeOptions={[5, 10, 25]}
            />
          </div>
        </Paper>
      </div>
    </>
  );
};

export default OpenCases;
