import http from "./httpService";

const apiEndpoint = "/ReportingPeriods";

export function getReportingPeriods() {
  return http.get(`${apiEndpoint}`);
}

export function checkReportingPeriod() {
  return http.get(`${apiEndpoint}/CurrentReportingPeriods`);
}

export function ReportingPeriodUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function postReportingPeriodByIdUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function AddReportingPeriodUrl() {
  return `${apiEndpoint}/AddReportingPeriod`;
}

export function postReportingPeriodById(username,body) {
  return http.post(postReportingPeriodByIdUrl(username),body);
}

export function grabReportingPeriodInfo(id) {
  return http.get(ReportingPeriodUrl(id));
}

export function postCreateReportingPeriod(postingData) {
  return http.post(AddReportingPeriodUrl(), postingData)
}


export function postUpdateReportingPeriod(postingData) {
  return http.post(ReportingPeriodUrl(postingData.Id), postingData);
}

export function postUpdateUnits(postingData){
  if(postingData.CaseId == '')
    postingData.CaseId = null;
  return http.post(`${apiEndpoint}/updateUnits`, postingData);
}


export default { getReportingPeriods, postUpdateReportingPeriod,grabReportingPeriodInfo, postCreateReportingPeriod };
