import React, { Component } from "react";

/*
    <div className="col-md-6">
                                            SoN ID:
                                        </div>
                                        <div className="col-md-6">
                                            {this.state.sonID}
                                        </div>

    <div className="row">
                  <div className="col-lg-6">
                    {this.state.sonID.label}:
                  </div>
                  <div className="col-lg-6">
                    {this.state.sonID.value}
                  </div>
                </div>
*/

class Home extends Component {
  state = {
    data: [
      { id: 1, label: "SoN ID", value: "14000"},
      { id: 2, label: "Program Info", value: "2575 - A CSI Test Case Program"},
      { id: 3, label: "Agency Name", value: "A Carrol County Board of Education"},
      { id: 4, label: "Service Model", value: "FSS/PEI"},
      { id: 5, label: "Federal Award", value: "$3,750.00"},
      { id: 6, label: "Amendment(s)", value: "$0.00"},
      { id: 7, label: "Required Match", value: "$1,250.00"},
      { id: 8, label: "Federal Award (No Match)", value: "$500.00"},
      { id: 9, label: "Amendment(s) (No Match)", value: "$0.00"},
      { id: 10, label: "Total Services", value: "$5,500.00"},
      { id: 11, label: "Average Caseload", value: "15 per month"},
    ],
  };
  render() {
    return (
      <>
        <h1>Home</h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="border border-warning m-2">
              <div className="m-2">
                <ul className="list-group">
                  {this.state.data.map((item) => (
                    <li key={item.id} className="list-group-item">
                      <div className="row">
                        <div className="col-md-6">{item.label}:</div>
                        <div className="col-md-6">{item.value}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="border border-danger m-2">
              <div className="m-2">Important Message for Providers<br/>Some special text that will probably change over time.</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;

/*
const Home= () => {
    return ( <h1>Home</h1> );
}
 
export default Home;
*/
