import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Copyright from "../common/copyright";
import { ChangeForgotPassword } from "../../services/accountservice";
import { Grid } from "@mui/material";
import logo from "../../img/header_pssfLogo.png";
import * as yup from "yup";

const ChangePasswordValidtionSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.*[^\s]).*$/,
      "Password must have at least 1 uppercase, 1 lowercase, 1 number, 1 non-alphanumeric character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Confirm Password is required."),
});

export default function ChangePassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    resolver: yupResolver(ChangePasswordValidtionSchema),
    mode: "onTouched",
  });

  const urlSearchParams = new URLSearchParams(location.search);
  const userName = urlSearchParams.get("username");
  const token = urlSearchParams.get("token");

  function handleApiErrors(errors) {
    if (errors) {
      setError("confirmPassword", { message: errors.data });
    }
  }

  const onSubmit = async (data) => {
    // Use nullish coalescing operator to provide default values for email and token
    data.userName = userName ?? data.userName;
    data.token = token ?? data.token;

    await ChangeForgotPassword(data)
      .then(() => {
        navigate("/account/resetconfirmation", {
          state: { confirm: "change" },
        });
      })
      .catch((error) => handleApiErrors(error));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="PSSF LOGO" style={{ width: "60%" }} />
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                {...register("password")}
                fullWidth
                required
                error={!!errors.password?.message}
                helperText={errors.password?.message}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                type="password"
                {...register("confirmPassword")}
                fullWidth
                required
                error={!!errors.confirmPassword?.message}
                helperText={errors.confirmPassword?.message}
                autoComplete="confirm-password"
              />
            </Grid>
          </Grid>
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
