import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getProgramsByIdandFiscalYear } from "../../services/getProgramService";
import { getTpsProviderDocuments } from "../../services/getSonServices";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";
import { downloadFile } from "../../services/downloadService";
import SonFilesTableRowForm from "../forms/sonfilestablerowform";

const SonFileManagement = () => {
  const [program, setProgram] = useState(0);
  const [agency, setAgency] = useState(0);
  const [tpsDocs, setTpsDocs] = useState(0);

  const { id, fy } = useParams();

  const fetchData = useCallback(async () => {
    try {
      const { data: programData } = await getProgramsByIdandFiscalYear(id, fy);
      setProgram(programData);
      const { data: agencyData } = await getAgencyByFiscalYearandId(
        fy,
        programData.agencyId
      );
      setAgency(agencyData);

      let { data: tpsDocsData } = await getTpsProviderDocuments(
        programData.programByFiscalYear[0].tpsProviderId
      );

      var regex = /_([^.]+)\./g;
      const specificValues = [
        "scr",
        "Audit",
        "Insurance"
      ];
      const newRecords = specificValues
        .filter((value) => {
          return !tpsDocsData.some((doc) => doc.documentFile.includes(value));
        })
        .map((value) => {
          const newRecord = {
            id: 0,
            documentId: 0,
            documentFile: tpsDocsData[0].documentFile.replace(
              regex,
              "_" + value + "."
            ),
            projectId: tpsDocsData[0].projectId,
            agencyProgramId: tpsDocsData[0].agencyProgramId,
            userId: tpsDocsData[0].userId,
            adminProvisional: false,
            notes: "",
            contentType: "application",
            contentSubType: "pdf",
            tpsProviderId: tpsDocsData[0].tpsProviderId,
          };
          return newRecord;
        });
      setTpsDocs([...tpsDocsData, ...newRecords]);
      console.log(tpsDocsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [id, fy]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSaveData = () => {
    fetchData();
  };

  function findDocument(doc, reqName) {
    var temp = doc.documentFile.split("_")[1];
    var name = temp.split(".")[0].toLowerCase();
    reqName = reqName.toLowerCase();

    return name === reqName;
  }

  function searchName(list, name) {
    const result = list.find((item) => findDocument(item, name));
    return result;
  }

  function downloadGroup(group) {
    switch (group) {
      case "Annex C": {
        searchName(tpsDocs, "cover") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "cover").documentFile
          );
        searchName(tpsDocs, "narrative") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "narrative").documentFile
          );
        searchName(tpsDocs, "contractorreport") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "contractorreport").documentFile
          );
        searchName(tpsDocs, "disasterplan") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "disasterplan").documentFile
          );
        searchName(tpsDocs, "disaster") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "disaster").documentFile
          );
        searchName(tpsDocs, "dfcs") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "dfcs").documentFile
          );
        break;
      }
      case "Annex D": {
        searchName(tpsDocs, "sds") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "sds").documentFile
          );
        searchName(tpsDocs, "budget") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "budget").documentFile
          );
        searchName(tpsDocs, "budgetnarrative") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "budgetnarrative").documentFile
          );
        searchName(tpsDocs, "services") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "services").documentFile
          );
        break;
      }
      case "Forms": {
        searchName(tpsDocs, "resolution") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "resolution").documentFile
          );
        searchName(tpsDocs, "match") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "match").documentFile
          );
        searchName(tpsDocs, "secim") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "secim").documentFile
          );
        searchName(tpsDocs, "crc") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "crc").documentFile
          );
        searchName(tpsDocs, "authorization") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "authorization").documentFile
          );
        searchName(tpsDocs, "corpres") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "corpres").documentFile
          );
        break;
      }
      case "Other": {
        searchName(tpsDocs, "COI") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "COI").documentFile
          );
        searchName(tpsDocs, "SOSregistration") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "SOSregistration").documentFile
          );
        searchName(tpsDocs, "sam") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "sam").documentFile
          );
        searchName(tpsDocs, "registration") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "registration").documentFile
          );
        searchName(tpsDocs, "insurance") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "insurance").documentFile
          );
        searchName(tpsDocs, "scr") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "scr").documentFile
          );
        searchName(tpsDocs, "tax") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "tax").documentFile
          );
        searchName(tpsDocs, "w9") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "w9").documentFile
          );
        searchName(tpsDocs, "risk") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "risk").documentFile
          );
        searchName(tpsDocs, "audit") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "audit").documentFile
          );
        searchName(tpsDocs, "sos") &&
          downloadFile(
            `son_docs~${fy}~${id}`,
            searchName(tpsDocs, "sos").documentFile
          );

        break;
      }
      default:
      // do Nothing.
    }
  }

  return (
    <>
      <h1>SoN File Management</h1>
      <div className="row offset-md-3">
        <div className="col-lg-4">Agency:</div>
        <div className="col-lg-6">{agency.name}</div>
      </div>
      <div className="row offset-md-3">
        <div className="col-lg-4">Program:</div>
        <div className="col-lg-6">{program.name}</div>
      </div>
      <div className="row offset-md-3">
        <div className="col-lg-4">Program ID:</div>
        <div className="col-lg-6">{program.id}</div>
      </div>
      <div className="row offset-md-3">
        <div className="col-lg-4">Model:</div>
        <div className="col-lg-6">
          {program.serviceModel &&
            `${program.serviceModel.serviceModelCategoryAbrv}/${program.serviceModel.modelAbrv}`}
        </div>
      </div>
      <br />
      <div className="row offset-md-1">
        {tpsDocs !== 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  <th className="px-2" style={{ textAlign: "center" }}>
                    Document Name
                  </th>
                  <th className="px-2">Provisional</th>
                  <th className="px-2" style={{ textAlign: "center" }}>
                    Notes
                  </th>
                  <th className="px-2" style={{ textAlign: "center" }}>
                    Re-Uploaded Document
                  </th>
                  <th className="px-2"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="px-2">
                  <td>
                    <h5>Annex C</h5>
                  </td>
                </tr>
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "cover")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "narrative")) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) =>
                      findDocument(doc, "contractorreport")
                    ) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "disaster")) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "disasterplan")) ||
                    {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "dfcs")) || {}}
                  onSaveData={handleSaveData}
                />
                <tr className="px-2">
                  <td />
                  <td />
                  <td />
                  <td className="row offset-md-1 px-2">
                    <button
                      onClick={() => downloadGroup("Annex C")}
                      className="btn btn-warning"
                    >
                      Download Annex C
                    </button>
                  </td>
                  <td />
                </tr>
                <tr className="px-2">
                  <td>
                    <h5>Annex D</h5>
                  </td>
                </tr>
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "sds")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "budget")) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) =>
                      findDocument(doc, "budgetnarrative")
                    ) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "services")) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <tr className="px-2">
                  <td />
                  <td />
                  <td />
                  <td className="row offset-md-1 px-2">
                    <button
                      onClick={() => downloadGroup("Annex D")}
                      className="btn btn-warning"
                    >
                      Download Annex D
                    </button>
                  </td>
                  <td />
                </tr>
                <tr className="px-2">
                  <td>
                    <h5>Forms</h5>
                  </td>
                </tr>
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "resolution")) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "match")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "secim")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "crc")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "authorization")) ||
                    {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "corpres")) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <tr className="px-2">
                  <td />
                  <td />
                  <td />
                  <td className="row offset-md-1 px-2">
                    <button
                      onClick={() => downloadGroup("Forms")}
                      className="btn btn-warning"
                    >
                      Download Forms
                    </button>
                  </td>
                  <td />
                </tr>
                <tr className="px-2">
                  <td>
                    <h5>Other</h5>
                  </td>
                </tr>
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "sam")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "COI")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "registration")) ||
                    {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) =>
                      findDocument(doc, "SOSregistration")
                    ) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={
                    tpsDocs.find((doc) => findDocument(doc, "insurance")) || {}
                  }
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "scr")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "tax")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "w9")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "risk")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "sos")) || {}}
                  onSaveData={handleSaveData}
                />
                <SonFilesTableRowForm
                  programId={id}
                  fy={fy}
                  data={tpsDocs.find((doc) => findDocument(doc, "Audit")) || {}}
                  onSaveData={handleSaveData}
                />

                <tr className="px-2">
                  <td />
                  <td />
                  <td />
                  <td className="row offset-md-1 px-2">
                    <button
                      onClick={() => downloadGroup("Other")}
                      className="btn btn-warning"
                    >
                      Download Other
                    </button>
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <div
            className="fw-bold"
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "center",
              marginTop: 100,
            }}
          >
            SORRY NO RESULTS FOUND HERE
          </div>
        )}
      </div>
    </>
  );
};

export default SonFileManagement;
