import React from "react";
import { Modal, Button } from "react-bootstrap";
import { DeleteUser } from "../../services/accountservice";

const ConfirmDeleteModal = ({ show, onHide, toDelete, fetchData }) => {
  
  const handleDeleteUser = async () => {
    await DeleteUser(toDelete);
    fetchData();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete the user ID <strong>{toDelete}</strong>?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteUser}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteModal;
