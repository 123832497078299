import http from "./httpService";

const apiEndpoint = "/ProvidedService";


export function getProvidedServices() {
  return http.get(`${apiEndpoint}`);
}

export function getProvidedServicesByProgramIdAndFiscalYear( programId, fiscalYear) {
  return http.get(`${apiEndpoint}/${programId}/${fiscalYear}`);
}

export function getServiceTotalsByProgramIdAndReportingPeriod( programId, fiscalYear, reportingPeriodId) {
  return http.get(`${apiEndpoint}/serviceTotals/${programId}/${fiscalYear}/${reportingPeriodId}`);
}

export function updateProvidedServicesUnits(newServicesId, units, reportingPeriodId) {
  return http.post(
    `${apiEndpoint}/updateProvidedServicesUnits/${newServicesId}/${units}/${reportingPeriodId}`
  );
}