import React from "react";
import { Link } from "react-router-dom";

const Prs = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Reunification</h3>
          <h5 className="text-center">Parent Reunification</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            Parents whose children have been removed from the home are often
            isolated and have very few supports to help them address the
            circumstances that resulted in the removal of their children or
            navigate the complex child welfare system.
            <br />
            <br />
            <span className="fw-bold">Parent Reunification</span> services
            include a comprehensive family assessment to identify the complex
            caregiver needs and develop a service plan to minimize or eliminate
            risk factors that precipitated removal and increase protective
            factors to improve the likelihood of a successful reunification. It
            is important that caregiver needs are correctly identified, and
            services target the specific issues that need to be resolved to
            support a safe and timely reunification.
            <br />
            <br />
            <span className="fw-bold">Service Requirements include</span>:
            <ol>
              <li>Initial Assessment & Service Plan</li>
              <li>Case Management</li>
              <li>Parent Education or Parent Training</li>
              <li>Therapy</li>
            </ol>
            <span className="fw-bold">
              Other supportive services may include
            </span>
            :
            <ul>
              <li>Life Skills</li>
              <li>Education Supports</li>
              <li>Employment Supports</li>
              <li>Peer Mentoring</li>
              <li>Peer Support Groups</li>
              <li>Substance Use Recovery Support</li>
              <li>Transportation</li>
              <li>Emergency Aid</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Prs;
