import React from "react";
import { Link } from "react-router-dom";

const Links = () => {
  return (
    
   
      
      <div className="row justify-content-center">
        <div className="col-10">
          <img
            alt=""
            src={require("../../img/masthead3.png")}
            style={{ align: "center" }}
            className="img-fluid"
          />
         <div className="row mt-5 mb-5">
            <strong>
              <h3 className="text-center fw-bold">Helpful Links to Child Welfare Resources!</h3>
            </strong>
         </div>
        </div>
        <div className="row justify-content-around">
          <div className="col-10">
            <div className="border border-warning m-2">
              <div className="row m-2">
                <div className="col-lg-6">
                    <h5>Georgia:</h5>
                    <ul>
                      <li>
                        <a className="link" href="https://www.georgiahousingsearch.org/"  rel="noopener noreferrer"  target="_blank">
                          Georgia Housing Search
                        </a>
                      </li>
                      <li>
                        <a className="link" href="https://www.dca.ga.gov/"  rel="noopener noreferrer"  target="_blank">
                          Department of Community Affairs
                        </a>
                      </li>
                      <li>
                        <a
                          className="link"
                          href="https://dfcs.georgia.gov/locations/regional-map/"
                          rel="noopener noreferrer"  target="_blank"
                        >
                          DFCS Regional Map
                        </a>
                      </li>
                      <li>
                        <a className="link" href="https://dfcs.georgia.gov/" rel="noopener noreferrer"  target="_blank">
                          Georgia Division of Family &amp; Children Services
                        </a>
                      </li>
                      <li>
                        <a className="link" href="https://dhs.georgia.gov/" rel="noopener noreferrer"  target="_blank">
                          Georgia Department of Human Services
                        </a>
                      </li>
                      <li>
                        <a className="link" href="https://dph.georgia.gov/" rel="noopener noreferrer"  target="_blank">
                          GA Department of Public Health
                        </a>
                      </li>
                      <li>
                        <a className="link" href="http://www.pcageorgiahelpline.org/" rel="noopener noreferrer"  target="_blank">
                          PCA Georgia Helpline
                        </a>
                      </li>
                      <li>
                        <a className="link" href="https://gafcp.org/" rel="noopener noreferrer"  target="_blank">
                          GA Family Connection Partnership
                        </a>
                      </li>
                      <li>
                        <a className="link" href="http://georgiavoices.org" rel="noopener noreferrer"  target="_blank">
                          VOICES for Georgia's Children
                        </a>
                      </li>
                    </ul>
                </div>
                <div className="col-lg-6">
                  <h5>National:</h5>
                  <ul>
                    <li>
                      <a className="link" href="https://www.childwelfare.gov/" rel="noopener noreferrer"  target="_blank">
                        Child Welfare Information Gateway - Spanish Resources
                      </a>
                    </li>
                    <li>
                      <a className="link" href="http://www.healthymarriageinfo.org/"rel="noopener noreferrer"  target="_blank">
                        Healthy Marriage Resource Center
                      </a>
                    </li>
                    <li>
                      <a className="link" href="https://www.hhs.gov/" rel="noopener noreferrer"  target="_blank">
                        U.S. Department of Health and Human Services
                      </a>
                    </li>
                    <li>
                      <a className="link" href="https://www.acf.hhs.gov/" rel="noopener noreferrer"  target="_blank">
                        Administration on Children, Youth and families
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
      
              <div className="row m-2">
                <strong align="center">
                  Community Resources: Check PSSF&nbsp;
                  <Link
                    to="/guide"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{
                      color: "#779442",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Resource Guide
                  </Link>
                  &nbsp;to find local resources!
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default Links;
