import React from "react";
import Form from "react-bootstrap/Form";
import FormHelperText from "@mui/material/FormHelperText";

const ChildForm = ({
  child,
  handleChildChange,
  errors,
  edStatuses,
  handleChildRelationship,
  relationships,
  over18,
  handleChildCheck,
  data
}) => {
  return (
    <Form>
      <div className="row">
        <Form.Group className="col-md-6 mb-2" controlId="shinesId">
          <Form.Label className="fw-bold">Shines Id</Form.Label>
          <FormHelperText>If unknown or N/A, leave field blank.</FormHelperText>
          <Form.Control
            type="text"
            className="form-control-sm"
            onChange={handleChildChange}
            autoFocus
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 mb-2" controlId="firstName">
          {errors.find((e) => e === "firstName") && (
            <FormHelperText className="text-danger">
              First Name is required.
            </FormHelperText>
          )}
          <Form.Label className="fw-bold">First Name</Form.Label>
          <Form.Control
            type="text"
            className="form-control-sm"
            onChange={handleChildChange}
            defaultValue={child.firstName}
            autoFocus
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 mb-2" controlId="lastName">
          {errors.find((e) => e === "lastName") && (
            <FormHelperText className="text-danger">
              Last Name is required.
            </FormHelperText>
          )}
          <Form.Label className="fw-bold">Last Name</Form.Label>
          <Form.Control
            type="text"
            className="form-control-sm"
            onChange={handleChildChange}
            defaultValue={child.lastName}
            autoFocus
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 mb-2" controlId="sex">
          {errors.find((e) => e === "sex") && (
            <FormHelperText className="text-danger">
              Please select a sex.
            </FormHelperText>
          )}
          <Form.Label className="fw-bold">Sex</Form.Label>
          <Form.Select
            onChange={handleChildChange}
            required
            defaultValue={child.sex}
          >
            <option value={""}>Open this select menu</option>
            <option value={0}>F</option>
            <option value={1}>M</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2" controlId="dateOfBirth">
          {errors.find((e) => e === "dateOfBirth") && (
            <FormHelperText className="text-danger">
              Date of Birth is required.
            </FormHelperText>
          )}
          {over18 && (
            <FormHelperText className="text-warning">
              This child is over 18 years old.
            </FormHelperText>
          )}
          <Form.Label className="fw-bold">Date of Birth</Form.Label>
          <Form.Control
            required
            type="date"
            className="form-control-sm"
            onChange={handleChildChange}
            autoFocus
            value={
              child.dateOfBirth
                ? child.dateOfBirth.toString().split("T")[0]
                : ""
            }
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 mb-2" controlId="educationStatus">
          {errors.find((e) => e === "educationStatus") && (
            <FormHelperText className="text-danger">
              Education Status is required.
            </FormHelperText>
          )}
          <Form.Label className="fw-bold">Education Status</Form.Label>
          <Form.Select
            onChange={handleChildChange}
            required
            defaultValue={child.educationStatus}
          >
            <option>Open this select menu</option>
            {edStatuses.length > 1 &&
              edStatuses.map((edStatus) => (
                <option key={edStatus.id} value={edStatus.value}>
                  {edStatus.value}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group
          className="col-md-6 mb-2"
          controlId="developmentalDisability"
        >
          <Form.Label className="fw-bold">Developmental Disability</Form.Label>
          <Form.Check
            type={"checkbox"}
            id="developmentalDisability"
            defaultValue={child?.isYouth || false}
            name="developmentalDisability"
            label="Developmental Disability"
            onChange={handleChildCheck}
          />
        </Form.Group>
      </div>
      <div className="row">
        {errors.find((e) => e === "childCaregiverRelationships") && (
          <FormHelperText className="text-danger">
            Please enter a relationship to the child for each caregiver.
          </FormHelperText>
        )}
        <Form.Label className="fw-bold">
          For each caregiver, primary and secondary, they should identify a
          relationship to the child
        </Form.Label>
        {data.caregivers &&
          data.caregivers.map((caregiver) => (
            <div className="col-lg-4" key={caregiver.id}>
              <Form.Label className="fw-bold">
                {caregiver.firstName}&nbsp;{caregiver.lastName}
              </Form.Label>
              <Form.Select
                onChange={(event) => handleChildRelationship(event, caregiver)}
                required
                defaultValue={
                  child.childCaregiverRelationships.find(
                    (x) => x.caregiverId === caregiver.id
                  ) &&
                  child.childCaregiverRelationships.find(
                    (x) => x.caregiverId === caregiver.id
                  ).relationship
                }
              >
                <option>Open this select menu</option>
                {relationships.length > 1 &&
                  relationships.map((relationship) => (
                    <option key={relationship.id} value={relationship.value}>
                      {relationship.value}
                    </option>
                  ))}
              </Form.Select>
            </div>
          ))}
      </div>
    </Form>
  );
};

export default ChildForm;
