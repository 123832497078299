import React from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Alert,
  InputLabel,
} from "@mui/material";
import Form from "../common/form";
import {
  getResourceGuideByFiscalYearandId,
  saveResourceGuide,
  saveDraftResourceGuide,
} from "../../services/resourceGuideService";
import { saveContact } from "../../services/getContactService";
import { updateProgramById } from "../../services/getProgramService";

class SonResourceGuideForm extends Form {
  state = {
    data: {},
    data2: {},
    counties: {},
    program: {},
    agency: {},
    rgContact: {},
    disableInputs: false,
    completedByError: false,
  };

  async componentDidMount() {
    const { programId, fiscalYear, counties, program, agency } = this.props;
    this.setState({ program, counties, agency });

    const rgContact = program.contacts.find(
      (e) => e.type === "Resource Guide Contact"
    );
    if (rgContact) {
      const data2 = {
        contactId: rgContact.id,
        programId: program.id,
        name: rgContact.name,
        email: rgContact.email,
        phone: rgContact.phone,
        type: rgContact.type,
        confidentialAddress: program.confidentialAddress,
      };
      this.setState({ data2 });
    }

    const { data } = await getResourceGuideByFiscalYearandId(
      fiscalYear,
      programId
    );
    const { data: lastYearData } = await getResourceGuideByFiscalYearandId(
      fiscalYear - 1,
      programId
    );

    if (data || lastYearData) {
      const disableInputs =
        !!data.dateApprovedByAdmin || this.checkDisableInputs(data);
      this.setState({
        disableInputs,
        data: {
          requestedEdits: data?.draftRequestedEdits || data?.requestedEdits,
          programDescription:
            data?.draftProgramDescription ||
            data?.programDescription ||
            lastYearData?.programDescription,
          additionalServicesOffered:
            data?.draftAdditionalServicesOffered ||
            data?.additionalServicesOffered ||
            lastYearData?.additionalServicesOffered,
          completedBy: data?.draftCompletedBy || data?.completedBy,
        },
      });
    }
  }

  checkDisableInputs = (data) => {
    return (
      !!data.requestedEdits ||
      !!data.programDescription ||
      !!data.additionalServicesOffered ||
      !!data.completedBy
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const { name, value } = input;
    const data = { ...this.state.data };
    data[name] = value;

    this.setState({ data });
  };

  saveDraft = async () => {
    const { data: resourceGuide } = this.state;
    const { programId, fiscalYear } = this.props;

    try {
      await saveDraftResourceGuide(programId, fiscalYear, resourceGuide);
      alert("Draft saved successfully");
    } catch (error) {
      console.log("Error:", error);
      alert("Failed to save draft");
    }
  };

  doSubmit = async () => {
    const { data: resourceGuide, program, data2 } = this.state;
    const { programId, fiscalYear } = this.props;

    if (!resourceGuide.completedBy) {
      this.setState({ completedByError: true });
      return;
    }

    const contact = {
      id: data2.contactId,
      name: data2.name,
      email: data2.email,
      phone: data2.phone,
      type: data2.type,
    };

    const programUpdate = {
      ...program,
      confidentialAddress: data2.confidentialAddress,
    };

    try {
      await saveContact(contact);
      await updateProgramById(programUpdate);
      await saveResourceGuide(programId, fiscalYear, resourceGuide);
      this.setState({ disableInputs: true });
      alert("Resource guide saved successfully");
    } catch (error) {
      console.log("Error:", error);
      alert("Failed to save resource guide");
    }
  };

  async handleDraftSave() {
    const { data: resourceGuide} = this.state;
    const { programId, fiscalYear } = this.props;

    const draftData = {
      ProgramId: programId,
      FiscalYear: fiscalYear,
      DraftRequestedEdits: resourceGuide.requestedEdits,
      DraftProgramDescription: resourceGuide.programDescription,
      DraftAdditionalServicesOffered: resourceGuide.additionalServicesOffered,
      DraftCompletedBy: resourceGuide.completedBy,
    };

    try {
      await saveDraftResourceGuide(programId, fiscalYear, draftData);
      alert("Draft saved successfully!");
    } catch (error) {
      console.log("Error saving draft:", error);
      alert("Failed to save draft");
    }
  }

  render() {
    const {
      data: resourceGuide,
      disableInputs,
      data2,
      completedByError,
    } = this.state;

    return (
      <Box component="form" onSubmit={this.handleSubmit} noValidate>
        <Box sx={{ padding: 2, paddingTop: 8 }}>
          <Typography variant="h6" gutterBottom>
            {data2.type}
          </Typography>
          <Grid container spacing={2}>
            <Grid container sx={{ paddingLeft: 21 }}>
              <Grid
                container
                item
                spacing={2}
                xs={12}
                sm={8}
                md={8}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <InputLabel id="name-label">Name:</InputLabel>
                  <TextField
                    fullWidth
                    name="name"
                    value={data2.name}
                    onChange={this.handleChange}
                    disabled={true}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="email-label">Email:</InputLabel>
                  <TextField
                    fullWidth
                    name="email"
                    value={data2.email}
                    onChange={this.handleChange}
                    disabled={true}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="phone-label">Phone:</InputLabel>
                  <TextField
                    fullWidth
                    name="phone"
                    value={data2.phone}
                    onChange={this.handleChange}
                    disabled={true}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="confidentialAddress"
                        checked={data2.confidentialAddress}
                        onChange={this.handleChange}
                        disabled={true}
                        size="small"
                      />
                    }
                    label="Program Address is confidential"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="requestedEdits-label">
                Please review and verify the above information. If the
                information is not correct, describe required edits:
              </InputLabel>
              <TextField
                fullWidth
                name="requestedEdits"
                value={resourceGuide.requestedEdits || ""}
                onChange={this.handleChange}
                disabled={disableInputs}
                variant="outlined"
                multiline
                minRows={4}
                maxRows={10}
                InputProps={{
                  style: {
                    resize: "vertical",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    overflow: "auto",
                  },
                  "& .MuiInputBase-input": {
                    resize: "vertical",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                id="programDescription-label"
                sx={{ whiteSpace: "normal" }}
              >
                Provide a brief description of the PSSF program at your agency.
                As part of the Resource Guide that will be utilized by DFCS
                staff and other community-based family services agencies, the
                description should include your intended target population and
                referral criteria, service objectives, and expected outcomes
                (limit 250 words):
              </InputLabel>
              <TextField
                fullWidth
                name="programDescription"
                value={resourceGuide.programDescription || ""}
                onChange={this.handleChange}
                disabled={disableInputs}
                variant="outlined"
                multiline
                minRows={4}
                maxRows={10}
                InputProps={{
                  style: {
                    resize: "vertical",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    overflow: "auto",
                  },
                  "& .MuiInputBase-input": {
                    resize: "vertical",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                id="additionalServicesOffered-label"
                sx={{ whiteSpace: "normal" }}
              >
                List any non -PSSF-funded services at your agency available to
                the families you serve. (DO NOT LIST SERVICES FROM YOUR SERVICE
                DELIVERY SCHEDULE. These will be listed in a separate section in
                the Resource Guide.) Describe each additional service one at a
                time in the box below. (Maximum 150 characters per service.):
              </InputLabel>
              <TextField
                fullWidth
                name="additionalServicesOffered"
                value={resourceGuide.additionalServicesOffered || ""}
                onChange={this.handleChange}
                disabled={disableInputs}
                variant="outlined"
                multiline
                minRows={4}
                maxRows={10}
                InputProps={{
                  style: {
                    resize: "vertical",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    overflow: "auto",
                  },
                  "& .MuiInputBase-input": {
                    resize: "vertical",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="completedBy-label">
                Please enter the name of the individual that has completed this
                form.
              </InputLabel>
              <TextField
                fullWidth
                name="completedBy"
                value={resourceGuide.completedBy || ""}
                onChange={this.handleChange}
                disabled={disableInputs}
                variant="outlined"
              />
            </Grid>
            {completedByError && (
              <Grid item xs={12}>
                <Alert severity="error">Required.</Alert>
              </Grid>
            )}
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.saveDraft()}
                  disabled={disableInputs}
                >
                  Save Draft
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disableInputs}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

export default SonResourceGuideForm;
