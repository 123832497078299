import React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FaPlusCircle } from "react-icons/fa";
import { createRegionalAccountingDirectors } from "../../services/regionalAccountingDirectorService";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RegionalContactModal = () => {
  const handleSave = (event) => {
    const form = document.getElementById("form1");
    setHasError(false);
    setHasEmailError(false);
    setHasRegionError(false);

    if (data.name === '') setHasError(true);
    if (data.email === "") setHasEmailError(true);
    if (data.region === "") setHasRegionError(true);
    if (data.region === undefined) setHasRegionError(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } 
    else {

    if (hasError === false && hasEmailError === false && hasRegionError === false ) saveData();
    }

    setValidated(true);
  };

  async function saveData() {
    setShowContact(false);
    await createRegionalAccountingDirectors(data);
    window.location.reload(false);
  }

  const handleClose = () => {
    setData({});
    setShowContact(false);
  };
  const handleShow = () => {
    setShowContact(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    let name = event.target.name;

    const copyOfObject = { ...data };

    delete copyOfObject[name];

    const updatedValue = {};

    updatedValue[name] = event.target.value;
    let newData = {
      ...copyOfObject,
      ...updatedValue,
    };

    setData(newData);
    if (name === 'region') setHasRegionError(false);
  };

  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({});
  const [showContact, setShowContact] = useState(false);
  let [hasError, setHasError] = useState(false);
  let [hasEmailError, setHasEmailError] = useState(false);
  let [hasRegionError, setHasRegionError] = useState(false);

  return (
    <>
      <button
        className="btn btn-link text-success"
        onClick={(e) => handleShow()}
      >
        <FaPlusCircle />
        Add Contact
      </button>
      <Modal show={showContact} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Regional Accounting Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="form1">
            <Form.Group className="mb-2" controlId="name">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="form-control"
                onChange={handleChange}
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-2" controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                className="form-control"
                onChange={handleChange}
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide an email.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="my-4" controlId="type">
              <FormControl
                sx={{ m: 0, minWidth: 120 }}
                size="small"
                error={hasRegionError}
              >
                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="region"
                  value={`${data.region ?? ""}`}
                  label="Region"
                  name="region"
                  onChange={handleChange}
                  required
                >
                  <MenuItem value={''} key={0}>
                    
                  </MenuItem>
                  {new Array(14).fill(14).map((_, index) => (
                    <MenuItem value={index + 1} key={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
                {hasRegionError && (
                  <FormHelperText>Region is required!</FormHelperText>
                )}
              </FormControl>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Create Contact
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegionalContactModal;
