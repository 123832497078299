import React from "react";
import { useSelector, useDispatch } from "react-redux";
import auth from "../../services/authService";
import SonSideBar from "./sonSidebar";
import ProviderSidebar from "./providerSidebar";


const SideBar = () => {
  const user =auth.getCurrentUser();
  const currentState = useSelector((state) => state.fiscalYear);

  if (user.role === "Provider") return <ProviderSidebar fiscalYear={currentState.current} />;

  if (user.role === "Son") return <SonSideBar collapsed={false} fiscalYear={currentState.son} />;

  return null;
};

export default SideBar;
