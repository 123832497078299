import React, { useState, useEffect } from "react";
import { Paper, Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import {
  getCoreServicesParticipationOptions,
  getEffectivenessOfParticipationOptions,
  getImprovements,
  getProgressAchievementOptions,
} from "../../../services/casesService";

const ExitFormB = ({
  user,
  data,
  setData,
  getData,
  caseId,
  handleNext,
  handleBack,
}) => {
  const [validated, setValidated] = useState(false);
  const [coreServicesParticipationOptions, setCoreServicesParticipationOptions] = useState({});
  const [effectivenessOfParticipationOptions, setEffectivenessOfParticipationOptions] = useState({});
  const [progressAchievementOptions, setProgressAchievementOptions] = useState({});
  const [improvements, setImprovements] = useState({});
  let [improvementHasError, setImprovementHasError] = useState(false);

  const handleChange = (event) => {
    const name = event.target.id;
    const newData = { ...data };
    newData[name] = event.target.value;
    setData(newData);
  };

  const handleCheck = (event) => {
    const name = event.target.name;
    const newData = { ...data };
    newData[name] = !newData[name];
    setData(newData);
  };

  const checkImprovements = (id) => {
    for (var i = 0; i < data.caseImprovements.length; i++) {
      if (caseId) {
        if (data.caseImprovements[i].improvementId === id) return i;
      } else {
        if (data.caseImprovements[i] === id) return i;
      }
    }
    return -1;
  };

  const handleImprovementsCheck = (event) => {
    const name = parseInt(event.target.name);
    const newData = { ...data };

    if (!newData.caseImprovements) newData["caseImprovements"] = [];
    if (caseId) {
      if (checkImprovements(parseInt(name)) > -1) {
        newData.caseImprovements.splice(checkImprovements(parseInt(name)), 1);
      } else {
        newData.caseImprovements.push({
          id: 0,
          caseId: parseInt(caseId),
          improvementId: parseInt(name),
        });
      }
    } else {
      newData.caseImprovements.indexOf(parseInt(name)) > -1
        ? newData.caseImprovements.splice(newData.caseImprovements.indexOf(parseInt(name)), 1)
        : newData.caseImprovements.push(parseInt(name));
    }

    setData(newData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //event.stopPropagation();
    //handleNext();
    const form = event.currentTarget;

    let hasError = false;
    setImprovementHasError(false);
    if (data.improvementMeasured && (!data.caseImprovements || data.caseImprovements.length < 1)) {
      setImprovementHasError(true);
      hasError = true;
    }

    if (form.checkValidity() === false || hasError === true) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleNext();
    }
    setValidated(true);
  };

  async function fetchData() {
    const { data: coreServicesParticipationOptions } = await getCoreServicesParticipationOptions();
    setCoreServicesParticipationOptions(coreServicesParticipationOptions);

    const { data: effectivenessOfParticipationOptions } =
      await getEffectivenessOfParticipationOptions();
    setEffectivenessOfParticipationOptions(effectivenessOfParticipationOptions);

    const { data: progressAchievementOptions } =
      await getProgressAchievementOptions();
    setProgressAchievementOptions(progressAchievementOptions);

    const { data: improvements } = await getImprovements();
    setImprovements(improvements);
  }

  useEffect(() => {
    fetchData();
  }, [data]);

  return (
    <>
      <h1>Exit Section B</h1>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <p>
          1. <u className="fw-bold">Access to community resources</u>
        </p>
        {/* <Form.Group className="row px-4" controlId="accessCommunityResources">
          <Form.Check        
            type={"checkbox"}
            id="accessCommunityResources"
            name="accessCommunityResources"
            label="Caregivers/youth identified and accessed other community-based services and/or supports for themselves and the children in their care."
            checked={data.accessCommunityResources}
            onChange={handleCheck}
          />     
        </Form.Group> */}

        <Form.Group
          className="row px-2 form-switch mt-4"
          controlId="accessCommunityResources"
        >
          <Form.Label>
            Caregivers/youth identified and accessed other community-based
            services and/or supports for themselves and the children in their
            care.
          </Form.Label>
          <div className="row">
            <div className="">
              <div className="d-inline-block me-4">No</div>
              <div className="d-inline-block">
                <Form.Check
                  type={"checkbox"}
                  id="accessCommunityResources"
                  name="accessCommunityResources"
                  label=""
                  value={data.accessCommunityResources || false}
                  checked={data.accessCommunityResources || false}
                  onChange={handleCheck}
                />
              </div>
              <div className="d-inline-block">Yes</div>
            </div>
          </div>
        </Form.Group>

        <p className="mt-4">
          2. <u className="fw-bold">Participation in core services</u>
        </p>
        <Form.Group
          className="mb-2"
          controlId="coreServicesParticipationOptionId"
        >
          <Form.Label>
            How engaged was the family/individual in completing their individual
            family service plan?
          </Form.Label>
          <Form.Select
            onChange={handleChange}
            required
            value={data.coreServicesParticipationOptionId || ''}
          >
            <option value="0">Open this select menu</option>
            {coreServicesParticipationOptions.length > 1 &&
              coreServicesParticipationOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <p className="mt-4">
          3.{" "}
          <u className="fw-bold">
            Effectiveness of services in addressing needs
          </u>
        </p>
        <Form.Group
          className="mb-2"
          controlId="effectivenessOfParticipationOptionId"
        >
          <Form.Label>
            How did the service provided address the needs (safety concerns
            and/or risk factors) identified in the initial assessment?
          </Form.Label>
          <Form.Select
            onChange={handleChange}
            required
            value={data.effectivenessOfParticipationOptionId || ''}
          >
            <option value="0">Open this select menu</option>
            {effectivenessOfParticipationOptions.length > 1 &&
              effectivenessOfParticipationOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <p className="mt-4">
          4. <u className="fw-bold">Progress achievement</u>
        </p>
        <Form.Group className="mb-2" controlId="progressAchievementOptionId">
          <Form.Label>
            Based on PSSF service model objectives select the response that best
            describes progress achieved during the provision of PSSF services
            (at time of exit):
          </Form.Label>
          <Form.Select
            onChange={handleChange}
            required
            value={data.progressAchievementOptionId || ''}
          >
            <option value="0">Open this select menu</option>
            {progressAchievementOptions.length > 1 &&
              progressAchievementOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <p className="mt-4">
          5. <u className="fw-bold">Demonstrated improvement</u>
        </p>
        <Form.Group
          className="row px-2 form-switch mt-4"
          controlId="improvementMeasured"
        >
          <Form.Label>
            Did this program provide Parenting Education, Behavior Management,
            or Therapeutic Counseling (with fidelity to Evidence Based Model) as
            a service?
          </Form.Label>
          <div className="row">
            <div className="">
              <div className="d-inline-block me-4">No</div>
              <div className="d-inline-block">
                <Form.Check
                  type={"checkbox"}
                  id="improvementMeasured"
                  name="improvementMeasured"
                  label=""
                  value={data.improvementMeasured || false}
                  checked={data.improvementMeasured || false}
                  onChange={handleCheck}
                />
              </div>
              <div className="d-inline-block">Yes</div>
            </div>
          </div>
        </Form.Group>

        {data.improvementMeasured && (
          <FormControl required error={improvementHasError}>
            <Form.Label className="fw-bold">
              Please indicate the areas in which the caregiver (or youth/young
              adult where applicable) demonstrated an improvement in knowledge
              and/or skills (check all that apply):
            </Form.Label>
            {improvementHasError && (
              <FormHelperText>
                Please select at least one area of improvement.
              </FormHelperText>
            )}
            <div className="row">
              {improvements.length > 0 &&
                improvements.map((option) => (
                  <Form.Group
                    className="col-md-3 mb-2"
                    key={option.id}
                    controlId={option.description}
                    noValidate
                  >
                    <Form.Check
                      type={"checkbox"}
                      //id={option.source}
                      //value={option.id}
                      name={option.id}
                      label={option.description}
                      onChange={handleImprovementsCheck}
                      checked={
                        (data.caseImprovements &&
                          data.caseImprovements.length > 0 &&
                          checkImprovements(option.id) > -1) ||
                        false
                      }
                    />
                  </Form.Group>
                ))}
            </div>
          </FormControl>
        )}

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit">{"Save & Continue"}</Button>
        </Box>
      </Form>
    </>
  );
};

export default ExitFormB;
