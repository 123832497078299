import React from "react";
import Form from "../common/form";
import { downloadFile } from "../../services/downloadService";
import {
  getProgramPendingProposalFiles,
  postTpsProviderDocument,
  saveApprovedFile,
} from "../../services/getSonServices";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";

class SonFilesTableRowForm extends Form {
  state = {
    data: {},
    submit: {},
    modalOpen: false,
    pendingFiles: {},
    approved: false,
  };

  async saveData(data) {
    await postTpsProviderDocument(data.id, data);
    if (typeof this.props.onSaveData === "function") {
      this.props.onSaveData();
    }
    if (data.id === 0 )  window.location.reload();
  }

  async getPendingFiles() {
    const { data } = await getProgramPendingProposalFiles(
      this.props.programId,
      this.props.fy
    );
    this.setState({ pendingFiles: data });
  }

  async componentDidMount() {
    const { data } = this.props;
    this.setState({ data });

    await this.getPendingFiles();
  }

  setModalOpen(modalOpen) {
    this.setState({ modalOpen });
  }

  approveFile() {
    saveApprovedFile(
      this.props.programId,
      this.props.fy,
      this.state.data.documentFile
    );

    this.setState({ approved: true });

    this.setModalOpen(false);
    this.render();
  }

  render() {
    const { data, modalOpen, pendingFiles, approved } = this.state;
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "white",
      borderRadius: "5px",
      boxShadow: 24,
      p: 4,
    };

    return (
      // HB: TODO - replace that 2023 with some kind of FY variable
      data.documentFile ? (
        <>
          <tr>
            <td className="px-2">
              <Button
                color={data.id === 0 ? "error" : "info"}
                onClick={() =>
                  downloadFile(
                    `son_docs~${this.props.fy}~${this.props.programId}`,
                    data.documentFile
                  )
                }
              >
                {data.documentFile}
              </Button>
            </td>
            <td className="px-2">{this.renderCheck("adminProvisional", "")}</td>
            <td className="px-2">{this.renderTextarea("notes", "")}</td>
            <td className="px-2">
              {pendingFiles.length > 0 &&
              pendingFiles.indexOf(data.documentFile) >= 0 &&
              !approved ? (
                <Button
                  label={data.documentFile}
                  onClick={() => this.setModalOpen(true)}
                >
                  {data.documentFile}
                </Button>
              ) : (
                ""
              )}
            </td>
            <td className="px-2">
              <button
                className="btn btn-success"
                onClick={() => this.saveData(data)}
              >
                Save
              </button>
            </td>
          </tr>
          <tr>
            {modalOpen && (
              <Modal
                onClose={() => this.setModalOpen(false)}
                title="Review Submitted Document"
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Button
                    onClick={() =>
                      downloadFile(
                        `son_docs_pending_review~${this.props.fy}~${this.props.programId}`,
                        data.documentFile
                      )
                    }
                  >
                    {data.documentFile}
                  </Button>
                  <br />
                  <br />
                  <button
                    id="approveDoc"
                    onClick={() => this.approveFile()}
                    className="btn btn-primary"
                  >
                    Approve
                  </button>
                </Box>
              </Modal>
            )}
          </tr>
        </>
      ) : (
        <></>
      )
    );
  }
}

export default SonFilesTableRowForm;
