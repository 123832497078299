import React, { useEffect} from "react";
import auth from "../../services/authService";

function Logout () {

    useEffect(() => {
      sessionStorage.clear();
      auth.logout();
    });

    return null;
}

export default Logout ;