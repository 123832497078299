import React from "react";
import { Navigate } from "react-router-dom";
import { createAgency } from "../../services/getAgencyService";
import Form from "../common/form";

class AddAgencyForm extends Form {
  state = {
    data: {
      entityType: "1",
      contacts: [
        {
          name: "",
          phone: "",
          email: "",
          fax: "",
        },
      ],
      agenciesByFiscalYear: [
        {
          yearEnd: "",

        },
      ],
    },
    submit: false,
  };

  doChange(input) {
    if (input.required) {
      if (input.value.length <= 3) {
        input.className = "form-control is-invalid";
      } else if (input.value.length > 3) {
        input.className = "form-control is-valid";
      } else {
        input.className = "form-control";
      }
    }
  }

  updateNestedState() {
    const { data: agency } = this.state;
    this.setState((state) => {
      if (agency.ContactName !== undefined || agency.ContactPhone !== undefined)
        this.state.data.contacts[0].Type = "Executive_Director";
      this.state.data.contacts[0].name = agency.ContactName;
      this.state.data.contacts[0].phone = agency.ContactPhone;
      this.state.data.contacts[0].email = agency.ContactEmail;
      this.state.data.contacts[0].fax = agency.ContactFax;
      this.state.data.agenciesByFiscalYear[0].yearEnd = agency.yearEnd;
      this.state.data.agenciesByFiscalYear[0].fiscalYear = this.props.fiscalYear;
      return state;
    });
  }

  doSubmit() {
    const { data } = this.state;
    this.updateNestedState();
    var forms = document.querySelector(".needs-validation");
    forms.classList.add("was-validated");
    if (forms.checkValidity() === true) {
      const submit = true;
      this.setState({ submit });
        createAgency(data);
        
    }
    else{
      const submit = false;
      this.setState({ submit });
    }
  }

  render() {
    const { submit } = this.state;
    const entityTypes = [
      { id: 1, name: "Non-Profit" },
      { id: 2, name: "Public Entity" },
    ];
    return (
      <>
        {submit && <Navigate to={"/admin/agencies"} replace={false} />}
        <form
          onSubmit={this.handleSubmit}
          className="row g-3 needs-validation"
          noValidate
        >
          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput(
                "name",
                "Agency Name",
                null,
                false,
                "text",
                true
              )}
            </div>
            <div className="col-lg-4">
              {this.renderSelect("entityType", "Entity Type", entityTypes)}
            </div>
            <div className="col-lg-4">
              {this.renderInput("yearEnd", "Year End")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("address", "Agency Mailing Address")}
            </div>
            <div className="col-lg-4">{this.renderInput("city", "City")}</div>
            <div className="col-lg-4">{this.renderInput("zip", "Zip")}</div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("streetAddress", "Agency Street Address")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("streetCity", "City")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("streetZip", "Zip")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("phone", "Phone Number")}
            </div>
            <div className="col-lg-4">{this.renderInput("fax", "Fax")}</div>
            <div className="col-lg-4">
              {this.renderInput("email", "Email Address")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("webAddress", "Web Address")}
            </div>
            <div className="col-lg-4">{this.renderInput("ein", "EIN")}</div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("ContactName", "ED Name")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("ContactPhone", "ED Phone")}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-4">
              {this.renderInput("ContactEmail", "ED Email")}
            </div>
            <div className="col-lg-4">
              {this.renderInput("ContactFax", "Ed Fax")}
            </div>
          </div>

          <div className="m-4">{this.renderButton("Save")}</div>
        </form>
      </>
    );
  }
}


export default AddAgencyForm;
