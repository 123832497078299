import http from "./httpService";

const apiEndpoint = "/ProviderStaff";

export function getProviderStaffByPfyId(pfyId){
  return http.get(`${apiEndpoint}/${pfyId}`);
}

export function insertProviderStaff(pfyId, staff) {
  const body =  { ...staff };
  return http.post(`${apiEndpoint}/${pfyId}`, body);
}

export function getProviderStaffById(Id) {
  return http.get(`${apiEndpoint}/byId/${Id}`);
}

export function updateProviderStaff(Id, staff) {
  const body = { ...staff };
  return http.post(`${apiEndpoint}/Update/${Id}`, body);
}
