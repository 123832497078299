import React from "react";
import { Link } from "react-router-dom";

const Hmi = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Support</h3>
          <h5 className="text-center">
            Healthy Relationship &amp; Co-Parenting
          </h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">
              Healthy Relationship &amp; Co-Parenting
            </span>{" "}
            services teach skills to help couples communicate better, manage
            their emotions more effectively when they disagree and be better
            parents for their children. Skills that help parents work
            cooperatively should also increase voluntary paternity establishment
            for children. Even when couples are unable to sustain a healthy
            marriage, parents who can work together are more likely to agree to
            fair support orders and to provide financial and emotional support
            for their children.
            <br />
            <br />
            Healthy Relationship & Co-Parenting services are designed to
            strengthen and promote stable and life-long parental or co-parenting
            relationships. Services should teach couples how to build and
            maintain healthy partnerships, identify and manage stress that
            threatens relationships, and promote and support co-parenting.
            <br />
            <br />
            Families referred for healthy marriage/relationship/co-parenting
            classes, include:
            <ul>
              <li>
                Non-married pregnant or parenting women and expectant or
                parenting fathers
              </li>
              <li>Separated or divorced couples with children</li>
              <li>Young adults</li>
              <li>Married couples</li>
              <li>Step parents</li>
            </ul>
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Initial Family Assessment & Service Plan</li>
              <li>Case Management</li>
              <li>Therapy - Couples/Family</li>
              <li>
                Healthy Relationship & Co-Parenting Education/Training including
                but not limited to the following evidence-based curricula:
              </li>
              <ul>
                <li>
                  Parenting/Parent Education curricula or Parent Training model,
                  such as Strengthening Families that focuses on increasing
                  family skills to support healthy child development, address
                  positive communication, family functioning, and discipline
                </li>
                <li>Effective Communication</li>
                <li>Financial Management</li>
                <li>Conflict Resolution</li>
                <li>Family Planning</li>
                <li>
                  Father Engagement, such as Supporting Father Involvement, a
                  preventive intervention designed to enhance fathers’ positive
                  involvement with their children
                </li>
                <li>
                  Domestic Violence Batterer Intervention Programs, such as
                  Domestic Abuse Intervention Project - The Duluth Model
                </li>
                <li>Anger Management</li>
              </ul>
            </ol>
            Based on needs identified by the Assessment and goals identified by
            the family, additional supports and services may be necessary to
            address challenges, gaps or barriers that reduce the effectiveness
            of core services. These may include:
            <ul>
              <li>Peer mentoring</li>
              <li>Support groups</li>
              <li>Parent/child enrichment activities</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Hmi;
