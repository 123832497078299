import http from "./httpService";

const apiEndpoint = "/RegionalDirectors";
const apiEndpointExtended = "/RegionalDirectors/byRegion";

export function getRegionalDirectors() {
  return http.get(apiEndpoint);
}

export function getRegionalDirectorsByIdUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function fetchRegionalDirectorsById(id) {
  return `${apiEndpointExtended}/${id}`;
}


export function getRegionalDirectorsByRegion(id) {
  return http.get(fetchRegionalDirectorsById(id));
}

export function getRegionalDirectorsById(id) {
  return http.get(getRegionalDirectorsByIdUrl(id));
}

export function saveRegionalDirectors(director) {
  if (director.id) {
    const body = { ...director };
    delete body.id;
    return http.post(getRegionalDirectorsByIdUrl(director.id), body);
  }
}