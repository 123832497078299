import React, { useEffect, useState } from "react";
import {
  Chip,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import {
  UpdateProgramByFiscalYear,
  getprogramByFiscalYearWithCounty,
} from "../../services/getProgramService";
import { getCounties } from "../../services/countiesService";
import { LoadingButton } from "@mui/lab";

export default function SonEditPrograms() {
  const defaultProgramData = {
    programStatus: true,
    fiscalCountyName: "",
    homeCountyName: "",
    averageCaseLoadPerMonth: "",
    totalCasesPerYear: "",
    totalServices: "",
    federalFundingRequested: "",
    localMatchPlanned: "",
    countyDirectorName: "",
    countyDirectorEmail: "",
    countyDirectorPhone: "",
  };

  const { fy, id } = useParams();
  const location = useLocation();
  const { sonUsers } = location.state;

  // State to store the fetched data
  const [programData, setProgramData] = useState(defaultProgramData);
  const [counties, setCounties] = useState(null);

  async function saveData() {
    try {
      await UpdateProgramByFiscalYear(programData);
      await fetchData();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }

  async function fetchData() {
    try {
      const { data } = await getprogramByFiscalYearWithCounty(id, fy);
      const { data: counties } = await getCounties();
      setProgramData(data);
      setCounties(counties);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <form>
      <Typography variant="h4">
        Edit Program {id} for Fiscal Year {fy}
      </Typography>
      <Grid item xs={12} mt={5}>
        <div className="row offset-md-0">
          <div className="col-2 fw-bold text-end fw-bold">Agency Name:</div>
          <div className="col-10 text-start">{sonUsers.agencyName}</div>
        </div>
        <div className="row offset-md-0">
          <div className="col-2 fw-bold text-end fw-bold">Program Name:</div>
          <div className="col-10 text-start">{sonUsers.name}</div>
        </div>
        <div className="row offset-md-0">
          <div className="col-2 fw-bold text-end fw-bold">SoN ID:</div>
          <div className="col-10 text-start">{sonUsers.sonId}</div>
        </div>
      </Grid>
      {programData && (
        <Grid container spacing={2} mt={2}>
          <Grid item xs={4}>
            <Typography variant="body1">Program Status (for FY{fy})</Typography>
            <Select
              fullWidth
              label="Program Status"
              value={programData.programStatus}
              onChange={(e) =>
                setProgramData({
                  ...programData,
                  programStatus: e.target.value,
                })
              }
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              Primary Service Area (Fiscal County)
            </Typography>
            {counties && (
              <Select
                fullWidth
                label="Primary Service Area"
                value={programData.fiscalCountyName}
                onChange={(e) =>
                  setProgramData({
                    ...programData,
                    fiscalCountyName: e.target.value,
                  })
                }
              >
                {counties.map((serviceArea) => (
                  <MenuItem key={serviceArea.id} value={serviceArea.name}>
                    {serviceArea.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          <Grid item xs={4}>
            <Chip
              label={`Region: ${programData.fiscalCountyRegion}`}
              bgcolor="success.main"
              color="success"
              style={{ fontSize: "20px" }}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body1">
              Number of Families per month
            </Typography>
            <TextField
              fullWidth
              value={programData.averageCaseLoadPerMonth}
              onChange={(e) =>
                setProgramData({
                  ...programData,
                  averageCaseLoadPerMonth: e.target.value,
                })
              }
              onInput={(e) => {
                // Restrict input to numbers only
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              type="number"
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Number of Families per year</Typography>
            <TextField
              fullWidth
              value={programData.totalCasesPerYear}
              onChange={(e) =>
                setProgramData({
                  ...programData,
                  totalCasesPerYear: e.target.value,
                })
              }
              onInput={(e) => {
                // Restrict input to numbers only
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              type="number"
            />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body1">Home County</Typography>
            {counties && (
              <Select
                fullWidth
                label="Primary Service Area"
                value={programData.homeCountyName}
                onChange={(e) =>
                  setProgramData({
                    ...programData,
                    homeCountyName: e.target.value,
                  })
                }
              >
                {counties.map((serviceArea) => (
                  <MenuItem key={serviceArea.id} value={serviceArea.name}>
                    {serviceArea.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              Federal ($) calculated at .75 of Total Services
            </Typography>
            <TextField
              fullWidth
              value={programData.federalFundingRequested}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              Cash Match ($) calculated at .25 of Total Services
            </Typography>
            <TextField
              fullWidth
              value={programData.localMatchPlanned}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Total Cost of Services ($)</Typography>
            <TextField
              fullWidth
              value={programData.totalServices}
              onChange={(e) =>
                setProgramData({
                  ...programData,
                  totalServices: e.target.value,
                })
              }
              onInput={(e) => {
                // Restrict input to numbers only
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              type="number"
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} mt={5}>
        <div className="row offset-md-0">
          <div className="col-2 fw-bold text-end fw-bold">County Director:</div>
          <div className="col-10 text-start">
            {programData.countyDirectorName}
          </div>
        </div>
        <div className="row offset-md-0">
          <div className="col-2 fw-bold text-end fw-bold">County:</div>
          <div className="col-10 text-start">
            {programData.fiscalCountyName}
          </div>
        </div>
        <div className="row offset-md-0">
          <div className="col-2 fw-bold text-end fw-bold">Email:</div>
          <div className="col-10 text-start">
            {programData.countyDirectorEmail}
          </div>
        </div>
        <div className="row offset-md-0">
          <div className="col-2 fw-bold text-end fw-bold">Phone Number:</div>
          <div className="col-10 text-start">
            {programData.countyDirectorPhone}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} mt={5}>
        <LoadingButton
          variant="contained"
          className="btn-primary"
          onClick={saveData}
        >
          Save Data
        </LoadingButton>
      </Grid>
    </form>
  );
}
