import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import { Modal, Button, Box, Typography } from "@mui/material";
import { IsLoggedIn, logout } from "../../services/authService";

const SessionTimeout = () => {
  const [isOpen, setOpen] = useState(false);
  const [logoutTime, setLogoutTime] = useState({ minutes: 0, seconds: 0 });

  // Constants for timeout configurations
  const timeoutTime = 60; // Total timeout time in minutes
  const timeoutWarning = 15; // Warning time before logout in minutes
  const cronStart = 60000 * (timeoutTime - timeoutWarning); // Start time for timeout warning

  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  let timeStampRef = useRef(null);
  // Function to handle session warning

  const warningInactive = useCallback((timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      if (!IsLoggedIn()) return; // If not logged in, return

      // Calculate max and popup times
      const maxTime = timeoutTime;
      const popTime = timeoutTime - timeoutWarning;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = Math.floor(diff.asMinutes()); // Convert difference to minutes

      if (minPast >= popTime && minPast < maxTime) {
        setOpen(true); // Open the modal
        const remainingSeconds =
          60 * timeoutTime - Math.floor(diff.asSeconds()); // Calculate remaining seconds
        const remainingMinutes = Math.floor(remainingSeconds / 60); // Calculate remaining minutes
        const remainingSecondsPart = remainingSeconds % 60; // Calculate remaining seconds
        setLogoutTime({
          minutes: remainingMinutes,
          seconds: remainingSecondsPart,
        }); // Set remaining logout time
      } else if (minPast >= maxTime) {
        clearInterval(warningInactiveInterval.current); // Clear warning interval
        setOpen(false); // Close the modal
        sessionStorage.removeItem("lastTimeStamp"); // Remove timestamp from sessionStorage
        logout(); // Logout the user
      } else return;
    }, 1000); // Check every second
  }, []);

  // Function to start the timeout checker
  const timeChecker = useCallback(() => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp"); // Get timestamp from sessionStorage
      warningInactive(storedTimeStamp); // Trigger warning inactive function
    }, cronStart); // Start timeout after cronStart milliseconds
  }, [warningInactive, cronStart]);

  // Function to reset the timer
  const resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    // If logged in, store current timestamp in sessionStorage
    if (IsLoggedIn()) {
      timeStampRef.current = moment();
      sessionStorage.setItem(
        "lastTimeStamp",
        timeStampRef.current.toISOString()
      );
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }

    timeChecker(); // Start timeout checker
    setOpen(false); // Close the modal
  }, [timeChecker]);

  // Function to handle modal close
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false); // Close the modal
    resetTimer(); // Reset the timer
  };

  const handleLogout = () => {
    setOpen(false);
    resetTimer();
    logout();
  };

  // Effect hook to set up event listeners and start the timer
  useEffect(() => {
    const handleEvent = (event) => {
      if (isOpen) return; // If modal is open, return
      if (event.type === "keydown" && event.key === "Escape") return; // If Esc key is pressed, return
      resetTimer(); // Reset the timer
    };

    const events = ["click", "load", "scroll", "keydown"]; // Events to listen for
    events.forEach((event) => {
      window.addEventListener(event, handleEvent); // Add event listener
    });

    timeChecker(); // Start timeout checker

    return () => {
      clearTimeout(startTimerInterval.current); // Cleanup: clear start timer interval
      events.forEach((event) => {
        window.removeEventListener(event, handleEvent); // Cleanup: remove event listeners
      });
    };
  }, [resetTimer, timeChecker, isOpen]); // Dependencies for useEffect hook

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="session-timeout-modal-title"
      aria-describedby="session-timeout-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="session-timeout-modal-title"
          variant="h6"
          component="h2"
        >
          Session Timeout
        </Typography>
        <Typography id="session-timeout-modal-description" sx={{ mt: 2 }}>
          Your session will expire in
          <Typography
            component="span"
            variant="h5"
            fontWeight="bold"
            display="block"
          >
            {logoutTime.minutes === 1
              ? `${logoutTime.minutes} minute`
              : `${logoutTime.minutes} minutes`}{" "}
            {logoutTime.seconds === 1
              ? `${logoutTime.seconds} second`
              : `${logoutTime.seconds} seconds`}
          </Typography>
          Please click "continue" to keep working or "logout" if you are
          finished.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" onClick={handleClose} sx={{ mr: 2 }}>
            Continue
          </Button>
          <Button variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SessionTimeout;
