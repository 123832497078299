import { Paper, Card } from "@material-ui/core";
import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import { getFslsByFiscalYearAndReportingPeriodId } from "../../services/familyServiceLogService";
import ProgramsWithReportsTable from "../Tables/programsWithReportsTable";
import MonthSearch from "./monthsearch";
import { getProgramByFiscalYearIdByFYandProgramId } from "../../services/getProgramService";

const ReportManagement = () => {
  const [data, setData] = useState([]);
  const [reportingPeriod, setReportingPeriod] = useState([]);
  const [programsReports, setProgramsReports] = useState([]);
  const [newProgramsReports, setNewProgramsReports] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const LocalFiscalYear = sessionStorage.getItem("LocalFiscalYear");
  const periodId = sessionStorage.getItem("periodId");
  const ProgramId = sessionStorage.getItem("ProgramId");

  const paperStyle = {
    padding: 20,
    height: "100%",
    minWidth: 700,
    width: "100%",
    margin: "20px auto",
    justify: "flex-end",
  };

  const cardStyle = {
    padding: 20,
    minWidth: 700,
    width: "100%",
    margin: "20px auto",
  };

  const handleChange = (event) => {
    setIsLoading(true);

    const id = event.target.id;
    let newData = { ...data };
    newData[id] = event.target.value;

    setData(newData);

    if (event.target.id === "fiscalYear") {
      setProgramsReports([]);
    }

    if (event.target.id === "reportingPeriodId") {
      const selectedReportingPeriodId = parseInt(event.target.value, 10);
      setReportingPeriod(selectedReportingPeriodId);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return;
  };

  const handleSearch = useCallback(async () => {
    const { reportingPeriodId, fiscalYear } = data;
    const thisReportingPeriodId = reportingPeriodId || periodId;

    const thisFiscalYear = fiscalYear || LocalFiscalYear;

    const { data: newprogramsReports } =
      await getFslsByFiscalYearAndReportingPeriodId(
        thisReportingPeriodId,
        thisFiscalYear
      );

    setProgramsReports(newprogramsReports);
    setNewProgramsReports(newprogramsReports);

    if (ProgramId && newprogramsReports.length !== 0) {
      const { data: programsbyId } =
        await getProgramByFiscalYearIdByFYandProgramId(
          thisFiscalYear,
          ProgramId
        );
      sessionStorage.setItem("ProgramByFiscalYearId", programsbyId.id);
      setPrograms(programs);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  }, [LocalFiscalYear, ProgramId, data, periodId, programs]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {}, [programsReports]);

  return (
    <Paper elevation={10} style={paperStyle}>
      <h1>Manage Programmatic Reporting</h1>
      <Card style={cardStyle}>
        <MonthSearch
          data={data}
          handleChange={handleChange}
          useProgramId={false}
          useReportingPeriodId={true}
        />

        <div className="pt-2">
          <button
            type="button"
            className="btn btn-outline-primary w-50"
            onClick={handleSearch}
            disabled={isLoading}
          >
            Search
          </button>
        </div>
      </Card>
      {data.reportingPeriodId && newProgramsReports.length > 0 ? (
        <ProgramsWithReportsTable
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          reportingPeriod={reportingPeriod}
          periodId={periodId}
          fiscalYear={data.fiscalYear ? data.fiscalYear : LocalFiscalYear}
          programsReports={programsReports}
        />
      ) : (
        <Card style={cardStyle}>
          <h6>No programs have begun reporting for the chosen period.</h6>
        </Card>
      )}
    </Paper>
  );
};

export default ReportManagement;
