import http from "./httpService";
const apiById = "/ProviderUsers/GetUsersById";

const apiEndpointById = "/ProviderUsers/GetAllUsersForProgramByUserId";

const apiEndpointByProgramId = "/Users/GetAllUsersByProgramId";

const apiEndpoint = "/ProviderUsers/GetAllProviderUsers";

const apiSaveProviderEndpoint = "/ProviderUsers/UpdateProviderUsers";

export function getAllUsers() {
  return http.get(apiEndpoint);
}

export function getUserByIdUrl(id) {
  return `${apiById}/${id}`;
}

export function getAllUsersByUserIdUrl(id) {
  return `${apiEndpointById}/${id}`;
}

export function getAllUsersByByProgramIdUrl(id) {
  return `${apiEndpointByProgramId}/${id}`;
}

export function saveProvidersUsersUrl(id) {
  return `${apiSaveProviderEndpoint}/${id}`;
}

export function getAllUsersByUserId(id) {
  return http.get(getAllUsersByUserIdUrl(id));
}

export function getAllUsersByByProgramId(id) {
  return http.get(getAllUsersByByProgramIdUrl(id));
}

export function getUserById(id) {
  return http.get(getUserByIdUrl(id));
}

export function saveProviderUsers(user) {
  if (user.id) {
    const body = { ...user };
    delete body.id;
    return http.post(saveProvidersUsersUrl(user.id), body);
  }
}
