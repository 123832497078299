import React from "react";
import { getCounties } from "../../services/countiesService";
import { getProgramById } from "../../services/getProgramService";
import { saveAdditionalCounties } from "../../services/getSonServices";
import { Navigate } from "react-router-dom";
import Form from "../common/form";

class AdditionalCountiesForm extends Form {
  state = {
    data: {}, // additionalCounties
    counties: {},
    submit: false,
  };

  async componentDidMount() {
    await this.getCounties();
    await this.getProgram();
  }

  async getCounties() {
    const { data } = await getCounties();
    this.setState({ counties: data });
  }

  async getProgram() {
    const { data } = await getProgramById(this.props.id);
    const additionalCounties = data.countiesServed;
    this.setState({ data: additionalCounties });
  }

  doSubmit = async () => {
    const { data } = this.state;
    try {
      await saveAdditionalCounties(this.props.id, data);
      this.setState({ submit: true });
    } catch (error) {
      this.setState({ submit: false });
    }
  };

  // Utility function to chunk the counties array
  chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  render() {
    const { data, counties, submit } = this.state;
    const LocalFiscalYear =
      sessionStorage.getItem("LocalFiscalYear")

    const chunkedCounties = this.chunkArray(counties, 27); // Chunk the counties

    return (
      <>
        {submit && (
          <Navigate
            to={`/admin/sonedit/${LocalFiscalYear}/${this.props.id}`}
            replace={false}
          />
        )}
        <form onSubmit={this.handleSubmit}>
          <div className="row m-2">
            {chunkedCounties.map((chunk, index) => (
              <div key={index} className="col">
                {chunk.map((county) => (
                  <div key={county.id} className="form-check">
                    <label htmlFor={county.name}>{county.name}</label>
                    {this.renderMultiCheckbox(
                      data[0] && data.find((elem) => elem.id === county.id)
                        ? true
                        : false,
                      county.name,
                      county.id
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="m-4">{this.renderButton("Submit")}</div>
        </form>
      </>
    );
  }
}

export default AdditionalCountiesForm;
