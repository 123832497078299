import React, { useState } from "react";
import { useEffect } from "react";
import { Paper, Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { getCounties } from "../../../services/countiesService";
import { getStates } from "../../../services/getStatesService";
import {
  getIncomeOptions,
  getIncomeSources,
} from "../../../services/casesService";
import Moment from "moment";
import { red } from "@material-ui/core/colors";

const CaseFormA = ({
  user,
  data,
  setData,
  getData,
  caseId,
  handleNext,
  handleBack,
  handleRadioChange
}) => {
  //HB: This form does not actually handle saving the data. That is all done in the parent class, NewCase, so that data can be shared btwn steps
  //const [data, setData] = useState({});
  const [states, setStates] = useState({});
  const [counties, setCounties] = useState({});
  const [incomeSources, setIncomeSources] = useState({});
  const [incomeOptions, setIncomeOptions] = useState({});
  const [validated, setValidated] = useState(false);
  let [incomeSourceHasError, setIncomeSourceHasError] = useState(false);

  const name = user.name;

  async function fetchData() {
    const { data: states } = await getStates();
    setStates(states);

    const { data: counties } = await getCounties();
    setCounties(counties);

    const { data: incomeSources } = await getIncomeSources();
    setIncomeSources(incomeSources);

    const { data: incomeOptions } = await getIncomeOptions();
    setIncomeOptions(incomeOptions);
  }

  const handleChange = (event) => {
    const name = event.target.id;
    const copyOfObject = { ...data };

    if (
      copyOfObject.caregivers &&
      copyOfObject.caregivers.find((caregiver) => caregiver.isPrimary)
    ) {
      copyOfObject.caregivers.find((caregiver) => caregiver.isPrimary)[name] =
        event.target.value;
      setData(copyOfObject);
    } else {
      delete copyOfObject[name];

      const updatedValue = {};
      if (event.target.files) {
        updatedValue[name] = event.target.files[0];
      } else {
        updatedValue[name] = event.target.value;
      }

      let newData = {
        ...copyOfObject,
        ...updatedValue,
      };

      setData(newData);
    }
  };

  const handleChange2 = (event) => {
    const name = event.target.id;
    const value = event.target.value;

    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheck = (event) => {
    const name = parseInt(event.target.name);
    const newData = { ...data };

    if (!newData.caseIncomeSources) newData["caseIncomeSources"] = [];
    if (caseId) {
      if (checkIncomeSources(parseInt(name)) > -1) {
        newData.caseIncomeSources.splice(checkIncomeSources(parseInt(name)), 1);
      } else {
        newData.caseIncomeSources.push({
          id: 0,
          caseId: parseInt(caseId),
          incomeSourceId: parseInt(name),
        });
      }
    } else {
      newData.caseIncomeSources.indexOf(parseInt(name)) > -1
        ? newData.caseIncomeSources.splice(
            newData.caseIncomeSources.indexOf(parseInt(name)),
            1
          )
        : newData.caseIncomeSources.push(parseInt(name));
    }

    setData(newData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    let hasError = false;
    setIncomeSourceHasError(false);
    if (data.pcType < 4 && (!data.caseIncomeSources || data.caseIncomeSources.length < 1)) {
      setIncomeSourceHasError(true);
      hasError = true;
    }

    if (!data.commenceDate || parseInt(data.commenceDate.split("-")[0]) < 1990)
      data.commenceDate = Moment(Date.now()).format("YYYY-MM-DD");

    if (form.checkValidity() === false || hasError === true) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleNext();
    }
    setValidated(true);
  };

  const checkIncomeSources = (id) => {
    for (var i = 0; i < data.caseIncomeSources.length; i++) {
      if (caseId) {
        if (data.caseIncomeSources[i].incomeSourceId === id) return i;
      } else {
        if (data.caseIncomeSources[i] === id) return i;
      }
    }
    return -1;
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  const maritalStatusOptions = [
    { id: 1, name: "Cohabitating" },
    { id: 2, name: "Divorced" },
    { id: 3, name: "Married" },
    { id: 4, name: "Separated" },
    { id: 5, name: "Single" },
    { id: 6, name: "Widowed" },
  ];

  const educationOptions = [
    { id: 1, name: "College Degree" },
    { id: 2, name: "Elementary (1-8)" },
    { id: 3, name: "GED" },
    { id: 4, name: "High School" },
    { id: 5, name: "Some College" },
    { id: 6, name: "Vocational Training" },
  ];

  const ethnicityOptions = [
    { id: 1, name: "African-American" },
    { id: 2, name: "American Indian" },
    { id: 3, name: "Asian" },
    { id: 4, name: "Caucasian" },
    { id: 5, name: "Hispanic" },
    { id: 6, name: "Other" },
  ];

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h6 className="py-3">Intake Section A: Primary Caregiver Information</h6>
      {caseId && <h6 className="py-4">Case Id: {caseId}</h6>}
      <Form.Group className="mb-2" controlId="reason">
        <Form.Label className="fw-bold">Select reason for Intake:</Form.Label>
        <Form.Check
          required
          type={"radio"}
          name={"reason"}
          value={data.reason ? data.reason : 0}
          label={"Opening new case"}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("reason", 0);
          }}
          checked={data.reason === 0}
        />
        <Form.Check
          required
          type={"radio"}
          name={"reason"}
          value={1}
          label={"Placement change for previously exited case"}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("reason", 1);
          }}
          checked={data.reason === 1}
        />
      </Form.Group>
      <Form.Group className="mb-2" controlId="completedBy">
        <Form.Label className="fw-bold">Completed by: {name}</Form.Label>
      </Form.Group>
      <Form.Group className="mb-2" controlId="commenceDate">
        <Form.Label className="fw-bold">
          Report date services commenced. If intake is being completed as a
          result of a placement change, report date that services originally
          commenced.
        </Form.Label>
        <Form.Control
          required
          type="date"
          className="form-control-sm"
          defaultValue={
            data.commenceDate ? data.commenceDate.toString().split("T")[0] : ""
          }
          onChange={handleChange2}
          autoFocus
        />
      </Form.Group>
      <Form.Group className="mb-2" controlId="pcType">
        <Form.Label className="fw-bold py-2">
          Enter the following information on the adult individual living in the
          household who is responsible for the care and protection of the
          children. For reporting consistency, the female head of household is
          usually identified as the PC.
        </Form.Label>
        <Form.Check
          type={"radio"}
          //id={"biologicalParent"}
          value={0}
          name={"pcType"}
          label={"Biological Parent"}
          //onChange={handleChange2}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("pcType", 0);
          }}
          checked={data.pcType === 0} //Using == because pcType might come out as a char of the number instead
          required
        />
        <Form.Check
          type={"radio"}
          value={1}
          name={"pcType"}
          label={"Relative Caregiver"}
          //onChange={handleChange2}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("pcType", 1);
          }}
          checked={data.pcType === 1}
          required
        />
        <Form.Check
          type={"radio"}
          value={2}
          name={"pcType"}
          label={"Adoptive Parent"}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("pcType", 2);
          }}
          checked={data.pcType === 2}
          required
        />
        <Form.Check
          type={"radio"}
          value={3}
          name={"pcType"}
          label={"Other youth/young adult"}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("pcType", 3);
          }}
          checked={data.pcType === 3}
          required
        />
        <Form.Check
          type={"radio"}
          value={5}
          name={"pcType"}
          label={"CPA/CCI"}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("pcType", 5);
          }}
          checked={data.pcType === 5}
          required
        />
        <Form.Check
          type={"radio"}
          value={6}
          name={"pcType"}
          label={"Foster Care: Regular Foster Home"}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("pcType", 6);
          }}
          checked={data.pcType === 6}
          required
        />
        <Form.Check
          type={"radio"}
          value={7}
          name={"pcType"}
          label={"Foster Care: Relative Caregiver"}
          onChange={(e) => {
            handleChange(e);
            handleRadioChange("pcType", 7);
          }}
          checked={data.pcType === 7}
          required
        />
      </Form.Group>
      <div className="row py-2">
        <Form.Group className="col-md-6 mb-2" controlId="firstName">
          <Form.Label className="fw-bold">First Name</Form.Label>
          <Form.Control
            type="text"
            className="form-control-sm"
            defaultValue={
              data.caregivers
                ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                    .firstName
                : data.firstName
            }
            onChange={handleChange}
            autoFocus
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 mb-2" controlId="lastName">
          <Form.Label className="fw-bold">Last Name</Form.Label>
          <Form.Control
            type="text"
            className="form-control-sm"
            defaultValue={
              data.caregivers
                ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                    .lastName
                : data.lastName
            }
            onChange={handleChange}
            autoFocus
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 mb-2" controlId="address">
          <Form.Label className="fw-bold">Address</Form.Label>
          <Form.Control
            type="text"
            className="form-control-sm"
            defaultValue={
              data.caregivers
                ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                    .address
                : data.address
            }
            onChange={handleChange}
            autoFocus
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 mb-2" controlId="city">
          <Form.Label className="fw-bold">City</Form.Label>
          <Form.Control
            type="text"
            className="form-control-sm"
            defaultValue={
              data.caregivers
                ? data.caregivers.find((caregiver) => caregiver.isPrimary).city
                : data.city
            }
            onChange={handleChange}
            autoFocus
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 mb-2" controlId="state">
          <Form.Label className="fw-bold">State</Form.Label>
          <Form.Select
            onChange={handleChange}
            required
            value={
              data.caregivers
                ? data.caregivers.find((caregiver) => caregiver.isPrimary).state
                : data.state
            }
          >
            <option value="">Open this select menu</option>
            {states.length > 1 &&
              states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.stateName}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 mb-2" controlId="county">
          <Form.Label className="fw-bold">County</Form.Label>
          {data.state === 11 ||
          (data.caregivers &&
            data.caregivers.find((caregiver) => caregiver.isPrimary).state ==
              11) ? (
            <Form.Select
              onChange={handleChange}
              required
              value={
                data.caregivers
                  ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                      .county
                  : data.county
              }
            >
              <option value="">Open this select menu</option>
              {counties.length > 1 &&
                counties.map((counties) => (
                  <option key={counties.id} value={counties.name}>
                    {counties.name}
                  </option>
                ))}
            </Form.Select>
          ) : (
            <Form.Control
              type="text"
              className="form-control-sm"
              defaultValue={
                data.caregivers
                  ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                      .county
                  : data.county
              }
              onChange={handleChange}
              autoFocus
            />
          )}
        </Form.Group>
      </div>
      <Form.Group className="col-md-6 mb-2" controlId="zip">
        <Form.Label className="fw-bold">Zip</Form.Label>
        <Form.Control
          type="text"
          className="form-control-sm"
          defaultValue={
            data.caregivers
              ? data.caregivers.find((caregiver) => caregiver.isPrimary).zip
              : data.zip
          }
          onChange={handleChange}
          autoFocus
          required
        />
      </Form.Group>

      {data.pcType < 4 && (
        <>
          <div className="row">
            <Form.Group className="col-md-6 mb-2" controlId="dateOfBirth">
              <Form.Label className="fw-bold">Date of Birth</Form.Label>
              <Form.Control
                type="date"
                className="form-control-sm"
                defaultValue={
                  data.caregivers
                    ? Moment(
                        data.caregivers.find((caregiver) => caregiver.isPrimary).dateOfBirth
                      ).format("YYYY-MM-DD")
                      : data.dateOfBirth
                }
                //Moment(data.commenceDate).format("YYYY-MM-DD")
                onChange={handleChange}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="col-md-6 mb-2" controlId="ssn">
              <Form.Label className="fw-bold">
                SSN (last 4 digits) If unknown enter 0000
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                defaultValue={
                  data.caregivers
                    ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                        .ssn
                    : data.ssn
                }
                onChange={handleChange}
                autoFocus
                required
              />
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col-md-6 mb-2" controlId="maritalStatus">
              <Form.Label className="fw-bold">Marital Status</Form.Label>
              <Form.Select
                onChange={handleChange}
                required
                defaultValue={
                  data.caregivers
                    ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                        .maritalStatus
                    : data.maritalStatus
                }
              >
                <option value="">Open this select menu</option>
                {maritalStatusOptions.length > 1 &&
                  maritalStatusOptions.map((option) => (
                    <option key={option.id} value={option.name}>
                      {option.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-6 mb-2" controlId="education">
              <Form.Label className="fw-bold">Education</Form.Label>
              <Form.Select
                onChange={handleChange}
                required
                defaultValue={
                  data.caregivers
                    ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                        .education
                    : data.education
                }
              >
                <option value="">Open this select menu</option>
                {educationOptions.length > 1 &&
                  educationOptions.map((option) => (
                    <option key={option.id} value={option.name}>
                      {option.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </div>
          <Form.Group className="col-md-6 mb-2" controlId="ethnicity">
            <Form.Label className="fw-bold">Ethnicity</Form.Label>
            <Form.Select
              onChange={handleChange}
              required
              defaultValue={
                data.caregivers
                  ? data.caregivers.find((caregiver) => caregiver.isPrimary)
                      .ethnicity
                  : data.ethnicity
              }
            >
              <option value="">Open this select menu</option>
              {ethnicityOptions.length > 1 &&
                ethnicityOptions.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <FormControl required error={incomeSourceHasError}>
            <Form.Label className="fw-bold">
              Income/Sources of Income (select all that apply):
            </Form.Label>
            {incomeSourceHasError && (
              <FormHelperText>
                Please select at least one source of income.
              </FormHelperText>
            )}
            <div className="row">
              {incomeSources.length > 0 &&
                incomeSources.map((option) => (
                  <Form.Group
                    className="col-md-3 mb-2"
                    key={option.id}
                    controlId={option.source}
                    noValidate
                  >
                    <Form.Check
                      type={"checkbox"}
                      //id={option.source}
                      //value={option.id}
                      name={option.id}
                      label={option.source}
                      onChange={handleCheck}
                      checked={
                        (data.caseIncomeSources &&
                          data.caseIncomeSources.length > 0 &&
                          checkIncomeSources(option.id) > -1) ||
                        false
                      }
                      sx={{ color: red }}
                    />
                  </Form.Group>
                ))}
            </div>
          </FormControl>

          <Form.Group className="col-md-6 mb-2" controlId="incomeOptionId">
            <Form.Label className="fw-bold">
              Estimated Income from all sources:
            </Form.Label>
            <Form.Select
              onChange={handleChange2}
              required
              value={data.incomeOptionId ? data.incomeOptionId : 0}
            >
              <option value="">Open this select menu</option>
              {incomeOptions.length > 0 &&
                incomeOptions.length > 1 &&
                incomeOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.option}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </>
      )}

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button type="submit">{"Save & Continue"}</Button>
      </Box>
    </Form>
  );
};

export default CaseFormA;
