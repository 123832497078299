import React, { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import DownloadIcon from "@mui/icons-material/Download";
import CustomDataTable from "../../common/customDataTable";
import {
  deleteCase,
  getCasesByProgramId,
  updateCaseStatusById,
} from "../../../services/casesService";
import { getProgramById } from "../../../services/getProgramService";
import { getAgencyById } from "../../../services/getAgencyService";
import { getReport } from "../../../services/reportService";

const PendingCases = ({ user }) => {
  const [program, setProgram] = useState({});
  const [agency, setAgency] = useState({});
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [toConfirm, setToConfirm] = useState(-1);
  const ProgramId = sessionStorage.getItem("ProgramId");
  const isAdmin = window.location.pathname.includes("admin");

    const [paginationModel, setPaginationModel] = React.useState({
      pageSize: 5,
      page: 0,
    });
    const [sortModel, setSortModel] = React.useState([
      {
        field: "sonId",
        sort: "asc",
      },
    ]);

  // Columns definition for DataTable
  const columns = [
    {
      field: "id",
      headerName: "Case ID",
      flex: 0.3,
      sortable: true,
      renderCell: (params) => (
        <Button variant="link" onClick={() => handleClick(params.value)}>
          {params.value}
        </Button>
      ),
    },
    {
      field: "primaryCaregiverFullName",
      headerName: "Primary Caregiver",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      sortable: true,
    },
    {
      field: "oldestChildFullName",
      headerName: "Oldest Child",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="primary"
            onClick={() => handleShowConfirm(params.row.id)}
            disabled={params.row.status === "Intake Incomplete"}
          >
            Confirm Intake
          </Button>
          <DownloadIcon
            style={{ cursor: "pointer", marginLeft: "10px" }}
            onClick={() => handleDownload(params.row.id)}
          />
          <Button
            variant="danger"
            onClick={() => handleDelete(params.row.id)}
            style={{ marginLeft: "10px" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];


  const handleClick = (id) => {
    window.location = `/${
      isAdmin === true ? "admin/editopencase/" : "provider/currentfy/newcase/"
    }${id}`;
  };

  const handleShowConfirm = (id) => {
    setToConfirm(id);
    setShowConfirm(true);
  };

    const handleCloseConfirm = () => {
      setToConfirm(-1);
      setShowConfirm(false);
    };

  const handleIntakeConfirm = async () => {
    await updateCaseStatusById(toConfirm, "Open");
    setShowConfirm(false);
    fetchData();
  };

  const handleDownload = async (id) => {
    await getReport("NewPssf_PendingIntake", [
      {
        key: "Case_id",
        value: id,
      },
    ]);
  };

  const handleDelete = async (id) => {
    await deleteCase(id);
    fetchData();
  };

const fetchData = useCallback(async () => {
  const { data: program } = await getProgramById(ProgramId);
  setProgram(program);

  const { data: agency } = await getAgencyById(program.agencyId);
  setAgency(agency);

  try {
    const { data: rows } = await getCasesByProgramId(ProgramId);

    // Process rows to find primary caregiver and oldest child
    rows.map((row) => {
      // Find primary caregiver
      const primaryCaregiver = row.caregivers.find(
        (caregiver) => caregiver.isPrimary
      );
      if (primaryCaregiver) {
        row.primaryCaregiverFullName = `${primaryCaregiver.lastName}, ${primaryCaregiver.firstName}`;
      } else {
        row.primaryCaregiverFullName = "";
      }

      // Find oldest child
      if (row.children.length > 0) {
        let oldestChild = row.children[0];
        for (const child of row.children) {
          if (new Date(child.dateOfBirth) < new Date(oldestChild.dateOfBirth)) {
            oldestChild = child;
          }
        }
        row.oldestChildFullName = `${oldestChild.lastName}, ${oldestChild.firstName}`;
    } else {
        row.oldestChildFullName = "";
      }

      return row;
    });

    const filteredRows = rows.filter(
      (row) =>
        row.status === "Intake Incomplete" || row.status === "Intake Pending"
    );

    setCount(filteredRows.length);
    setRows(filteredRows);
  } catch (error) {
    console.error("Error fetching cases:", error);
  }
}, [ProgramId]);


  useEffect(() => {
    fetchData();
  }, [ProgramId, fetchData]);

  return (
    <>
      <h1 className="mt-5 mb-5 text-center">Pending Cases</h1>

      <div className="row">
        <div className="row">
          <div className="col-3 fw-bold">Agency:</div>
          <div className="col-6"> {agency.name}</div>
        </div>
        <div className="row">
          <div className="col-3 fw-bold">Program: </div>
          <div className="col-6"> {program.name}</div>
        </div>
        <div className="col-3 fw-bold">Program ID:</div>
        <div className="col-6 text-start">{ProgramId}</div>
      </div>
      <div className="row">
        <div className="col-3 fw-bold">Number of pending Intakes</div>
        <div className="col-6">{count}</div>
      </div>

      <Box component={Paper} className="py-4" sx={{width: "99%"}}>
        {/* Replace existing Table component with DataTable */}
        <CustomDataTable
          columns={columns}
          rows={rows}
          paginationModel={paginationModel}
          rowHeight={60}
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          pageSizeOptions={[5, 10, 25]}
          onRowClicked={(row) => handleClick(row.id)}
        />
      </Box>

      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Selecting “confirm” will transfer this case to the “Open Cases” list
            and no edits can be made once it is transferred.{" "}
          </p>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Close
          </Button>
          &nbsp;
          <Button variant="success" onClick={handleIntakeConfirm}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PendingCases;
