import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { getExitReasons, getExitStatuses } from '../../../services/casesService';

const ExitFormA = ({ user, data, setData, getData, caseId, handleNext, handleBack }) => {
  const [validated, setValidated] = useState(false);
  const [exitReasons, setExitReasons] = useState([]);
  const [exitStatuses, setExitStatuses] = useState([]);

  const handleChange = (event) => {
    const name = event.target.id;
    const newData = { ...data, [name]: event.target.value };
    setData(newData);
  }

  const handleCheck = (event) => {
    const name = event.target.name;
    const newData = { ...data, [name]: !data[name] };
    setData(newData);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      handleNext();
    }
    setValidated(true);
  }

  const fetchData = async () => {
    const { data: exitReasonsData } = await getExitReasons();
    setExitReasons(exitReasonsData);

    const { data: exitStatusesData } = await getExitStatuses();
    setExitStatuses(exitStatusesData);
  }

  useEffect(() => {
    fetchData();
  }, [data]);

  return ( 
    <>
      <h1>Exit Section A</h1>
      <div>
        <p>Remember to report all services on FSL before completing this exit. Once this case is exited, it will no longer appear in the “Open Cases” list and you will no longer be able to report services on this case.</p>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="row">
          <Form.Group className="col-md-6 mb-2" controlId="dateOfLastContact">
            <Form.Label>Indicate date last service provided (prior to completion of exit)</Form.Label>
            <Form.Control
              required
              type="date"
              className="form-control-sm"
              defaultValue={data.dateOfLastContact ? data.dateOfLastContact.toString().split('T')[0]: ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="col-md-6 mb-2" controlId="exitStatusId">
            <Form.Label>Case Status at Exit</Form.Label>
            <Form.Select onChange={handleChange} required value={data.exitStatusId || ""}>
              <option value="">Open this select menu</option>
              {exitStatuses.length > 1 && exitStatuses.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
            </Form.Select>
          </Form.Group>    
        </div>

        <Form.Group className="row px-2 form-switch mt-4" controlId="servicesComplete">
          <Form.Label>Did the family/individual complete their individual/family service plan (did they complete services)?</Form.Label>
          <div className="row">
            <div className="">
              <div className='d-inline-block me-4'>No</div>
              <div className='d-inline-block'>
                <Form.Check        
                  type={"checkbox"}
                  id="servicesComplete"
                  name="servicesComplete"
                  label=""
                  value={data.servicesComplete || false}
                  checked={data.servicesComplete || false}
                  onChange={handleCheck}
                />
              </div>
              <div className='d-inline-block'>Yes</div>
            </div>
          </div>
        </Form.Group>

        {!data.servicesComplete && 
          <Form.Group className="col-md-6" controlId="exitReasonId">
            <Form.Label>If no, select primary reason for exit</Form.Label>
            <Form.Select onChange={handleChange} required value={data.exitReasonId || ""}>
              <option value="">Open this select menu</option>
              {exitReasons.length > 1 && exitReasons.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        }
    

        <Form.Group className="mt-4" controlId="clientSatisfactionSurvey">
          <Form.Label>Was a PSSF Client Satisfaction Survey given to the primary caregiver to complete?</Form.Label>
          <Form.Check 
            required
            type={"radio"}
            name={"reason"}
            value={"No"}
            label={"No"}
            onChange={handleChange}
            checked={data.clientSatisfactionSurvey === "No"}
          />
          <Form.Check
            required
            type={"radio"}
            name={"reason"}
            value={"Yes"}
            label={"Yes"}
            onChange={handleChange}
            checked={data.clientSatisfactionSurvey === "Yes"}
          />
          <Form.Check
            required
            type={"radio"}
            name={"reason"}
            value={"N/A"}
            label={"N/A"}
            onChange={handleChange}
            checked={data.clientSatisfactionSurvey === "N/A"}
          />
        </Form.Group>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit">
            {"Save & Continue" }
          </Button>
        </Box>
      </Form>
    </>
  );
}
 
export default ExitFormA;