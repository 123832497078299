import React from 'react';

const Textarea = ({ name, label, error, disable=false, ...rest }) => {
  return (
    <>
      <h6 htmlFor={name}>{label}</h6>
      <textarea {...rest} id={name} name={name} style={{width: '100%'}} disabled={disable}/>
      {error && <p className="text-danger">{error}</p>}
    </>
  );
}
 
export default Textarea;