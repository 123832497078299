import http from "./httpService";

const apiEndpoint = "/UserProgram";
const apiEndpointNames = "/Program/programNames";

export function getUserPrograms() {
  return http.get(apiEndpoint);
}

export function getPrograms() {
  return http.get(apiEndpointNames);
}

export function getUserProgramsByIdUrl(username) {
  return `${apiEndpoint}/${username}`;
}

export function getUserProgramsByIdAndFyUrl(username, fy) {
  return `${apiEndpoint}/${username}/${fy}`;
}

export function getProgramsByFiscalYear(id) {
  return http.get(`Program/programByFiscalYear/${id}`);
}

export function postUserProgramsByIdUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getUserProgramsByIdAndFy(id, fy) {
  return http.get(getUserProgramsByIdAndFyUrl(id, fy));
}

export function getUserProgramsById(id) {
  return http.get(getUserProgramsByIdUrl(id));
}

export function postUserProgramsById(username, body) {
  return http.post(postUserProgramsByIdUrl(username), body);
}

const userPrograms = {
  getPrograms,
  getUserProgramsByIdAndFyUrl,
  getUserPrograms,
  getUserProgramsById,
  getUserProgramsByIdAndFy,
  postUserProgramsById,
};

export default userPrograms;