import Axios from "axios";
import http from "./httpService";

export function downloadFile(folder, docName, newFileName = null) {
  Axios({
    url: `/Download/download/${folder}/${docName}`,
    method: "POST",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      let downloadName = docName;
      const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(
            /filename\*=UTF-8''([^;]+)(;|$)/
          );
          if (filenameMatch && filenameMatch.length > 1) {
            downloadName = filenameMatch[1].replace(/['"]/g, "");
          } else {
            // Fallback to filename* if filename is not found
            const filenameStarMatch = contentDisposition.match(
              /filename\*=UTF-8''(.+)/
            );
            if (filenameStarMatch && filenameStarMatch.length > 1) {
              downloadName = decodeURIComponent(filenameStarMatch[1]);
            }
          }
        }
      if (newFileName) {
        const extension = downloadName.split(".").pop();
        downloadName = `${newFileName}.${extension}`;
      }
      link.setAttribute("download", downloadName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error(
        `🚀 ~ file: Error on docName on downloadService.js ~ line 20 ~ Download/download/${folder}/${docName} ~ error`,
        error
      );
    });
}

export async function checkFileExists(folder, docName) {
  const response = await http.get(`/Download/fileExists/${folder}/${docName}`);
  const contentDisposition = response.headers['content-disposition'];
  let filename = docName;
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
    if (filenameMatch) {
      filename = filenameMatch[1];
    }
  }
  return {
    data: response.status === 200,
    filename: filename
  };
}