import http from "./httpService";

const apiEndpoint = "/Program";

const apiSonEndPoint = "/Son";

export function programUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function insertProgramUrl() {
  return `${apiEndpoint}`;
}

export function updateResetProgramByIdUrl(id) {
  return `${apiEndpoint}/reset/${id}`;
}

export function updateProgramByIdUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function programFiscalYearUrl(id, fy) {
  return `${apiEndpoint}/${fy}/${id}`;
}

export function programFiscalYearId(id) {
  return `${apiEndpoint}/programByFiscalYear/${id}`;
}

export function getProgramById(id) {
  return http.get(programUrl(id));
}

export function UpdateProgramByFiscalYear(program) {
  return http.put(`${apiEndpoint}`, program)
}

export function getprogramByFiscalYearWithCounty(id, fy) {
  return http.get(`${apiEndpoint}/GetprogramByFiscalYearWithCounty/${id}/${fy}`)
}

export function GetProgramList(id) {
  return http.get(`${apiEndpoint}/programList/${id}`)
}

//HB: This is distinct from getProgramsByIdandFiscalYear below because this function is using the Id of the ProgramByFiscalYear table to look up the data.
//BC: Overrulled 11/1/2023 with the actual ProgamId and changed on controllers ITDEV-410
export function getProgramByFiscalYearById(id) {
  return http.get(programFiscalYearId(id));
}

export function getSonsIdByProgram(id, fy) {
  return `${apiSonEndPoint}/sonsIdByProgram/${id}/${fy}`;
}

export function getProgramByFiscalYearIdByFYandProgramId(fy, programId) {
  return http.get(`${apiEndpoint}/GetProgramByFiscalYearId/${fy}/${programId}`);
}


export function getServiceModels() {
  return http.get(`${apiEndpoint}/serviceModels`);
}

export function insertPrograms(body) {
  return http.post(insertProgramUrl(), body);
}

export function getProgramsByIdandFiscalYear(id, fy) {
  return http.get(programFiscalYearUrl(id, fy));
}

export function getSonsIdOnly(id, fy) {
  return http.get(getSonsIdByProgram(id, fy));
}

export function getProgramsNamesFiscalYear(fy) {
  return http.get(`${apiEndpoint}/programNames/${fy}`);
}

export function updateResetProgramById(body) {
  return http.post(updateResetProgramByIdUrl(body.id), body);
}

export function updateProgramById(body) {
  return http.post(updateProgramByIdUrl(body.id), body);
}

export function updateProgramsAlertStatus(locked) {
  return http.post(`${apiEndpoint}/alertStatuses/${locked}`);
}

export function updateProgramsAlertStatusesById(id, locked) {
  return http.post(`${apiEndpoint}/alertStatusesById/${id}/${locked}`);
}
