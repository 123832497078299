import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { sendImage } from "../../services/imageService";

const TinyMCE = ({ value, onChange, editorRef, ...props }) => {
  return (
    <Editor
      tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
      onInit={(evt, editor) => {
        // Assign the editor instance to the passed editorRef
        if (editorRef) {
          editorRef.current = editor;
        }
      }}
      init={{
        license_key: "gpl",
        menubar: false,
        selector: "form",
        indent_use_margin: true,
        plugins: [
          "image",
          "link",
          "lists",
          "media",
          "table",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "preview",
          "help",
          "wordcount",
        ],
        toolbar:
          "blocks | forecolor bold italic underline link removeformat | bullist numlist | outdent indent | image blockquote table media | undo redo",
        content_style:
          "body { font-family: Helvetica, Arial, sans-serif; font-size:14px }",
        images_upload_url: `${process.env.REACT_APP_API_URL}/Image/upload`,
        images_upload_handler: (blobInfo) =>
          new Promise((success, failure) => {
            // Convert blob to FormData
            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());
            // Check file size before uploading
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            if (blobInfo.blob().size > maxSize) {
              failure("File size exceeds the limit (2MB)");
              return;
            }
            sendImage(formData)
              .then((response) => {
                // Check if the response contains the URL of the uploaded image
                if (response && response.data) {
                  // Call the success callback with the URL
                  success(response.data);
                } else {
                  // If the response doesn't contain the URL, call the failure callback
                  failure("Image upload failed: No URL returned");
                }
              })
              .catch((error) => {
                // Call the failure callback with the error message
                failure("Image upload failed: " + error.message);
              });
          }),
      }}
      value={value}
      onEditorChange={onChange}
      {...props}
    />
  );
};

export default TinyMCE;
