import React, { Component } from "react";
import { Link } from "react-router-dom";
import ColoredLine from "../common/coloredLine";
import ContentCard from "../common/contentCard";

class PssfYear extends Component {
  onTabChange = (e, ElementId) => {
    let i, x, tablinks;
    const tab = document.getElementById(ElementId);

    x = document.getElementsByClassName("category");
    for (i = 0; i < x.length; i++) {
      x[i].classList.add("d-none");
    }

    tab.classList.remove("d-none");

    tablinks = document.getElementsByClassName("tab-link");
    for (i = 0; i < x.length; i++) {
      tablinks[i].classList.remove("tabbeddiv-custom-blue");
      tablinks[i].classList.remove("tabbeddiv-custom");
    }
    document.getElementById("tab" + e).classList.add("tabbeddiv-custom-blue");
  };
  render() {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="row col mt-5 mb-5">
              <div className="row">
                <h3 align="center">Making a Difference in Georgia</h3>
              </div>
              <div className="row">
                <div className="col">
                  An important element of Georgia's child welfare program is the
                  development of a strengths-based, prevention-driven community
                  response to vulnerable children and families. Each year, Georgia's
                  Division of Family and Children Services issues a "Statement of
                  Need" to seek proposals from non-profit organizations and public
                  entities to provide coordinated community-based programs and
                  services.
                  <br />
                  <br />
                  <span>
                    A statewide needs assessment is conducted annually to identify
                    family and community service needs through:
                  </span>
                  <br />
                  <br />
                  <ul>
                    <li>
                      Consultation with Division leadership, unit, and program
                      managers.
                    </li>
                    <li>
                      An online survey for regional and county directors,
                      administrators, supervisors, and case managers.
                    </li>
                    <li>
                      Input from service providers and community stakeholders
                      representing all PSSF program areas and service models.
                    </li>
                  </ul>
                  The annual Statement of Need (SoN) incorporates feedback from all
                  sources in response to changing family needs, community resources,
                  and state and federal priorities.
                  <br />
                  <br />
                  For more information about becoming a PSSF network service provider
                  visit{" "}
                  <Link
                    to="/funding"
                    rel="noopener noreferrer"
                    className="text-warning"
                  >
                    Funding Opportunities {">>"}
                  </Link>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4 mb-2 mt-2">
                <ContentCard
                  header={"FFY2023 Network Service Providers"}
                  body={
                    "141 programs were selected from more than 180 proposals submitted."
                  }
                  link={"See a full listing of programs"}
                  linkto={"/providers"}
                />
              </div>
              <div className="col-md-4 mb-2 mt-2">
                <ContentCard
                  header={"Resource Guide"}
                  body={
                    "Find services or a network service provider in your community."
                  }
                  link={"Search for local resources"}
                  linkto={"/guide"}
                />
              </div>
              <div className="col-md-4 mb-2 mt-2">
                <ContentCard
                  header={"New in FFY2023"}
                  body={
                    "19 new programs were added to the PSSF Network this fiscal year."
                  }
                  link={"Get more information"}
                  linkto={"/providersnew"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col mt-5">
                <img
                  src={require("../../img/2021 PSSF Network Infographic 032222-part-2.png")}
                  style={{ width: "100%" }}
                  alt="PSSF Network Infographic 032222-part-2"
                />
              </div>
            </div>
            <div className="row mt-4">
              <strong>
                <h2>Meeting the Unique Needs of Georgia's Families</h2>
              </strong>
              <p>
                In Georgia, service models in each of the four PSSF program
                categories are designed to reinforce and support the priorities
                identified in the state's five-year Child & Family Services Plan.
                Service plans must include evidence-based programs, practices and/or
                strategies proven effective in meeting the needs of children and
                their families.
              </p>
            </div>
            <nav
              className="navbar navbar-expand-lg  tabbedNavbar-custom"
              style={{ padding: 0 }}
            >
              <div className="collapse navbar-collapse">
                <div
                  className="navbar-nav nav-fill w-100"
                  style={{ height: "60px" }}
                >
                  <div
                    id="tab1"
                    className="nav-item nav-link tab-link border-end border-white col-3 tabbeddiv-custom-blue"
                    onClick={() => this.onTabChange(1, "support")}
                  >
                    Family Support Service Models
                  </div>
                  <div
                    id="tab2"
                    className="nav-item nav-link tab-link border-end border-white col-3 tabbeddiv-custom"
                    onClick={() => this.onTabChange(2, "preservation")}
                  >
                    Family Preservation Service Models
                  </div>
                  <div
                    id="tab3"
                    className="nav-item nav-link tab-link border-end border-white col-3 tabbeddiv-custom"
                    onClick={() => this.onTabChange(3, "reunification")}
                  >
                    Family Reunification Models
                  </div>
                  <div
                    id="tab4"
                    className="nav-item nav-link tab-link col-3 tabbeddiv-custom"
                    onClick={() => this.onTabChange(4, "permanency")}
                  >
                    Adoption Promotion and Post-Permanency Support Models
                  </div>
                </div>
              </div>
            </nav>
            <div
              id="support"
              className="card category p-2"
              style={{ backgroundColor: "#ede7de" }}
            >
              <h6>Prevention & Early Intervention (FSS/PEI):</h6>
              <p>
                {" "}
                Voluntary, in-home or center-based supports and services to help
                families identify and address family issues that threaten child
                safety, strengthen family protective capacity, reducing the risk of
                CPS intervention.{" "}
                <Link to="/pei" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
              <h6>Home Visiting (FSS/HVS):</h6>{" "}
              <p>
                Voluntary, evidence-based, in-home services that support positive
                parent-child relationships, child health and development, parental
                self-sufficiency, and safe home environments to prevent child abuse
                and neglect.{" "}
                <Link to="/hvs" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
              <h6>Healthy Relationship & Co-Parenting (FSS/HMI):</h6>
              <p>
                Services designed to build and maintain healthy partnerships,
                identify and manage stress that threatens relationships, and promote
                and support life-long parental or co-parenting relationships.{" "}
                <Link to="/hmi" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
              <h6>Supports & Services for Homeless Youth (FSS/SHY):</h6>
              <p>
                {" "}
                Services and supports to help unaccompanied homeless youth or
                victims of sexual exploitation transition to independent living and
                become self-sufficient through community involvement and
                relationships, education, employment, health and safety{" "}
                <Link to="/shy" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
            </div>
            <div
              id="preservation"
              className="card category p-2 d-none"
              style={{ backgroundColor: "#ede7de" }}
            >
              <h6>Placement Prevention (FPS/PPS):</h6>{" "}
              <p>
                Short-term services and supports to address caregiver
                characteristics or child behavior to ensure child safety and reduce
                the risk of child removal from the home and placement in foster
                care.{" "}
                <Link to="/pps" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
              <h6>Crisis Intervention (FPS/CIS):</h6>
              <p>
                Preserve families, ensure child safety and prevent repeat
                maltreatment.
                <Link to="/cis" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>{" "}
              </p>
              <h6>Relative Caregiver/Kinship Family (FPS/RCS):</h6>
              <p>
                Services for grandparents and relative caregivers who are primary
                caregivers of children other than their own to address caregiver
                capacity, family functioning, child well-being and placement
                stability.{" "}
                <Link to="/rcs" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
              <h6>Residential/Post-Placement Aftercare (FPS/RAC) :</h6>{" "}
              <p>
                Therapeutic services, available 2-3 months pre-discharge and 6-9
                months post-discharge, to support the reintegration of children into
                their homes and communities and/or to sustain treatment outcomes to
                prevent placement disruption{" "}
                <Link to="/rac" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
              <h6>Substance Abuse TX & Recovery Support (FPS/STR) :</h6>
              <p>
                Services to prevent abandonment, maltreatment or child removal due
                to caregiver substance abuse, and/or to support reunification and
                prevent relapse.{" "}
                <Link to="/str" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
            </div>
            <div
              id="reunification"
              className="card category p-2 d-none"
              style={{ backgroundColor: "#ede7de" }}
            >
              <h6>Supervised Family Visitation (TLR/SFV):</h6>
              <p>
                Services to increase the frequency, quality and consistency of the
                interactions of children in foster care with their parents, their
                siblings in different placements, or to visit with extended family
                members or other significant adults in less restrictive but secure,
                non-threatening environments.{" "}
                <Link to="/sfv" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
              <h6>Child and Family Advocacy (TLR/CFA):</h6>
              <p>
                Services and supports for children involved in dependency
                proceedings to advocate for timely permanency decisions that are in
                the best interest of the child. Services ensure that the needs of
                children are met and families receive needed supports so that
                children who must be removed from their home maintain connections to
                their families and communities.{" "}
                <Link to="/cfa" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
    
              <h6>Parent Reunification (TLR/PRS):</h6>
              <p>
                Services to assist caregivers in their efforts to address behaviors
                that resulted in the placement of their children in foster care,
                satisfy the conditions set forth in their case plans, and help
                prepare for the return of children to the home.{" "}
                <Link to="/prs" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
            </div>
            <div
              id="permanency"
              className="card category p-2 d-none"
              style={{ backgroundColor: "#ede7de" }}
            >
              <h6>Adoption Promotion (APP/APS):</h6>
              <p>
                Services to encourage and support adoption or relative guardianship
                and/or to prevent disruption/dissolution of adoptions.{" "}
                <Link to="/aps" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
              <h6>Transition & Emancipation (APP/TES):</h6>
              <p>
                Services to help youth transitioning, or who have transitioned, out
                of foster care develop skills for independent living and establish
                meaningful adult connections.{" "}
                <Link to="/tes" rel="noopener noreferrer" className="text-warning">
                  More Info {">>"}
                </Link>
              </p>
            </div>
            <ColoredLine color="#779442" />
          </div>
        </div>
      </>
    );
  }
}

export default PssfYear;
