import React from 'react';
import { Route, Routes } from "react-router-dom";
import PSSFGA from "../components/content/pssfga"
import PSSFYEAR from "../components/content/pssfyear";
import GUIDE from "../components/content/guide";
import FUNDING from "../components/content/funding";
import LINKS from "../components/content/links";
import CONTACT from "../components/content/contact";
import HOME from "../components/content/home";
import PROVIDERS from "../components/content/providers";
import PROVIDERSNEW from "../components/content/providersnew";
import FEATURED from "../components/content/featured";
import Hmi from "../components/content/hmi";
import Hvs from "../components/content/hvs";
import Shy from "../components/content/shy";
import Pei from "../components/content/pei";
import Pps from "../components/content/pps";
import Cis from "../components/content/cis";
import Rcs from "../components/content/rcs";
import Rac from "../components/content/rac";
import Str from "../components/content/str";
import Sfv from "../components/content/sfv";
import Cfa from "../components/content/cfa";
import Prs from "../components/content/prs";
import Aps from "../components/content/aps";
import Tes from "../components/content/tes";
import GuideResults from '../components/content/guideresults';
import GuideDetails from '../components/content/guidedetails';

const ContentRoute = () => {
    return (
      <Routes>
        <Route path="/pssfga" element={<PSSFGA />} />
        <Route path="/providers" element={<PROVIDERS />} />
        <Route path="/providersnew" element={<PROVIDERSNEW />} />
        <Route path="/pssfyear" element={<PSSFYEAR />} />
        <Route path="/guide/results" element={<GuideResults />} />
        <Route path="/guide/details" element={<GuideDetails />} />
        <Route path="/guide" element={<GUIDE />} />
        <Route path="/links" element={<LINKS />} />
        <Route path="/funding" element={<FUNDING />} />
        <Route path="/featured" element={<FEATURED />} />
        <Route path="/contact" element={<CONTACT />} />
        <Route path="/hmi" element={<Hmi />} />
        <Route path="/hvs" element={<Hvs />} />
        <Route path="/shy" element={<Shy />} />
        <Route path="/pei" element={<Pei />} />
        <Route path="/cis" element={<Cis />} />
        <Route path="/pps" element={<Pps />} />
        <Route path="/rcs" element={<Rcs />} />
        <Route path="/rac" element={<Rac />} />
        <Route path="/str" element={<Str />} />
        <Route path="/sfv" element={<Sfv />} />
        <Route path="/cfa" element={<Cfa />} />
        <Route path="/prs" element={<Prs />} />
        <Route path="/aps" element={<Aps />} />
        <Route path="/tes" element={<Tes />} />
        <Route path="/" exact element={<HOME />} />

        {/* <Route path='/calendar' element={<Calendar />} />
				<Route path='/newintake' element={<NewIntake />} />
				<Route path='/newexit' element={<NewExit />} />
				<Route path='/opencases' element={<OpenCases />} />
				<Route path='/archivedcases' element={<ArchivedCases />} />
				<Route path='/viewdocuments' element={<ViewDocuments />} />
				<Route path='/uploaddocuments' element={<UploadDocuments />} />
				<Route path='/programmaticreports' element={<ProgrammaticReports />} />
				<Route path='/summaryreports' element={<SummaryReports />} />
				<Route path='/services' element={<Services />} />
				<Route path='/staffqualifications' element={<StaffQualifications />} />
				<Route path='/events' element={<Events />} />
				<Route path='/users' element={<Users />} />
				<Route path='/awarddashboard' element={<AwardDashboard />} />
				<Route path='/awardinformation' element={<AwardLetter />} />
				<Route path='/contractprep' element={<ContractPrep />} />
				<Route path='/uploaddocsnextFY' element={<UploadDocs />} />
				<Route path='/resourceguide' element={<ResourceGuide />} />
				<Route path='/contractorsmeetingreginfo' element={<ContractorsMeetingRegInfo />} />
				<Route path='/traininginfo' element={<TrainingInfo />} />
				<Route path='/currentcontract' element={<CurrentContract />} /> */}
      </Routes>
    );
}
 
export default ContentRoute;