import http from "./httpService";

const apiEndpoint = "/RegionalAccountingDirectors";

export function getRegionalAccountingDirectors() {
  return http.get(apiEndpoint);
}

export function getRegionalAccountingDirectorsByIdUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getRegionalAccountingDirectorsById(id) {
  return http.get(getRegionalAccountingDirectorsByIdUrl(id));
}

export function deleteRegionalAccountingDirectorsById(id) {
  return http.delete(getRegionalAccountingDirectorsByIdUrl(id));
}

export function saveRegionalAccountingDirectors(director) {
  if (director.id) {
    const body = { ...director };
    delete body.id;
    return http.post(getRegionalAccountingDirectorsByIdUrl(director.id), body);
  }
}
export function createRegionalAccountingDirectors(director) {
  const body = { ...director };
  return http.post(apiEndpoint, body);
}
