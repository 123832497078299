import React, { useState } from "react";
import { useEffect } from "react";
import { getReportingPeriods } from "../../services/reportingPeriodsService";
import Form from "react-bootstrap/Form";

const MonthSearch = ({
  data = {},
  handleChange,
  useQuarterly = false,
  useProgramId = false,
  useReportingPeriodId = false,
  useRegion = false,
  useRportType = false,
  useFiscalYear = true,
  lockCurrentReport = false
}) => {
  const [reportingPeriods, setReportingPeriods] = useState([]);

  const LocalFiscalYear = sessionStorage.getItem("LocalFiscalYear");

  const periodId = sessionStorage.getItem("periodId");

  useEffect(() => {
    const fetchReportingPeriods = async () => {
      try {
        const { data } = await getReportingPeriods();
        setReportingPeriods(data);
      } catch (error) {}
    };

    fetchReportingPeriods();
  }, []);

  return (
    <div className="row">
      {useQuarterly && (
        <div className="col-lg-3 mb-4">
          <Form.Group controlId="quarter">
            <Form.Label className="fw-bold">Quarterly:</Form.Label>
            <Form.Select
              defaultValue={
                data.reportingPeriodId ? data.reportingPeriodId : periodId
              }
              onChange={handleChange}
            >
              <option>Open this select menu</option>
              <option value={4}>QTR1 (October, November, December)</option>
              <option value={1}>QTR2 (January, February, March)</option>
              <option value={2}>QTR3 (April, May, June)</option>
              <option value={3}>QTR4 (July, August, September)</option>
            </Form.Select>
          </Form.Group>
        </div>
      )}

      {useFiscalYear && (
        <div className="col-lg-3 mb-4">
          <Form.Group controlId="fiscalYear">
            <Form.Label className="fw-bold">Fiscal Year</Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              value={data.fiscalYear ? data.fiscalYear : LocalFiscalYear}
              onChange={handleChange}
            />
          </Form.Group>
        </div>
      )}
      {useReportingPeriodId && (
        <div className="col-lg-3">
          <Form.Group controlId="reportingPeriodId">
            <Form.Label className="fw-bold">Month</Form.Label>
            <Form.Select
              onChange={handleChange}
              value={
                data.reportingPeriodId !== null
                  ? data.reportingPeriodId
                  : periodId || ""
              }
            >
              <option>Open this select menu</option>
              {reportingPeriods
                .filter(
                  (option) =>
                    parseInt(option.fiscalYear) ===
                    parseInt(
                      data?.fiscalYear ? data.fiscalYear : LocalFiscalYear
                    ) &&
                    !(lockCurrentReport && option.id === parseInt(periodId))
                )
                .map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.id + ") "} {option.month}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </div>
      )}
      {useProgramId && (
        <div className="col-lg-3">
          <Form.Group controlId="programId">
            <Form.Label className="fw-bold">Program Id:</Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              value={data.programId !== undefined ? data.programId : 0}
              onChange={handleChange}
            />
          </Form.Group>
        </div>
      )}
      {useRegion && (
        <div className="col-lg-3">
          <Form.Group controlId="region">
            <Form.Label>Region:</Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              value={data.region || 0}
              onChange={handleChange}
            />
          </Form.Group>
        </div>
      )}
      {useRportType && (
        <div className="col-lg-3">
          <Form.Group controlId="Type">
            <Form.Label>Report Type:</Form.Label>
            <Form.Select onChange={handleChange} value={data.Type}>
              <option key={"PDF"} value={"PDF"}>
                PDF
              </option>
              <option key={"excel"} value={"excel"}>
                Excel
              </option>
            </Form.Select>
          </Form.Group>
        </div>
      )}
    </div>
  );
};

export default MonthSearch;
