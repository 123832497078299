import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { sendEmailBlast } from "../../services/emailService";
import { saveEmailBlast } from "../../services/emailBlastService";
import { getAllUsers } from "../../services/providerUserManagement";
import { getRegionalDirectors } from "../../services/regionalDirectors";
import { getRegionalAccountingDirectors } from "../../services/regionalAccountingDirectorService";
import { getCountyDirectors } from "../../services/countyDirectors";
import { getUserContactsByRole } from "../../services/userService";
import { DataGrid } from "@mui/x-data-grid";

import { apifetchEmailBlastSentList } from "../../services/emailBlastService";

import EblastForm from "../forms/eblastForm";
import EblastPreview from "../content/eblastPreview";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReactModal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CancelIcon from "@mui/icons-material/Cancel";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

import TinyMCE from "../admin/tinyMCE";

import auth from "../../services/authService";
import Moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EblastProvider = () => {
  const defaultData = {
    audience: `0`,
    Name: ``,
    to: "",
    bcc: "",
    cc: "",
    subject: ``,
    test: `1`,
    body: ``,
    From: "communications@pssfnet.com",
    Type: `0`,
  };

  const defaultErrorData = {
    toError: 0,
    subjectError: 0,
    bodyError: 0,
  };

  const editorRef = useRef(null);

  const [blastTo, setBlastTo] = useState([]);
  const [blastSubject, setBlastSubject] = useState([]);
  const [blastBody, setBlastBody] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [emailListSavedConfig, setEmailListSavedConfig] = useState("");
  const [emailListSentConfig, setEmailListSentConfig] = useState("");
  const [archiveBtnConfig, setArchiveBtnConfig] = useState(true);
  const [saveToBtnConfig, setSaveToBtnConfig] = useState(false);

  //  Setting Email State
  const [data, setData] = useState(defaultData);

  const [savedEmailRows, setSavedEmailRows] = useState([]);
  const [sentEmailRows, setSentEmailRows] = useState([]);

  const [testStatus, setTestStatus] = useState("1");

  //  Setting Errors For Form Validation
  const [errorForm, setErrorForm] = React.useState(defaultErrorData);

  const [
    selectedSpecificProviderSelectedRows,
    setSpecificProviderSelectedRows,
  ] = useState([]);

  const [showSpecificProviderModal, setShowSpecificProviderModal] =
    useState(false);
  const [
    showRegionalContactsBySpecificRegionModal,
    setRegionalContactsBySpecificRegionModal,
  ] = useState(false);
  const [
    showAllRegionalContactsBySpecificCountyModal,
    setAllRegionalContactsBySpecificCountyModal,
  ] = useState(false);
  const [showAllCountyContactsModal, setShowAllCountyContactsModal] =
    useState(false);
  const [showAllSonContactsModal, setShowAllSonContactsModal] = useState(false);

  const [allSpecificProverderRows, setAllSpecificProverderRows] = useState([]);
  const [
    regionalAcctingContactsFromSpecificRegion,
    setRegionalAcctingDirctrsContsFrmSpcRegion,
  ] = useState([]);
  const [
    regionalContactsFromSpecificRegion,
    setRegionalDirctrsContsFrmSpcRegion,
  ] = useState([]);
  const [
    allRegionalContactsFromSpecificCounty,
    setAllRegionalContactsBySpecificRegion,
  ] = useState([]);
  const [allCountyContacts, setAllCountyContacts] = useState([]);
  const [allSonContacts, setAllSonContacts] = useState([]);

  const [LocalFiscalYear] = useState(sessionStorage.getItem("LocalFiscalYear"));

  const [tabValue, setTabValue] = React.useState("1");

  const handleResetChildConfigs = () => {
    setDisableSubmit(true);
    setEmailListSavedConfig("");
    setEmailListSentConfig("");
    setArchiveBtnConfig(true);
    setSaveToBtnConfig();
  };

  async function resetForm() {
    setDisableSubmit(true);
    setData(defaultData);
    setErrorForm(defaultErrorData);
    setBlastTo([]);
    setBlastSubject([]);
    setBlastBody("");
    setSentEmail(false);
    handleResetChildConfigs();
    editorRef.current.setContent("");
  }

  const handleBlastToChange = (newBlastTo) => {
    data[`to`] = newBlastTo;
    data["subject"] = blastSubject;

    setBlastTo(newBlastTo);
  };

  const updateSavedEmailRows = (newRows) => {
    setSavedEmailRows(newRows);
  };

  const columnsProviders = [
    {
      field: "name",
      headerName: "Name",
      description: "Name Of Email...",
      flex: 1,
      sortable: true,
      width: 120,
    },
    {
      field: "email",
      headerName: "Email",
      description: "Available Email Address...",
      flex: 1,
      sortable: true,
      minWidth: 80,
    },
  ];

  const columnsDirectors = [
    {
      field: "id",
      headerName: "Id",
      description: "Record Id",
      flex: 1,
      sortable: true,
      maxWidth: 30,
    },
    {
      field: "organizationName",
      headerName: "Organization Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      description: "First Name...",
      flex: 1,
      sortable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      description: "Last Name...",
      flex: 1,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      description: "Available Email Address...",
      flex: 1,
      sortable: true,
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      sortable: true,
    },
  ];

  const columnsAccountingDirectors = [
    {
      field: "id",
      headerName: "Id",
      description: "Record Id",
      flex: 1,
      sortable: true,
    },
    { field: "name", headerName: "Name", flex: 1, sortable: true },
    {
      field: "email",
      headerName: "Email",
      description: "Available Email Address...",
      flex: 1,
      sortable: true,
    },
    { field: "region", headerName: "Region", flex: 1, sortable: true },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <div className="text-capitalize">{params.field}</div>
      ),
    },
  ];

  const columnsCountyDirectors = [
    {
      field: "id",
      headerName: "Id",
      description: "Record Id",
      flex: 1,
      sortable: true,
    },
    { field: "directorName", headerName: "Name", flex: 1, sortable: true },
    {
      field: "email",
      headerName: "Email",
      description: "Available Email Address...",
      flex: 1,
      sortable: true,
    },
    {
      field: "County",
      headerName: "County",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <div className="text-capitalize">{params.row.county.name}</div>
      ),
    },
  ];

  const columnsAllCountyContacts = [
    {
      field: "id",
      headerName: "Id",
      description: "Record Id",
      flex: 1,
      sortable: true,
    },
    { field: "directorName", headerName: "Name", flex: 1, sortable: true },
    {
      field: "email",
      headerName: "Email",
      description: "Available Email Address...",
      flex: 1,
      sortable: true,
    },
    {
      field: "County",
      headerName: "County",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <div className="text-capitalize">{params.row.county.name}</div>
      ),
    },
  ];

  const columnsAllSonContacts = [
    {
      field: "id",
      headerName: "Id",
      description: "Record Id",
      flex: 1,
      sortable: true,
    },
    { field: "userName", headerName: "Name", flex: 1, sortable: true },
    {
      field: "email",
      headerName: "Email",
      description: "Available Email Address...",
      flex: 1,
      sortable: true,
    },
    { field: "role", headerName: "Role", flex: 1, sortable: true },
  ];

  const handleChange = (event) => {
    const name = event.target.name;
    const newData = { ...data };

    newData[name] = event.target.value;

    if (event.target.name === "test") {
      setTestStatus(event.target.value);
    }

    setData(newData);

    // Call validateErrors and update the errorForm state
    validateErrors();

    setSentEmail(false);
  };

  const handleSubjectChange = (event) => {
    setBlastSubject(event.target.value);

    handleChange(event); // Forward the event to the parent component
    const newErrorForm = { ...errorForm };
    newErrorForm["subjectError"] = 0;
    setErrorForm(newErrorForm);
  };

  const handleSelectChange = async (event) => {
    const name = event.target.name;
    const thevalue = event.target.value;

    data[name] = thevalue;
    data[`to`] = blastTo;

    const updatedValue = {};
    updatedValue[name] = event.target.value;
    updatedValue[`to`] = blastTo;

    setData(updatedValue);

    const newErrorForm = { ...errorForm };
    newErrorForm["toError"] = 0;
    setErrorForm(newErrorForm);

    // This is gonna trigger the event of which function to call depending on the value
    switch (thevalue) {
      case `1`:
        // Call the function for All Providers
        await fetcthAllProviders();
        break;
      case `2`:
        // Call the function for Specific Provider
        await fetcthSpecificProvider(); // Replace with actual user data
        break;
      case `3`:
        // Call the function for Regional Contacts From Specific Region
        await fetcthregionalAcctingContactsFromSpecificRegion(); // Replace with actual region data
        break;
      case `4`:
        // Call the function for All Regional Contacts From Specific County
        await fetcthRegionalContactsFromSpecificCounty(); // Replace with actual county data
        break;
      case `5`:
        // Call the function for All County Contacts
        await fetcthAllCountyContacts(); // Replace with actual county data
        break;
      case `6`:
        // Call the function for All {LocalFiscalYear} Son Contacts
        await fetcthAllSonContacts(); // Replace with actual county data
        break;
      default:
        // Handle other cases or do nothing
        break;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSpecificProviderClose = () => {
    setShowSpecificProviderModal(false);
  };

  const handleRegionalContactsBySpecificRegionClose = () => {
    setRegionalContactsBySpecificRegionModal(false);
  };

  const handleAllRegionalContactsBySpecificCountyClose = () => {
    setAllRegionalContactsBySpecificCountyModal(false);
  };

  const handleAllCountyContactsClose = () => {
    setShowAllCountyContactsModal(false);
  };

  const handleAllSonContactsClose = () => {
    setShowAllSonContactsModal(false);
  };

  async function fetcthAllProviders() {
    var { data: users } = await getAllUsers();

    const usersEmails = users
      .map((user) =>
        user?.email
          ? //`<${user?.name}> ${user?.userName}`
            `${user?.email}`
          : null
      )
      .join(", ");

    data["audience"] = 1;
    data[`to`] = usersEmails;
    var newData = { ...data };

    setBlastTo(usersEmails);

    setTimeout(() => setData(newData), 0);
  }

  async function fetcthSpecificProvider(id, user) {
    setShowSpecificProviderModal(true);

    var { data: users } = await getAllUsers();

    const usersRows = users.map((user) => user);

    setAllSpecificProverderRows(usersRows);
  }

  async function fetcthregionalAcctingContactsFromSpecificRegion(region) {
    setRegionalContactsBySpecificRegionModal(true);

    var { data: directors } = await getRegionalDirectors();

    setRegionalDirctrsContsFrmSpcRegion(directors);

    var { data: acctdirectors } = await getRegionalAccountingDirectors();

    setRegionalAcctingDirctrsContsFrmSpcRegion(acctdirectors);
  }

  async function fetcthRegionalContactsFromSpecificCounty(id, region) {
    setAllRegionalContactsBySpecificCountyModal(true);

    var { data: countyDirectors } = await getCountyDirectors();

    setAllRegionalContactsBySpecificRegion(countyDirectors);
  }

  async function fetcthAllCountyContacts(county) {
    setShowAllCountyContactsModal(true);

    var { data: allCountyContacts } = await getCountyDirectors();

    setAllCountyContacts(allCountyContacts);
  }

  async function fetcthAllSonContacts(county) {
    setShowAllSonContactsModal(true);

    var { data: fetchedSonContact } = await getUserContactsByRole(4);

    setAllSonContacts(fetchedSonContact);
  }

  async function validateErrors() {
    const newErrorForm = { ...errorForm };

    const subjectLenth = blastSubject[0]?.length;

    const bodyLength = data?.body?.length;

    const toEmailLength = blastTo[0]?.length;
    if (fieldEdited["to"]) {
      if (toEmailLength > 0 || toEmailLength !== undefined) {
        newErrorForm["toError"] = 0;
      } else {
        newErrorForm["toError"] = 1;
      }
    }

    if (fieldEdited["subject"]) {
      if (subjectLenth > 0) {
        newErrorForm["subjectError"] = 0;
      } else {
        newErrorForm["subjectError"] = 1;
      }
    }
    if (fieldEdited["body"]) {
      if (blastBody.length > 0) {
        newErrorForm["bodyError"] = 0;
      } else {
        newErrorForm["bodyError"] = 1;
      }
    }

    if (toEmailLength > 0 && subjectLenth > 0 && bodyLength > 0) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }

    setErrorForm(newErrorForm);
  }

  async function refetchSentEmails() {
    try {
      const { data: sentEmails } = await apifetchEmailBlastSentList();
      setSentEmailRows(sentEmails);
      setSentEmail(true);
    } catch (error) {
      console.error("Error fetching sent email list:", error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.getCurrentUser();
    const date = Moment(new Date()).format("M/D/YY hh:mm:ss A");
    var Name = `${blastSubject} ${date} created by: ${user.name}`;
    if (testStatus === "1") {
      Name = `<TEST> ${Name}`;
    }

    const emailData = {
      ...data,
      Name: Name,
      test: testStatus,
      From: "communications@pssfnet.com",
      to: blastTo,
      subject: blastSubject, // Include blastSubject in the data
      body: blastBody,
    };

    await sendEmailBlast(emailData);
    await saveEmailBlast(emailData);
    setData(defaultData);
    resetForm();
    setSentEmail(true);

    setTimeout(async () => {
      await refetchSentEmails();
    }, 1000);
  };

  const [fieldEdited, setFieldEdited] = useState({
    to: false,
    subject: false,
    body: false,
  });

  const handleFieldChange = (field) => {
    setFieldEdited((prev) => ({ ...prev, [field]: true }));
  };

  useEffect(() => {
    // Apply styles to the body element
    document.body.style.overflowY = "unset";
    // Override overflow-y property// You can add more styles as needed// Cleanup function
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div className="p-2 mt-5">
      <h1 className="ms-1 px-3">Eblast Providers</h1>

      <div className="ms-2 mb-3">
        <Button variant="outlined" color="error" onClick={resetForm}>
          Clear Email Blast
        </Button>
      </div>

      <Stack className="ms-2 mb-3" sx={{ width: "100%" }} spacing={2}>
        {errorForm.toError === 1 && (
          <Alert severity="warning">
            <strong>Attention:</strong> Recipients are needed before the email
            can be sent. Including the subject and body fields is also required.
          </Alert>
        )}
      </Stack>

      <Stack className="ms-2 mb-3" sx={{ width: "100%" }} spacing={2}>
        {errorForm.subjectError === 1 && (
          <Alert severity="error">
            <strong>Error:</strong> Subject line content is too short!
          </Alert>
        )}
      </Stack>

      <Stack className="ms-2 mb-3" sx={{ width: "100%" }} spacing={2}>
        {errorForm.bodyError === 1 && (
          <Alert severity="error">
            <strong>Error:</strong> Body content is too short!
          </Alert>
        )}
      </Stack>
      {errorForm.subjectError === 0 &&
      errorForm.toError === 0 &&
      errorForm.bodyError === 0 &&
      sentEmail === true ? (
        <>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">Email Sent</Alert>
          </Stack>
        </>
      ) : null}

      <form id="form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-xl-6">
            <EblastForm
              defaultData={defaultData}
              data={data}
              blastSubject={blastSubject}
              handleSelectChange={handleSelectChange}
              handleChange={handleChange}
              handleSubjectChange={handleSubjectChange}
              errorForm={errorForm}
              setErrorForm={setErrorForm}
              handleFieldChange={handleFieldChange}
            />
          </div>

          <div className="col-sm-12 col-md-12 col-xl-6">
            <EblastPreview
              defaultData={defaultData}
              data={data}
              blastTo={blastTo}
              setData={setData}
              setDisableSubmit={setDisableSubmit}
              errorForm={errorForm}
              setErrorForm={setErrorForm}
              setBlastTo={handleBlastToChange}
              setBlastSubject={setBlastSubject}
              blastSubject={blastSubject}
              blastBody={blastBody}
              setBlastBody={setBlastBody}
              editorRef={editorRef}
              emailListSavedConfig={emailListSavedConfig}
              emailListSentConfig={emailListSentConfig}
              setEmailListSavedConfig={setEmailListSavedConfig}
              setEmailListSentConfig={setEmailListSentConfig}
              archiveBtnConfig={archiveBtnConfig}
              setArchiveBtnConfig={setArchiveBtnConfig}
              saveToBtnConfig={saveToBtnConfig}
              setSaveToBtnConfig={setSaveToBtnConfig}
              validateErrors={validateErrors}
              setSentEmailRows={setSentEmailRows}
              setSavedEmailRows={updateSavedEmailRows}
              savedEmailRows={savedEmailRows}
              sentEmailRows={sentEmailRows}
              setTestStatus={setTestStatus}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              style={{
                minHeight: "400px",
                minWidth: "600px",
              }}
              className="px-3 mt-sm-5 mt-md-0 mt-xl-0"
            >
              <TinyMCE
                value={data.body}
                onChange={(content) => {
                  setData((prev) => ({
                    ...prev,
                    body: content,
                  }));
                  handleFieldChange("body");
                  validateErrors();
                  setBlastBody(content);
                }}
                editorRef={editorRef}
              />

              <Box mt={2}>
                <Button
                  type="submit"
                  disabled={disableSubmit}
                  variant="contained"
                  color="primary"
                >
                  Send Eblast
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </form>

      <div id="specific_providers">
        <ReactModal
          open={showSpecificProviderModal}
          onClose={handleSpecificProviderClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography
                  id="modal-title"
                  className="mb-3"
                  variant="h6"
                  component="h2"
                >
                  Choose Your Specific Providers Below
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right" className="pe-3">
                <CancelIcon
                  className="pointer"
                  fontSize="small"
                  onClick={handleSpecificProviderClose}
                />
              </Grid>
            </Grid>

            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                id="specificProvider"
                columns={columnsProviders}
                rows={allSpecificProverderRows}
                getRowId={(allSpecificProverderRows) =>
                  allSpecificProverderRows.id
                }
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  const uniqueUserNamesSet = new Set();

                  newSelection.forEach((index) => {
                    const rowIndex = allSpecificProverderRows.findIndex(
                      (row) => row.id === index
                    );

                    if (rowIndex !== -1) {
                      uniqueUserNamesSet.add(
                        allSpecificProverderRows[rowIndex].email
                      );
                    }
                  });
                  const newSelections = [...uniqueUserNamesSet].join(", ");

                  setSpecificProviderSelectedRows(newSelection);

                  setBlastTo(newSelections);

                  const newData = { ...data };
                  newData[`to`] = newSelections;
                  setTimeout(() => setData(newData), 0);
                }}
                selectionModel={selectedSpecificProviderSelectedRows}
                filterMode="client"
              />
            </div>

            <hr />

            <div className="row">
              <Button variant="contained" onClick={handleSpecificProviderClose}>
                Use & Close
              </Button>
            </div>
          </Box>
        </ReactModal>
      </div>

      <div id="allregionalcontacts_specificregions">
        <ReactModal
          open={showRegionalContactsBySpecificRegionModal}
          onClose={handleRegionalContactsBySpecificRegionClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography
                  id="modal-modal-title"
                  className={"mt-3 mb-3"}
                  variant="h6"
                  component="h2"
                >
                  Choose Regional Contacts Below From Specific Region
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right" className="pe-3">
                <CancelIcon
                  className="pointer"
                  fontSize="small"
                  onClick={handleRegionalContactsBySpecificRegionClose}
                />
              </Grid>
            </Grid>

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Accounting Directors" value="1" />
                    <Tab label="Regional Directors" value="2" />
                  </TabList>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabPanel value="1">
                    <Typography
                      id="modal-title"
                      className="mb-3"
                      variant="h6"
                      component="h2"
                    >
                      Choose And Filter Amongst Accounting Directors
                    </Typography>
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        id="regionalAccountingCountyContactsbyRegion"
                        columns={columnsAccountingDirectors}
                        rows={regionalAcctingContactsFromSpecificRegion}
                        getRowId={(regionalAcctingContactsFromSpecificRegion) =>
                          regionalAcctingContactsFromSpecificRegion.id
                        }
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                          const uniqueEmailsSet = new Set();

                          newSelection.forEach((index) => {
                            const rowIndex =
                              regionalAcctingContactsFromSpecificRegion.findIndex(
                                (row) => row.id === index
                              );

                            if (rowIndex !== -1) {
                              uniqueEmailsSet.add(
                                regionalAcctingContactsFromSpecificRegion[
                                  rowIndex
                                ].email
                              );
                            }
                          });

                          const selectedEmails = [...uniqueEmailsSet].join(
                            ", "
                          );

                          setSpecificProviderSelectedRows(newSelection);
                          setBlastTo(selectedEmails);

                          const newData = { ...data };
                          newData[`to`] = selectedEmails;
                          setTimeout(() => setData(newData), 0);
                        }}
                        selectionModel={selectedSpecificProviderSelectedRows}
                        filterMode="client"
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <Typography
                      id="modal-title"
                      className="mb-3"
                      variant="h6"
                      component="h2"
                    >
                      Choose And Filter Amongst Directors
                    </Typography>

                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        id="regionalDirectoryCountyContactsbyRegion"
                        columns={columnsDirectors}
                        rows={regionalContactsFromSpecificRegion}
                        getRowId={(regionalContactsFromSpecificRegion) =>
                          regionalContactsFromSpecificRegion.id
                        }
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                          const uniqueEmailsSet = new Set();

                          newSelection.forEach((index) => {
                            const rowIndex =
                              regionalContactsFromSpecificRegion.findIndex(
                                (row) => row.id === index
                              );

                            if (rowIndex !== -1) {
                              uniqueEmailsSet.add(
                                regionalContactsFromSpecificRegion[rowIndex]
                                  .email
                              );
                            }
                          });

                          const selectedUserNames = [...uniqueEmailsSet].join(
                            ", "
                          );

                          setSpecificProviderSelectedRows(newSelection);
                          setBlastTo(selectedUserNames);

                          const newData = { ...data };
                          newData[`to`] = selectedUserNames;
                          setTimeout(() => setData(newData), 0);
                        }}
                        selectionModel={selectedSpecificProviderSelectedRows}
                        filterMode="client"
                      />
                    </div>
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>

            <Button
              className="mt-3 mb-3"
              variant="contained"
              onClick={handleRegionalContactsBySpecificRegionClose}
            >
              Use & Close
            </Button>
          </Box>
        </ReactModal>
      </div>

      <div id="regionalcontacts_specificcounty">
        <ReactModal
          open={showAllRegionalContactsBySpecificCountyModal}
          onClose={handleAllRegionalContactsBySpecificCountyClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  List All Regional Contacts By Specific County.
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right" className="pe-3">
                <CancelIcon
                  className="pointer"
                  fontSize="small"
                  onClick={handleAllRegionalContactsBySpecificCountyClose}
                />
              </Grid>
            </Grid>

            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                id="listRegionalSpecificProvider"
                columns={columnsCountyDirectors}
                rows={allRegionalContactsFromSpecificCounty}
                getRowId={(allRegionalContactsFromSpecificCounty) =>
                  allRegionalContactsFromSpecificCounty.id
                }
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  const uniqueEmailsSet = new Set();

                  newSelection.forEach((index) => {
                    const rowIndex =
                      allRegionalContactsFromSpecificCounty.findIndex(
                        (row) => row.id === index
                      );

                    if (rowIndex !== -1) {
                      uniqueEmailsSet.add(
                        allRegionalContactsFromSpecificCounty[rowIndex].email
                      );
                    }
                  });

                  const selectedUserNames = [...uniqueEmailsSet].join(", ");

                  setSpecificProviderSelectedRows(newSelection);
                  setBlastTo(selectedUserNames);

                  const newData = { ...data };
                  newData[`to`] = selectedUserNames;
                  setTimeout(() => setData(newData), 0);
                }}
                selectionModel={selectedSpecificProviderSelectedRows}
                filterMode="client"
              />
            </div>

            <Button
              variant="contained"
              onClick={handleAllRegionalContactsBySpecificCountyClose}
            >
              Use & Close
            </Button>
          </Box>
        </ReactModal>
      </div>

      <div id="allcounty_contactsproviders">
        <ReactModal
          open={showAllCountyContactsModal}
          onClose={handleAllCountyContactsClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography
                  id="modal-modal-title"
                  className="mb-3"
                  variant="h6"
                  component="h2"
                >
                  Viewing List Of All County Contacts
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right" className="pe-3">
                <CancelIcon
                  className="pointer"
                  fontSize="small"
                  onClick={handleAllCountyContactsClose}
                />
              </Grid>
            </Grid>

            <div className="mb-3" style={{ height: 400, width: "100%" }}>
              <DataGrid
                id="listAllCountyContacts"
                columns={columnsAllCountyContacts}
                rows={allCountyContacts}
                getRowId={(allCountyContacts) => allCountyContacts.id}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  const uniqueEmailsSet = new Set();

                  newSelection.forEach((index) => {
                    const rowIndex = allCountyContacts.findIndex(
                      (row) => row.id === index
                    );

                    if (rowIndex !== -1) {
                      uniqueEmailsSet.add(allCountyContacts[rowIndex].email);
                    }
                  });

                  const selectedUserNames = [...uniqueEmailsSet].join(", ");

                  setSpecificProviderSelectedRows(newSelection);
                  setBlastTo(selectedUserNames);

                  const newData = { ...data };
                  newData[`to`] = selectedUserNames;
                  setTimeout(() => setData(newData), 0);
                }}
                selectionModel={selectedSpecificProviderSelectedRows}
                filterMode="client"
              />
            </div>

            <Button variant="contained" onClick={handleAllCountyContactsClose}>
              Use & Close
            </Button>
          </Box>
        </ReactModal>
      </div>

      <div id="allSon_contactsproviders">
        <ReactModal
          open={showAllSonContactsModal}
          onClose={handleAllSonContactsClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  List All {LocalFiscalYear} Son Contacts
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Viewing List Of All Son Contacts
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right" className="pe-3">
                <CancelIcon
                  className="pointer"
                  fontSize="small"
                  onClick={handleAllSonContactsClose}
                />
              </Grid>
            </Grid>

            <div className="mb-3" style={{ height: 400, width: "100%" }}>
              <DataGrid
                container
                columns={columnsAllSonContacts}
                rows={allSonContacts}
                getRowId={(allSonContacts) => allSonContacts.id}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  const uniqueEmailsSet = new Set();

                  newSelection.forEach((index) => {
                    const rowIndex = allSonContacts.findIndex(
                      (row) => row.id === index
                    );

                    if (rowIndex !== -1) {
                      uniqueEmailsSet.add(allSonContacts[rowIndex].email);
                    }
                  });

                  const selectedUserNames = [...uniqueEmailsSet].join(", ");

                  setSpecificProviderSelectedRows(newSelection);
                  setBlastTo(selectedUserNames);

                  const newData = { ...data };
                  newData[`to`] = selectedUserNames;
                  setTimeout(() => setData(newData), 0);
                }}
                selectionModel={selectedSpecificProviderSelectedRows}
                filterMode="client"
              />
            </div>

            <Button variant="contained" onClick={handleAllSonContactsClose}>
              Close
            </Button>
          </Box>
        </ReactModal>
      </div>
    </div>
  );
};
export default EblastProvider;
