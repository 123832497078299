import React from "react";
import ChangeForgotPasswordForm from "../forms/changeForgotPasswordForm";

const ChangePassword = () => {
    return (
      <>
        <ChangeForgotPasswordForm />
      </>
    );
}
 
export default ChangePassword;