import React from 'react';


const Footer = () => {
    return ( <div>
    <footer className="text-right border-top" style={{ background:'#415868', height:'100px'}} align="right" >
        <p className="text-white text-right px-3">Care Solutions &copy; {new Date().getFullYear()}</p>
    </footer>
  </div> );
}
 

export default Footer;


