import React from "react";
import { Link } from "react-router-dom";

const Aps = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">
            PSSF Adoption Promotion &amp; Post-Permanency
          </h3>
          <h5 className="text-center">Adoption and Post-Permanency Support</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">
              Adoption and Post-Permanency Support
            </span>{" "}
            services are designed to encourage and support permanency for
            children through adoption, when adoption is in the best interest of
            the child or to facilitate permanency for children through relative
            guardianship and to prevent disruption or dissolution of those
            relationships. It is common for adoptive families to need support
            and services to prepare for and sustain adoption. Families of
            children who have experienced trauma, neglect, abuse, out-of-home
            care, or institutionalization may require more intensive services as
            children may have ongoing emotional, developmental, physical, or
            behavioral difficulties. Families who adopt children with special
            needs also face additional challenges that may be compounded by the
            child’s past experiences of child abuse and neglect.
            <br />
            <br />
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Initial Family Assessment & Service Plan</li>
              <li>Case Management</li>
              <li>Respite</li>
              <li>Parent Training</li>
            </ol>
            Based on the results of the assessment and case plan goals, service
            plans may also include:
            <ul>
              <li>Behavior Management</li>
              <li>Therapy</li>
              <li>Legal Services</li>
              <li>Supervised pre-adoption visits with adoptive parents</li>
              <li>
                Supervised post-adoption visits with relatives, other
                significant adults or siblings
              </li>
              <li>Support groups</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Aps;
