import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Copyright from "../common/copyright";
import logo from "../../img/header_pssfLogo.png";
import { ForgotPassword } from "../../services/accountservice";
import { Link, Grid } from "@mui/material";

export default function ResetPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "onTouched",
  });

  const onSubmit = async (data) => {
    await ForgotPassword(data).then(() => {
      navigate("/account/resetconfirmation", { state: { confirm: "reset" } });
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="PSSF LOGO" style={{ width: "60%" }} />

        <Typography variant="body1" mt={2}>
          Enter your username or email below and an email will be sent with
          instructions on how to reset your password.
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Username or Email"
            margin="normal"
            fullWidth
            {...register("userName")}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            autoComplete="username"
            inputProps={{ autoComplete: "username" }}
          />
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </LoadingButton>
        </Box>
        <Grid item xs textAlign="right">
          <Link
            href="../"
            className="text-end"
            variant="body2"
            sx={{ mt: 3, mb: 2 }}
          >
            Go Back To Home
          </Link>
        </Grid>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
