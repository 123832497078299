import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import ContactMgmtArea from "../components/admin/fieldcontactmgmtarea";
import ContactMgmtCounty from "../components/admin/contactmgmtcounty";
import ContactMgmtRegion from "../components/admin/contactmgmtregion";
import EblastProvider from "../components/admin/eblastprovider";
import EblastRDR from "../components/admin/eblastRDR";
import EventManagement from "../components/admin/eventmanagement";
import ExecContractMgmt from "../components/admin/execcontractmgmt";
import FileManagement from "../components/admin/filemanagement";
import PrepContracts from "../components/admin/prepcontracts";
import Progress from "../components/admin/progress";
import ProviderManagement from "../components/admin/providermanagement";
import ProviderMessages from "../components/admin/providermessages";
import ProviderSiteVisits from "../components/admin/providersitevisits";
import ProviderStaffChanges from "../components/admin/providerstaffchanges";
import Report from "../components/admin/report";
import ReportManagement from "../components/admin/reportmanagement";
import Systemreportingperiods from "../components/admin/systemreportingperiods";
import SoNHome from "../components/admin/sonhome";
import SoNEdit from "../components/admin/sonedit";
import UserManagement from "../components/admin/usermanagement";
import UserManagementProvider from "../components/provider/usermanagement";
import Agency from "../components/admin/agency";
import authService from "../services/authService";
import AdditionalCounties from "../components/admin/additionalcounties";
import ContactEdit from "../components/admin/contactedit";
import SonFileManagement from "../components/admin/sonfilemanagement";
import ApprovedServices from "../components/admin/approvedservices";
import EditApprovedServices from "../components/admin/editapprovedservices";
import EditApprovedServicesProvider from "../components/provider/editapprovedservices";
import Agencies from "../components/admin/agencies";
import AddAgency from "../components/admin/addAgency";
import Programs from "../components/admin/programs";
import AddProgram from "../components/admin/addProgram";
import AdminDashBoard from "../components/admin/admindashboard";
import AdminSystemReportingPeriods from "../components/admin/systemreportingperiods";
import AdminResourceGuide from "../components/admin/adminresourceguide";
import OpenCases from "../components/provider/currentFY/opencases";
import PendingCases from "../components/provider/currentFY/pendingCases";
import ArchivedCases from "../components/provider/archivedcases";
import NewCase from "../components/provider/currentFY/newcase";
import ProgrammaticReports from "../components/provider/programmaticreports";
import ViewReports from "../components/admin/viewreports";
import EditUnits from "../components/admin/editunits";
import FamilyServiceLog from "../components/provider/currentFY/familyservicelog";
import ErrorPage from "../components/common/errorPage";
import ExitCase from "../components/provider/currentFY/exitcase";
import StaffQualifications from "../components/provider/staffqualifications";
import ProviderHome from "../components/provider/currentFY/providerhome";
import TpsConnector from "../components/admin/tpsconnector";
import SonEditPrograms from "../components/forms/soneditprograms";
import EditEmail from "../components/admin/editEmail";

const AdminRoute = () => {
  const user = authService.getCurrentUser();

  var Role = user.role;

  const currentState = useSelector((state) => state.fiscalYear);

  let LocalFiscalYear = sessionStorage.getItem("LocalFiscalYear");

  if (!user) {
    return <Navigate to="/account/login" replace />;
  }

  if (Role !== "Admin") {
    return <Navigate to="/error" replace />;
  }

  if (!currentState) {
    return null; // currentState is not ready, do not render TpsConnector
  }

  return (
    <Routes>
      <Route path="/admin/fieldcontactmgmtarea" element={<ContactMgmtArea />} />
      <Route path="/admin/contactmgmtcounty" element={<ContactMgmtCounty />} />
      <Route path="/admin/contactmgmtregion" element={<ContactMgmtRegion />} />
      <Route path="/admin/dashboard" element={<AdminDashBoard />} />
      <Route
        path="/admin/systemreportingperiods"
        element={<AdminSystemReportingPeriods />}
      />
      <Route path="/admin/eblastprovider" element={<EblastProvider />} />
      <Route path="/admin/eblastRDR" element={<EblastRDR />} />
      <Route path="/admin/eventmanagement" element={<EventManagement />} />
      <Route
        path="/admin/execcontractmgmt"
        //element={<ExecContractMgmt fiscalYear={currentState.current} />}
        element={<ExecContractMgmt />}
      />
      {currentState.son && currentState.son.value && (
        <Route
          path="/admin/filemanagement"
          element={<FileManagement fiscalYear={currentState.son.value} />}
        />
      )}
      <Route
        path="/admin/exitcase/:id"
        element={
          <ExitCase
            user={user ? user : null}
            fiscalYear={currentState.current}
          />
        }
      ></Route>
      <Route path="/admin/prepcontracts" element={<PrepContracts />} />
      <Route path="/admin/progress" element={<Progress />} />
      <Route path="/admin/add-agency" element={<AddAgency />} />
      <Route path="/admin/add-program/:fy" element={<AddProgram />} />
      <Route
        path="/admin/providermanagement"
        element={<ProviderManagement />}
      />
      <Route path="/admin/providermessages" element={<ProviderMessages />} />
      <Route
        path="/admin/providersitevisits"
        element={<ProviderSiteVisits />}
      />
      <Route
        path="/admin/providerstaffchanges"
        element={<ProviderStaffChanges />}
      />
      <Route
        path="/admin/currentfy/home"
        element={
          <ProviderHome
            user={user ? user : null}
            fiscalYear={currentState.current}
          />
        }
      />
      <Route
        path="/admin/editapprovedservices/"
        element={
          <EditApprovedServicesProvider fiscalYear={currentState.current} />
        }
      />
      <Route path="/admin/report" element={<Report />} />
      <Route
        path="/admin/reportmanagement"
        element={<ReportManagement fiscalYear={currentState.current} />}
      />
      <Route path="/admin/editunits" element={<EditUnits />} />
      <Route path="/admin/editSONEmail" element={<EditEmail />} />
      <Route path="/admin/viewreports" element={<ViewReports />} />
      <Route path="/admin/usermanagement" element={<UserManagement />} />
      <Route
        path="/admin/usermanagement-provider"
        element={<UserManagementProvider />}
      />
      <Route
        path="/admin/sonhome"
        element={<SoNHome fiscalYear={currentState.current} />}
      />
      <Route path="/admin/pendingcases" element={<PendingCases />} />
      <Route path="/admin/archivedcases" element={<ArchivedCases />} />
      <Route
        path="/admin/newcase/:id"
        element={<NewCase user={user ? user : null} key="2" />}
      ></Route>
      <Route
        path="/admin/editopencase/:id"
        element={<NewCase user={user ? user : null} key="2" />}
      />
      <Route path="/admin/opencases" element={<OpenCases />} />
      <Route path="/admin/agency/:id/:fy" element={<Agency />} />
      <Route path="/admin/agencies" element={<Agencies />} />
      <Route path="/admin/programs" element={<Programs />} />
      <Route path="/admin/sonedit/:fy/:id" element={<SoNEdit />} />
      <Route
        path="/admin/soneditprograms/:id/:fy"
        element={<SonEditPrograms />}
      />
      <Route
        path="/admin/additionalcounties/:id"
        element={<AdditionalCounties />}
      />
      <Route path="/admin/contactedit/:id" element={<ContactEdit />} />
      <Route
        path="/admin/sonfilemanagement/:fy/:id"
        element={<SonFileManagement fiscalYear={currentState.current} />}
      />
      <Route
        path="/admin/editcurrentyearservices"
        element={<ApprovedServices fiscalYear={currentState.current} />}
      />
      <Route
        path="/admin/approvedservices"
        //element={<ApprovedServices fiscalYear={currentState.son} />}
        element={<ApprovedServices fiscalYear={currentState.current} />}
      />
      <Route
        path="/admin/editapprovedservices/:id"
        element={<EditApprovedServices />}
      />
      <Route
        path="/admin/resourceguide"
        //element={<AdminResourceGuide fiscalYear={currentState.son} />}
        element={<AdminResourceGuide fiscalYear={currentState.current} />}
      />
      <Route
        path="/admin/tpsconnector"
        element={<TpsConnector fiscalYear={currentState.son} />}
      />
      <Route
        path="/admin/familyservicelog/:id"
        element={
          <FamilyServiceLog
            user={user ? user : null}
            fiscalYear={currentState.current}
          />
        }
      ></Route>
      <Route
        path="/admin/currentfy/newcase/"
        element={<NewCase user={user ? user : null} key="1" />}
      ></Route>
      <Route
        path="/admin/currentfy/newcase/:id"
        element={<NewCase user={user ? user : null} key="2" />}
      ></Route>
      <Route
        path="/admin/currentfy/pendingcases"
        element={<PendingCases user={user ? user : null} />}
      ></Route>
      <Route
        path="/admin/programmaticreports"
        element={<ProgrammaticReports fiscalYear={currentState.current} />}
      />
      <Route
        path="/admin/staffqualifications"
        element={<StaffQualifications />}
      />
      <Route
        path="/admin/systemreportingperiods"
        element={<Systemreportingperiods />}
      />
      <Route path="/error" element={<ErrorPage code={401} />} />
    </Routes>
  );
};

export default AdminRoute;
