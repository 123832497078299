import React from "react";
import { Link } from "react-router-dom";

const Pei = () => {
  return (
    <>
      <div className="row col">
        <strong>
          <h3 className="fw-bold text-center">PSSF Family Support</h3>
          <h5 className="text-center">Prevention and Early Intervention</h5>
        </strong>
        <div className="border border-warning m-2">
          <p className="m-2">
            <span className="fw-bold">Prevention and Early Intervention</span>{" "}
            services are voluntary, short-term, in-home or center-based family
            supports and services offered to help families identify and address
            problematic family issues, strengthen families' protective
            capacities and reduce risk to prevent CPS intervention.
            <br />
            <br />
            Prevention and Early Intervention services often target families
            based on common characteristics, needs or circumstances, such as,
            but not limited to:
            <ul>
              <li>Pregnant and parenting teens</li>
              <li>Victims of demestic violnce and their children</li>
              <li>Families with children who have special needs</li>
              <li>
                Caregivers affected by substance abuse, mental health, and
                cognitive disabilities
              </li>
            </ul>
            <span className="fw-bold">Service Requirements</span>:
            <ol>
              <li>Initial Family Assessment & Service Plan</li>
              <li>Case Management</li>
              <li>
                Parent Education/Parent Training limited to the following
                evidence-based curricula:
              </li>
              <ul>
                <li>Incredible Years</li>
                <li>Strengthening Families</li>
                <li>Triple P: Positive Parenting Program</li>
                <li>STEP: Systemic Training for Effective Parenting</li>
                <li>Nurturing Parenting Program</li>
              </ul>
            </ol>
            Based on needs identified by the Family Assessment and goals
            identified by the family, additional supports and services may be
            necessary to address challenges, gaps or barriers that reduce the
            effectiveness of required services in preventing child abuse and
            neglect for identified target population. These may include:
            <ul>
              <li>Family life skills such as budgeting, housekeeping, etc.</li>
              <li>Transportation</li>
              <li>Mentoring</li>
              <li>Behavior Management</li>
              <li>Substance abuse recovery support</li>
              <li>Job skills/vocational training</li>
              <li>Therapy</li>
              <li>Support groups</li>
              <li>After-school supervision and/or enrichment activities</li>
              <li>Emergency childcare</li>
              <li>Educational supports</li>
            </ul>
            Questions?&nbsp;
            <Link
              to="/contact"
              rel="noopener noreferrer"
              style={{ color: "#779442" }}
            >
              Contact Us
            </Link>
            &nbsp;for more information!
          </p>
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Pei;
