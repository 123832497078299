import React from "react";

const Input = ({ name, label, error, disable=false, ...rest }) => {
  return (
    <div className="form-group">
      <h6 htmlFor={name}>{label}</h6>
      <input {...rest} id={name} name={name} className="form-control " disabled={disable} />
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
};

export default Input;
