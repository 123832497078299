import React, { useEffect, useCallback, useState, useMemo } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  FaBriefcase,
  FaCalendarAlt,
  FaFile,
  FaFileAlt,
  FaUser,
  FaUsers,
  FaWpforms,
  FaFilePdf,
  FaGlobeAmericas,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { checkReportingPeriod } from "../../services/reportingPeriodsService";
import userPrograms from "../../services/userPrograms";
import { getProgramByFiscalYearIdByFYandProgramId } from "../../services/getProgramService";

import moment from "moment";

const AdminNav = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {
  //Time Settings variables

  const [show, setShow] = useState(false);
  const [isMultiple, setIsMultiple] = useState("");
  const [data, setData] = useState([]);

  const currentState = useSelector((state) => state.fiscalYear);
  const [fiscalYear, setFiscalYear] = useState([]);
  const [programs, setPrograms] = useState([]);

  const ReportingPeriod = sessionStorage.getItem("ReportingPeriod");
  if (!ReportingPeriod || ReportingPeriod === -1) {
    /* This is being set but will change through conditions below not to remain -1 */
    sessionStorage.setItem("ReportingPeriod", -1);
  }

  let UserId = sessionStorage.getItem("UserId");

  let Username = sessionStorage.getItem("Username");
  let sProgramId;

  const showNavSubMenu = sessionStorage.getItem("showNavSubMenu");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRemove = () => {
    sessionStorage.clear();
    window.location = "/admin/sonhome";
  };
  const handleSave = () => {
    setShow(false);
    window.location.reload(false);
  };

  function findArrayElementByEdit(array, Id) {
    return array[array.findIndex((x) => x.programId === Id)];
  }

  const now = moment().month("MMMM", true).format("YYYY-MM-DD");
  sessionStorage.setItem("DateTimeNow", now);

  // UserId = sessionStorage.getItem("UserId");
  Username = sessionStorage.getItem("Username");

  sProgramId = sessionStorage.getItem("ProgramId");

  let LocalFiscalYear = sessionStorage.getItem("LocalFiscalYear");

  let periodId = sessionStorage.getItem("periodId");
  if (!periodId === null) {
    periodId = sessionStorage.setItem("periodId", -1);
  }

  const fetchData = useCallback(async () => {
    setFiscalYear(currentState.current);

    /* This function sets the session Year to be used globally. */
    if (isNaN(LocalFiscalYear) || !LocalFiscalYear) {
      sessionStorage.setItem("LocalFiscalYear", currentState.current.value);
      /*   sessionStorage.setItem("LocalFiscalYear", fiscalYear);   */
    }
    if (fiscalYear.value && UserId) {
      const { data } = await userPrograms.getUserProgramsByIdAndFy(
        UserId,
        fiscalYear.value
      );
      if (!sProgramId && data[0]?.programId) {
        sessionStorage.setItem("ProgramId", data[0]?.programId);
      }
      setData(data);
    }

    sProgramId >= 1
      ? setIsMultiple(data.length > 1 ? true : false)
      : setIsMultiple(false);

    if (data.length > 1 && sProgramId == null) setShow(true);

    if (isMultiple === false && sProgramId === null && data.length > 0) {
      sessionStorage.setItem("ProgramId", data[0].programId);
      sessionStorage.setItem(
        "ProgramByFiscalYearId",
        data[0].programByFiscalYearId
      );
    }

    try {
      const { data: checkReportingId } = await checkReportingPeriod();

      if (sessionStorage.getItem("ProgramId") === "2575") {
        sessionStorage.setItem(
          "ReportingPeriod",
          Math.abs(checkReportingId.id)
        );
        sessionStorage.setItem("periodId", Math.abs(checkReportingId.id));
      } else {
        sessionStorage.setItem("ReportingPeriod", checkReportingId.id);
        sessionStorage.setItem("periodId", checkReportingId.id);
      }
      sessionStorage.setItem("beginDate", checkReportingId.beginDate);
      sessionStorage.setItem("endDate", checkReportingId.endDate);
      sessionStorage.setItem(
        "beginDateDisplay",
        checkReportingId.displayBeginDate
      );
      sessionStorage.setItem("endDateDisplay", checkReportingId.displayEndDate);
    } catch (error) {
      console.warn("Sorry No Reporting Period Found");
    }

    // B.C. If theres a session ProgramId present they will pull for the ProgrambyFiscalyearId if not skip
    if (sProgramId && sProgramId !== "undefined") {
      const { data: programsbyId } =
        await getProgramByFiscalYearIdByFYandProgramId(
          LocalFiscalYear,
          sProgramId
        );
      if (programsbyId) {
        sessionStorage.setItem("ProgramByFiscalYearId", programsbyId.id);
      }

      setPrograms(programs);
    }
  }, [
    LocalFiscalYear,
    UserId,
    currentState,
    data,
    fiscalYear.value,
    isMultiple,
    programs,
    sProgramId,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetchData = useMemo(() => fetchData, [UserId, fiscalYear]);

  useEffect(() => {
    memoizedFetchData();
  }, [memoizedFetchData]);

  return (
    <div>
      <ProSidebar
        image={false}
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div className="pssfLoggedintext text-center">
            <img
              className="ourLogo"
              src={require("../../../src/img/pssf-logo-solo.png")}
              alt="Logo"
            />
          </div>
          <div className="text-left text-gray text-center">Admin</div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <SubMenu title="DFCS Contacts" icon={<FaBriefcase />}>
              <MenuItem>
                <Link
                  to="/admin/fieldcontactmgmtarea"
                  rel="noopener noreferrer"
                >
                  Field Contact Management
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/contactmgmtcounty" rel="noopener noreferrer">
                  County Contact Management
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/contactmgmtregion" rel="noopener noreferrer">
                  Regional Contact Management
                </Link>
              </MenuItem>
            </SubMenu>

            <SubMenu title="eBlast" icon={<FaBriefcase />}>
              {/* <MenuItem>
                <Link to="/admin/eblastRDR" rel="noopener noreferrer">
                  Send to RDRs
                </Link>
              </MenuItem> */}
              <MenuItem>
                <Link to="/admin/eblastprovider" rel="noopener noreferrer">
                  Send eBlast
                </Link>
              </MenuItem>
            </SubMenu>

            {/* <SubMenu title="Events" icon={<FaFile />}>
              <MenuItem>
                <Link to="/admin/eventmanagement" rel="noopener noreferrer">
                  Event Management
                </Link>
              </MenuItem>
            </SubMenu> */}

            <SubMenu title="Providers" icon={<FaGlobeAmericas />}>
              {/* <MenuItem>
                <Link to="/admin/providermanagement" rel="noopener noreferrer">
                  Provider Management
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link to="/admin/providermessages" rel="noopener noreferrer">
                  Provider Messages
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link to="/admin/providersitevisits" rel="noopener noreferrer">
                  Provider Site Visits
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  to="/admin/providerstaffchanges"
                  rel="noopener noreferrer"
                >
                  Provider Staff Changes
                </Link>
              </MenuItem> */}
              <MenuItem>
                <Link to="/admin/agencies" rel="noopener noreferrer">
                  Agencies
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/programs" rel="noopener noreferrer">
                  Programs
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/admin/editcurrentyearservices"
                  rel="noopener noreferrer"
                >
                  Edit Current Year Services
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/reportmanagement" rel="noopener noreferrer">
                  Manage Programmatic
                </Link>
              </MenuItem>
            </SubMenu>

            <SubMenu title="Reports" icon={<FaFilePdf />}>
              {/* <MenuItem>
                <Link to="/admin/report" rel="noopener noreferrer">
                  Run a Report
                </Link>
              </MenuItem> */}
              <MenuItem>
                <Link to="/admin/viewreports" rel="noopener noreferrer">
                  View Reports
                </Link>
              </MenuItem>
            </SubMenu>

            <SubMenu title="SoN Management" icon={<FaFileAlt />}>
              <MenuItem>
                <Link to="/admin/sonhome" rel="noopener noreferrer">
                  SoN Home
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/filemanagement" rel="noopener noreferrer">
                  File Management (Contract Prep)
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/execcontractmgmt" rel="noopener noreferrer">
                  Executed Contract Management
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/approvedservices" rel="noopener noreferrer">
                  Approved Services
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/resourceguide" rel="noopener noreferrer">
                  Resource Guide
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/tpsconnector" rel="noopener noreferrer">
                  TPS Connector
                </Link>
              </MenuItem>
              {/* <MenuItem>
                <Link to="/admin/prepcontracts" rel="noopener noreferrer">
                  Prepare Contracts
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link to="/admin/progress" rel="noopener noreferrer">
                  Track Progress
                </Link>
              </MenuItem> */}
            </SubMenu>

            <SubMenu title="Users" icon={<FaFileAlt />}>
              <MenuItem>
                <Link to="/admin/usermanagement" rel="noopener noreferrer">
                  User Management
                </Link>
              </MenuItem>
            </SubMenu>

            <hr />

            <SubMenu title="Admin Functions" icon={<FaFileAlt />}>
              <MenuItem>
                <Link
                  to="/admin/systemreportingperiods"
                  rel="noopener noreferrer"
                >
                  System Reporting Periods
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/editunits" rel="noopener noreferrer">
                  Edit Units
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/editSONEmail" rel="noopener noreferrer">
                  Edit SoN Email
                </Link>
              </MenuItem>
            </SubMenu>

            {}

            {UserId || showNavSubMenu === "true" ? (
              <>
                <hr />
                {Username && UserId ? (
                  <MenuItem>Viewing {Username}</MenuItem>
                ) : (
                  <div className="text-center">
                    Viewing Program #{sProgramId}
                  </div>
                )}

                {UserId ? (
                  <MenuItem>
                    <Link
                      className="text-success"
                      onClick={handleShow}
                      to={window.location.pathname}
                      role="button"
                    >
                      Switch Program
                    </Link>
                  </MenuItem>
                ) : null}

                <MenuItem icon={<FaCalendarAlt />}>
                  <Link to={`/admin/currentfy/home`} state={{ fiscalYear }}>
                    Home
                  </Link>
                </MenuItem>
                <MenuItem icon={<FaWpforms />}>
                  <Link
                    to={`/admin/editapprovedservices/`}
                    state={{ fiscalYear }}
                  >
                    Services
                  </Link>
                </MenuItem>
                <SubMenu title="Reports" icon={<FaFile />}>
                  <MenuItem>
                    <Link
                      to="/admin/programmaticreports"
                      rel="noopener noreferrer"
                    >
                      Programmatic Report
                    </Link>
                  </MenuItem>
                </SubMenu>

                <SubMenu title="Cases" icon={<FaBriefcase />}>
                  <MenuItem>
                    <Link
                      to="/admin/opencases"
                      rel="noopener noreferrer"
                      onClick={sessionStorage.removeItem("CaseId")}
                    >
                      Open Cases
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="/admin/currentfy/pendingcases"
                      rel="noopener noreferrer"
                    >
                      Pending Cases
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="/admin/archivedcases"
                      rel="noopener noreferrer"
                      onClick={sessionStorage.removeItem("CaseId")}
                    >
                      Archived Cases
                    </Link>
                  </MenuItem>
                </SubMenu>
                <MenuItem icon={<FaUsers />}>
                  <Link
                    to="/admin/usermanagement-provider"
                    rel="noopener noreferrer"
                    icon={<FaUsers />}
                  >
                    User Management
                  </Link>
                </MenuItem>
                <MenuItem icon={<FaUsers />}>
                  <Link
                    to="/admin/staffqualifications"
                    rel="noopener noreferrer"
                    icon={<FaUsers />}
                  >
                    Staff Qualifications
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    className="text-danger"
                    onClick={handleRemove}
                    to={window.location.pathname}
                    role="button"
                  >
                    Remove
                  </Link>
                </MenuItem>
              </>
            ) : null}
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <FaUser />
            <Link
              to="/account/logout"
              rel="noopener noreferrer"
              className="text-white"
            >
              Logout
            </Link>
          </div>
        </SidebarFooter>
      </ProSidebar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose a program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="programId">
              <Form.Select
                aria-label="Default select example"
                value={sProgramId || null}
                onChange={async (Id) => {
                  const selectedProgramId = parseInt(Id.target.value);
                  sessionStorage.setItem("ProgramId", selectedProgramId);

                  let kungFuJoe = await findArrayElementByEdit(
                    data,
                    selectedProgramId
                  );
                  sessionStorage.setItem(
                    "ProgramByFiscalYearId",
                    kungFuJoe.programByFiscalYearId
                  );
                }}
              >
                <option>Choose a program</option>
                {data.map((row) => (
                  <option value={row.programId} key={row.programId}>
                    {row.name} - {row.programId}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminNav;
