import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ReactModal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

import {
  fetchSavedEmailList,
  saveToEmailList,
  fetchSingleEmail,
} from "../../services/emailService";
import {
  apifetchEmailBlastSentList,
  apiSetSaveSentEmailToArchived,
  fetchEmailBlastByid,
} from "../../services/emailBlastService";

// Variables to set state on handleChanges
const Name = "Name";
const Type = "Type";

const EblastPreview = (props) => {
  const [openModal, setOpen] = useState(false);
  const [nameToSave, setNameToSave] = useState(false);

  const handleOpen = () => {
    const newErrorForm = { ...props.errorForm };

    var subject = props.data?.subject;

    var body = props.data?.body;

    var toEmail = props?.data?.to;

    var subjectLenth = subject?.length;

    var toEmailLength = toEmail?.length;

    var bodyLength = body?.length;

    if (toEmailLength > 0) {
      newErrorForm["toError"] = 0;
      props.setDisableSubmit(false);
    } else {
      newErrorForm["toError"] = 1;
      props.setDisableSubmit(true);
    }

    if (subjectLenth > 0) {
      newErrorForm["subjectError"] = 0;
      props.setDisableSubmit(false);
    } else {
      newErrorForm["subjectError"] = 1;
      props.setDisableSubmit(true);
    }

    if (bodyLength > 0) {
      newErrorForm["bodyError"] = 0;
      props.setDisableSubmit(false);
    } else {
      newErrorForm["bodyError"] = 1;
      props.setDisableSubmit(true);
    }

    props.setErrorForm(newErrorForm);

    if (subjectLenth > 0 && toEmailLength > 0) {
      // Main Action
      setOpen(true);
    }
  };

  const handleClose = () => setOpen(false);

  const saveModal = async (event) => {
    event.preventDefault();

    props.data[Name] = nameToSave;
    props.data[Type] = 0;
    saveToEmailList(props.data);
    setOpen(false);
    await fetchSavedEmailBlast();
  };

  const handleNameChange = async (event) => {
    setNameToSave(event.target.value);
  };

  const handleChangeBlastSentAlready = async (event) => {
    const selectedValue = event?.target?.value;

    props.setEmailListSentConfig(selectedValue);
    props.setEmailListSavedConfig("");

    if (selectedValue) {
      props.setArchiveBtnConfig(false);

      try {
        var { data: row } = await fetchEmailBlastByid(selectedValue);

        if (row) {
          if (row?.to) {
            props.setBlastTo(row.to);
          }
          if (row?.subject) {
            props.setBlastSubject(row.subject);
          }

          if (row?.body) {
            props.setBlastBody(row.body);
            props.editorRef.current.setContent(row.body);
          }

          const newData = { ...row };
          props.setData(newData);
          props.setTestStatus("1");
          props.setDisableSubmit(false);
        }
      } catch (error) {
        // Handle any errors that occurred during the request
        console.warn("Error fetching saved email list from blast:", error);
      }
    } else {
      props.setArchiveBtnConfig(true);
    }
  };

  const handleChangeEmailList = async (event) => {
    const selectedValue = event?.target?.value;

    props.setEmailListSavedConfig(selectedValue);
    props.setEmailListSentConfig("");

    if (selectedValue) {
      props.setArchiveBtnConfig(true);
      props.setDisableSubmit(false);
      try {
        const response = await fetchSingleEmail(selectedValue);

        if (response?.data?.to) {
          props.setBlastTo(response.data.to);
        }

        if (response?.data?.subject) {
          props.setBlastSubject(response?.data?.subject);
        }

        if (response?.data?.body) {
          props.setBlastBody(response?.data?.body);
          props.editorRef.current.setContent(response?.data?.body);
        }

        const newData = { ...response };

        props.setData(newData);

        props.setDisableSubmit(false);

        props.setTestStatus("1");

        const newErrorForm = { ...props.errorForm };
        newErrorForm["toError"] = 0;
        newErrorForm["subjectError"] = 0;
        newErrorForm["bodyError"] = 0;

        props.setErrorForm(newErrorForm);
        props.validateErrors();
      } catch (error) {
        // Handle any errors that occurred during the request
        console.warn("Error fetching saved email list:", error);
      }
    } else {
      props.setArchiveBtnConfig(true);
      props.setSaveToBtnConfig(false);
      props.validateErrors();
    }
  };

  const handleArchiveEmail = async () => {
    try {
      await apiSetSaveSentEmailToArchived(props.emailListSentConfig);
      props.setEmailListSentConfig("");
      fetchEmailListSent();
      props.validateErrors();
    } catch (error) {
      console.error("setSaveSentEmailToArch error", error);
    }
  };

  async function fetchSavedEmailBlast() {
    var { data: rows } = await fetchSavedEmailList();
    const filteredRows = rows.filter((row) => row.type === 0);
    props.setSavedEmailRows(filteredRows);
  }

  async function fetchEmailListSent() {
    var { data: rows } = await apifetchEmailBlastSentList();

    props.setSentEmailRows(rows);
  }

  useEffect(() => {
    fetchEmailListSent();

    fetchSavedEmailBlast();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div className="position-relative container-fluid mt-5 mb-4">
      <div className="row">
        <label className="col-12 col-form-label fw-bold position-relative">
          <h6 className="fw-bold left">Eblast Preview</h6>
        </label>
      </div>

      <hr />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>
              <h6>Previous Emails Sent</h6>
              <hr />

              <FormControl fullWidth>
                <InputLabel id="previous-emails-select-label">
                  Emails Sent Already
                </InputLabel>
                <Select
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                  labelId="previous-emails-select-label"
                  id="previousSentEmails"
                  name="previousSentEmails"
                  fullWidth
                  value={props?.emailListSentConfig}
                  label="Emails"
                  onChange={handleChangeBlastSentAlready}
                >
                  <MenuItem value={``}>Choose From Sent Already</MenuItem>
                  {props.sentEmailRows.map((row) => (
                    <MenuItem key={row.id} value={String(row.id)}>
                      #{row.id}
                      {")"}{" "}
                      {row?.name
                        ? row.name
                        : row?.subject
                        ? row.subject
                        : "Subject Missing..."}{" "}
                      {row.date}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <h6>Previously Saved</h6>
              <hr />
              <FormControl fullWidth>
                <InputLabel id="previous-emailsSaved-select-label">
                  Previously Saved
                </InputLabel>
                <Select
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                  labelId="previous-emailsSaved-select-label"
                  id="previousSavedEmails"
                  name="previousSavedEmails"
                  fullWidth
                  value={props.emailListSavedConfig}
                  label="Email List Saved"
                  onChange={handleChangeEmailList}
                >
                  <MenuItem value={``}>Choose From Saved Email List</MenuItem>
                  {props.savedEmailRows.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      #{row.id}
                      {")"}{" "}
                      <span className="fw-bold me-1 ms-1">
                        {row?.name ? row.name : "Name Missing..."}
                      </span>{" "}
                      - {row?.subject ? row?.subject : "Subject Missing"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <hr />

      <Stack
        spacing={2}
        direction="row"
        sx={{ justifyContent: "space-between" }}
      >
        <Button
          disabled={props.archiveBtnConfig}
          onClick={handleArchiveEmail}
          variant="contained"
        >
          Archive Email
        </Button>

        <Button
          disabled={props.saveToBtnConfig}
          variant="contained"
          id="savedTobtn"
          onClick={handleOpen}
        >
          Save This 'TO' List
        </Button>
      </Stack>

      <div className="mb-3 row">
        <label className="col-12 col-form-label fw-bold position-relative mb-3">
          <span className="left">Preview TO:</span>
        </label>
        <div className="col fst-italic">
          <div
            className="d-flex flex-column overflow-scroll overflow-hidden"
            style={{ height: "300px" }}
          >
            {(() => {
              if (props.blastTo.length === 0) {
                return <p>Empty List...</p>;
              } else {
                const emails = props.blastTo.split(", ");
                const rows = [];
                const col = 2; //change this if you want a different number of columns
                for (let i = 0; i < emails.length; i += col) {
                  const row = emails.slice(i, i + col);
                  rows.push(
                    <tr key={i}>
                      {row.map((email, index) => (
                        <td key={index}>{email}</td>
                      ))}
                    </tr>
                  );
                }
                return (
                  <table className="table">
                    <tbody>{rows}</tbody>
                  </table>
                );
              }
            })()}
          </div>
        </div>
      </div>

      <div id="specific_providers">
        <ReactModal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <InputLabel
              className="mb-4 col-6 fw-bold"
              htmlFor="outlinedNameoflist"
            >
              Create A New Name For This Email List To Save
            </InputLabel>
            <div className="mb-4 col-12">
              <TextField
                fullWidth
                required
                id="outlinedNameoflist"
                label="Create A List Name..."
                defaultValue=""
                onChange={handleNameChange}
              />
            </div>

            <hr />

            <div
              className="position-relative mb-5 overflow-scroll fs-6"
              style={{ height: 400, width: "100%" }}
            >
              {props?.blastTo ? (
                <pre>{props.blastTo}</pre>
              ) : (
                <div className="text-danger">"No Emails Available To Save"</div>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <Button
                variant="contained"
                className="bg-danger"
                onClick={handleClose}
              >
                Cancel
              </Button>

              {props?.blastTo ? (
                <Button
                  variant="contained"
                  className="bg-success"
                  onClick={saveModal}
                >
                  Create New List
                </Button>
              ) : (
                ""
              )}
            </div>
          </Box>
        </ReactModal>
      </div>
    </div>
  );
};

export default EblastPreview;
