import React, { useEffect, useState, useCallback } from "react";
import { Paper, Box } from "@mui/material";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";
import { getProgramsByIdandFiscalYear } from "../../services/getProgramService";
import {
  saveContract,
  saveContractMgmtData,
  getContractMgmtByFyAndId,
} from "../../services/getSonServices";
import { checkFileExists, downloadFile } from "../../services/downloadService";

const CurrentContract = ({ user, soNFiscalYear }) => {
  const [program, setProgram] = useState("");
  const [agency, setAgency] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileSaved, setFileSaved] = useState("");
  const [contract, setContract] = useState("");
  const [uContractExists, setUContractExists] = useState(false);
  const [eContractExists, setEContractExists] = useState(false);
  const [sContractExists, setSContractExists] = useState(false);
  const [sContractFilename, setSContractFilename] = useState("");
  const [SonsID] = useState(user.name);

  const fy = soNFiscalYear;

  const programId = user ? user.ProgramId : null;
  const sonId = user.name;

  const fetchData = useCallback(async () => {
    if (programId !== null) {
      const { data: program } = await getProgramsByIdandFiscalYear(
        programId,
        fy
      );

      setProgram(program);

      const { data: agency } = await getAgencyByFiscalYearandId(
        fy,
        program.agencyId
      );
      setAgency(agency);

      const { data: contract } = await getContractMgmtByFyAndId(fy, programId);
      setContract(contract);

      var executedContractUrl = `executedContracts~${fy}~${program.id}`;
      var unsignedContractUrl = `unsignedContracts~${fy}~${program.id}`;
      var signedContractUrl = `signedContracts~${fy}~${program.id}`;

      const { data: uContractExists } = await checkFileExists(
        unsignedContractUrl,
        `${sonId}_unsigned.pdf`
      );
      setUContractExists(uContractExists);

      const { data: eContractExists } = await checkFileExists(
        executedContractUrl,
        `${sonId}_executed.pdf`
      );

      setEContractExists(eContractExists);

      const { data: sContractExists, filename: sContractFilename } =
        await checkFileExists(signedContractUrl, `${sonId}_signed.pdf`);
      setSContractFilename(sContractFilename);

      setSContractExists(sContractExists);
    }
    setFileName(`${user.name}_upload.`);
  }, [fy, programId, sonId, user.name]);

  useEffect(() => {
    if (fy) fetchData();
  }, [programId, fy, fetchData, fileSaved]);

  function onFileChange(event) {
    setFile(event.target.files[0]);
  }

  async function downloadUnsigned() {
    downloadFile(
      `unsignedContracts~${fy}~${program.id}`,
      `${sonId}_unsigned.pdf`,
      `${sonId}_file1`
    );
  }

  async function onFileUpload() {
    try {
      const fileExtension = file.name.split(".").pop();
      const formData = new FormData();
      formData.append("FormFile", file); //upload and signedContract are synonymous
      formData.append("Name", fileName + fileExtension); //
      formData.append("LocalFiscalYear", fy);
      formData.append("sonId", sonId);

      saveContract(programId, "signedContracts", formData);

      contract.dateProviderSigned = new Date();

      await saveContractMgmtData(fy, programId, contract);

      setFileSaved("1");
      setTimeout(() => setFileSaved(""), 2000);
    } catch (error) {
      console.error(
        "🚀 ~ file: contractprep.jsx ~ line 44 ~ onFileUpload ~ error",
        error
      );
    }
  }

  const paperStyle = {
    padding: 20,
    height: "95%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  return (
    <Paper elevation={10} style={paperStyle}>
      <Box>
        <h1>FY{fy} Contract</h1>
        <div className="row offset-md-2">
          <div className="col-lg-4 fw-bold">Agency:</div>
          <div className="col-lg-6">{agency.name}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4 fw-bold">Program:</div>
          <div className="col-lg-6">{program.name}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4 fw-bold">Program ID:</div>
          <div className="col-lg-6">{program.id}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4 fw-bold">SoN:</div>
          <div className="col-lg-6">{SonsID}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4 fw-bold">Model:</div>
          <div className="col-lg-6">
            {program.serviceModel &&
              `${program.serviceModel.serviceModelCategoryAbrv}/${program.serviceModel.modelAbrv}`}
          </div>
        </div>
        <br />
        <div className="py-2">
          <strong>The FFY2025 contract process is being updated.</strong>
        </div>
        <div className="py-2">
          Please complete the{" "}
          <span style={{ backgroundColor: "yellow" }}>Contract Prep form</span>{" "}
          and upload it below so that your contract documents can be routed to
          the appropriate individual(s).
        </div>
        <div className="py-2">
          <strong style={{ color: "red" }}>Reminder:</strong> Verify the
          individuals listed on the Agency’s Secretary of State Registration
          (non-profits) or Authorization (public entities) before completing the
          form.
        </div>
        <div className="py-2">
          If you have questions, please email contractsupport@pssfnet.com and
          reference the SoN#.
        </div>
        <div className="py-2">Upload completed form:</div>
        <div className="py-2">
          <input type="file" onChange={(event) => onFileChange(event)} />
          <div className="py-3">
            {fileSaved ? (
              <button className="btn btn-success">File Saved!</button>
            ) : (
              <button className="btn btn-primary" onClick={onFileUpload}>
                Upload
              </button>
            )}
          </div>
          {sContractExists ? (
            <div className="py-2">
              <div>
                <strong>
                  <u>SoN Uploads</u>
                </strong>
              </div>
              <div>
                <button
                  className="btn btn-link"
                  onClick={() =>
                    downloadFile(
                      `signedContracts~${fy}~${program.id}`,
                      `${sonId}_signed.pdf`,
                      `${sonId}_upload`
                    )
                  }
                >
                  {`${sonId}_upload.` + sContractFilename.split(".").pop()}
                </button>
              </div>
            </div>
          ) : null}
          <strong>
            <u>Admin Uploads</u>
          </strong>
          <div className="py-2">
            {uContractExists ? (
              <button
                className="btn btn-link"
                onClick={() => downloadUnsigned()}
              >
                File 1
              </button>
            ) : (
              <div>File 1 TBA</div>
            )}
          </div>
          <div>
            {eContractExists ? (
              <button
                className="btn btn-link"
                onClick={() =>
                  downloadFile(
                    `executedContracts~${fy}~${program.id}`,
                    `${sonId}_executed.pdf`,
                    `${sonId}_file2`
                  )
                }
              >
                File 2
              </button>
            ) : (
              <div>File 2 TBA</div>
            )}
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default CurrentContract;
