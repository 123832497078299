import http from "./httpService";

const apiEndpoint = "/FamilyServiceLog";

export function getCommunityResources() {
  return http.get(`${apiEndpoint}/communityResources`);
}

export function deleteFamilyServiceLog(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export function getFamilyServiceLog(caseId, reportingPeriodId) {
  return http.get(`${apiEndpoint}/${caseId}/${reportingPeriodId}`);
}

export function getFamilyServiceLogCount(
  caseId,
  reportingPeriodId,
  FamilyServiceLogId
) {
  return http.get(
    `${apiEndpoint}/GetFamilyServiceLogCount/${caseId}/${reportingPeriodId}/${FamilyServiceLogId}`
  );
}

export function getFamilyServiceLogByProgramByFiscalYear(
  programId,
  reportingPeriodId
) {
  return http.get(
    `${apiEndpoint}/getFslByProgramByProgramId/${programId}/${reportingPeriodId}`
  );
}

export function getFslsByFiscalYearAndReportingPeriodId(
  reportingPeriodId,
  fiscalYear
) {
  return http.get(
    `${apiEndpoint}/fslsByFiscalYearAndReportingPeriodId/${reportingPeriodId}/${fiscalYear}`
  );
}

export function postFamilyServiceLog(
  caseId,
  reportingPeriod,
  familyServiceLog
) {
  const body = { ...familyServiceLog };
  body.reportingPeriodId = reportingPeriod;
  //window.location = `/provider/currentfy/opencases`
  return http.post(`${apiEndpoint}/${caseId}`, body);
}

export function updateFamilyServiceLog(
  caseId,
  reportingPeriod,
  familyServiceLog
) {
  const body = { ...familyServiceLog };
  body.reportingPeriodId = reportingPeriod;
  //window.location = `/provider/currentfy/opencases`
  return http.post(`${apiEndpoint}/update/${caseId}`, body);
}

export function updateFamilyServiceLogProgReportComplete(
  programId,
  reportingPeriodId
) {
  return http.post(
    `${apiEndpoint}/progReportComplete/${programId}/${reportingPeriodId}`
  );
}

export function getUnitTable(
  periodId,
  programId,
  serviceCode,
  letterCode,
  caseId
) {
  var requestURL = `${apiEndpoint}/UnitsTable?periodId=${periodId}&programId=${programId}&serviceCode=${serviceCode}`;

  if (letterCode) requestURL += `&letterCode=${letterCode}`;
  if (caseId) requestURL += `&caseId=${caseId}`;
  return http.get(requestURL);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFamilyServiceLogByProgramByFiscalYear,
  getFslsByFiscalYearAndReportingPeriodId,
  postFamilyServiceLog,
  updateFamilyServiceLog,
  updateFamilyServiceLogProgReportComplete,
};
