import React, { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import {
  getFamilyStatuses,
  getReferralReasons,
  getReferralSources,
} from "../../../services/casesService";

const CaseFormD = ({
  user,
  data,
  setData,
  getData,
  caseId,
  handleNext,
  handleBack,
}) => {
  const [pc, setPc] = useState({});
  const [refReasons, setRefReasons] = useState({});
  const [refSources, setRefSources] = useState({});
  const [familyStatuses, setFamilyStatuses] = useState({});
  const [validated, setValidated] = useState(false);
  const [hasReferralError, setHasReferralError] = useState(false);

  async function fetchData() {
    var pc = data.caregivers && data.caregivers.find((caregiver) => caregiver.isPrimary);
    setPc(pc);

    var {data: reasons} = await getReferralReasons();
    setRefReasons(reasons);

    var {data: sources} = await getReferralSources();
    setRefSources(sources);

    var {data: statuses} = await getFamilyStatuses();
    setFamilyStatuses(statuses);
  };

  const handleChange = (event) => {
    const name = event.target.id;
    const newData = { ...data };
    newData[name] = event.target.value;
    setData(newData);
  }

  const handleCheck = (event) => {
    const newData = { ...data };

    const item = {caseId: parseInt(caseId), referralReasonId: parseInt(event.target.value)}

    if(!newData.caseReferralReasons) newData["caseReferralReasons"] = [];

    let indexToRemove = -1;
    for(var i = 0; i < newData.caseReferralReasons.length; i++)
    {
      if(newData.caseReferralReasons[i].referralReasonId === item.referralReasonId){
        indexToRemove = i;
      }
    }

    indexToRemove > -1 ?
      newData.caseReferralReasons.splice(indexToRemove, 1)
    :
      newData.caseReferralReasons.push(item)
    setData(newData);
  }

  const checkReferralReasons = (id) => {
    for(var i = 0; i < data.caseReferralReasons.length; i++)
    {
      if(data.caseReferralReasons[i].referralReasonId === id) return i;
    }
    return -1;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    let hasError = false;
    setHasReferralError(false);
    if(!data.caseReferralReasons || data.caseReferralReasons.length < 1) {
      setHasReferralError(true);
      hasError = true
    }

    if (
      form.checkValidity() === false ||
      hasError === true
    ) {
      event.preventDefault();
      event.stopPropagation();
    } else {   
      handleNext();
    }
    setValidated(true);
  }

  useEffect(() => {
    fetchData();
  }, [data]);

  return ( 
    <>
      <div className="py-4">
        <h5>
          Primary Caregiver:{" "}
          {pc.firstName}{" "}
          {pc.lastName}
        </h5>
        <h6>Case Id: {caseId}</h6>
      </div>

      <h6>Intake Section D. Referral Information</h6>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="row">
          <Form.Group className="col-md-6 mb-2" controlId="referralSource">
            <Form.Label className='fw-bold'>Referral Source</Form.Label>
            <Form.Select onChange={handleChange} required value={data.referralSource}>
              <option value="">Open this select menu</option>
              {refSources.length > 1 && refSources.map((refSources) => (
                <option key={refSources.id} value={refSources.id}>
                  {refSources.source}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="col-md-6 mb-2" controlId="familyStatus">
            <Form.Label className='fw-bold'>Family Status</Form.Label>
            <Form.Select onChange={handleChange} required value={data.familyStatus}>
              <option value="">Open this select menu</option>
              {familyStatuses.length > 1 && familyStatuses.map((familyStatus) => (
                <option key={familyStatus.id} value={familyStatus.id}>
                  {familyStatus.status}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        {/* <Form.Group className="col-md-6 mb-2" controlId="shinesCaseId">
          <Form.Label className='fw-bold'>Shines Case Id</Form.Label>
          <Form.Control
            type="text"
            className="form-control-sm"
            defaultValue={data.shinesCaseId}
            onChange={handleChange}
            autoFocus
            required
          />
        </Form.Group> */}


        <FormControl required error={hasReferralError}>        
          <Form.Label className='fw-bold'>Reason(s) for referral.</Form.Label>
          {hasReferralError && (
            <FormHelperText>Please select at least one reason for referral.</FormHelperText>
          )}
          <div className="row">
            {refReasons.length > 0 && refReasons.map( (option) => (
              <Form.Group className="col-md-4 mb-2" key={option.id} controlId={option.reason}>   
                <Form.Check        
                  type={"checkbox"}
                  //id={option.source}
                  value={option.id}
                  name={option.reason}
                  label={option.reason}
                  onChange={handleCheck}
                  checked={ data.caseReferralReasons && data.caseReferralReasons.length > 0 && checkReferralReasons(option.id) > -1 || false }
                />     
              </Form.Group>    
            ))}
          </div>
        </FormControl>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit">
            {"Save & Continue"}
          </Button>
        </Box>
      </Form>
    </>
  );
}
 
export default CaseFormD;