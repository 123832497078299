import http from "./httpService";

const apiEndpoint = "/NewServices";

export function getNewServices() {
  return http.get(apiEndpoint);
}

export function getNewServiceByIdUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getNewServiceById(id) {
  return http.get(getNewServiceByIdUrl(id));
}

export function getNewServicesByProgramId(programId, fiscalYear) {
  return http.get(`${apiEndpoint}/servicesByProgramId/${programId}/${fiscalYear}`)
}

export function getSerivceOptions(){
  return http.get(`${apiEndpoint}/getServiceOptions`)
}

export function getServiceTypes(){
  return http.get(`${apiEndpoint}/getServiceTypes`)
}

export function getServiceCodeList() {
  return http.get(`${apiEndpoint}/NewServiceCodeList`);
}

export function insertNewService(programId, fiscalYear, service) {
  const body = { ...service };
  return http.post(`${apiEndpoint}/${programId}/${fiscalYear}`, body)
}

export function saveNewServicesById(service) {
  if (service.id) {
    const body = { ...service };
    delete body.id;
    return http.post(getNewServiceByIdUrl(service.id), body);
  }
}

export function deleteServiceById(id){
  return http.post(`${apiEndpoint}/delete/${id}`);
}
