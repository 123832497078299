import http from "./httpService";

const apiEndpoint = "/TPSBridge";

export function getTPSProjects() {
  return http.get(`${apiEndpoint}/getprojects`);
}

export function getSonUsers(id) {
  return http.get(`${apiEndpoint}/${id}`);
}


export function linkTPSBridge(tpsData) {
  const body = { ...tpsData };
  return http.post(`${apiEndpoint}`, body);
}
