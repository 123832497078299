import React, { useState, useEffect } from "react";
import CustomDataTable from "../common/customDataTable";
import { AiOutlineDownload } from "react-icons/ai";
import {
  getCasesByProgramId,
  updateCaseStatusById,
} from "../../services/casesService";
import { getReport } from "../../services/reportService";
import auth from "../../services/authService";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button"; //using this because of a bug with @material-ui/core's button with the table
import {  CircularProgress, Stack } from "@mui/material";

const ArchivedCasesTable = () => {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "Intake ID",
      sort: "asc",
    },
  ]);

  const [isLoading, setLoading] = useState(true);
  const programId = sessionStorage.getItem("ProgramId");
  const pfyId = sessionStorage.getItem("ProgramByFiscalYearId");
  const user = auth.getCurrentUser();

  const handleDownload = async (id) => {
    await getReport("NewPssf_ArchivedCase", [
      {
        key: "Case_id",
        value: id,
      },
    ]);
  };

  const handleMove = async (id) => {
    await updateCaseStatusById(id, "Open");
    // Update the count or trigger data refetch if needed
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data: rows } = await getCasesByProgramId(programId);
        rows.forEach((row) => {
          row.dateOfIntake = moment(row.dateOfIntake).format("MM-DD-YYYY");
          row.dateOfExit = moment(row.dateOfExit).format("MM-DD-YYYY");

          // Find the oldest child and combine first and last names
          if (row.children.length > 0) {
            let oldestChild = row.children[0];

            for (const child of row.children) {
              if (
                new Date(child.dateOfBirth) < new Date(oldestChild.dateOfBirth)
              ) {
                oldestChild = child;
              }
            }

            row.oldestChildFullName = `${oldestChild.lastName}, ${oldestChild.firstName}`;
          } else {
            row.oldestChildFullName = "";
          }

          row.primaryCaregiverFullName =
            row?.caregivers
              .filter((caregiver) => caregiver.isPrimary)
              .map(
                (caregiver) => `${caregiver.lastName}, ${caregiver.firstName}`
              )[0] || "";
        });

        const filteredRows = rows.filter((row) => row.status === "Archived");
        setRows(filteredRows);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [programId, pfyId]);

  // Define columns for DataTable
  const columns = [
    {
      headerName: "Intake ID",
      field: "id",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 0.6,
      renderCell: (params) =>
        user.role === "Admin" ? (
          <Link to={`/admin/newcase/${params.row.id}`}>{params.row.id}</Link>
        ) : (
          <span>{params.row.id}</span>
        ),
    },
    {
      headerName: "Intake Date",
      field: "dateOfIntake",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      headerName: "Exit Date",
      field: "dateOfExit",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    user.role === "Admin" && {
      field: "exitData",
      headerName: "Exit Data",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Link to={`/admin/exitcase/${params.row.id}`}>Exit Data</Link>
      ),
    },
    {
      headerName: "Primary Caregiver",
      field: "primaryCaregiverFullName",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      headerName: "Oldest Child",
      field: "oldestChildFullName",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      headerName: "Download",
      field: "download",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <AiOutlineDownload
          role="button"
          size={24}
          onClick={() => handleDownload(params.row.id)}
        />
      ),
    },
    user.role === "Admin" && {
      headerName: "-",
      field: "opencase",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Button variant="outlined" onClick={() => handleMove(params.row.id)}>
          Move to Open Cases
        </Button>
      ),
    },
  ];

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          marginTop: 150,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <CircularProgress />
        </Stack>
      </div>
    );
  }

  return (
    <div style={{ width: "99%" }} className="col">
      <CustomDataTable
        columns={columns}
        rows={rows}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        rowHeight={90}
        pageSizeOptions={[5, 10, 25]}
      />
    </div>
  );
};

export default ArchivedCasesTable;
