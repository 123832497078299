import React, { useEffect, useState } from "react";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";
import { getProgramsByIdandFiscalYear } from "../../services/getProgramService";
import {
  getProgramPendingProposalFiles,
  saveProvisionalFile,
  getCommentsByProgram,
  getTpsProviderDocuments,
} from "../../services/getSonServices";
import { Paper, Box } from "@mui/material";
import Select from "../common/select";

const UploadDocs = ({ user, soNFiscalYear }) => {
  const [program, setProgram] = useState("");
  const [agency, setAgency] = useState("");
  const [missingFiles, setMissingFiles] = useState("");
  const [pendingFiles, setPendingFiles] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileSaved, setFileSaved] = useState("");
  const [comments, setComments] = useState("");
  const [tpsDocs, setTpsDocs] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [SonsID] = useState(user.name);
  const fy = soNFiscalYear;
  const programId = user ? user.ProgramId : null;
  async function fetchData() {
    if (programId !== null) {
      const { data: program } = await getProgramsByIdandFiscalYear(
        programId,
        fy
      );
      setProgram(program);

      const { data: agency } = await getAgencyByFiscalYearandId(
        fy,
        program.agencyId
      );
      setAgency(agency);

      const { data: pendingFiles } = await getProgramPendingProposalFiles(
        programId,
        fy
      );
      setPendingFiles(pendingFiles);

      const { data: comments } = await getCommentsByProgram(programId);
      setComments(comments);

      let { data: tpsDocs } = await getTpsProviderDocuments(
        program.programByFiscalYear[0].tpsProviderId
      );
      //tpsDocs.map((doc) => (doc["id"] = doc["documentId"]));
      setTpsDocs(tpsDocs);

      let missingFiles = [];
      tpsDocs.map((doc) =>
        doc.adminProvisional ? missingFiles.push(doc.documentFile) : <></>
      );

      //switch(agency.entityType){
      //  case 1: missingFiles.splice(missingFiles.indexOf("Authorization.pdf"), 1);
      //  //In theory there is supposed to be a case for 2 as well, but I am not sure what needs doing.
      //}
      setMissingFiles(missingFiles);

      setFileName(missingFiles[0]);
    }
  }

  const paperStyle = {
    padding: 20,
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  useEffect(() => {
    if (fy) fetchData();
  }, [programId, fy]);

  async function onFileUpload() {
    try {
      const formData = new FormData();
      formData.append("FormFile", file);
      formData.append("Name", fileName);

      if (file.length === 0) {
        setErrMsg("Please choose a document to upload.");
      } else {
        await saveProvisionalFile(programId, fy, formData);
        await fetchData();
      }

      setFileSaved("1");
      setTimeout(() => setFileSaved(""), 2000);

      await fetchData();
    } catch (error) {
      console.log(
        "🚀 ~ file: contractprep.jsx ~ line 44 ~ onFileUpload ~ error",
        error
      );
    }
  }

  function onFileChange(event) {
    var fileSplit = event.target.files[0].name.split(".");
    var requiredSplit = fileName.split(".");
    var fileExtension = fileSplit[fileSplit.length - 1].substring(0, 3);
    var reqExtension = requiredSplit[requiredSplit.length - 1].substring(0, 3);

    if (fileExtension === reqExtension) {
      setFile(event.target.files[0]);
      setErrMsg("");
    } else {
      setFile("");
      setErrMsg("Please choose a document with the correct file extension.");
    }
  }

  const options = missingFiles
    ? missingFiles.map((file, index) => ({ id: index, name: file }))
    : [{ id: 0, name: "" }];

  //function onFileNameChange(event){
  //  setFileName(options[event.target.value]);
  //}

  //const options = [{}];

  return (
    <Paper elevation={10} style={paperStyle}>
      <Box>
        <h1>Contract Prep</h1>
        <div className="row offset-md-2">
          <div className="col-lg-4">Agency:</div>
          <div className="col-lg-6">{agency.name}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Program:</div>
          <div className="col-lg-6">{program.name}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Program ID:</div>
          <div className="col-lg-6">{program.id}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">SoN:</div>
          <div className="col-lg-6">{SonsID}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Model:</div>
          <div className="col-lg-6">
            {program.serviceModel &&
              `${program.serviceModel.serviceModelCategoryAbrv}/${program.serviceModel.modelAbrv}`}
          </div>
        </div>
        <br />
        <div className="offset-md-1">
          The following documents have been marked provisional by the TA Team
          and need your attention. Please see note for each document to take
          action.
          <br />
          {/* All documents must be resolved by XX/XX/XX. */}
          <br />
          Email contractsupport@pssfnet.com with questions.
        </div>
        <br />
        {/* <div className="row offset-md-1">
          Comments from Admin<br/>
          {comments && comments.map( comment => (
            comment.type == 1 ? <div key={comment.id}>{comment.comment} - {comment.dateCreated.split('T')[0]}</div> : ""
          ))}
        </div>
        <br/><br/> */}
        <div className="row offset-md-1">
          <div className="col-lg-6">
            Provisional Files
            {missingFiles &&
              missingFiles.map((file) => (
                <div key={file}>
                  <div className="text-danger">{file}</div>
                  <div>
                    {tpsDocs &&
                      tpsDocs.filter((doc) => doc.documentFile === file)[0] &&
                      tpsDocs.filter((doc) => doc.documentFile === file)[0]
                        .notes &&
                      tpsDocs.filter((doc) => doc.documentFile === file)[0]
                        .notes.length > 0 &&
                      " - " +
                        tpsDocs.filter((doc) => doc.documentFile === file)[0]
                          .notes}
                  </div>
                </div>
              ))}
          </div>
          <div className="col-lg-6">
            Files Pending Review
            {pendingFiles &&
              pendingFiles.map((file) => (
                <div key={file} className="text-success">
                  {file}
                </div>
              ))}
          </div>
        </div>
        <div className="py-5">
          <div className="py-3">
            <Select
              name={"fileName"}
              // value={fileName}
              label={"File to Replace"}
              options={options}
              onChange={(event) =>
                setFileName(options[event.target.value].name)
              }
            />
          </div>
          <div className="py-3 text-danger">{errMsg}</div>
          <input type="file" onChange={(event) => onFileChange(event)} />
          <div className="py-3">
            {fileSaved ? (
              <button className="btn btn-success">File Saved!</button>
            ) : (
              <button className="btn btn-primary" onClick={onFileUpload}>
                Upload
              </button>
            )}
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default UploadDocs;
