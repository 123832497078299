import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../../services/authService";
import Copyright from "../common/copyright";

const theme = createTheme();

const LoginForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateLogin = (ex) => {
    let err = ex.response.data.reasonPhrase;
    let errors = [];
    if (ex.response.status > 0) {
      errors.password = err;
      errors.email = undefined;
    } else {
      errors.username = err.Email !== undefined ? err.Email[0] : undefined;
      errors.password =
        err.Password !== undefined ? err.Password[0] : undefined;
    }
    if(err === undefined){
      err = 'Invalid Email Or Username'
    }
    setError(err);
    console.log('err: ',err);
    console.log('errors: ', errors);
  };

  const HandleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      
        await auth.login(data.get("username"), data.get("password"));
        
        const user = auth.getCurrentUser();

        var Role = user.role;

        if (Role === "Admin") navigate("/admin/sonhome");

        if (Role === "Provider" && user.isFirstLogin === "True") {

          navigate("/account/changepassword");
        
        } else if (Role === "Provider" && user.isFirstLogin === "False") {
         
          navigate("/provider/currentfy/home");

        }

        if (Role === "Son") navigate("/provider/awardinformation");

        if (Role === "PowerUser") navigate("/admin/sonhome");

      } catch (error) {

          console.log('error', error)
          validateLogin(error);

        }
  };


  useEffect(() => {

  }, [error]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            type="image"
            alt="Pssf Network Promoting Safe & Stable Familes Logo"
            src={require("../../img/header_pssfLogo.png")}
            style={{
              border: 0,
              position: "relative",
              top: 30,
            }}
            className="img-fluid mb-5"
          />

          <Box component="form" onSubmit={HandleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              className="w-100"
              id="username iuname"
              label="Username or Email"
              type="text"
              name="username"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              className="w-100 ipasw"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="block-eye-toggler">
                    <IconButton
                      className="eye-toggler"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {error && <Alert severity="error">{error}</Alert>}
            <Button
              type="submit"
              className="w-100"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container  justifyContent="space-between">
              <Grid item xs textAlign="left">
                <Link href="/account/forgotpassword" variant="body2">
                  Forgot Password
                </Link>
              </Grid>
              <Grid item xs textAlign="right">
                <Link
                  href="../"
                  className="text-end"
                  variant="body2"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Go Back To Home
                </Link>
              </Grid>
            </Grid>

            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default LoginForm;
