import React, { useEffect, useState } from "react";
import { Paper, Box } from "@mui/material";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";
import { getProgramsByIdandFiscalYear } from "../../services/getProgramService";
import { getCounties } from "../../services/countiesService";
import SonResourceGuideForm from "../forms/sonResourceGuideForm";

const ResourceGuide = ({ user, soNFiscalYear }) => {
  const [program, setProgram] = useState("");
  const [agency, setAgency] = useState("");
  const [counties, setCounties] = useState("");
  const fy = soNFiscalYear;
  const programId = user ? user.ProgramId : null;
  const [SonsID] = useState(user.name);
  //const [rgContact, setRgContact ] = useState("");

  async function fetchData() {
    if (programId !== null) {
      const { data: program } = await getProgramsByIdandFiscalYear(
        programId,
        fy
      );
      setProgram(program);

      const { data: agency } = await getAgencyByFiscalYearandId(
        fy,
        program.agencyId
      );
      setAgency(agency);

      const { data: counties } = await getCounties();
      setCounties(counties);
    }
  }

  function findCounty(id) {
    for (var i = 0; i < counties.length; i++) {
      if (counties[i].id === id) return counties[i].name;
    }
  }

  useEffect(async () => {
    if (fy) await fetchData();
  }, [programId, fy]);

  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  return (
    <Paper elevation={10} style={paperStyle}>
      <Box>
        <h1>Resource Guide</h1>
        <div className="row offset-md-2">
          <div className="col-lg-4">Agency:</div>
          <div className="col-lg-6">{agency.name}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Agency Mailing Address:</div>
          <div className="col-lg-6">{agency.address}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Program:</div>
          <div className="col-lg-6">{program.name}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Program Address:</div>
          <div className="col-lg-6">{program.address}&nbsp;&nbsp;</div>
          {/**{program.confidentialAddress ? <span className="text-danger"> (Confidential Address)</span> : <></>} */}
        </div>
        {/* <div className="row offset-md-2">
          <div className="col-lg-4">Home County:</div>
          <div className="col-lg-6">
            {program &&
              counties &&
              program.programByFiscalYear &&
              findCounty(program.programByFiscalYear[0].homeCounty)}
          </div>
        </div> */}
        <div className="row offset-md-2">
          <div className="col-lg-4">Primary (Fiscal) County:</div>
          <div className="col-lg-6">
            {program &&
              counties &&
              program.programByFiscalYear &&
              findCounty(program.programByFiscalYear[0].fiscalCounty)}
          </div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">SoN:</div>
          <div className="col-lg-6">{SonsID}</div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Model:</div>
          <div className="col-lg-6">
            {program.serviceModel &&
              `${program.serviceModel.serviceModelCategoryAbrv}/${program.serviceModel.modelAbrv}`}
          </div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Additional Counties Served:</div>
          <div className="col-lg-6">
            {program &&
              program.countiesServed &&
              program.countiesServed.map((county, i, arr) => (
                <span key={county.id}>
                  {county.name}
                  {arr.length - 1 === i ? <></> : <>,&nbsp;</>}
                </span>
              ))}
          </div>
        </div>
        <div className="row offset-md-2">
          <div className="col-lg-4">Agency Web Address:</div>
          <div className="col-lg-6">{agency.webAddress}</div>
        </div>
        {program && (
          <SonResourceGuideForm
            programId={program.id}
            fiscalYear={fy}
            counties={counties}
            program={program}
            agency={agency}
          />
        )}
      </Box>
    </Paper>
  );
};

export default ResourceGuide;
