import React from 'react';
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import AgenciesWithFiscalYearTable from '../Tables/agenciesWithYearTable';



function Agencies() {
    return (
      <>
        <h1>Agencies</h1>
        <div className="mb-4  mt-3">
          <Link
            className="text-success"
            to={`/admin/add-agency`}
          >
          
            <FaPlusCircle className="me-2" />
            Add Agency
          
          </Link>
        </div>
        <AgenciesWithFiscalYearTable />
      </>
    );
}

export default Agencies;