import React, { Component } from "react";

import ContentCard from "../common/contentCard";

class PssfGa extends Component {
  onTabChange = (e, ElementId) => {
    let i, x, tablinks;
    const tab = document.getElementById(ElementId);

    x = document.getElementsByClassName("category");
    for (i = 0; i < x.length; i++) {
      x[i].classList.add("d-none");
    }

    tab.classList.remove("d-none");

    tablinks = document.getElementsByClassName("tab-link");
    for (i = 0; i < x.length; i++) {
      tablinks[i].classList.remove("tabbeddiv-custom-blue");
      tablinks[i].classList.remove("tabbeddiv-custom");
    }
    document.getElementById("tab" + e).classList.add("tabbeddiv-custom-blue");
  };
  render() {
    return (
     <div className="row justify-content-center">
        <div className="col-10">
          <div className="row mb-5">
            <div className="col-6">
              <div className="col mt-3 mb-5">
                <h2 className="text-center fw-bold">
                  Network of Service and Supports
                  <br /> for Families
                </h2>
              </div>
              <p>
                Families at greatest risk of entering Georgia's child protection
                system often have complex and interrelated problems such as poverty,
                unemployment, domestic violence, substance abuse and teen pregnancy
                which increase family stressors, impair family functioning and place
                children in situations where they may be unsafe. Children have the
                greatest chance for a safe and stable home environment when their
                parents and caregivers are knowledgeable of and have access to
                essential supports and services in their own communities.
                <br />
                <br />
                Georgia's Promoting Safe and Stable Families program reflects the
                ongoing commitment of the Division of Family and Children Services
                to ensuring that families, communities and organizations have access
                to the highest quality services and resources needed to help
                children and youth grow into healthy, thriving and contributing
                members of our society.
                <br />
                <br />
                Learn more about our Services, Families, and History.
              </p>
            </div>
            <div className="col-6 mt-3">
              <img
                src={require("../../img/pssfga_mom.png")}
                style={{ width: "100%" }}
                alt="Mother Helping Daughter With Writing Color Letters In Notebook"
              />
            </div>
            <div className="row">
              <div className="col-4">
                <ContentCard
                  header={"Our Vision"}
                  body={
                    "Every child will be secure from abuse and neglect and become a healthy, literate, productive and economically self-sustaining citizen within a nurturing, caring, permanent home environment."
                  }
                />
              </div>
              <div className="col-4">
                <ContentCard
                  header={"Our Mission"}
                  body={
                    "Strengthen Georgia families and remove barriers to child safety, permanency and well-being."
                  }
                />
              </div>
              <div className="col-4">
                <ContentCard
                  header={"Our Goals"}
                  body={
                    "To achieve better outcomes for children and families by recruiting, training and sustaining and coordinated network of community-based, family-centered, outcomes-driven organizations."
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col mt-5">
                <img
                  src={require("../../img/2021 PSSF Network Infographic 032222-part-1.png")}
                  style={{ width: "100%" }}
                  alt="PSSF Network Infographic"
                />
              </div>
            </div>
            <div className="col mt-4">
              <strong>
                <h2>PSSF Program Categories</h2>
              </strong>
              <p>
                Promoting Safe and Stable Families is designed to build capacity
                between state, local child welfare agencies and community-based
                family service agencies to ensure that children and their families
                have access to comprehensive, high quality prevention and
                intervention services in each of four broad categories: family
                support, family preservation, family reunification, and adoption
                promotion.
              </p>
            </div>
            <nav
              className="navbar navbar-expand-lg  tabbedNavbar-custom"
              style={{ padding: 0 }}
            >
              <div className="collapse navbar-collapse">
                <div
                  className="navbar-nav nav-fill w-100"
                  style={{ height: "60px" }}
                >
                  <div
                    id="tab1"
                    className="nav-item nav-link tab-link border-end border-white col-3 tabbeddiv-custom-blue"
                    onClick={() => this.onTabChange(1, "support")}
                  >
                    Family Support
                  </div>
                  <div
                    id="tab2"
                    className="nav-item nav-link tab-link border-end border-white col-3 tabbeddiv-custom"
                    onClick={() => this.onTabChange(2, "preservation")}
                  >
                    Family Preservation
                  </div>
                  <div
                    id="tab3"
                    className="nav-item nav-link tab-link border-end border-white col-3 tabbeddiv-custom"
                    onClick={() => this.onTabChange(3, "reunification")}
                  >
                    Family Reunification
                  </div>
                  <div
                    id="tab4"
                    className="nav-item nav-link tab-link col-3 tabbeddiv-custom"
                    onClick={() => this.onTabChange(4, "permanency")}
                  >
                    Adoption Promotion and Post-Permanency
                  </div>
                </div>
              </div>
            </nav>
            <div
              id="support"
              className="card category p-2"
              style={{ backgroundColor: "#ede7de" }}
            >
              <h6>Family Support Services:</h6> Primarily community-based preventive
              activities designed to promote parental competencies and behaviors
              that will increase the ability of families to successfully nurture
              their children; enable families to use other resources and
              opportunities available in the community; create supportive networks
              to enhance child-rearing abilities of parents and help compensate for
              the increased social isolation and vulnerability of families; and
              strengthen parental relationships and promote healthy marriages.
              <br />
              <br />
              <h6>Goal:</h6> Prevent and reduce the risk of child maltreatment by
              promoting well-being of entire family.
              <br />
              <br />
              <h6>Objectives:</h6>
              <ul>
                <li>
                  To increase parental understanding of child development, increase
                  parental capacity to care for children, reduce risk factors that
                  threaten child safety.
                </li>
                <li>
                  To increase family access to and utilization of informal and
                  formal community supports.
                </li>
              </ul>
              <h6>Target Population:</h6> Families not known to DFCS; families that
              were screened out, referred for services, assigned to Family Support,
              or had an unsubstantiated investigation; or families with prior CPS
              history (closed but referred for follow-up supports/services).
            </div>
            <div
              id="preservation"
              className="card category p-2 d-none"
              style={{ backgroundColor: "#ede7de" }}
            >
              <h6>Family Preservation Services:</h6> Provided to families that come
              to the attention of child welfare because of child abuse or neglect,
              child or parent behavioral challenges, or serious parent-child
              conflict so that families at risk or in crisis can be preserved and
              children safely maintained in their homes when families receive
              intensive support and therapeutic services to improve family
              functioning and stability, as an alternative to placement in
              out-of-home care.
              <br />
              <br />
              <h6>Goal:</h6>Preserve families, ensure child safety and prevent
              repeat maltreatment.
              <br />
              <br />
              <h6>Objectives:</h6>
              Improve family/caregiver/child functioning and maintain children their
              homes.
              <br />
              <br />
              <h6>Target Population:</h6> Families that have had DFCS involvement -
              Open Family Preservation or Foster Care, or relatives caring for
              children who are not their own, to prevent placement of children in
              foster care.
            </div>
            <div
              id="reunification"
              className="card category p-2 d-none"
              style={{ backgroundColor: "#ede7de" }}
            >
              <h6>Family Reunification Services:</h6>Provided to a child who is
              removed from home and placed in a foster care setting and to the
              parents or primary caregiver. These services are available only for 15
              months from the date the child enters foster care. Family
              reunification services facilitate the safe and timely reunification of
              the child with the family, when that is in the best interest of the
              child, and help sustain permanency.
              <br />
              <br />
              <h6>Goal:</h6>Promote and sustain permanency for children and their
              families and to prevent repeat maltreatment.
              <br />
              <br />
              <h6>Objectives:</h6>
              Improve family functioning and stability.
              <br />
              <br />
              <h6>Target Population:</h6>Families whose children have been removed
              from their care and are in foster care, or other temporary placement.
            </div>
            <div
              id="permanency"
              className="card category p-2 d-none"
              style={{ backgroundColor: "#ede7de" }}
            >
              <h6>Adoption Promotion and Post-Permanency Support Services:</h6>
              Designed to encourage more adoptions of children out of the foster
              care system when adoptions are in the best interests of the children.
              They include pre- and post- adoption services designed to expedite the
              adoption process and support families to prevent disruption or
              dissolution of adoptions. Services are also provided to relatives
              pursuing guardianship of children. Permanency support services are
              provided to youth for whom adoption by age 18 is not assured to assist
              them in the transition to independent living and provide connections
              to other caring adults.
              <br />
              <br />
              <h6>Goal:</h6>Promote and sustain permanency and community connections
              for children and families.
              <br />
              <br />
              <h6>Objectives:</h6>
              To support adoptive and guardian families and youth in foster care
              with their transitions.
              <br />
              <br />
              <h6>Target Population:</h6>Families of children exiting foster care or
              relative care to adoption or guardianship, and youth transitioning to
              independent living.
            </div>
            <div className="col-12">
              <strong align="center">
                <h3>Summary and Regional Profiles</h3>
              </strong>
              <p>
                Each year, summary profiles are compiled on PSSF network activities.
                These activities are included in the state's Annual Progress and
                Services Report (APSR). FFY2022 profiles will be completed and
                posted in April 2023. Click on the links below for profiles from
                previous years.
              </p>
            </div>
            <div className="row mb-4">
              <div className="col-3">
                <div
                  className="card text-center p-3"
                  style={{
                    backgroundColor: "#ede7de",
                    border: "1px solid #415868",
                  }}
                >
                  <strong>
                    <h6>2020:</h6>
                  </strong>
                  <a
                    href={require("../../docs/Infographic2020final_.pdf")}
                    target="_blank"
                    className="text-warning"
                    rel="noopener noreferrer"
                  >
                    2020 Statewide Summary Profile
                  </a>
                </div>
              </div>
              <div className="col-3 ">
                <div
                  className="card text-center p-3"
                  style={{
                    backgroundColor: "#ede7de",
                    border: "1px solid #415868",
                  }}
                >
                  <strong>
                    <h6>2019:</h6>
                  </strong>
                  <a
                    href={require("../../docs/pssfprofile_2019.pdf")}
                    target="_blank"
                    className="text-warning"
                    rel="noopener noreferrer"
                  >
                    2019 Statewide Summary Profile
                  </a>
                </div>
              </div>
              <div className="col-3 ">
                <div
                  className="card text-center p-3"
                  style={{
                    backgroundColor: "#ede7de",
                    border: "1px solid #415868",
                  }}
                >
                  <strong>
                    <h6>2018:</h6>
                  </strong>
                  <a
                    href={require("../../docs/pssfprofile_2018.pdf")}
                    target="_blank"
                    className="text-warning"
                    rel="noopener noreferrer"
                  >
                    2018 Statewide Summary Profile
                  </a>
                </div>
              </div>
              <div className="col-3 ">
                <div
                  className="card text-center p-3"
                  style={{
                    backgroundColor: "#ede7de",
                    border: "1px solid #415868",
                  }}
                >
                  <strong>
                    <h6>2017:</h6>
                  </strong>
                  <a
                    href={require("../../docs/pssfprofile_2017.pdf")}
                    target="_blank"
                    className="text-warning"
                    rel="noopener noreferrer"
                  >
                    2017 Statewide Summary Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
    );
  }
}

export default PssfGa;
