import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  FormHelperText,
  Alert,
} from "@mui/material";
import { CreateUser } from "../../services/accountservice";
import { postUserProgramsById } from "../../services/userPrograms";

const CreateUserModal = ({ show, onHide, fetchData, providers }) => {
  const [data, setData] = useState({});
  const [validated, setValidated] = useState(false);
  const [showPrograms, setShowPrograms] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showUsername, setShowUsername] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    special: false,
  });
  const [hasRoleError, setHasRoleError] = useState(false);
  const [hasProgramError, setHasProgramError] = useState(false);
  const [hasSingleProgramError, setHasSingleProgramError] = useState(false);
  const [selectedNewPrograms, setSelectedNewPrograms] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const availableRoles = ["Son", "Provider", "PowerUser", "Admin"];
  const availableTypes = ["", "ED", "PC", "Other"];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    if (newPassword !== "") {
      const validation = validatePassword(newPassword);
      setPasswordValidation(validation);
    } else {
      setPasswordValidation({
        minLength: false,
        lowerCase: false,
        upperCase: false,
        number: false,
        special: false,
      });
    }
  };

  const validatePassword = (password) => {
    return {
      minLength: password.length >= 6,
      lowerCase: /[a-z]/.test(password),
      upperCase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password),
    };
  };

  const handleChangeCreate = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setData((prevState) => ({ ...prevState, role: selectedRole }));
    if (selectedRole === "Provider" || selectedRole === "Son") {
      setShowPrograms(true);
      setShowType(selectedRole === "Provider");
      setShowUsername(selectedRole === "Son");
      if (providers.length > 0) {
        if (selectedRole === "Son") setSelectedNewPrograms("");
        else setSelectedNewPrograms([]);
        setData((prevState) => ({
          ...prevState,
          programId: null,
        }));
      }
    } else {
      setShowPrograms(false);
      setShowType(false);
      setShowUsername(false);
      setSelectedNewPrograms([]);
    }
  };

  const handleChangeSelectNewPrograms = (event) => {
    const { value } = event.target;
    if (data.role === "Son") {
      const selectedProgram =
        typeof value === "string" ? value : String(value);
      setSelectedNewPrograms([selectedProgram]);
      setData((prevState) => ({
        ...prevState,
        programId: selectedProgram,
      }));
    } else {
      const newSelectedPrograms =
        typeof value === "string" ? value.split(",") : value;
      setSelectedNewPrograms(newSelectedPrograms);
      setData((prevState) => ({
        ...prevState,
        programId: null,
      }));
    }
  };

  const handleSubmitCreate = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setErrorMessage("");
    if (
      form.checkValidity() === false ||
      !data.role ||
      (showPrograms && selectedNewPrograms.length === 0) ||
      (data.role === "Provider" && !data.type) ||
      !data.password ||
      !passwordValidation.minLength ||
      !passwordValidation.lowerCase ||
      !passwordValidation.upperCase ||
      !passwordValidation.number ||
      !passwordValidation.special
    ) {
      setValidated(true);
      if (!data.role) setHasRoleError(true);
      if (showPrograms && selectedNewPrograms.length === 0)
        setHasProgramError(true);
      if (data.role === "Provider" && !data.type)
        setHasSingleProgramError(true);
      return;
    }
    try {
      if (data.role !== "Son") {
        data.userName = data.email;
      }
      await CreateUser(data);
      if (data.role === "Provider") {
        await postUserProgramsById(data.userName, selectedNewPrograms);
      }
      fetchData();
      onHide();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const backendErrors = error.response.data.errors;
      const errorMessagesArray = Array.isArray(backendErrors)
        ? backendErrors
        : Object.values(backendErrors).flat();
      setErrorMessage(`Error creating user: ${errorMessagesArray.join(", ")}`);
      } else {
        setErrorMessage("Error creating user.");
      }
      console.error("Error creating user:", error);
    }
  };

  const getValidationTextColor = (isValid) =>
    isValid ? "text-success" : "text-danger";

  return (
    <Modal
      show={show}
      onHide={() => {onHide();
        setData({});
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        )}
        <Form noValidate validated={validated} onSubmit={handleSubmitCreate}>
          <Form.Group className="mb-3" controlId="validationName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="name"
              value={data.name || ""}
              onChange={handleChangeCreate}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter email"
              name="email"
              value={data.email || ""}
              onChange={handleChangeCreate}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Enter password"
              name="password"
              value={data.password || ""}
              onChange={(e) => {
                handlePasswordChange(e);
                handleChangeCreate(e);
              }}
              isInvalid={
                data.password &&
                (!passwordValidation.minLength ||
                  !passwordValidation.lowerCase ||
                  !passwordValidation.upperCase ||
                  !passwordValidation.number ||
                  !passwordValidation.special)
              }
            />
            <Form.Control.Feedback type="invalid">
              Please provide a password that meets the criteria.
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              Password must be{" "}
              <span
                className={getValidationTextColor(passwordValidation.minLength)}
              >
                at least 6 characters long
              </span>
              ,{" "}
              <span
                className={getValidationTextColor(passwordValidation.lowerCase)}
              >
                include a lowercase letter
              </span>
              ,{" "}
              <span
                className={getValidationTextColor(passwordValidation.upperCase)}
              >
                an uppercase letter
              </span>
              ,{" "}
              <span
                className={getValidationTextColor(passwordValidation.number)}
              >
                a number
              </span>
              , and{" "}
              <span
                className={getValidationTextColor(passwordValidation.special)}
              >
                a special character
              </span>
              .
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationPhoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter phone number"
              name="phoneNumber"
              value={data.phoneNumber || ""}
              onChange={handleChangeCreate}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a phone number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationRole">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              name="role"
              value={data.role || ""}
              onChange={handleRoleChange}
              isInvalid={hasRoleError}
            >
              <option value="">Select role</option>
              {availableRoles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a role.
            </Form.Control.Feedback>
          </Form.Group>
          {showType && (
            <Form.Group className="mb-3" controlId="validationType">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={data.type || ""}
                onChange={handleChangeCreate}
                isInvalid={hasSingleProgramError || data.type === ""}
              >
                <option value="">Select type</option>
                {availableTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a type.
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {showPrograms && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="programs-label">Program(s)</InputLabel>
              <Select
                labelId="programs-label"
                id="programs"
                multiple={data.role !== "Son"}
                value={selectedNewPrograms}
                onChange={handleChangeSelectNewPrograms}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Programs" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                error={hasProgramError}
              >
                {providers.map((provider) => (
                  <MenuItem key={provider.id} value={String(provider.id)}>
                    {provider.id} - {provider.name}
                  </MenuItem>
                ))}
              </Select>
              {hasProgramError && (
                <FormHelperText error>
                  Please select at least one program.
                </FormHelperText>
              )}
            </FormControl>
          )}
          {showUsername && (
            <Form.Group className="mb-3" controlId="validationUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                required={data.role === "Son"}
                type="text"
                placeholder="Enter username"
                name="userName"
                value={data.userName || ""}
                onChange={handleChangeCreate}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid username.
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmitCreate}>
          Create User
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUserModal;
