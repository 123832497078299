import Copyright from "../common/copyright";
import { Container, CssBaseline, Box, Typography, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function Results() {
  const location = useLocation();

  const { confirm } = location.state || {};
  let message = "";
  let mlink;

  if (confirm === "reset") {
    message = "Forgot password Email Sent.";
    mlink = (
      <Typography variant="body2" mt={2}>
        If there is an account on file you will recieve an email. Please check
        your email and follow instructions, {" "}
        <Link href="/account/login" variant="body2">
          {"Click here to return to log in"}
        </Link>
      </Typography>
    );
  } else {
    message = "Reset password confirmation."; 
    mlink = (
      <Typography variant="body2" mt={2}>
        Your password has been reset,{" "}
        <Link href="/account/login" variant="body2">
          {"Click here to log in"}
        </Link>
      </Typography>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          {message}
        </Typography>
        {mlink}
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
