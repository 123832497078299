import React from "react";
import { Link } from "react-router-dom";

const Featured = () => {
  return (
    <>
      <div className="row col mt-3 text-center">
        <strong>
          <h3>Featured Service Provider</h3>
          <h6>Community Partnership of Elbert County</h6>
        </strong>
      </div>
      <div className="row">
        <div className="col-4">
          <img
            src={require("../../img/featured_TaneshaPose.jpg")}
            style={{ width: "100%" }}
            className="card-img-top"
          />
        </div>
        <div className="col-8 p-3">
          <div className="border border-warning p-4">
            <p>
              <strong>The Community Partnership of Elbert County</strong>
              celebrates family successes every month. The Jones' family is one
              such success story.
              <br />
              <br />
              Tani enrolled in PAT services when she became pregnant as a senior
              in high school. Her son, Kai, was born in November 2011 and Tani
              graduated from high school in May of 2012. After graduation, she
              worked at a local grocery store and enrolled in nursing classes.
              While attending college, she also gained experience by working in
              home health care and at an urgent care facility. In December of
              2016, Tani graduated from nursing school and is now employed at
              the local hospital.
              <br />
              <br />
              Although she had a lot on her plate, Tani was fully committed to
              the PAT program, determined to provide a safe and stable home for
              her son. No longer enrolled in the PAT program Tani and Kai have
              stayed in touch with their home visitor and are thankful for her
              support over the years.
            </p>
          </div>
          <Link
            to="/pssfyear"
            rel="noopener noreferrer"
            className="btn btn-warning mt-3 text-white"
          >
            <font>Return to PSSF This Year</font>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Featured;
