import React, { useState, useEffect } from "react";
import {
  getResourceGuideByFiscalYear,
  getResourceGuideByFiscalYearandId,
  updateResourceGuide,
} from "../../services/resourceGuideService";
import CustomDataTable from "../common/customDataTable";
import { Box, CircularProgress, Stack } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ModeEditOutlineSharpIcon from "@mui/icons-material/ModeEditOutlineSharp";

const AdminResourceGuide = () => {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [sortModel, setSortModel] = React.useState([
    {
      field: "sonId",
      sort: "asc",
    },
  ]);
  const [isLoading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});

  const LocalFiscalYear = sessionStorage.getItem("LocalFiscalYear");

  const defaultData = {
    sonId: "",
    requestedEdits: "",
    programDescription: "",
    additionalServicesOffered: "",
    // Add other properties here with default values as needed
  };

  // Define columns for CustomDataTable
  const columns = [
    {
      field: "edit",
      headerName: "",
      renderCell: (params) => (
        <div
          className="pointer"
          onClick={() => handleShow(params.row)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: 60,
          }}
        >
          <ModeEditOutlineSharpIcon />
          <span>Edit</span>
        </div>
      ),
    },
    {
      field: "sonId",
      headerName: "SoN",
      flex: 1,
    },
    {
      field: "agencyName",
      headerName: "Agency",
      flex: 2,
    },
    {
      field: "programName",
      headerName: "Program",
      flex: 2,
    },
    {
      field: "programStatus",
      headerName: "Program Status",
      flex: 1,
      renderCell: (params) =>
        params.value ? <div>Active</div> : <div>Inactive</div>,
    },
    {
      field: "completedBy",
      headerName: "Completed By",
      flex: 1,
      valueFormatter: (value) => value || "Not Completed",
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 1,
      valueFormatter: (value) =>
        value ? new Date(value).toLocaleDateString() : "Not Completed",
    },
    {
      field: "dateApprovedByAdmin",
      headerName: "Date Approved By Admin",
      flex: 1,
      valueFormatter: (value) =>
        value ? new Date(value).toLocaleDateString() : "Not Completed",
    },
  ];

  // Fetch data when component mounts or LocalFiscalYear changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (LocalFiscalYear) {
        const { data: rows } = await getResourceGuideByFiscalYear(
          LocalFiscalYear
        );
        setRows(rows.filter((row) => row.totalServices > 0));
      }
      setLoading(false);
    };

    fetchData();
  }, [LocalFiscalYear]);

  // Fetch data by ID
  const fetchDataById = async (id) => {
    try {
      // Fetch the data
      const { data } = await getResourceGuideByFiscalYearandId(
        LocalFiscalYear,
        id
      );

      // Check if data is available in the response
      if (data) {
        return data;
      } else {
        // Use the default data object if data is not found
        return defaultData;
      }
    } catch (error) {
      // Handle any errors that occur during the fetch
      console.error("Error fetching data:", error);
      // Use the default data object in case of error
      return defaultData;
    }
  };

  // Modal event handlers
  const handleClose = () => {
    setShow(false);
    setData({});
  };

  const handleShow = async (row) => {
    try {
      // Fetch data and set state
      const fetchedData = await fetchDataById(row.id);

      // If the data fetch was successful, update state with data and sonId
      setData({ ...fetchedData, sonId: row.sonId });

      // Open the modal
      setShow(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value !== null ? value : "",
    }));
  };

  const saveData = async (approved) => {
    await updateResourceGuide(data, data.id, approved);
    handleClose();
  };

  const handleApprove = async () => {
    await saveData(true);
  };

  const handleSave = async () => {
    await saveData(false);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          marginTop: 150,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <CircularProgress />
        </Stack>
      </div>
    );
  }
  return (
    <Box sx={{ width: "98%" }}>
      <h1 className="py-2">Current Resource Guides</h1>
      <span className="badge bg-success mb-4 p-3">
        Viewing System Fiscal Year: {LocalFiscalYear}
      </span>
      <CustomDataTable
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Resource Guide - {data.sonId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="requestedEdits">
              <Form.Label>Requested Edits:</Form.Label>
              <Form.Control
                as="textarea"
                className="form-control-sm"
                value={data.requestedEdits !== null ? data.requestedEdits : ""}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group controlId="programDescription">
              <Form.Label>Program Description:</Form.Label>
              <Form.Control
                as="textarea"
                className="form-control-sm"
                value={
                  data.programDescription !== null
                    ? data.programDescription
                    : ""
                }
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="additionalServicesOffered">
              <Form.Label>Additional Services Offered:</Form.Label>
              <Form.Control
                as="textarea"
                className="form-control-sm"
                value={
                  data.additionalServicesOffered !== null
                    ? data.additionalServicesOffered
                    : ""
                }
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
          <Button variant="success" onClick={handleApprove}>
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
};

export default AdminResourceGuide;
